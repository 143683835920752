import { KatalisTranslationRoutes } from '../translations.interface';

const messages: KatalisTranslationRoutes = {
  routes: {
    PainelDeControle: 'Painel de Controle',
    // auth
    AuthAtivarConta: 'Ativar conta',
    AuthEsqueciMinhaSenha: 'Esqueci minha senha',
    AuthLogin: 'Entrar no sistema',
    AuthRenovarSenha: 'Renovar senha',
    // catalisadores-pecas
    CatalisadoresPecasImportar: 'Importar peças de catalisadores',
    CatalisadoresPecasIndex: 'Catalisadores - Peças',
    // catalisadores-po
    CatalisadoresPoCalculadora: 'Catalisadores - Pó',
    CatalisadoresPoIndex: 'Catalisadores - Pó',
    // cotacoes
    CotacoesIndex: 'Cotações',
    // erro
    ErroNaoEncontrado: 'Não encontrado',
    // familias
    FamiliasIndex: 'Famílias',
    // funcionalidades
    FuncionalidadesIndex: 'Funcionalidades',
    FuncionalidadesPermissoesAlterar: 'Alterar Funcionalidade',
    //permissoes
    PermissoesIndex: 'Permissões',
    // grupos
    GruposIndex: 'Grupos',
    GruposPermissoes: 'Permissões',
    GruposVer: 'Grupo',
    // lojas
    LojasCadastro: 'Cadastro Loja',
    LojasEdicao: 'Alterar Loja',
    LojasIndex: 'Lojas',
    LojasVer: 'Loja',
    // pedidos
    PedidosIndex: 'Pedidos',
    PedidosRecebimento: 'Recebimento',
    PedidosRecebimentoIndex: 'Recebimento',
    // placas
    PlacasIndex: 'Placas Eletrônicas',
    // usuarios
    UsuariosCadastroFuncionario: 'Cadastro Funcionário',
    UsuariosCadastroParceiro: 'Cadastro Parceiro de Negócios',
    UsuariosDispositivosIndex: 'Dispositivos',
    UsuariosEdicao: 'Alterar Usuário',
    UsuariosIndex: 'Usuários',
    UsuariosVer: 'Usuário',
  },
};

export default messages;
