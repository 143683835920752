import axios from 'axios';

const atualiza = async (grupoId: number, formData: any): Promise<any> => {
  const res = await axios.patch<any>(`grupos/${grupoId}`, formData);
  return res.data;
};

const busca = async (grupoId: number, queryObject?: any): Promise<any> => {
  const res = await axios.get<any>(`grupos/${grupoId}`, {
    ...(queryObject ? { params: queryObject } : {}),
  });
  return res.data;
};

const cria = async (formData: any): Promise<any> => {
  const res = await axios.post<any>('grupos', formData);
  return res.data;
};

const desvinculaFuncionalidade = async (
  grupoId: number,
  funcionalidadeId: number,
): Promise<any> => {
  const res = await axios.delete<any>(
    `grupos/${grupoId}/funcionalidades/${funcionalidadeId}`,
  );
  return res.data;
};

const lista = async (queryObject?: any): Promise<any[]> => {
  const res = await axios.get<any[]>('grupos', {
    ...(queryObject ? { params: queryObject } : {}),
  });
  return res.data;
};

const listaUsuarios = async (grupoId: number): Promise<any[]> => {
  const res = await axios.get<any[]>(`grupos/${grupoId}/usuarios`);
  return res.data;
};

const remove = async (grupoId: number): Promise<any> => {
  const res = await axios.delete<any>(`grupos/${grupoId}`);
  return res.data;
};

const vinculaFuncionalidade = async (
  grupoId: number,
  formData: any,
): Promise<any> => {
  const res = await axios.patch<any>(
    `grupos/${grupoId}/funcionalidades`,
    formData,
  );
  return res.data;
};

export default {
  atualiza,
  busca,
  cria,
  desvinculaFuncionalidade,
  lista,
  listaUsuarios,
  remove,
  vinculaFuncionalidade,
};
