import Vue from 'vue';
import i18n from '@/plugins/i18n';

/**
 * Filtro para formatar o valor de peso
 * @param {number} value Valor numérico
 * @param {{ fractionDigits?: number; locale?: string }} options Opções contendo número de casas decimais e/ou locale para formatação
 * @returns {string} Valor formatado
 */
const filterFormatPeso = (
  value: number,
  options: { fractionDigits?: number; locale?: string },
) => {
  return new Intl.NumberFormat(options?.locale || i18n.locale, {
    minimumFractionDigits: options?.fractionDigits || 3,
    maximumFractionDigits: options?.fractionDigits || 3,
  }).format(value);
};

Vue.filter('formatPeso', filterFormatPeso);
