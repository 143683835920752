


































































































































































































import Vue from 'vue';

import requester from '@/requester';
import { debounce } from 'lodash';
import { getPedidoStatusHtml } from '@/utils/pedidos-helper';
import { ListarRecebimentoQueryDto } from '../../../../back/src/pedidos/dto/listar-recebimento-query.dto';
import { ViewPedidoAtual } from '../../../../back/src/models/views/view-pedido-atual.model';
import { relativeTime } from '@/utils/intl-functions';
import { StatusPedidoEnum } from '../../../../back/src/pedidos/pedidos.constants';
import PedidosRecebimentoDialogInfo from './PedidosRecebimentoDialogInfo.vue';
import InputData from '../inputs/InputData.vue';
import { DateTime } from 'luxon';

export default Vue.extend({
  components: { PedidosRecebimentoDialogInfo, InputData },
  name: 'PedidosTabAcompanhamento',
  data: () => ({
    loading: {
      pedidos: true,
    },
    search: null,
    page: 1,
    itemsPerPage: 10,
    sortDesc: true,
    pedidos: [] as ViewPedidoAtual[],
    serverItemsLength: 0,
    dialogInfo: false,
    pedidoInfoId: null,
    menuDatas: false,
    filterMinDate: null,
    filterMaxDate: null,
    menuStatus: false,
    filterStatus: [
      {
        status: StatusPedidoEnum.AGUARDANDO_APROVACAO,
        value: false,
        text: 'Aguardando aprovação',
        color: 'info',
      },
      {
        status: StatusPedidoEnum.APROVADO,
        value: true,
        text: 'Aprovado',
        color: 'success',
      },
      {
        status: StatusPedidoEnum.APROVADO_COM_ALTERACOES,
        value: true,
        text: 'Aprovado com alterações',
        color: 'warning',
      },
    ],
  }),
  computed: {
    paginationLength() {
      return Math.ceil(this.serverItemsLength / this.itemsPerPage);
    },
  },
  watch: {
    filterStatus: {
      deep: true,
      handler() {
        this.listaPedidosRecebimento(true);
      },
    },
    page() {
      this.listaPedidosRecebimento(false);
    },
    itemsPerPage() {
      this.listaPedidosRecebimento(true);
    },
    sortDesc() {
      this.listaPedidosRecebimento(true);
    },
    search() {
      this.loading.pedidos = true;
      this.listaPedidosRecebimentoDebounce(true);
    },
    filterMinDate() {
      this.listaPedidosRecebimento(true);
      if (this.filterMinDate && this.filterMaxDate) this.menuDatas = false;
    },
    filterMaxDate() {
      this.listaPedidosRecebimento(true);
      if (this.filterMinDate && this.filterMaxDate) this.menuDatas = false;
    },
  },
  mounted() {
    this.listaPedidosRecebimento(true);
  },
  methods: {
    getPedidoStatusHtml,
    relativeTime,
    infoPedido(pedidoId: number) {
      this.pedidoInfoId = pedidoId;
      this.dialogInfo = true;
    },

    montaQueryDto(resetPage = false): ListarRecebimentoQueryDto {
      if (resetPage) {
        this.page = 1;
      }
      const page = this.page || 1;
      const itemsPerPage = this.itemsPerPage || 10;
      const limit = String(itemsPerPage);
      const offset = String((page - 1) * itemsPerPage);
      const orderBy = ['pedidoLogAtualCreatedAt'];
      const orderDesc = [String(this.sortDesc)];
      const search = this.search?.trim() || null;
      const pedidoLogAtualStatus = this.filterStatus
        .filter((el) => el.value)
        .map((el) => el.status);

      const dataMin = this.filterMinDate
        ? DateTime.fromISO(this.filterMinDate).toString()
        : null;

      const dataMax = this.filterMaxDate
        ? DateTime.fromISO(this.filterMaxDate)
            .plus({
              days: 1,
            })
            .toString()
        : null;

      return {
        limit,
        offset,
        orderBy,
        orderDesc,
        ...(search ? { search } : {}),
        pedidoLogAtualStatus,
        ...(dataMin ? { dataMin } : {}),
        ...(dataMax ? { dataMax } : {}),
      } as ListarRecebimentoQueryDto;
    },
    listaPedidosRecebimentoDebounce: debounce(function (resetPage = false) {
      this.listaPedidosRecebimento(resetPage);
    }, 250),
    async listaPedidosRecebimento(resetPage = false) {
      this.loading.pedidos = true;
      try {
        const queryDto = this.montaQueryDto(resetPage);
        const resData = await requester.pedidos.listaPedidosRecebimento(
          queryDto,
        );
        this.pedidos = resData?.rows || [];
        this.serverItemsLength = resData?.count || 0;
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.pedidos = false;
      }
    },
  },
});
