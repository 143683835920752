import { KatalisTranslationComponents } from '../translations.interface';

const messages: KatalisTranslationComponents = {
  // catalisadores-pecas
  CatalisadoresPecasDialogAtualizarLote: {
    alterarCatalisadoresPecasEmLote: 'Cambiar piezas de catalizadores en lote',
    quantidadePecasSelecionadas: 'Cantidad de piezas seleccionadas',
    mensagemSucesso: 'Piezas de catalizadores cambiadas exitosamente',
  },
  CatalisadoresPecasDialogInfo: {
    catalisadorPecaRemovidoComSucesso:
      'Pieza de catalizador removida con éxito',
    certezaRemoverCatalisadorPeca:
      '¿Seguro que desea quitar esta pieza de catalizador?',
    removerCatalisadorPeca: 'Eliminar pieza de catalizador',
  },
  CatalisadoresPecasFormCriarEditar: {
    cadastrarNovaMontadora: 'Registrar nuevo ensamblador',
    mensagemSucessoAlterar: 'Pieza de catalizador cambiada exitosamente',
    mensagemSucessoCriar: 'Pieza de catalizador creada exitosamente',
  },
  CatalisadoresPecasInfo: {
    deAcordoComACotacaoAtual: 'De acuerdo con la cotización actual',
  },
  CatalisadoresPecasImportarStep1: {
    arquivoNaoSuportado: 'Archivo no soportado',
    arrasteArquivoOuClique: 'Arrastre un archivo aquí o haga clic',
    extensoesSuportadas: 'Extensiones soportadas',
    solteArquivo: 'Suelta el archivo',
  },
  CatalisadoresPecasImportarStep2: {
    atributoPecaCatalisador: 'Atributo de la pieza de catalizador',
    colunaArquivo: 'Columna del archivo',
    colunasObrigatoriasNaoSelecionadas:
      'Columnas obligatorias no seleccionadas',
    identificacaoDasColunas: 'Identificación de las columnas',
  },
  CatalisadoresPecasImportarStep3: {
    dadosDoArquivo: 'Datos del archivo',
    existeErro: 'Existen elementos con error',
    mostrarErro: 'Mostrar elementos con error',
    mostrarTodos: 'Mostrar todos los elementos',
  },
  CatalisadoresPecasImportarStep4: {
    existeInconsistentes: 'Existen elementos inconsistentes no resueltos',
    mostrarInconsistentes: 'Mostrar elementos no resueltos',
    mostrarTodos: 'Mostrar todos los elementos',
    nenhumInconsistente: 'Ningún elemento inconsistente',
  },
  CatalisadoresPecasImportarStep5: {
    novasPecas: 'Nuevas piezas',
    pecasQueSeraoAtualizadas: 'Piezas que se actualizarán',
  },
  CatalisadoresPecasImportarStep6: {
    importarNovoArquivo: 'Importar nuevo archivo',
    naoFoiPossivelImportarAsSeguintesPecas:
      'No se pudieron importar las siguientes piezas',
    pecasImportadasComSucesso: 'Piezas importadas exitosamente',
    voltarParaListaDePecas: 'Volver a la lista de piezas',
  },
  // catalisadores-po
  CatalisadoresPoDialogInfo: {
    catalisadorPoRemovidoComSucesso:
      'Clase de polvo de catalizador eliminada con éxito',
    certezaRemoverCatalisadorPo:
      '¿Seguro que desea quitar esta clase de polvo de catalizador?',
    removerCatalisadorPo: 'Eliminar clase de polvo de catalizador',
  },
  CatalisadoresPoFormCriarAlterar: {
    catalisadorPoAlteradoComSucesso:
      'Clase de polvo de catalizador cambiada exitosamente',
    catalisadorPoCriadoComSucesso:
      'Clase de polvo de catalizador creada exitosamente',
  },
  // cotacoes
  CotacoesConfigIndex: {
    verPesosEm: 'Ver pesos en',
    verPrecosEm: 'Ver precios en',
  },
  CotacoesDialogInfo: {
    certezaRemoverCotacao: '¿Seguro que desea eliminar esta cotización?',
    cotacaoRemovidaComSucesso: 'Cotización eliminada con éxito',
    removerCotacao: 'Eliminar cotización',
  },
  CotacoesFormCriar: {
    cotacaoCriadaComSucesso: 'Cotización creada exitosamente',
  },
  // enderecos
  EnderecosFormBrasil: {
    cepNaoEncontrado: 'CEP no encontrado',
  },
  // familias
  FamiliasDialogInfo: {
    certezaRemoverFamilia: '¿Seguro que desea eliminar esta familia?',
    familiaRemovidaComSucesso: 'Familia eliminada con éxito',
    removerFamilia: 'Eliminar familia',
  },
  FamiliasFormCriarAlterar: {
    familiaAlteradaComSucesso: 'Familia cambiada exitosamente',
    familiaCriadaComSucesso: 'Familia creada exitosamente',
  },
  // grupos
  GruposDialogEdicao: {
    alterarGrupo: 'Cambiar grupo',
  },
  GruposFormCriarAlterar: {
    grupoAlteradoComSucesso: 'Grupo cambiado exitosamente',
    grupoCriadoComSucesso: 'Grupo creado exitosamente',
  },
  // lojas
  LojasForm: {
    lojaAlteradaComSucesso: 'Tienda cambiada exitosamente',
    lojaCriadaComSucesso: 'Tienda creada exitosamente',
  },
  // placas
  PlacasDialogInfo: {
    certezaRemoverPlaca: '¿Seguro que desea eliminar esta placa electrónica?',
    placaRemovidaComSucesso: 'Placa electrónica eliminada con éxito',
    removerPlaca: 'Eliminar placa electrónica',
  },
  PlacasFormCriarAlterar: {
    placaAlteradaComSucesso: 'Placa electrónica cambiada exitosamente',
    placaCriadaComSucesso: 'Placa electrónica creada exitosamente',
  },
  // pedidos
  PedidosRecebimento: {
    avisoConfirmarRecebimento:
      'Atención, para que los responsables de este pedido reciban un correo electrónico con los cambios en la recepción, ¡es imprescindible confirmar la recepción!',
    nenhumCodigoEncontradoComOTermo: 'Ningún código encontrado con el término',
  },
  PedidosItemRecebimentoPeca: {
    certezaDesfazerAprovacao:
      '¿Seguro que deseas deshacer la aprobación de esta pieza?',
    certezaDesfazerReprovacao:
      '¿Seguro que deseas deshacer la desaprobación de esta pieza? La justificación será eliminada.',
  },
  // usuarios
  UsuariosFormFuncionario: {
    usuarioAlteradoComSucesso: 'Usuario cambiado exitosamente',
    usuarioCriadoComSucesso: 'Usuario creado exitosamente',
  },
  UsuariosFormParceiro: {
    usuarioAlteradoComSucesso: 'Usuario cambiado exitosamente',
    usuarioCriadoComSucesso: 'Usuario creado exitosamente',
  },
};

export default messages;
