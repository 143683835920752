


































































































import Vue from 'vue';
import { debounce, orderBy } from 'lodash';

import requester from '@/requester';
import { getPedidoStatusHtml } from '@/utils/pedidos-helper';

import { Loja } from '../../../../back/src/models/loja.model';
import { ViewPedidoAtual } from '../../../../back/src/models/views/view-pedido-atual.model';
import { ListarPedidosQueryDto } from '../../../../back/src/pedidos/dto/listar-pedidos-query.dto';
import { StatusPedidoEnum } from '../../../../back/src/pedidos/pedidos.constants';
import { LojaScopesEnum } from '../../../../back/src/lojas/lojas.constants';

import TextOrNA from '@/components/TextOrNA.vue';
import PedidosDialogInfo from './PedidosDialogInfo.vue';
import { CategoriaUsuarioEnum } from '../../../../back/src/usuarios/usuarios.constants';

export default Vue.extend({
  name: 'PedidosTabela',
  components: {
    TextOrNA,
    PedidosDialogInfo,
  },
  props: {
    parceiros: {
      type: Boolean,
      default: false,
    },
    isLoja: {
      type: Boolean,
      default: false,
    },
    isParceiro: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: {
      pedidos: true,
      lojas: true,
    },
    dialogInfo: false,
    pedidoInfoId: null,
    search: null,
    options: {
      itemsPerPage: 10,
      mustSort: true,
      sortBy: ['id'],
      sortDesc: [true],
    },
    lojas: [] as Loja[],
    pedidos: [] as ViewPedidoAtual[],
    serverItemsLength: 0,
    filterStatus: null,
    filterLojaId: null,
    filterUsuarioCreatedByCategoria: CategoriaUsuarioEnum.PARCEIRO,
  }),
  computed: {
    itemsUsuarioCreatedByCategoria() {
      return [
        {
          value: CategoriaUsuarioEnum.PARCEIRO,
          text: this.$t('comum.parceiroNegocios'),
        },
        {
          value: CategoriaUsuarioEnum.PREPOSTO,
          text: this.$t('comum.preposto'),
        },
      ];
    },
    itemsLojas() {
      return orderBy(
        this.lojas.map((loja) => {
          const municipio = loja?.endereco?.municipioNome;
          const bairro = loja?.endereco?.bairro;
          const lojaIdentificacao = municipio
            ? `${municipio}${bairro ? ` - ${bairro}` : ''}`
            : `${loja.id}`;
          return {
            value: loja.id,
            text: lojaIdentificacao,
          };
        }),
        ['text'],
      );
    },
    itemsStatus() {
      return orderBy(
        Object.values(StatusPedidoEnum).map((status) => ({
          value: status,
          text: this.$t(`StatusPedidoEnum.${status}`),
        })),
        ['text'],
      );
    },
    headers() {
      return [
        {
          value: 'id',
          text: '#',
          class: 'text-no-wrap',
        },
        ...(this.isLoja
          ? [
              {
                value: 'lojaIdentificacao',
                text: this.$t('comum.loja'),
                class: 'text-no-wrap',
              },
            ]
          : []),
        {
          value: 'fornecedorNome',
          text: this.$t('comum.fornecedor'),
          class: 'text-no-wrap',
        },
        {
          value: 'fornecedorDocumento',
          text: this.$t('comum.documento'),
          class: 'text-no-wrap',
        },
        {
          value: 'usuarioCreatedByNomeCompleto',
          text: this.$t('comum.criadoPor'),
          class: 'text-no-wrap',
        },
        {
          value: 'createdAt',
          text: this.$t('comum.criadoEm'),
          class: 'text-no-wrap',
        },
        {
          value: 'pedidoLogAtualCreatedAt',
          text: this.$t('comum.ultimaAtualizacaoEm'),
          class: 'text-no-wrap',
        },
        {
          value: 'pedidoLogAtualStatus',
          text: this.$t('comum.status'),
          class: 'text-no-wrap',
        },
        {
          value: 'info',
          text: this.$t('comum.detalhes'),
          class: 'text-no-wrap',
          sortable: false,
          align: 'center',
        },
      ];
    },
  },
  watch: {
    filterStatus() {
      this.listaPedidos(true);
    },
    filterLojaId() {
      this.listaPedidos(true);
    },
    filterUsuarioCreatedByCategoria() {
      this.listaPedidos(true);
    },
    options() {
      this.listaPedidos();
    },
    search() {
      this.loading.pedidos = true;
      this.listaPedidosDebounce(true);
    },
  },
  mounted() {
    this.listaLojas();
  },
  methods: {
    async listaLojas() {
      this.loading.lojas = true;
      try {
        const queryObject = {
          scope: LojaScopesEnum.ComUsuarios,
        };
        this.lojas = await requester.lojas.lista(queryObject);
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.lojas = false;
      }
    },
    getPedidoStatusHtml,
    infoPedido(pedidoId: number) {
      this.pedidoInfoId = pedidoId;
      this.dialogInfo = true;
    },
    onPedidoAlterado() {
      this.listaPedidos();
    },
    montaQueryDto(resetPage = false): ListarPedidosQueryDto {
      if (resetPage) {
        this.options.page = 1;
      }
      const page = this.options?.page || 1;
      const itemsPerPage = this.options?.itemsPerPage || 10;
      const limit = String(itemsPerPage);
      const offset = String((page - 1) * itemsPerPage);
      const orderBy = this.options.sortBy;
      const orderDesc = this.options.sortDesc.map(String);
      const search = this.search?.trim() || null;
      const status = this.filterStatus || null;
      const lojaId = this.filterLojaId || null;
      const isLoja = this.isLoja ? 'true' : null;
      const isParceiro = this.isParceiro ? 'true' : null;
      let usuarioCreatedByCategoria = null;
      if (!isLoja) {
        usuarioCreatedByCategoria = this.filterUsuarioCreatedByCategoria;
      }
      return {
        limit,
        offset,
        orderBy,
        orderDesc,
        ...(search ? { search } : {}),
        ...(status ? { status } : {}),
        ...(lojaId ? { lojaId } : {}),
        ...(isLoja ? { isLoja } : {}),
        ...(isParceiro ? { isParceiro } : {}),
        ...(usuarioCreatedByCategoria ? { usuarioCreatedByCategoria } : {}),
      } as ListarPedidosQueryDto;
    },
    listaPedidosDebounce: debounce(function (resetPage = false) {
      this.listaPedidos(resetPage);
    }, 250),
    async listaPedidos(resetPage = false) {
      this.loading.pedidos = true;
      try {
        const queryDto = this.montaQueryDto(resetPage);
        const resData = await requester.pedidos.lista(queryDto);
        this.pedidos = resData?.rows || [];
        this.serverItemsLength = resData?.count || 0;
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.pedidos = false;
      }
    },
  },
});
