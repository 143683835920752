



























































































import Vue from 'vue';

import requester from '@/requester';

import { Rules as CaslRules } from '../../../../back/src/casl/casl.constants';
import { CONVERSION_TROY_OZ_TO_G } from '@/utils/constants/back.constants';

import CotacoesConfigIndex from '@/components/cotacoes/CotacoesConfigIndex.vue';
import CotacoesDialogCadastro from '@/components/cotacoes/CotacoesDialogCadastro.vue';
import CotacoesDialogInfo from '@/components/cotacoes/CotacoesDialogInfo.vue';
import CotacoesResumoAtual from '@/components/cotacoes/CotacoesResumoAtual.vue';

export default Vue.extend({
  name: 'CotacoesIndex',
  components: {
    CotacoesConfigIndex,
    CotacoesDialogCadastro,
    CotacoesDialogInfo,
    CotacoesResumoAtual,
  },
  data: () => ({
    loading: {
      cotacoes: true,
    },
    dialogCadastro: false,
    dialogInfo: false,
    options: {},
    totalCotacoes: 0,
    cotacoes: [],
    cotacaoAtual: null,
    cotacaoAnterior: null,
    cotacaoInfoId: 0,
    config: {
      converterMoeda: false,
      converterPeso: false,
      teorPt: 250,
      teorPd: 1000,
      teorRh: 130,
    },
  }),
  computed: {
    currency() {
      return process.env.VUE_APP_PAIS_CURRENCY || 'BRL';
    },
    permissaoCriar() {
      const rule = CaslRules.Cotacao.criar;
      return this.$ability.can(rule?.action, rule?.subject);
    },
    unidade() {
      const moeda = this.config.converterMoeda
        ? this.$t('comum.moedaSistema')
        : this.$t('comum.usd');
      const peso = this.config.converterPeso
        ? this.$t('comum.g')
        : this.$t('comum.oz');
      return `${moeda}/${peso}`;
    },
    unidadePrecoMedio() {
      const moeda = this.config.converterMoeda
        ? this.$t('comum.moedaSistema')
        : this.$t('comum.usd');
      return `${moeda}/${this.$t('comum.kg')}`;
    },
    headers() {
      return [
        {
          value: 'createdAt',
          text: this.$t('comum.data'),
          class: 'text-no-wrap',
          sortable: true,
        },
        {
          value: 'bidPt',
          text: `${this.$t('comum.platina')} - ${this.$t('comum.pt')} (${
            this.unidade
          })`,
          class: 'text-no-wrap',
          align: 'center',
          sortable: false,
        },
        {
          value: 'bidPd',
          text: `${this.$t('comum.paladio')} - ${this.$t('comum.pd')} (${
            this.unidade
          })`,
          class: 'text-no-wrap',
          align: 'center',
          sortable: false,
        },
        {
          value: 'bidRh',
          text: `${this.$t('comum.rodio')} - ${this.$t('comum.rh')} (${
            this.unidade
          })`,
          class: 'text-no-wrap',
          align: 'center',
          sortable: false,
        },
        {
          value: 'precoMedio',
          text: `${this.$t('comum.precoMedio')} (${this.unidadePrecoMedio})`,
          class: 'text-no-wrap',
          align: 'center',
          sortable: false,
        },
        {
          value: 'dolar',
          text: `${this.$t('comum.dolar')} (${this.currency})`,
          class: 'text-no-wrap',
          align: 'center',
          sortable: false,
        },
        {
          value: 'info',
          text: `${this.$t('comum.info')}`,
          class: 'text-no-wrap',
          align: 'center',
          sortable: false,
        },
      ];
    },
  },
  watch: {
    options() {
      this.listaCotacoes();
    },
  },
  mounted() {
    this.buscaCotacaoAtualEAnterior();
  },
  methods: {
    cotacaoCriada() {
      this.buscaCotacaoAtualEAnterior();
      this.listaCotacoes();
    },
    cotacaoRemovida() {
      this.buscaCotacaoAtualEAnterior();
      this.listaCotacoes();
    },
    aplicarConfig(config) {
      this.config = config;
    },
    converteValor(valor: number, dolar: number) {
      const moeda = this.config.converterMoeda ? dolar : 1;
      const peso = this.config.converterPeso ? CONVERSION_TROY_OZ_TO_G : 1;
      return Math.floor((valor * moeda) / peso);
    },
    calculaPrecoMedio(cotacao) {
      const valor =
        this.config.teorPt * cotacao.valorPt +
        this.config.teorPd * cotacao.valorPd +
        this.config.teorRh * cotacao.valorRh;
      const moeda = this.config.converterMoeda ? cotacao.dolar : 1;
      return (valor * moeda) / CONVERSION_TROY_OZ_TO_G / 1000;
    },
    infoCotacao(cotacao) {
      this.dialogInfo = true;
      this.cotacaoInfoId = cotacao?.id || 0;
    },
    async buscaCotacaoAtualEAnterior() {
      this.loading.cotacaoAtual = true;
      try {
        const paginationQuery = {
          limit: 2,
          offset: 0,
          orderBy: ['createdAt'],
          orderDesc: [true],
        };
        const resData = await requester.cotacoes.lista(paginationQuery);
        this.cotacaoAtual = resData?.rows[0] || null;
        this.cotacaoAnterior = resData?.rows[1] || null;
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.cotacaoAtual = false;
      }
    },
    async listaCotacoes() {
      this.loading.cotacoes = true;
      try {
        const limit = this.options.itemsPerPage;
        const offset = (this.options.page - 1) * (limit > 0 ? limit : 0);
        const paginationQuery = {
          ...(limit > 0 ? { limit } : {}),
          offset,
          orderBy: this.options.sortBy,
          orderDesc: this.options.sortDesc,
        };
        const resData = await requester.cotacoes.lista(paginationQuery);
        this.cotacoes = resData?.rows || [];
        this.totalCotacoes = resData?.count || 0;
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.cotacoes = false;
      }
    },
  },
});
