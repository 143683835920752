














































































import Vue from 'vue';

import { ArquivoKeyEnum } from '@/utils/constants/back.constants';

export default Vue.extend({
  name: 'InputFotoPreview',
  props: {
    file: {
      default: null,
    },
    fotoArquivo: {
      type: Object || null,
      default: null,
    },
    aspectRatio: {
      type: Number,
      default: () => 4 / 3,
    },
    small: {
      type: Boolean,
      default: false,
    },
    principal: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    removerArquivo: false,
  }),
  computed: {
    src() {
      return this.file ? URL.createObjectURL(this.file) : this.fotoArquivoSrc;
    },
    fotoArquivoSrc() {
      return this.fotoArquivo
        ? `${process.env.VUE_APP_URL_API}${this.fotoArquivo.path}/${ArquivoKeyEnum.WATERMARK}`
        : null;
    },
  },
  methods: {
    desfazer() {
      this.removerArquivo = false;
      this.$emit('desfazer');
    },
    onRemoverArquivo() {
      this.removerArquivo = true;
      this.$emit('remover-arquivo');
    },
  },
});
