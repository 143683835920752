




















































import Vue from 'vue';

import { map } from 'lodash';

import parsePhoneNumber, {
  validatePhoneNumberLength,
  isValidNumberForRegion,
  AsYouType,
  getCountryCallingCode,
  CountryCode,
  CountryCallingCode,
} from 'libphonenumber-js';

import countries from 'i18n-iso-countries';
import ptLocale from 'i18n-iso-countries/langs/pt.json';
countries.registerLocale(ptLocale);

import CountryFlag from 'vue-country-flag';
import { rulesRequired } from '@/utils/input-rules';

export default Vue.extend({
  name: 'InputTelefone',
  components: {
    CountryFlag,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    dense: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    defaultRegion: {
      type: String,
      default: 'BR',
    },
  },
  data: () => ({
    number: '',
    region: 'BR',
    locale: 'pt',
  }),
  computed: {
    regions() {
      return map(
        countries.getNames(this.locale),
        (countryName, countryCode: CountryCode) => {
          let callingCode: CountryCallingCode;
          try {
            callingCode = getCountryCallingCode(countryCode);
          } catch (err) {
            callingCode = '';
          }
          return {
            value: countryCode,
            text: `${countryName}: +${callingCode}`,
            code: `+${callingCode}`,
          };
        },
      ).filter(
        (el: { value: CountryCode | string; text: string; code: string }) =>
          el.code !== '+',
      );
    },
    rules() {
      return [
        ...(this.required ? rulesRequired : []),
        (v: string) =>
          v.length === 0 ||
          isValidNumberForRegion(v, this.region as CountryCode) ||
          this.$t('rules.telefoneInvalido'),
      ];
    },
  },
  mounted() {
    if (!this.value) {
      this.region = this.defaultRegion || 'BR';
    } else {
      const phoneNumber = parsePhoneNumber(this.value);
      this.number = String(phoneNumber?.nationalNumber) || '';
      this.region = phoneNumber?.country || 'BR';
      this.maskPhone(this.number);
    }
  },
  watch: {
    value(val, oldVal) {
      if (!oldVal && val) {
        const phoneNumber = parsePhoneNumber(val);
        this.number = String(phoneNumber?.nationalNumber) || '';
        this.region = phoneNumber?.country || 'BR';
        this.maskPhone(this.number);
      }
    },
    number(val) {
      this.$emit(
        'input',
        parsePhoneNumber(val, this.region as CountryCode)?.number,
      );
    },
    defaultRegion(val) {
      if (!this.number) {
        this.region = val;
      }
    },
  },
  methods: {
    maskPhone(number: string) {
      let digits = number.replace(/\D*/g, '');
      while (
        validatePhoneNumberLength(digits, this.region as CountryCode) ===
        'TOO_LONG'
      ) {
        digits = digits.substr(0, digits.length - 1);
      }
      this.$nextTick(() => {
        this.number = new AsYouType(this.region as CountryCode).input(digits);
      });
    },
  },
});
