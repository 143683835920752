import { KatalisTranslationEnums } from '../translations.interface';

const messages: KatalisTranslationEnums = {
  AvisoCatalisadorPecaEnum: {
    CHAMAR_COMERCIAL: 'Llamar al comercial',
    SEPARAR_PARA_ANALISE: 'Separar para análisis',
  },
  CategoriaFamiliaEnum: {
    CATALISADORES_PECAS: 'Catalizadores - Piezas',
    CATALISADORES_PO: 'Catalizadores - Polvo',
    PLACAS: 'Placas electrónicas',
  },
  CategoriaPedidoItemEnum: {
    CATALISADORES_PECAS: 'Catalizadores - Piezas',
    CATALISADORES_PO: 'Catalizadores - Polvo',
  },
  CategoriaUsuarioEnum: {
    FUNCIONARIO: 'Empleado',
    PARCEIRO: 'Socio de negocios',
    PREPOSTO: 'Apoderado',
  },
  StatusPedidoEnum: {
    AGUARDANDO_APROVACAO: 'Esperando aprobación',
    APROVADO: 'Aprobado',
    CANCELADO: 'Cancelado',
    APROVADO_COM_ALTERACOES: 'Aprobado con modificaciones',
  },
  StatusPedidoItemPecaRecebimentoEnum: {
    APROVADA: 'Pieza aprobada | Piezas aprobadas',
    REPROVADA_DANIFICADA: 'Pieza dañada | Piezas dañadas',
    REPROVADA_FALTANTE: 'Pieza faltante | Piezas faltantes',
    REPROVADA_INCORRETA: 'Pieza incorrecta | Piezas incorrectas',
    REPROVADA_OUTROS: 'Otra justificación',
  },
  VinculoLojaUsuarioEnum: {
    COMPRADOR: 'Comprador',
    RESPONSAVEL_ADMINISTRATIVO: 'Responsable administrativo',
    RESPONSAVEL_COMERCIAL: 'Responsable comercial',
  },
};

export default messages;
