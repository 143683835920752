

















































import Vue from 'vue';
import { orderBy } from 'lodash';

import { Rules as CaslRules } from '../../../../back/src/casl/casl.constants';
import { CategoriaUsuarioEnum } from '@/utils/constants/back.constants';

import UsuariosTabela from './UsuariosTabela.vue';

export default Vue.extend({
  name: 'UsuariosTabFuncionarios',
  components: {
    UsuariosTabela,
  },
  props: {
    usuarios: {
      type: Array,
      default: () => [],
    },
    grupos: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    search: '',
    filterGrupo: null,
  }),
  computed: {
    usuariosFuncionarios() {
      return this.usuarios.filter(
        (el) => el.categoria === CategoriaUsuarioEnum.FUNCIONARIO,
      );
    },
    usuariosFuncionariosFiltrados() {
      return this.usuariosFuncionarios.filter((el) =>
        this.filterGrupo ? el.grupoId === this.filterGrupo : true,
      );
    },
    itemsGrupos() {
      return orderBy(
        this.grupos.map((el) => ({ value: el.id, text: el.nome })),
        ['text'],
      );
    },
    permissaoCriar() {
      const rule = CaslRules.Usuario.criar;
      return this.$ability.can(rule?.action, rule?.subject);
    },
  },
});
