










































































import Vue from 'vue';

import requester from '@/requester';

import { Rules as CaslRules } from '../../../../back/src/casl/casl.constants';
import { CategoriaFamiliaEnum } from '@/utils/constants/back.constants';

import FamiliasDialogCadastro from '@/components/familias/FamiliasDialogCadastro.vue';
import FamiliasDialogInfo from '@/components/familias/FamiliasDialogInfo.vue';

export default Vue.extend({
  name: 'FamiliasIndex',
  components: {
    FamiliasDialogCadastro,
    FamiliasDialogInfo,
  },
  data: () => ({
    loading: {
      familias: true,
    },
    search: '',
    dialogCadastro: false,
    dialogInfo: false,
    filterCategoria: null,
    familiaInfoId: 0,
    familias: [],
  }),
  computed: {
    familiasFiltradas() {
      return this.familias
        .filter((el) => {
          if (this.filterCategoria) {
            return el.categoria === this.filterCategoria;
          }
          return true;
        })
        .map((el) => ({
          ...el,
          categoria: this.$t(`CategoriaFamiliaEnum.${el.categoria}`),
        }));
    },
    itemsCategorias() {
      return Object.values(CategoriaFamiliaEnum).map((el) => ({
        value: el,
        text: this.$t(`CategoriaFamiliaEnum.${el}`),
      }));
    },
    headers() {
      return [
        {
          value: 'categoria',
          text: this.$t('comum.categoria'),
          class: 'text-no-wrap',
          filterable: true,
          sortable: true,
        },
        {
          value: 'codigo',
          text: this.$t('comum.codigo'),
          class: 'text-no-wrap',
          filterable: true,
          sortable: true,
        },
        {
          value: 'descricao',
          text: this.$t('comum.descricao'),
          class: 'text-no-wrap',
          filterable: true,
          sortable: true,
        },
        {
          value: 'descricaoCatalogo',
          text: this.$t('comum.descricaoCatalogo'),
          class: 'text-no-wrap',
          filterable: true,
          sortable: true,
        },
        {
          value: 'info',
          text: this.$t('comum.info'),
          class: 'text-no-wrap',
          filterable: false,
          sortable: false,
          align: 'center',
          width: 1,
        },
      ];
    },
    permissaoCriar() {
      const rule = CaslRules.Familia.criar;
      return this.$ability.can(rule?.action, rule?.subject);
    },
  },
  mounted() {
    this.listaFamilias();
  },
  methods: {
    infoFamilia(familiaId: number) {
      this.familiaInfoId = familiaId;
      this.dialogInfo = true;
    },
    familiaCriada(familia) {
      this.familias.push(familia);
    },
    familiaAlterada(familia) {
      const index = this.familias.findIndex((el) => el.id === familia.id);
      if (index >= 0) {
        this.$set(this.familias, index, familia);
      }
    },
    familiaRemovida(familiaId: number) {
      const index = this.familias.findIndex((el) => el.id === familiaId);
      if (index >= 0) {
        this.$delete(this.familias, index);
      }
    },
    async listaFamilias() {
      this.loading.familias = true;
      try {
        this.familias = await requester.familias.lista();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.familias = false;
      }
    },
  },
});
