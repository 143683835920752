var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('UsuariosDialogDispositivoInfo',{attrs:{"usuarioDispositivo":_vm.dispositivoSelecionado,"hide-usuario":""},on:{"dispositivo-autorizado":_vm.dispositivoAlterado,"dispositivo-bloqueado":_vm.dispositivoAlterado},model:{value:(_vm.dialogInfo),callback:function ($$v) {_vm.dialogInfo=$$v},expression:"dialogInfo"}}),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"items":_vm.dispositivos,"headers":_vm.headers,"sort-by":"updatedAt","sort-desc":"","disable-pagination":"","hide-default-footer":"","item-class":function (item) { return (item.dispositivoAutorizado ? '' : 'error lighten-5'); }},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.updatedAt))+" ")]}},{key:"item.dispositivoInfo.isMobile",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.getIconDevice(item.dispositivoInfo))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.getTypeDevice(item.dispositivoInfo))+" ")])]}},{key:"item.dispositivoInfo.platform",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.getIconPlatform(item.dispositivoInfo))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.dispositivoInfo.platform ? item.dispositivoInfo.platform : _vm.$t('comum.desconhecida'))+" ")])]}},{key:"item.dispositivoInfo.browser",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.getIconBrowser(item.dispositivoInfo))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.dispositivoInfo.browser ? item.dispositivoInfo.browser : _vm.$t('comum.desconhecido'))+" ")])]}},{key:"item.dispositivoInfo.source",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption text--disabled"},[_vm._v(" "+_vm._s(item.dispositivoIdentificacao || 'N/A')+" ")])]}},{key:"item.dispositivoAutorizado",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.dispositivoAutorizado)?[_c('v-icon',{attrs:{"color":"success","left":""}},[_vm._v("mdi-shield-lock-open-outline")]),_c('span',{staticClass:"success--text",domProps:{"textContent":_vm._s(_vm.$t('comum.sim'))}})]:[_c('v-icon',{attrs:{"color":"error","left":""}},[_vm._v("mdi-shield-lock")]),_c('span',{staticClass:"error--text font-weight-medium",domProps:{"textContent":_vm._s(_vm.$t('comum.nao'))}})]],2)]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.infoDialog(item)}}},[_c('v-icon',[_vm._v("mdi-information")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }