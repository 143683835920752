
















































































































































































































































































































































import Vue from 'vue';
import { orderBy } from 'lodash';

import { Rules as CaslRules } from '../../../../back/src/casl/casl.constants';
import { maximumFractionDigitsCurrency } from '@/utils/intl-functions';

import CatalisadoresPecasGaleria from './CatalisadoresPecasGaleria.vue';

export default Vue.extend({
  name: 'CatalisadoresPecasInfo',
  components: {
    CatalisadoresPecasGaleria,
  },
  props: {
    catalisadorPeca: {
      type: Object || null,
      default: null,
    },
    fotosArquivos: {
      type: Array,
      default: () => [],
    },
    margemUsuario: {
      type: Number,
      default: 30,
    },
  },
  computed: {
    permissaoBuscarAudit() {
      const rule = CaslRules.CatalisadorPeca.buscarAudit;
      return this.$ability.can(rule?.action, rule?.subject);
    },
    analisesOrdenadas() {
      return orderBy(this.catalisadorPeca?.analises || [], ['ref']);
    },
    locale() {
      return process.env.VUE_APP_PAIS_LOCALE || 'pt-BR';
    },
    currency() {
      return process.env.VUE_APP_PAIS_CURRENCY || 'BRL';
    },
  },
  methods: {
    maximumFractionDigitsCurrency,
  },
});
