var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticStyle:{"overflow-y":"scroll"},attrs:{"tile":"","outlined":""}},[_c('v-card-title',[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-view-list")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('CatalisadoresPecasImportarStep3.dadosDoArquivo'))+" ")])],1)]),_c('v-divider'),_c('v-data-table',{attrs:{"items":_vm.showOnlyError ? _vm.itemsError : _vm.items,"headers":_vm.headers,"disable-sort":"","disable-filtering":"","item-class":_vm.itemClass},scopedSlots:_vm._u([{key:"item.ref",fn:function(ref){
var item = ref.item;
return [(item.edit)?_c('v-text-field',{staticStyle:{"width":"100px"},attrs:{"rules":_vm.rules.integerPositiveNonZero,"hide-details":"","outlined":"","dense":""},model:{value:(item.ref),callback:function ($$v) {_vm.$set(item, "ref", $$v)},expression:"item.ref"}}):(item.ref)?_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(Number(item.ref))+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.codigo",fn:function(ref){
var item = ref.item;
return [(item.edit)?_c('v-text-field',{staticStyle:{"width":"100px"},attrs:{"rules":_vm.rules.required,"hide-details":"","outlined":"","dense":""},model:{value:(item.codigo),callback:function ($$v) {_vm.$set(item, "codigo", $$v)},expression:"item.codigo"}}):(item.codigo)?_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.codigo)+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.detalhe",fn:function(ref){
var item = ref.item;
return [(item.edit)?_c('v-text-field',{staticStyle:{"width":"100px"},attrs:{"hide-details":"","outlined":"","dense":""},model:{value:(item.detalhe),callback:function ($$v) {_vm.$set(item, "detalhe", $$v)},expression:"item.detalhe"}}):(item.detalhe)?_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.detalhe)+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.observacoes",fn:function(ref){
var item = ref.item;
return [(item.edit)?_c('v-text-field',{staticStyle:{"width":"100px"},attrs:{"hide-details":"","outlined":"","dense":""},model:{value:(item.observacoes),callback:function ($$v) {_vm.$set(item, "observacoes", $$v)},expression:"item.observacoes"}}):(item.observacoes)?_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.observacoes)+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.montadora",fn:function(ref){
var item = ref.item;
return [(item.edit)?_c('v-text-field',{staticStyle:{"width":"100px"},attrs:{"hide-details":"","outlined":"","dense":""},model:{value:(item.montadora),callback:function ($$v) {_vm.$set(item, "montadora", $$v)},expression:"item.montadora"}}):(item.montadora)?_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.montadora.toUpperCase())+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.modelo",fn:function(ref){
var item = ref.item;
return [(item.edit)?_c('v-text-field',{staticStyle:{"width":"100px"},attrs:{"hide-details":"","outlined":"","dense":""},model:{value:(item.modelo),callback:function ($$v) {_vm.$set(item, "modelo", $$v)},expression:"item.modelo"}}):(item.modelo)?_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.modelo)+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.margemPeca",fn:function(ref){
var item = ref.item;
return [(item.edit)?_c('v-text-field',{staticStyle:{"width":"100px"},attrs:{"rules":_vm.rules.integerPositive,"hide-details":"","outlined":"","dense":"","suffix":"%"},model:{value:(item.margemPeca),callback:function ($$v) {_vm.$set(item, "margemPeca", $$v)},expression:"item.margemPeca"}}):(item.margemPeca)?_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(Number(item.margemPeca))+"% ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.ptPpm",fn:function(ref){
var item = ref.item;
return [(item.edit)?_c('v-text-field',{staticStyle:{"width":"100px"},attrs:{"rules":_vm.rules.numericPositive,"hide-details":"","outlined":"","dense":""},model:{value:(item.ptPpm),callback:function ($$v) {_vm.$set(item, "ptPpm", $$v)},expression:"item.ptPpm"}}):(item.ptPpm)?_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(Number(item.ptPpm))+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.pdPpm",fn:function(ref){
var item = ref.item;
return [(item.edit)?_c('v-text-field',{staticStyle:{"width":"100px"},attrs:{"rules":_vm.rules.numericPositive,"hide-details":"","outlined":"","dense":""},model:{value:(item.pdPpm),callback:function ($$v) {_vm.$set(item, "pdPpm", $$v)},expression:"item.pdPpm"}}):(item.pdPpm)?_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(Number(item.pdPpm))+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.rhPpm",fn:function(ref){
var item = ref.item;
return [(item.edit)?_c('v-text-field',{staticStyle:{"width":"100px"},attrs:{"rules":_vm.rules.numericPositive,"hide-details":"","outlined":"","dense":""},model:{value:(item.rhPpm),callback:function ($$v) {_vm.$set(item, "rhPpm", $$v)},expression:"item.rhPpm"}}):(item.rhPpm)?_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(Number(item.rhPpm))+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.pesoKg",fn:function(ref){
var item = ref.item;
return [(item.edit)?_c('v-text-field',{staticStyle:{"width":"100px"},attrs:{"rules":_vm.rules.numericPositiveNonZero,"hide-details":"","outlined":"","dense":""},model:{value:(item.pesoKg),callback:function ($$v) {_vm.$set(item, "pesoKg", $$v)},expression:"item.pesoKg"}}):(item.pesoKg)?_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(Number(item.pesoKg))+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.dataUltimaAnalise",fn:function(ref){
var item = ref.item;
return [(item.edit)?_c('InputData',{staticStyle:{"width":"200px"},attrs:{"hide-details":"","outlined":"","dense":""},model:{value:(item.dataUltimaAnalise),callback:function ($$v) {_vm.$set(item, "dataUltimaAnalise", $$v)},expression:"item.dataUltimaAnalise"}}):(item.dataUltimaAnalise)?_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.dataUltimaAnalise))+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.fotosArquivos",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":item.fotosArquivos.length === 0 ? 'grey' : 'secondary'}},on),[_c('v-icon',[_vm._v(" "+_vm._s(item.fotosArquivos.length === 0 ? 'mdi-image-off' : 'mdi-image')+" ")])],1)]}}],null,true),model:{value:(item.editFoto),callback:function ($$v) {_vm.$set(item, "editFoto", $$v)},expression:"item.editFoto"}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-image")]),_vm._v(" "+_vm._s(_vm.$t('comum.fotos'))+" - "+_vm._s(_vm.$t('comum.codigo'))+" "+_vm._s(item.codigo)+" "),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){item.editFoto = false}}},[_vm._v("mdi-close")])],1),_c('v-divider'),_c('CatalisadoresPecasFormFotos',{staticClass:"py-7 px-3",model:{value:(item.fotosArquivos),callback:function ($$v) {_vm.$set(item, "fotosArquivos", $$v)},expression:"item.fotosArquivos"}})],1)],1)]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [(!item.edit)?_c('v-btn',{attrs:{"icon":"","color":"warning"},on:{"click":function($event){item.edit = true}}},[_c('v-icon',[_vm._v("mdi-pencil-circle")])],1):_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":function($event){item.edit = false}}},[_c('v-icon',[_vm._v("mdi-check-circle")])],1)]}},{key:"item.remove",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.removeItem(item.key)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)})],1),(_vm.itemsError.length > 0)?_c('v-alert',{staticClass:"mt-3",attrs:{"color":"error"}},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"color":"white","left":""}},[_vm._v("mdi-alert")]),_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.$t('CatalisadoresPecasImportarStep3.existeErro'))+" ")])],1),_c('v-btn',{attrs:{"outlined":"","color":"white"},on:{"click":function($event){_vm.showOnlyError = !_vm.showOnlyError}}},[_vm._v(" "+_vm._s(_vm.showOnlyError ? _vm.$t('CatalisadoresPecasImportarStep3.mostrarTodos') : _vm.$t('CatalisadoresPecasImportarStep3.mostrarErro'))+" ")])],1)]):_vm._e(),_c('v-divider',{staticClass:"my-3"}),_c('div',{staticClass:"d-flex justify-space-between align-center mt-2"},[_c('v-btn',{attrs:{"color":"secondary","outlined":""},on:{"click":function($event){return _vm.$emit('previous-step')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left")]),_c('span',[_vm._v(_vm._s(_vm.$t('comum.voltar')))])],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.nextStep()}}},[_c('span',[_vm._v(_vm._s(_vm.$t('comum.prosseguir')))]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-arrow-right")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }