import { KatalisTranslationRoutes } from '../translations.interface';

const messages: KatalisTranslationRoutes = {
  routes: {
    PainelDeControle: 'Dashboard',
    // auth
    AuthAtivarConta: 'Activate Account',
    AuthEsqueciMinhaSenha: 'Forgot My Password',
    AuthLogin: 'Login to the System',
    AuthRenovarSenha: 'Renew Password',
    // catalisadores-pecas
    CatalisadoresPecasImportar: 'Import Catalyst Parts',
    CatalisadoresPecasIndex: 'Catalysts - Parts',
    // catalisadores-po
    CatalisadoresPoCalculadora: 'Catalysts - Powder',
    CatalisadoresPoIndex: 'Catalysts - Powder',
    // cotacoes
    CotacoesIndex: 'Quotations',
    // erro
    ErroNaoEncontrado: 'Not Found',
    // familias
    FamiliasIndex: 'Families',
    // funcionalidades
    FuncionalidadesIndex: 'Functionalities',
    FuncionalidadesPermissoesAlterar: 'Change Functionality',
    //permissoes
    PermissoesIndex: 'Permissions',
    // grupos
    GruposIndex: 'Groups',
    GruposPermissoes: 'Permissions',
    GruposVer: 'Group',
    // lojas
    LojasCadastro: 'Store Registration',
    LojasEdicao: 'Edit Store',
    LojasIndex: 'Stores',
    LojasVer: 'Store',
    // pedidos
    PedidosIndex: 'Orders',
    PedidosRecebimento: 'Reception',
    PedidosRecebimentoIndex: 'Reception',
    // placas
    PlacasIndex: 'Electronic Boards',
    // usuarios
    UsuariosCadastroFuncionario: 'Employee Registration',
    UsuariosCadastroParceiro: 'Business Partner Registration',
    UsuariosDispositivosIndex: 'Devices',
    UsuariosEdicao: 'Edit User',
    UsuariosIndex: 'Users',
    UsuariosVer: 'User',
  },
};

export default messages;
