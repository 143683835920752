import { KatalisTranslationRules } from '../translations.interface';

const messages: KatalisTranslationRules = {
  rules: {
    campoObrigatorio: 'Campo obligatorio',
    cepInvalido: 'CEP inválido',
    cnpjInvalido: 'CNPJ inválido',
    confirmacaoSenha: 'Confirmación diferente a la contraseña ingresada',
    cpfInvalido: 'CPF inválido',
    emailInvalido: 'Email inválido',
    telefoneInvalido: 'Número de teléfono inválido',
    inteiroPositivoMaiorZero: 'El número debe ser positivo',
    inteiroPositivo: 'El número debe ser un entero positivo o cero',
    dataInvalida: 'Fecha inválida',
    // ---
    numerico: 'Campo numérico',
    numericoInteiro: 'El número debe ser un entero',
    numericoPositivoOuZero: 'El número debe ser positivo o cero',
    numericoNegativoOuZero: 'El número debe ser negativo o cero',
    numericoZero: 'El número debe ser cero',
    numericoPositivo: 'El número debe ser positivo',
    numericoNegativo: 'El número debe ser negativo',
    numericoIncorreto: 'Validación configurada incorrectamente',
  },
};

export default messages;
