import { KatalisTranslationEnums } from '../translations.interface';

const messages: KatalisTranslationEnums = {
  AvisoCatalisadorPecaEnum: {
    CHAMAR_COMERCIAL: 'Chamar o comercial',
    SEPARAR_PARA_ANALISE: 'Separar para análise',
  },
  CategoriaFamiliaEnum: {
    CATALISADORES_PECAS: 'Catalisadores - Peças',
    CATALISADORES_PO: 'Catalisadores - Pó',
    PLACAS: 'Placas eletrônicas',
  },
  CategoriaPedidoItemEnum: {
    CATALISADORES_PECAS: 'Catalisadores - Peças',
    CATALISADORES_PO: 'Catalisadores - Pó',
  },
  CategoriaUsuarioEnum: {
    FUNCIONARIO: 'Funcionário',
    PARCEIRO: 'Parceiro de negócios',
    PREPOSTO: 'Preposto',
  },
  StatusPedidoEnum: {
    AGUARDANDO_APROVACAO: 'Aguardando aprovação',
    APROVADO: 'Aprovado',
    CANCELADO: 'Cancelado',
    APROVADO_COM_ALTERACOES: 'Aprovado com alterações',
  },
  StatusPedidoItemPecaRecebimentoEnum: {
    APROVADA: 'Peça aprovada | Peças aprovadas',
    REPROVADA_DANIFICADA: 'Peça danificada | Peças danificadas',
    REPROVADA_FALTANTE: 'Peça faltante | Peças faltantes',
    REPROVADA_INCORRETA: 'Peça incorreta | Peças incorretas',
    REPROVADA_OUTROS: 'Outra justificativa',
  },
  VinculoLojaUsuarioEnum: {
    COMPRADOR: 'Comprador',
    RESPONSAVEL_ADMINISTRATIVO: 'Responsável administrativo',
    RESPONSAVEL_COMERCIAL: 'Responsável comercial',
  },
};
export default messages;
