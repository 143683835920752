import Vue from 'vue';
import parsePhoneNumber, {
  formatIncompletePhoneNumber,
} from 'libphonenumber-js';

Vue.filter('formatPhone', (number: string): string => {
  const phoneNumber = parsePhoneNumber(number);
  return phoneNumber
    ? `+${phoneNumber.countryCallingCode} ${phoneNumber.formatNational()}`
    : formatIncompletePhoneNumber(number);
});
