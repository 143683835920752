import { KatalisTranslationComponents } from '../translations.interface';

const messages: KatalisTranslationComponents = {
  // catalisadores-pecas
  CatalisadoresPecasDialogAtualizarLote: {
    alterarCatalisadoresPecasEmLote: 'Batch Change Catalyst Parts',
    quantidadePecasSelecionadas: 'Number of selected parts',
    mensagemSucesso: 'Catalyst parts changed successfully',
  },
  CatalisadoresPecasDialogInfo: {
    catalisadorPecaRemovidoComSucesso: 'Catalyst part removed successfully',
    certezaRemoverCatalisadorPeca:
      'Are you sure you want to remove this catalyst part?',
    removerCatalisadorPeca: 'Remove catalyst part',
  },
  CatalisadoresPecasFormCriarEditar: {
    cadastrarNovaMontadora: 'Register new assembler',
    mensagemSucessoAlterar: 'Catalyst part changed successfully',
    mensagemSucessoCriar: 'Catalyst part created successfully',
  },
  CatalisadoresPecasInfo: {
    deAcordoComACotacaoAtual: 'According to the current quotation',
  },
  CatalisadoresPecasImportarStep1: {
    arquivoNaoSuportado: 'File not supported',
    arrasteArquivoOuClique: 'Drag a file here or click',
    extensoesSuportadas: 'Supported extensions',
    solteArquivo: 'Drop the file',
  },
  CatalisadoresPecasImportarStep2: {
    atributoPecaCatalisador: 'Catalyst part attribute',
    colunaArquivo: 'File column',
    colunasObrigatoriasNaoSelecionadas: 'Mandatory columns not selected',
    identificacaoDasColunas: 'Column identification',
  },
  CatalisadoresPecasImportarStep3: {
    dadosDoArquivo: 'File data',
    existeErro: 'There are items with errors',
    mostrarErro: 'Show items with errors',
    mostrarTodos: 'Show all items',
  },
  CatalisadoresPecasImportarStep4: {
    existeInconsistentes: 'There are unresolved inconsistent items',
    mostrarInconsistentes: 'Show unresolved items',
    mostrarTodos: 'Show all items',
    nenhumInconsistente: 'No inconsistent items',
  },
  CatalisadoresPecasImportarStep5: {
    novasPecas: 'New parts',
    pecasQueSeraoAtualizadas: 'Parts that will be updated',
  },
  CatalisadoresPecasImportarStep6: {
    importarNovoArquivo: 'Import new file',
    naoFoiPossivelImportarAsSeguintesPecas:
      'Could not import the following parts',
    pecasImportadasComSucesso: 'Parts imported successfully',
    voltarParaListaDePecas: 'Back to parts list',
  },
  // catalisadores-po
  CatalisadoresPoDialogInfo: {
    catalisadorPoRemovidoComSucesso:
      'Catalyst powder class removed successfully',
    certezaRemoverCatalisadorPo:
      'Are you sure you want to remove this catalyst powder class?',
    removerCatalisadorPo: 'Remove catalyst powder class',
  },
  CatalisadoresPoFormCriarAlterar: {
    catalisadorPoAlteradoComSucesso:
      'Catalyst powder class changed successfully',
    catalisadorPoCriadoComSucesso: 'Catalyst powder class created successfully',
  },
  // cotacoes
  CotacoesConfigIndex: {
    verPesosEm: 'View weights in',
    verPrecosEm: 'View prices in',
  },
  CotacoesDialogInfo: {
    certezaRemoverCotacao: 'Are you sure you want to remove this quotation?',
    cotacaoRemovidaComSucesso: 'Quotation removed successfully',
    removerCotacao: 'Remove quotation',
  },
  CotacoesFormCriar: {
    cotacaoCriadaComSucesso: 'Quotation created successfully',
  },
  // enderecos
  EnderecosFormBrasil: {
    cepNaoEncontrado: 'CEP not found',
  },
  // familias
  FamiliasDialogInfo: {
    certezaRemoverFamilia: 'Are you sure you want to remove this family?',
    familiaRemovidaComSucesso: 'Family removed successfully',
    removerFamilia: 'Remove family',
  },
  FamiliasFormCriarAlterar: {
    familiaAlteradaComSucesso: 'Family changed successfully',
    familiaCriadaComSucesso: 'Family created successfully',
  },
  // grupos
  GruposDialogEdicao: {
    alterarGrupo: 'Change group',
  },
  GruposFormCriarAlterar: {
    grupoAlteradoComSucesso: 'Group changed successfully',
    grupoCriadoComSucesso: 'Group created successfully',
  },
  // lojas
  LojasForm: {
    lojaAlteradaComSucesso: 'Store changed successfully',
    lojaCriadaComSucesso: 'Store created successfully',
  },
  // placas
  PlacasDialogInfo: {
    certezaRemoverPlaca:
      'Are you sure you want to remove this electronic board?',
    placaRemovidaComSucesso: 'Electronic board removed successfully',
    removerPlaca: 'Remove electronic board',
  },
  PlacasFormCriarAlterar: {
    placaAlteradaComSucesso: 'Electronic board changed successfully',
    placaCriadaComSucesso: 'Electronic board created successfully',
  },
  // pedidos
  PedidosRecebimento: {
    avisoConfirmarRecebimento:
      'Attention, in order for those responsible for this order to receive an email with the changes in receipt, it is essential to confirm the receipt!',
    nenhumCodigoEncontradoComOTermo: 'No code found with the term',
  },
  PedidosItemRecebimentoPeca: {
    certezaDesfazerAprovacao:
      'Are you sure you want to undo the approval of this part?',
    certezaDesfazerReprovacao:
      'Are you sure you want to undo the disapproval of this part? The justification will be removed.',
  },
  // usuarios
  UsuariosFormFuncionario: {
    usuarioAlteradoComSucesso: 'User changed successfully',
    usuarioCriadoComSucesso: 'User created successfully',
  },
  UsuariosFormParceiro: {
    usuarioAlteradoComSucesso: 'User changed successfully',
    usuarioCriadoComSucesso: 'User created successfully',
  },
};

export default messages;
