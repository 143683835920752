

































































































































































































































































import Vue from 'vue';
import { debounce } from 'lodash';

import requester from '@/requester';
import { rulesRequired } from '@/utils/input-rules';
import { inputPriceToNumber, maskCurrency } from '@/utils/intl-functions';

import InputData from '@/components/inputs/InputData.vue';
import CustomCancelarSalvar from '../custom/CustomCancelarSalvar.vue';
import CotacoesFormDolar from './CotacoesFormDolar.vue';

export default Vue.extend({
  name: 'CotacoesFormCriar',
  components: {
    InputData,
    CustomCancelarSalvar,
    CotacoesFormDolar,
  },
  props: {
    grupo: {
      type: null || Object,
      default: null,
    },
    cotacaoAtual: {
      type: Object || null,
      default: null,
    },
  },
  data: () => ({
    loading: {
      submitForm: false,
    },
    form: {
      bidPt: null,
      deductionPt: null,
      bidPd: null,
      deductionPd: null,
      bidRh: null,
      deductionRh: null,
      dataBid: new Date().toISOString().substr(0, 10),
      dataDeduction: new Date().toISOString().substr(0, 10),
      fonteMetais: null,
    },
    rules: {
      required: rulesRequired,
    },
  }),
  computed: {
    rulesBidPt() {
      return [
        ...rulesRequired,
        () =>
          this.valorPt >= 0 || this.$t('rules.bidNaoPodeSerMenorQueDeduction'),
      ];
    },
    rulesBidPd() {
      return [
        ...rulesRequired,
        () =>
          this.valorPd >= 0 || this.$t('rules.bidNaoPodeSerMenorQueDeduction'),
      ];
    },
    rulesBidRh() {
      return [
        ...rulesRequired,
        () =>
          this.valorRh >= 0 || this.$t('rules.bidNaoPodeSerMenorQueDeduction'),
      ];
    },
    valorPt() {
      const bidPt = inputPriceToNumber(this.form.bidPt) || 0;
      const deductionPt = inputPriceToNumber(this.form.deductionPt) || 0;
      return bidPt - deductionPt;
    },
    valorPd() {
      const bidPd = inputPriceToNumber(this.form.bidPd) || 0;
      const deductionPd = inputPriceToNumber(this.form.deductionPd) || 0;
      return bidPd - deductionPd;
    },
    valorRh() {
      const bidRh = inputPriceToNumber(this.form.bidRh) || 0;
      const deductionRh = inputPriceToNumber(this.form.deductionRh) || 0;
      return bidRh - deductionRh;
    },
  },
  mounted() {
    this.preencheForm();
  },
  methods: {
    maskCurrency,
    preencheForm() {
      if (!this.cotacaoAtual) return;
      this.form.deductionPt = maskCurrency(
        this.cotacaoAtual?.deductionPt?.toFixed(2),
      );
      this.form.deductionPd = maskCurrency(
        this.cotacaoAtual?.deductionPd?.toFixed(2),
      );
      this.form.deductionRh = maskCurrency(
        this.cotacaoAtual?.deductionRh?.toFixed(2),
      );
      this.form.fonteMetais = this.cotacaoAtual?.fonteMetais || null;
    },
    focusInput(inputRef: string) {
      (this.$refs[inputRef] as HTMLElement).focus();
    },
    cancelar() {
      this.$refs.form.reset();
      this.$nextTick(this.preencheForm);
      this.$emit('cancelar');
    },
    submitFormDebounce: debounce(async function () {
      await this.submitForm();
    }, 200),
    async submitForm() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading.submitForm = true;
      try {
        const formDolar = {};
        Object.assign(formDolar, this.$refs?.cotacoesFormDolar?.getForm());
        const formData = {
          bidPt: inputPriceToNumber(this.form.bidPt),
          deductionPt: inputPriceToNumber(this.form.deductionPt),
          bidPd: inputPriceToNumber(this.form.bidPd),
          deductionPd: inputPriceToNumber(this.form.deductionPd),
          bidRh: inputPriceToNumber(this.form.bidRh),
          deductionRh: inputPriceToNumber(this.form.deductionRh),
          dataBid: String(this.form.dataBid),
          dataDeduction: String(this.form.dataDeduction),
          fonteMetais: String(this.form.fonteMetais),
          ...formDolar,
        };
        const cotacao = await requester.cotacoes.cria(formData);
        this.$emit('cotacao-criada', cotacao);
        this.$root.$emit(
          'toastSuccess',
          this.$t('CotacoesFormCriar.cotacaoCriadaComSucesso'),
        );
        this.$refs.form.reset();
        this.$nextTick(this.preencheForm);
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.submitForm = false;
      }
    },
  },
});
