import axios from 'axios';

const atualiza = async (lojaId: number, formData: any): Promise<any> => {
  const res = await axios.patch<any>(`lojas/${lojaId}`, formData);
  return res.data;
};

const busca = async (
  lojaId: number,
  buscarLojaQueryDto?: any,
): Promise<any> => {
  const res = await axios.get<any>(`lojas/${lojaId}`, {
    ...(buscarLojaQueryDto ? { params: buscarLojaQueryDto } : {}),
  });
  return res.data;
};

const cria = async (formData: any): Promise<any> => {
  const res = await axios.post<any>('lojas', formData);
  return res.data;
};

const lista = async (listarLojasQueryDto?: any): Promise<any[]> => {
  const res = await axios.get<any[]>('lojas', {
    ...(listarLojasQueryDto ? { params: listarLojasQueryDto } : {}),
  });
  return res.data;
};

const remove = async (lojaId: number): Promise<any> => {
  const res = await axios.delete<any>(`lojas/${lojaId}`);
  return res.data;
};

export default {
  atualiza,
  busca,
  cria,
  lista,
  remove,
};
