import { RouteConfig } from 'vue-router';

import FamiliasIndex from '@/pages/familias/FamiliasIndex.vue';
import { Rules as CaslRules } from '../../../back/src/casl/casl.constants';

const routes: RouteConfig[] = [
  {
    path: '/familias',
    name: 'FamiliasIndex',
    component: FamiliasIndex,
    meta: {
      icon: 'mdi-view-list',
      rules: [CaslRules.Familia.listar],
    },
  },
];

export default routes;
