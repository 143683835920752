









































import PedidosTabAcompanhamento from '@/components/pedidos/PedidosTabAcompanhamento.vue';
import PedidosTabRecebimento from '@/components/pedidos/PedidosTabRecebimento.vue';
import Vue from 'vue';

export default Vue.extend({
  components: { PedidosTabRecebimento, PedidosTabAcompanhamento },
  name: 'PedidosRecebimentoIndex',
  data: () => ({
    tab: 0,
  }),
});
