import axios from 'axios';

const atualiza = async (familiaId: number, formData: any): Promise<any> => {
  const res = await axios.patch<any>(`familias/${familiaId}`, formData);
  return res.data;
};

const busca = async (
  familiaId: number,
  buscarFamiliaQuery?: any,
): Promise<any> => {
  const res = await axios.get<any>(`familias/${familiaId}`, {
    ...(buscarFamiliaQuery ? { params: buscarFamiliaQuery } : {}),
  });
  return res.data;
};

const cria = async (formData: any): Promise<any> => {
  const res = await axios.post<any>('familias', formData);
  return res.data;
};

const lista = async (listarFamiliasQuery?: any): Promise<any[]> => {
  const res = await axios.get<any[]>('familias', {
    ...(listarFamiliasQuery ? { params: listarFamiliasQuery } : {}),
  });
  return res.data;
};

const listaCatalisadoresPo = async (familiaId: number): Promise<any[]> => {
  const res = await axios.get<any[]>(`familias/${familiaId}/catalisadores_po`);
  return res.data;
};

const remove = async (familiaId: number): Promise<any> => {
  const res = await axios.delete<any>(`familias/${familiaId}`);
  return res.data;
};

export default {
  atualiza,
  busca,
  cria,
  lista,
  listaCatalisadoresPo,
  remove,
};
