































































































































































































































































import CotacoesResumoAtual from '@/components/cotacoes/CotacoesResumoAtual.vue';
import requester from '@/requester';
import { CONVERSION_TROY_OZ_TO_G } from '@/utils/constants/back.constants';
import { rulesNumericPositive, rulesRequired } from '@/utils/input-rules';
import Vue from 'vue';
export default Vue.extend({
  name: 'CatalisadoresPoCalculadora',
  components: {
    CotacoesResumoAtual,
  },
  data: () => ({
    loading: {
      cotacaoAtual: true,
    },
    cotacaoAtual: null,
    form: {
      pesoBruto: null,
      impurezas: null,
      umidade: null,
      ptPpm: null,
      pdPpm: null,
      rhPpm: null,
      margem: 30,
    },
    rules: {
      required: rulesRequired,
      numericPositive: rulesNumericPositive,
    },
  }),
  computed: {
    pesoLiquido() {
      if (this.form.pesoBruto === null || this.form.pesoBruto === '')
        return null;
      return Number(this.form.pesoBruto) - Number(this.form.impurezas);
    },
    pesoSeco() {
      if (this.pesoLiquido === null) return null;
      return (
        Number(this.pesoLiquido) * (1 - Number(this.form.umidade || 0) / 100)
      );
    },
    valorTotalKg() {
      if (
        this.form.ptPpm === null ||
        this.form.ptPpm === '' ||
        this.form.pdPpm === null ||
        this.form.pdPpm === '' ||
        this.form.rhPpm === null ||
        this.form.rhPpm === ''
      )
        return null;
      return (
        (Number(this.form.ptPpm || 0) * (this.cotacaoAtual?.valorPt || 0) +
          Number(this.form.pdPpm || 0) * (this.cotacaoAtual?.valorPd || 0) +
          Number(this.form.rhPpm || 0) * (this.cotacaoAtual?.valorRh || 0)) *
        ((this.cotacaoAtual?.dolar || 0) / CONVERSION_TROY_OZ_TO_G / 1000)
      );
    },
    valorTotal() {
      if (this.pesoSeco === null) return null;
      return this.pesoSeco * this.valorTotalKg;
    },
    valorLiquidoKg() {
      if (this.valorTotalKg === null) return null;
      return this.valorTotalKg * (1 - Number(this.form.margem) / 100);
    },
    valorLiquido() {
      if (this.valorTotal === null) return null;
      return this.valorTotal * (1 - Number(this.form.margem) / 100);
    },
  },
  mounted() {
    this.buscaCotacaoAtual();
  },
  methods: {
    colorAlert(value: number) {
      if (value < 0) return 'error lighten-4';
      if (value > 0) return 'success lighten-4';
      return 'grey lighten-4';
    },
    classAlert(value: number) {
      if (value < 0) return 'error--text text--darken-2';
      if (value > 0) return 'success--text text--darken-2';
      return 'grey--text';
    },
    colorFinal(value: number) {
      if (value < 0) return 'error';
      if (value > 0) return 'primary';
      return 'grey lighten-4';
    },
    classFinal(value: number) {
      if (value < 0) return 'white--text';
      if (value > 0) return 'white--text';
      return 'grey--text';
    },
    reset() {
      this.form.pesoBruto = null;
      this.form.impurezas = null;
      this.form.umidade = null;
      this.form.ptPpm = null;
      this.form.pdPpm = null;
      this.form.rhPpm = null;
      this.form.margem = 30;
      this.$refs.form.resetValidation();
    },
    maskNumeric(value: string) {
      const replaced = String(value)
        .replace(/,/, '.')
        .replace(/[^\d.]+/, '');
      const splitted = replaced.split('.');
      if (splitted.length === 1) return splitted[0];
      return `${splitted.slice(0, 1)}.${splitted.slice(1).join('')}`;
    },
    async buscaCotacaoAtual() {
      this.loading.cotacaoAtual = true;
      try {
        const paginationQuery = {
          limit: 1,
          offset: 0,
          orderBy: ['createdAt'],
          orderDesc: [true],
        };
        const resData = await requester.cotacoes.lista(paginationQuery);
        this.cotacaoAtual = resData?.rows[0] || null;
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.cotacaoAtual = false;
      }
    },
  },
});
