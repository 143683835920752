













import Vue from 'vue';
export default Vue.extend({
  name: 'CustomCancelarSalvar',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    cancelarIcon: {
      type: String,
      default: 'mdi-close',
    },
    cancelarText: {
      type: String,
      default: 'comum.cancelar',
    },
    salvarIcon: {
      type: String,
      default: 'mdi-floppy',
    },
    salvarText: {
      type: String,
      default: 'comum.salvar',
    },
  },
});
