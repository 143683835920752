import { RouteConfig } from 'vue-router';

import PermissoesIndex from '@/pages/permissoes/PermissoesIndex.vue';
// import { Rules as CaslRules } from '../../../back/src/casl/casl.constants';

const routes: RouteConfig[] = [
  {
    path: '/permissoes',
    name: 'PermissoesIndex',
    component: PermissoesIndex,
    meta: {
      icon: 'mdi-lock',
    },
  },
];

export default routes;
