

























































import Vue from 'vue';

import requester from '@/requester';
import { autocompleteFilterDeburr } from '@/utils/filter-functions';
import { rulesRequired } from '@/utils/input-rules';

export default Vue.extend({
  name: 'EnderecosFormInternacional',
  props: {
    endereco: {
      type: Object || null,
      default: null,
    },
  },
  data: () => ({
    loading: {
      paises: false,
    },
    paises: [],
    form: {
      codigoPostal: null,
      logradouro: null,
      complemento: null,
      municipioNome: null,
      ufNome: null,
      paisIsoAlpha3: null,
    },
    rules: {
      required: rulesRequired,
    },
  }),
  computed: {
    itemsPaises() {
      return this.paises.map((el) => ({
        value: el?.id?.['ISO-ALPHA-3'],
        text: el?.nome,
      }));
    },
  },
  async mounted() {
    await this.buscaPaises();
    this.preencheForm();
  },
  methods: {
    preencheForm() {
      if (!this.endereco) return;
      this.form.codigoPostal = this.endereco?.codigoPostal || null;
      this.form.logradouro = this.endereco?.logradouro || null;
      this.form.complemento = this.endereco?.complemento || null;
      this.form.municipioNome = this.endereco?.municipioNome || null;
      this.form.ufNome = this.endereco?.ufNome || null;
      this.form.paisIsoAlpha3 = this.endereco?.paisIsoAlpha3 || null;
    },
    validate() {
      return this.$refs?.form?.validate();
    },
    getFormData() {
      return {
        codigoPostal: this.form?.codigoPostal?.trim() || null,
        logradouro: this.form?.logradouro?.trim() || null,
        numero: null,
        complemento: this.form?.complemento?.trim() || null,
        bairro: null,
        municipioCodigoIbge: null,
        municipioNome: this.form?.municipioNome?.trim() || null,
        ufSigla: null,
        ufNome: this.form?.ufNome?.trim() || null,
        paisIsoAlpha3: this.form?.paisIsoAlpha3,
        paisNome:
          this.itemsPaises
            ?.find((el) => el.value === this.form?.paisIsoAlpha3)
            ?.text?.trim() || null,
        latitude: null,
        longitude: null,
      };
    },
    autocompleteFilterDeburr,
    async buscaPaises() {
      this.loading.paises = true;
      try {
        this.paises = await requester.externo.ibge.paises();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.paises = false;
      }
    },
  },
});
