



















































import Vue from 'vue';

import InputFoto from '../inputs/InputFoto.vue';
import InputFotoPreview from '../inputs/InputFotoPreview.vue';

import draggable from 'vuedraggable';

export default Vue.extend({
  name: 'CatalisadoresPecasFormFotos',
  components: {
    draggable,
    InputFoto,
    InputFotoPreview,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    tab: 0,
    dynamicKey: 1,
  }),
  computed: {
    blanks() {
      if (this.$vuetify.breakpoint.xsOnly) return [];
      const blanks = [];
      const valueLength = this.value.length;
      for (let i = 0; i < 6 - valueLength; i++) {
        blanks.push(`blank_${i}`);
      }
      if (blanks.length === 0) {
        if (valueLength % 3 === 1) return ['blank_0', 'blank_1'];
        if (valueLength % 3 === 2) return ['blank_0'];
      }
      return blanks;
    },
  },
  methods: {
    srcItem(file: File | null) {
      return file ? URL.createObjectURL(file) : null;
    },
    removeItem(key: number) {
      const index = this.value.findIndex((el) => el.key === key);
      if (index >= 0) {
        this.$emit('input', [
          ...(index > 0 ? this.value.slice(0, index) : []),
          ...this.value.slice(index + 1),
        ]);
        setTimeout(() => {
          this.tab = index;
        }, 25);
      }
    },
    addFiles(files: FileList) {
      const newValues = [];
      for (let i = 0; i < files.length; i++) {
        newValues.push({
          key: this.dynamicKey++,
          file: files[i],
          fotoArquivo: null,
          remover: false,
        });
      }
      this.$emit('input', [...this.value, ...newValues]);
      setTimeout(() => {
        this.tab = this.value.length;
      }, 25);
    },
  },
});
