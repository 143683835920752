

















































import Vue from 'vue';
import { Usuario } from '@models/usuario.model';
import { CategoriaUsuarioEnum } from '../../../../back/src/usuarios/usuarios.constants';
import { calculaMargemComposta } from '@/utils/usuarios-margens-helper';

export default Vue.extend({
  name: 'UsuariosTabelaMargens',
  props: {
    usuario: {
      type: Object as () => Usuario,
      required: true,
    },
  },
  computed: {
    isFuncionario() {
      return this.usuario.categoria === CategoriaUsuarioEnum.FUNCIONARIO;
    },
    isPreposto() {
      return this.usuario.categoria === CategoriaUsuarioEnum.PREPOSTO;
    },
  },
  methods: {
    calculaMargemComposta,
    buscaMargemCategoriaDoResponsavel(categoria) {
      return this.usuario.responsavel.margens?.find(
        (el) => el.categoria === categoria,
      )?.margem;
    },
    margemComposta(itemMargem) {
      return this.calculaMargemComposta(
        this.buscaMargemCategoriaDoResponsavel(itemMargem.categoria),
        itemMargem.margem,
      );
    },
  },
});
