














































import Vue from 'vue';

import requester from '@/requester';
import { CategoriaFamiliaEnum } from '@/utils/constants/back.constants';

import CatalisadoresPoTabela from '@/components/catalisadores-po/CatalisadoresPoTabela.vue';

export default Vue.extend({
  name: 'CatalisadoresPoIndex',
  components: {
    CatalisadoresPoTabela,
  },
  data: () => ({
    tab: 0,
    loading: {
      familias: true,
    },
    familiasCatalisadoresPo: [],
  }),
  mounted() {
    this.listaFamilias();
  },
  methods: {
    async listaFamilias() {
      this.loading.familias = true;
      try {
        const listarFamiliasQuery = {
          categoria: CategoriaFamiliaEnum.CATALISADORES_PO,
        };
        this.familiasCatalisadoresPo = await requester.familias.lista(
          listarFamiliasQuery,
        );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.familias = false;
      }
    },
  },
});
