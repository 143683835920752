import { KatalisTranslationRules } from '../translations.interface';

const messages: KatalisTranslationRules = {
  rules: {
    campoObrigatorio: 'Campo obrigatório',
    cepInvalido: 'CEP inválido',
    cnpjInvalido: 'CNPJ inválido',
    confirmacaoSenha: 'Confirmação diferente da senha digitada',
    cpfInvalido: 'CPF inválido',
    emailInvalido: 'Email inválido',
    telefoneInvalido: 'Número de telefone inválido',
    inteiroPositivoMaiorZero: 'Número deve ser positivo',
    inteiroPositivo: 'Número deve ser inteiro zero ou positivo',
    dataInvalida: 'Data inválida',
    // ---
    numerico: 'Campo numérico',
    numericoInteiro: 'Número deve ser um inteiro',
    numericoPositivoOuZero: 'Número deve ser positivo ou zero',
    numericoNegativoOuZero: 'Número deve ser negativo ou zero',
    numericoZero: 'Número deve ser zero',
    numericoPositivo: 'Número deve ser positivo',
    numericoNegativo: 'Número deve ser negativo',
    numericoIncorreto: 'Validação configurada incorretamente',
  },
};

export default messages;
