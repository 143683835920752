import { KatalisTranslationComum } from '../translations.interface';

const messages: KatalisTranslationComum = {
  comum: {
    acao: 'Action',
    acoes: 'Actions',
    acompanhamento: 'Follow-up',
    adicionar: 'Add',
    administrativo: 'Administrative',
    agencia: 'Agency',
    aguardandoCadastroSenha: 'Waiting for password registration',
    aguardandoVerificacao: 'Waiting for verification',
    alterar: 'Change',
    alterarEmLote: 'Change in batch',
    alterarRecebimento: 'Change receipt',
    analise: 'Analysis',
    analises: 'Analyses',
    aplicar: 'Apply',
    aprovada: 'Approved',
    aprovadas: 'Approved',
    aprovadoComAlteracoes: 'Approved with changes',
    ativa: 'Active',
    ativacao: 'Activation',
    ativo: 'Active',
    autorizado: 'Authorized',
    autorizar: 'Authorize',
    autorizarNovosDispositivos: 'Authorize new devices',
    aviso: 'Warning',
    bairro: 'Neighborhood',
    baixarPdf: 'Download PDF',
    banco: 'Bank',
    bid: 'Bid',
    bloquear: 'Block',
    brasil: 'Brazil',
    busca: 'Search',
    buscar: 'Search',
    cadastrar: 'Register',
    cadastrarNovaPeca: 'Register new part',
    calculadora: 'Calculator',
    camposComAsteriscoObrigatorios: 'Fields with * are mandatory',
    cancelar: 'Cancel',
    cancelarPedido: 'Cancel order',
    catalisadoresPecas: 'Catalysts - Parts',
    catalisadoresPo: 'Catalysts - Powder',
    categoria: 'Category',
    categoriaUsuario: 'User category',
    celular: 'Cellphone',
    cep: 'ZIP Code',
    certezaRemoverGrupo: 'Are you sure you want to remove this group?',
    cidade: 'City',
    classe: 'Class',
    cnpj: 'CNPJ',
    codigo: 'Code',
    codigoPostal: 'Postal code',
    codigos: 'Codes',
    codigoSap: 'SAP code',
    coluna: 'Column',
    comercial: 'Commercial',
    complemento: 'Complement',
    compradores: 'Buyers',
    configuracoes: 'Settings',
    configuracoesTabela: 'Table settings',
    confirmacaoSenha: 'Password confirmation',
    confirmar: 'Confirm',
    confirmarRecebimento: 'Confirm receipt',
    conta: 'Account',
    controle: 'Control',
    controleAcesso: 'Access control',
    conviteNaoEnviado: 'Invitation not sent',
    cotacao: 'Quotation',
    cotacaoAtual: 'Current quotation',
    cotacoes: 'Quotations',
    cpf: 'CPF',
    criacao: 'Creation',
    criadoEm: 'Created at',
    criadoPor: 'Created by',
    dadosBancarios: 'Banking data',
    dadosGerais: 'General data',
    dadosPessoais: 'Personal data',
    data: 'Date',
    dataAnalise: 'Analysis date',
    dataUltimaAnalise: 'Last analysis date',
    de: 'From',
    deduction: 'Deduction',
    desconhecida: 'Unknown',
    desconhecido: 'Unknown',
    descricao: 'Description',
    descricaoCatalogo: 'Catalog description',
    desfazer: 'Undo',
    desfazerAprovacao: 'Undo approval',
    desfazerReprovacao: 'Undo disapproval',
    detalhe: 'Detail',
    detalhes: 'Details',
    dispositivo: 'Device',
    dispositivos: 'Devices',
    documento: 'Document',
    dolar: 'Dollar',
    email: 'Email',
    emailsSecundarios: 'Secondary emails',
    emConstrucao: 'Under construction',
    endereco: 'Address',
    enderecoNaoCadastrado: 'Address not registered',
    entrar: 'Enter',
    enviar: 'Send',
    envio: 'Shipping',
    espanhol: 'Spanish',
    estadoProvinciaRegiao: 'State/Province/Region',
    exibindo: 'Displaying',
    existente: 'Existing',
    familia: 'Family',
    familias: 'Families',
    fechar: 'Close',
    filtrar: 'Filter',
    fonte: 'Source',
    fornecedor: 'Supplier',
    foto: 'Photo',
    fotos: 'Photos',
    funcionalidade: 'Functionality',
    funcionalidades: 'Functionalities',
    funcionario: 'Employee',
    funcionarios: 'Employees',
    g: 'g',
    grama: 'Gram',
    grupo: 'Group',
    grupoRemovidoComSucesso: 'Group removed successfully',
    grupos: 'Groups',
    historico: 'History',
    historicoAlteracoes: 'Change history',
    id: 'ID',
    identificacao: 'Identification',
    importar: 'Import',
    importarPlanilha: 'Import spreadsheet',
    impurezas: 'Impurities',
    info: 'Info',
    informacao: 'Information',
    informacoes: 'Informations',
    ingles: 'English',
    internacional: 'International',
    item: 'Item',
    justificativa: 'Justification',
    justificativaCancelamento: 'Cancellation justification',
    kg: 'kg',
    linhaEndereco: 'Address line',
    lista: 'List',
    listagem: 'Listing',
    logoutRealizadoComSucesso: 'Logout successful',
    logradouro: 'Street',
    loja: 'Store',
    lojas: 'Stores',
    margem: 'Margin',
    margemComposta: 'Composite margin',
    margemMedia: 'Average margin',
    margemMinima: 'Minimum margin',
    margemPadrao: 'Standard margin',
    margemPeca: 'Part margin',
    margemPecas: 'Part margins',
    margemPo: 'Powder margin',
    margemResponsavel: 'Responsible margin',
    margemUsuario: 'User margin',
    margens: 'Margins',
    material: 'Material',
    metais: 'Metals',
    metal: 'Metal',
    modelo: 'Model',
    moedaSistema: process.env.VUE_APP_MOEDA_SISTEMA || 'BRL',
    moedaSistemaPorKg: `${process.env.VUE_APP_MOEDA_SISTEMA || 'BRL'}/kg`,
    montadora: 'Assembler',
    mp: 'MP',
    mu: 'MU',
    municipio: 'Municipality',
    nao: 'No',
    navegador: 'Browser',
    nenhumaMargemCadastrada: 'No margin registered',
    nenhumCompradorVinculado: 'No buyer linked',
    nome: 'Name',
    nomeSistema: process.env.VUE_APP_NOME_SISTEMA || 'Administrative Portal',
    nova: 'New',
    novasAnalises: 'New analyses',
    novo: 'New',
    numero: 'Number',
    observacoes: 'Observations',
    observacoesParceiro: 'Partner observations',
    observacoesParceiroNegocios: 'Business partner observations',
    onca: 'Ounce',
    ordenacaoCrescente: 'Ascending order',
    ordenacaoDecrescente: 'Descending order',
    outros: 'Others',
    oz: 'Oz',
    painelDeControle: 'Control panel',
    pais: 'Country',
    paladio: 'Palladium',
    paraEnvioAtualizacoesPedido: 'For sending order updates',
    parceiro: 'Partner',
    parceiroNegocios: 'Business partner',
    parceiros: 'Partners',
    parceirosNegocios: 'Business partners',
    pd: 'Pd',
    peca: 'Piece',
    pecaCatalisador: 'Catalyst piece',
    pecaDanificada: 'Damaged part',
    pecaFaltante: 'Missing part',
    pecaIncorreta: 'Incorrect part',
    pecas: 'Pieces',
    pecasCatalisadores: 'Catalyst pieces',
    pedido: 'Order',
    pedidos: 'Orders',
    pendentes: 'Pending',
    permissao: 'Permission',
    permissoes: 'Permissions',
    peso: 'Weight',
    pesoBruto: 'Gross weight',
    pesoDaCeramica: 'Ceramic weight',
    pesoLiquido: 'Net weight',
    pesoSeco: 'Dry weight',
    pesoUn: 'Weight un',
    placa: 'Plate',
    placaEletronica: 'Electronic board',
    placas: 'Plates',
    placasEletronicas: 'Electronic boards',
    plataforma: 'Platform',
    platina: 'Platinum',
    poCatalisadores: 'Catalyst powder',
    portugues: 'Portuguese',
    ppm: 'ppm',
    precoMedio: 'Average price',
    preposto: 'Agent',
    prepostos: 'Agents',
    previaFoto: 'Preview photo',
    primeiroAcessoEm: 'First access at',
    principal: 'Main',
    produto: 'Product',
    produtos: 'Products',
    prosseguir: 'Proceed',
    pt: 'Pt',
    qtde: 'Qty',
    qtdeItens: 'Qty. items',
    quantidade: 'Quantity',
    recebido: 'Received',
    recebimento: 'Receipt',
    ref: 'Ref',
    referencia: 'Reference',
    registro: 'Record',
    remover: 'Remove',
    removerGrupo: 'Remove group',
    removerOrdenacao: 'Remove sorting',
    reprovada: 'Disapproved',
    reprovadas: 'Disapproved',
    responsavel: 'Responsible',
    responsavelAdm: 'Administrative responsible',
    responsavelComercial: 'Commercial responsible',
    rh: 'HR',
    rodio: 'Rhodium',
    sair: 'Exit',
    salvar: 'Save',
    selecionar: 'Select',
    selecione: 'Select',
    selecionePais: 'Select the country',
    semEndereco: 'No address',
    semFoto: 'No photo',
    senha: 'Password',
    senhaCadastrada: 'Registered password',
    separarParaAnalise: 'Separate for analysis',
    sim: 'Yes',
    sobrenome: 'Last name',
    status: 'Status',
    telefone: 'Phone',
    telefoneFixo: 'Landline',
    teor: 'Content',
    teores: 'Contents',
    teorPd: 'Content Pd',
    teorPt: 'Content Pt',
    teorRh: 'Content Rh',
    total: 'Total',
    uf: 'UF',
    ultimaAtualizacaoEm: 'Last update at',
    ultimaAtualizacaoPor: 'Last update by',
    ultimaModificacaoEm: 'Last modification at',
    ultimaModificacaoPor: 'Last modification by',
    ultimoAcessoEm: 'Last access at',
    umidade: 'Humidity',
    unidade: 'Unit',
    usd: 'USD',
    usuario: 'User',
    usuarioAlteradoComSucesso: 'User changed successfully',
    usuarioCriadoComSucesso: 'User created successfully',
    usuarios: 'Users',
    valor: 'Value',
    valores: 'Values',
    valorKg: 'Value per kg',
    valorLiquido: 'Net value',
    valorMedio: 'Average value',
    valorTotal: 'Total value',
    valorUn: 'Unit value',
    verAnalises: 'View analyses',
    verificadas: 'Verified',
    verificarPecas: 'Check parts',
    verPecas: 'View parts',
    vinculo: 'Link',
    visivel: 'Visible',
    visualizacao: 'Visualization',
    visualizar: 'View',
    voltar: 'Back',
    vt: 'VT',
    vtMpMu: 'VT x MP x MU',
  },
  moeda: {
    BRL: 'Brazilian Real',
    CLP: 'Chilean Peso',
    COP: 'Colombian Peso',
    JPY: 'Japanese Yen',
  },
};

export default messages;
