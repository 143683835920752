












































































































































































import Vue from 'vue';
import { debounce } from 'lodash';

import requester from '@/requester';
import {
  getIconBrowser,
  getIconDevice,
  getIconPlatform,
  getTypeDevice,
  itemsBrowser,
  itemsDevice,
  itemsPlatform,
} from '@/utils/usuarios-dispositivos-helper';

import { UsuarioDispositivo } from '../../../../back/src/models/usuario-dispositivo.model';
import { ListarDispositivosQueryDto } from '../../../../back/src/usuarios/dto/listar-dispositivos-query.dto';
import { CategoriaUsuarioEnum } from '../../../../back/src/usuarios/usuarios.constants';

import UsuariosDialogDispositivoInfo from './UsuariosDialogDispositivoInfo.vue';

export default Vue.extend({
  name: 'UsuariosTabDispositivos',
  components: { UsuariosDialogDispositivoInfo },
  data: () => ({
    loading: {
      dispositivos: true,
    },
    dataTableOptions: {
      page: 1,
      itemsPerPage: 10,
      mustSort: true,
      sortBy: ['createdAt'],
      sortDesc: [true],
    },
    dispositivos: [] as UsuarioDispositivo[],
    dialogInfo: false,
    dispositivoSelecionado: null as null | UsuarioDispositivo,
    serverItemsLength: 0,
    search: null,
    filterCategoria: null,
    filterAutorizado: null,
    filterDevice: null,
    filterPlatform: null,
    filterBrowser: null,
    itemsDevice,
    itemsPlatform,
    itemsBrowser,
  }),
  computed: {
    itemsCategorias() {
      return [
        {
          value: CategoriaUsuarioEnum.FUNCIONARIO,
          text: this.$t(
            `CategoriaUsuarioEnum.${CategoriaUsuarioEnum.FUNCIONARIO}`,
          ),
        },
        {
          value: CategoriaUsuarioEnum.PARCEIRO,
          text: this.$t(
            `CategoriaUsuarioEnum.${CategoriaUsuarioEnum.PARCEIRO}`,
          ),
        },
        {
          value: CategoriaUsuarioEnum.PREPOSTO,
          text: this.$t(
            `CategoriaUsuarioEnum.${CategoriaUsuarioEnum.PREPOSTO}`,
          ),
        },
      ];
    },
    itemsAutorizado() {
      return [
        {
          value: 'true',
          text: this.$t('comum.sim'),
        },
        {
          value: 'false',
          text: this.$t('comum.nao'),
        },
      ];
    },
    headers() {
      return [
        {
          value: 'usuario.nomeCompleto',
          text: this.$t('comum.usuario'),
          class: 'text-no-wrap',
        },
        {
          value: 'usuario.email',
          text: this.$t('comum.email'),
          class: 'text-no-wrap',
        },
        {
          value: 'usuario.categoria',
          text: this.$t('comum.categoria'),
          class: 'text-no-wrap',
        },
        {
          value: 'createdAt',
          text: this.$t('comum.primeiroAcessoEm'),
          class: 'text-no-wrap',
        },

        {
          value: 'dispositivoInfo.isMobile',
          text: this.$t('comum.dispositivo'),
          class: 'text-no-wrap',
          align: 'center',
          sortable: false,
        },
        {
          value: 'dispositivoInfo.platform',
          text: this.$t('comum.plataforma'),
          class: 'text-no-wrap',
          align: 'center',
          sortable: false,
        },
        {
          value: 'dispositivoInfo.browser',
          text: this.$t('comum.navegador'),
          class: 'text-no-wrap',
          align: 'center',
          sortable: false,
        },
        {
          value: 'dispositivoInfo.source',
          text: this.$t('comum.identificacao'),
          class: 'text-no-wrap',
          sortable: false,
        },
        {
          value: 'dispositivoAutorizado',
          text: this.$t('comum.autorizado'),
          class: 'text-no-wrap',
        },
        {
          value: 'info',
          text: this.$t('comum.detalhes'),
          class: 'text-no-wrap',
          sortable: false,
        },
      ];
    },
  },
  watch: {
    dataTableOptions() {
      this.listaDispositivos(false);
    },
    filterCategoria() {
      this.listaDispositivos(true);
    },
    filterAutorizado() {
      this.listaDispositivos(true);
    },
    filterDevice() {
      this.listaDispositivos(true);
    },
    filterPlatform() {
      this.listaDispositivos(true);
    },
    filterBrowser() {
      this.listaDispositivos(true);
    },
    search() {
      this.loading.dispositivos = true;
      this.listaDispositivosDebounce(true);
    },
  },
  mounted() {
    this.listaDispositivos();
  },
  methods: {
    getIconBrowser,
    getIconDevice,
    getIconPlatform,
    getTypeDevice,
    dispositivoAlterado(dispositivo: UsuarioDispositivo) {
      const index = this.dispositivos.findIndex(
        (el) => el.id === dispositivo.id,
      );
      if (index < 0) return;
      this.$set(this.dispositivos, index, dispositivo);
      this.dispositivoSelecionado = dispositivo;
    },
    infoDialog(dispositivo: UsuarioDispositivo) {
      this.dispositivoSelecionado = dispositivo;
      this.dialogInfo = true;
    },
    montaQueryDto(resetPage: boolean) {
      // page/itemsPerPage (limit/offset)
      if (resetPage) {
        this.dataTableOptions.page = 1;
      }
      const page = this.dataTableOptions?.page || 1;
      const itemsPerPage = this.dataTableOptions?.itemsPerPage || 10;
      const limit = String(itemsPerPage);
      const offset = String((page - 1) * itemsPerPage);
      // order
      const orderBy = this.dataTableOptions?.sortBy || [];
      const orderDesc = this.dataTableOptions?.sortDesc || [];
      // busca
      const search = this.search?.trim() || null;
      // filters
      const categoria = this.filterCategoria || null;
      const dispositivoAutorizado = this.filterAutorizado || null;
      const dispositivoInfoDevice = this.filterDevice || null;
      const dispositivoInfoPlatform = this.filterPlatform || null;
      const dispositivoInfoBrowser = this.filterBrowser || null;

      // query dto
      const queryDto: ListarDispositivosQueryDto = {
        limit,
        offset,
        orderBy,
        orderDesc,
        ...(search ? { search } : {}),
        ...(categoria ? { categoria } : {}),
        ...(dispositivoAutorizado ? { dispositivoAutorizado } : {}),
        ...(dispositivoInfoDevice ? { dispositivoInfoDevice } : {}),
        ...(dispositivoInfoPlatform ? { dispositivoInfoPlatform } : {}),
        ...(dispositivoInfoBrowser ? { dispositivoInfoBrowser } : {}),
      };
      return queryDto;
    },
    listaDispositivosDebounce: debounce(function (resetPage = false) {
      this.listaDispositivos(resetPage);
    }, 250),
    async listaDispositivos(resetPage = false) {
      this.loading.dispositivos = true;
      try {
        const queryDto = this.montaQueryDto(resetPage);
        const resData = await requester.usuarios.listaDispositivos(queryDto);
        this.dispositivos = resData.rows;
        this.serverItemsLength = resData.count;
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.dispositivos = false;
      }
    },
  },
});
