import axios from 'axios';
import { Permissao } from '../../../back/src/models/permissao.model';

const listar = async (): Promise<Permissao[]> => {
  const res = await axios.get<Permissao[]>('permissoes');
  return res.data;
};
const criar = async (formData: any): Promise<Permissao> => {
  const res = await axios.post<Permissao>('permissoes', formData);
  return res.data;
};

const buscar = async (id: number): Promise<Permissao> => {
  const res = await axios.get<Permissao>(`permissoes/${id}`);
  return res.data;
};
const remove = async (permissaoId: number): Promise<Permissao> => {
  const res = await axios.delete<Permissao>(`permissoes/${permissaoId}`);
  return res.data;
};

const sincronizar = async (): Promise<any> => {
  const res = await axios.post<any>('permissoes/sincronizar');
  return res.data;
};

export default {
  listar,
  criar,
  buscar,
  remove,
  sincronizar,
};
