var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CotacoesDialogCadastro',{attrs:{"cotacao-atual":_vm.cotacaoAtual},on:{"cotacao-criada":function($event){return _vm.cotacaoCriada()}},model:{value:(_vm.dialogCadastro),callback:function ($$v) {_vm.dialogCadastro=$$v},expression:"dialogCadastro"}}),_c('CotacoesDialogInfo',{attrs:{"cotacao-id":_vm.cotacaoInfoId},on:{"cotacao-removida":function($event){return _vm.cotacaoRemovida()}},model:{value:(_vm.dialogInfo),callback:function ($$v) {_vm.dialogInfo=$$v},expression:"dialogInfo"}}),_c('CotacoesResumoAtual',{staticClass:"mb-3",attrs:{"cotacao-atual":_vm.cotacaoAtual,"cotacao-anterior":_vm.cotacaoAnterior,"converter-moeda":_vm.config.converterMoeda,"converter-peso":_vm.config.converterPeso}}),_c('div',{staticClass:"\n      d-flex\n      flex-column-reverse flex-sm-row\n      justify-sm-space-between\n      align-sm-center\n      mb-0 mb-sm-3\n    "},[_c('CotacoesConfigIndex',{attrs:{"config":_vm.config},on:{"aplicar":function($event){return _vm.aplicarConfig($event)}}}),(_vm.permissaoCriar)?_c('v-btn',{staticClass:"my-3 my-sm-0",attrs:{"block":_vm.$vuetify.breakpoint.xsOnly,"depressed":"","color":"success"},on:{"click":function($event){_vm.dialogCadastro = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_c('span',[_vm._v(_vm._s(_vm.$t('comum.cotacao')))])],1):_vm._e()],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"items":_vm.cotacoes,"headers":_vm.headers,"loading":_vm.loading.cotacoes,"options":_vm.options,"server-items-length":_vm.totalCotacoes,"item-key":"id","sort-by":"createdAt","sort-desc":"","must-sort":"","disable-filtering":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdAt))+" ")]}},{key:"item.bidPt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPrice")(_vm.converteValor(item.bidPt, item.dolar),{ fractionDigits: 2 }))+" ")]}},{key:"item.bidPd",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPrice")(_vm.converteValor(item.bidPd, item.dolar),{ fractionDigits: 2 }))+" ")]}},{key:"item.bidRh",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPrice")(_vm.converteValor(item.bidRh, item.dolar),{ fractionDigits: 2 }))+" ")]}},{key:"item.precoMedio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPrice")(_vm.calculaPrecoMedio(item),{ fractionDigits: 2 }))+" ")]}},{key:"item.dolar",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.dolar,{ fractionDigits: 4 }))+" ")]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.infoCotacao(item)}}},[_c('v-icon',[_vm._v("mdi-information")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }