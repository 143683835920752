























import Vue from 'vue';
import { debounce } from 'lodash';

import requester from '@/requester';
import { rulesRequired } from '@/utils/input-rules';

import CustomCancelarSalvar from '../custom/CustomCancelarSalvar.vue';

export default Vue.extend({
  name: 'GruposFormCriarAlterar',
  components: {
    CustomCancelarSalvar,
  },
  props: {
    grupo: {
      type: null || Object,
      default: null,
    },
  },
  data: () => ({
    loading: {
      submitForm: false,
    },
    form: {
      nome: '',
    },
    rules: {
      required: rulesRequired,
    },
  }),
  mounted() {
    this.preencheForm();
  },
  methods: {
    preencheForm() {
      if (this.grupo) {
        this.form.nome = this.grupo.nome;
      }
    },
    cancelar() {
      this.$refs.form.reset();
      this.$emit('cancelar');
    },
    submitFormDebounce: debounce(async function () {
      await this.submitForm();
    }, 200),
    async submitForm() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading.submitForm = true;
      try {
        const formData = {
          nome: this.form?.nome?.trim() || '',
        };
        let grupo;
        if (this.grupo) {
          grupo = await requester.grupos.atualiza(this.grupo.id, formData);
          this.$root.$emit(
            'toastSuccess',
            this.$t('GruposFormCriarAlterar.grupoAlteradoComSucesso'),
          );
          this.$emit('grupo-alterado', grupo);
        } else {
          grupo = await requester.grupos.cria(formData);
          this.$root.$emit(
            'toastSuccess',
            this.$t('GruposFormCriarAlterar.grupoCriadoComSucesso'),
          );
          this.$emit('grupo-criado', grupo);
        }
        this.$refs.form.reset();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.submitForm = false;
      }
    },
  },
});
