


























































































































































import Vue from 'vue';
import { forEach, groupBy } from 'lodash';
export default Vue.extend({
  name: 'CatalisadoresPecasImportarStep4',
  data: () => ({
    headers: [],
    headersAnalises: [],
    dadosAgrupadosCodigo: {},
    dadosInconsistentes: {},
    showOnlyError: false,
  }),
  computed: {
    errorInconsistentes() {
      let errorInconsistentes = false;
      forEach(this.dadosInconsistentes, (items) => {
        errorInconsistentes =
          errorInconsistentes || this.nenhumItemSelecionado(items);
      });
      return errorInconsistentes;
    },
  },
  methods: {
    nenhumItemSelecionado(items) {
      return !items.reduce((acc, item) => acc || item.utilizar, false);
    },
    nextStep() {
      this.$emit('next-step', {
        headers: [...this.headers, ...this.headersAnalises].slice(1),
        items: this.dadosAgrupadosCodigo,
      });
    },
    inicio(headers, items) {
      this.headers = [
        {
          value: 'utilizar',
          text: this.$t('comum.selecionar'),
          align: 'center',
          width: 1,
        },
        ...headers.slice(0, headers.length - 2),
      ];
      this.dadosAgrupadosCodigo = groupBy(
        items,
        (el) =>
          `${el.codigo?.trim()}${
            el.detalhe?.trim() ? ` - ${el.detalhe?.trim()}` : ''
          }`,
      );
      this.dadosInconsistentes = {};
      Object.keys(this.dadosAgrupadosCodigo).forEach((codigoDetalhe) => {
        if (this.dadosAgrupadosCodigo[codigoDetalhe].length > 1) {
          const aux = this.dadosAgrupadosCodigo[codigoDetalhe];
          const montadorasDiferentes = new Set(
            aux.map((el) => el?.montadora?.trim()),
          ).size;
          const modelosDiferentes = new Set(aux.map((el) => el?.modelo?.trim()))
            .size;
          const margensDiferentes = new Set(aux.map((el) => el?.margemPeca))
            .size;
          if (
            montadorasDiferentes > 1 ||
            modelosDiferentes > 1 ||
            margensDiferentes > 1
          ) {
            this.dadosInconsistentes[codigoDetalhe] = aux;
          }
        }
      });
    },
  },
});
