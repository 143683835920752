


































































































































































































































































































































































































































import Vue from 'vue';
import { debounce, orderBy, uniq } from 'lodash';
import InputNumber from '@/components/inputs/InputNumber.vue';

import requester from '@/requester';
import {
  rulesBoolean,
  rulesCpf,
  rulesEmail,
  rulesNumericPositive,
  rulesRequired,
} from '@/utils/input-rules';
import { autocompleteFilterDeburr } from '@/utils/filter-functions';

import {
  CategoriaUsuarioEnum,
  UsuarioScopesEnum,
} from '../../../../back/src/usuarios/usuarios.constants';

import EnderecosFormBrasil from '@/components/enderecos/EnderecosFormBrasil.vue';
import EnderecosFormInternacional from '@/components/enderecos/EnderecosFormInternacional.vue';
import InputTelefone from '@/components/inputs/InputTelefone.vue';

import { calculaMargemComposta } from '@/utils/usuarios-margens-helper';
import { CategoriaPedidoItemEnum } from '../../../../back/src/pedidos/pedidos.constants';

export default Vue.extend({
  name: 'UsuariosFormParceiro',
  components: {
    EnderecosFormBrasil,
    EnderecosFormInternacional,
    InputTelefone,
    InputNumber,
  },
  props: {
    usuario: {
      type: Object || null,
      default: null,
    },
  },
  data: () => ({
    loading: {
      bancos: true,
      categorias: true,
      usuarios: true,
      submitForm: false,
    },
    temEndereco: true,
    form: {
      cpf: null,
      documento: null,
      nome: null,
      sobrenome: null,
      email: null,
      celular: null,
      telefone: null,
      bancoCodigo: null,
      bancoNome: null,
      bancoAgencia: null,
      bancoConta: null,
      ativo: null,
      autorizarNovosDispositivos: null,
      categoria: CategoriaUsuarioEnum.PARCEIRO,
      responsavelId: null,
      emailsSecundarios: [],
    },
    dynamicKey: 1,
    rules: {
      required: rulesRequired,
      email: [...rulesRequired, ...rulesEmail],
      cpf: rulesCpf,
      numericPositive: rulesNumericPositive,
      boolean: rulesBoolean,
    },
    bancos: [],
    categorias: [],
    usuarios: [],
    itemsCategorias: [],
    CategoriaUsuarioEnum,
  }),
  computed: {
    mensagemTooltipSwitch() {
      if (!this.parceiroSelecionado) return 'Selecione um parceiro';
      return 'Margem não cadastrada para o parceiro';
    },
    parceiroSelecionado() {
      return this.usuarios?.find((el) => el.id === this.form.responsavelId);
    },
    isPreposto() {
      return this.form.categoria === CategoriaUsuarioEnum.PREPOSTO;
    },
    isParceiro() {
      return this.form.categoria === CategoriaUsuarioEnum.PARCEIRO;
    },
    paisIso2() {
      return process.env.VUE_APP_PAIS_ISO2 || 'BR';
    },
    isBrasil() {
      return this.paisIso2 === 'BR';
    },
    endereco() {
      return this.usuario?.fornecedor?.endereco || null;
    },
    enderecoBrasil() {
      return this.endereco?.paisIsoAlpha3 === 'BRA' ? this.endereco : null;
    },
    enderecoInternacional() {
      return !!this.endereco && this.endereco?.paisIsoAlpha3 !== 'BRA'
        ? this.endereco
        : null;
    },
    itemsBancos() {
      return orderBy(
        this.bancos
          .filter((el) => el.code)
          .map((el) => ({
            value: el.code,
            text: `${el.code} - ${el.name}`,
          })),
        ['value'],
      );
    },
    headers() {
      return [
        {
          value: 'categoria',
          text: this.$t('comum.categoria'),
          sortable: false,
        },
        this.form.categoria === CategoriaUsuarioEnum.PREPOSTO
          ? {
              value: 'margemParceiro',
              text: this.$t('comum.margemResponsavel'),
              sortable: false,
            }
          : {},
        {
          value: 'margem',
          text: this.$t('comum.margem'),
          sortable: false,
        },
        this.form.categoria === CategoriaUsuarioEnum.PREPOSTO
          ? {
              value: 'margemComposta',
              text: this.$t('comum.margemComposta'),
              sortable: false,
            }
          : {},
        {
          value: 'temMargem',
          text: '',
          sortable: false,
        },
      ];
    },
    itemsResponsaveis() {
      return orderBy(
        this.usuarios
          .filter(
            (el) =>
              el.categoria ===
              (this.form.categoria === CategoriaUsuarioEnum.PARCEIRO
                ? CategoriaUsuarioEnum.FUNCIONARIO
                : CategoriaUsuarioEnum.PARCEIRO),
          )
          .map((el) => ({
            value: el.id,
            text: `${el.nomeCompleto} ${el.email} ${el.grupo?.nome || ''}`,
            nomeCompleto: el.nomeCompleto,
            email: el.email,
            grupoNome: el.grupo?.nome || null,
          })),
        ['nomeCompleto'],
      );
    },
    disabledAddEmailSecundario() {
      return !!this.form.emailsSecundarios.find((el) =>
        el.delete ? !el.original : !el.email,
      );
    },
  },
  async mounted() {
    await Promise.all([
      this.listaCategorias(),
      this.listaBancos(),
      this.listaUsuarios(),
    ]);
    this.preencheForm();
  },
  methods: {
    autocompleteFilterDeburr,
    onChangeTemMargem(itemCategoria) {
      if (itemCategoria.temMargem) {
        itemCategoria.margem = 0;
        itemCategoria.margemComposta = itemCategoria.margemParceiro;
      } else {
        itemCategoria.margem = undefined;
        itemCategoria.margemComposta = undefined;
      }
    },
    onChangeCategoria() {
      this.form.responsavelId = null;
      this.zeraMargens();
    },
    /**
     * Somente deve ser usada para usuários que são prepostos.
     */
    buscaMargemCategoriaDoParceiro(categoria, parceiro): number | undefined {
      if (!this.isPreposto) {
        console.error('Buscando margem de parceiro mas usuário não é preposto');
        return null;
      }
      return parceiro?.margens?.find((el) => el.categoria === categoria)
        ?.margem;
    },
    isInputMargemHabilitado(itemCategoria) {
      // Quando for parceiro, habilita sempre.
      if (this.isParceiro) return true;

      // Somente preposto pode continuar daqui para frente.
      if (!this.isPreposto) return false;

      // Quando for preposto, habilita quando tiver um parceiro selecionado
      // e o parceiro selecionado tiver margem para a categoria em questão.
      const parceiroSelecionado = this.parceiroSelecionado;
      if (!parceiroSelecionado) return false;
      if (this.isPreposto) {
        const margemCategoriaParceiro = this.buscaMargemCategoriaDoParceiro(
          itemCategoria.categoria,
          parceiroSelecionado,
        );
        return Number.isFinite(margemCategoriaParceiro);
      }
    },
    preencheForm() {
      if (!this.usuario) return;
      this.form.responsavelId = this.usuario?.responsavelId || null;
      this.form.documento = this.usuario?.fornecedor?.documento || null;
      this.form.nome = this.usuario?.nome || null;
      this.form.sobrenome = this.usuario?.sobrenome || null;
      this.form.email = this.usuario?.email || null;
      this.form.celular = this.usuario?.celular || null;
      this.form.telefone = this.usuario?.fornecedor?.telefone || null;
      this.form.bancoCodigo = this.usuario?.fornecedor?.bancoCodigo || null;
      this.form.bancoNome = this.usuario?.fornecedor?.bancoNome || null;
      this.form.bancoAgencia = this.usuario?.fornecedor?.bancoAgencia || null;
      this.form.bancoConta = this.usuario?.fornecedor?.bancoConta || null;
      this.form.ativo = this.usuario?.ativo ?? null;
      this.form.autorizarNovosDispositivos =
        this.usuario?.autorizarNovosDispositivos ?? null;
      this.form.categoria =
        this.usuario?.categoria || CategoriaUsuarioEnum.PARCEIRO;
      this.preencheTodasMargens();
      this.form.emailsSecundarios = (this.usuario?.emailsSecundarios || []).map(
        (email) => ({
          email,
          original: email,
          key: this.dynamicKey++,
          delete: false,
        }),
      );
    },
    preencheTodasMargens() {
      // Método usado somente pelo preencheForm.
      this.itemsCategorias.forEach((itemCategoria, key) => {
        const margemParceiro = this.isPreposto
          ? Number(
              this.buscaMargemCategoriaDoParceiro(
                itemCategoria.categoria,
                this.parceiroSelecionado,
              ),
            )
          : null;

        let margem =
          this.usuario?.margens?.find((el) => {
            return el.categoria === itemCategoria.categoria;
          })?.margem * 100;

        if (Number.isFinite(margem)) {
          this.$set(this.itemsCategorias[key], 'margem', margem);
          this.$set(this.itemsCategorias[key], 'temMargem', true);
        }

        if (Number.isFinite(margemParceiro)) {
          this.$set(
            this.itemsCategorias[key],
            'margemParceiro',
            margemParceiro,
          );
          if (margem) {
            this.setMargemComposta(itemCategoria);
          }
        }
      });
    },
    zeraMargens() {
      this.itemsCategorias.forEach((categoria, key) => {
        this.$set(this.itemsCategorias[key], 'margemParceiro', undefined);
        this.$set(this.itemsCategorias[key], 'margem', undefined);

        this.$set(this.itemsCategorias[key], 'margemComposta', undefined);

        this.$set(this.itemsCategorias[key], 'temMargem', false);
      });
    },
    preencheMargensParceiro() {
      if (!this.isPreposto) return;

      const parceiro = this.parceiroSelecionado;

      if (!parceiro) return;

      this.zeraMargens();
      this.itemsCategorias.forEach((itemCategoria, key) => {
        const usuarioMargem = this.buscaMargemCategoriaDoParceiro(
          itemCategoria.categoria,
          parceiro,
        );
        this.$set(this.itemsCategorias[key], 'margemParceiro', usuarioMargem);
      });
    },
    setMargemComposta(itemCategoria) {
      this.$set(
        itemCategoria,
        'margemComposta',
        this.calculaMargemComposta(itemCategoria),
      );
    },
    calculaMargemComposta(itemCategoria) {
      return calculaMargemComposta(
        itemCategoria.margemParceiro,
        itemCategoria.margem / 100,
      );
    },
    addEmailSecundario() {
      this.form.emailsSecundarios.push({
        email: '',
        original: null,
        key: this.dynamicKey++,
        delete: false,
      });
    },
    removeEmailSecundario(key: number) {
      const index = this.form.emailsSecundarios.findIndex(
        (el) => el.key === key,
      );
      if (index < 0) return;
      if (!this.form.emailsSecundarios[index].original) {
        this.$delete(this.form.emailsSecundarios, index);
      } else {
        this.$set(this.form.emailsSecundarios[index], 'delete', true);
      }
    },
    async listaCategorias() {
      this.loading.categorias = true;
      try {
        this.categorias = Object.values(CategoriaPedidoItemEnum); // temporario
        this.itemsCategorias = this.categorias.map((el) => ({
          categoria: el,
          margem: null,
          temMargem: false,
          margemComposta: null,
          margemParceiro: null,
        }));
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.categorias = false;
      }
    },
    async listaBancos() {
      this.loading.bancos = true;
      try {
        this.bancos = await requester.externo.brasilApi.bancos();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.bancos = false;
      }
    },
    async listaUsuarios() {
      this.loading.usuarios = true;
      try {
        const queryObject = {
          scope: UsuarioScopesEnum.ComGrupoMargens,
        };
        this.usuarios = await requester.usuarios.lista(queryObject);
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.usuarios = false;
      }
    },
    submitFormDebounce: debounce(async function () {
      await this.submitForm();
    }, 200),
    async submitForm() {
      const validForm = this.$refs?.form?.validate();
      let validEnderecoForm = true;
      if (this.temEndereco) {
        validEnderecoForm = this.isBrasil
          ? this.$refs?.enderecosFormBrasil?.validate()
          : this.$refs?.enderecosFormInternacional?.validate();
      }
      if (!validForm || !validEnderecoForm) {
        return;
      }
      const formDataEndereco = this.isBrasil
        ? this.$refs?.enderecosFormBrasil?.getFormData()
        : this.$refs?.enderecosFormInternacional?.getFormData();
      this.loading.submitForm = true;
      try {
        const formData = {
          grupoId: null,
          lojaId: null,
          ...(this.usuario ? { ativo: this.form.ativo } : {}),
          autorizarNovosDispositivos: this.form.autorizarNovosDispositivos,
          responsavelId: this.form?.responsavelId || null,
          categoria: this.form?.categoria || null,
          nome: this.form.nome?.trim() || null,
          sobrenome: this.form.sobrenome?.trim() || null,
          email: this.form.email?.trim() || null,
          celular: this.form.celular?.trim() || null,
          emailsSecundarios: uniq(
            this.form.emailsSecundarios
              .filter((el) => !!el.email?.trim() && !el.delete)
              .map((el) => el.email?.trim()),
          ),
          fornecedor: {
            ...(this.usuario?.fornecedor
              ? { id: this.usuario.fornecedor.id }
              : {}),
            documento:
              (this.isBrasil
                ? this.form.cpf?.trim()
                : this.form.documento?.trim()) || null,
            telefone: this.form.telefone?.trim() || null,
            bancoCodigo: this.form?.bancoCodigo || null,
            bancoNome: this.form?.bancoCodigo
              ? this.bancos
                  ?.find((el) => el.code === this.form?.bancoCodigo)
                  ?.name?.trim() || null
              : this.form?.bancoNome?.trim() || null,
            bancoAgencia: this.form?.bancoAgencia?.trim() || null,
            bancoConta: this.form?.bancoConta?.trim() || null,
            ...(this.temEndereco ? { endereco: formDataEndereco } : {}),
          },
          margens: this.itemsCategorias
            .filter((el) => el.temMargem)
            .map((el) => ({
              categoria: el.categoria,
              margem: Number(el.margem) / 100,
            })),
        };
        let usuario;
        if (this.usuario) {
          usuario = await requester.usuarios.altera(this.usuario.id, formData);
          this.$root.$emit(
            'toastSuccess',
            this.$t('comum.usuarioAlteradoComSucesso'),
          );
        } else {
          usuario = await requester.usuarios.cria(formData);
          this.$root.$emit(
            'toastSuccess',
            this.$t('comum.usuarioCriadoComSucesso'),
          );
        }
        this.$router.push({
          name: 'UsuariosVer',
          params: { usuarioId: usuario.id },
        });
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.submitForm = false;
      }
    },
  },
});
