import { KatalisTranslationComum } from '../translations.interface';

const messages: KatalisTranslationComum = {
  comum: {
    acao: 'Acción',
    acoes: 'Acciones',
    acompanhamento: 'Seguimiento',
    adicionar: 'Agregar',
    administrativo: 'Administrativo',
    agencia: 'Agencia',
    aguardandoCadastroSenha: 'Esperando registro de contraseña',
    aguardandoVerificacao: 'Esperando verificación',
    alterar: 'Cambiar',
    alterarEmLote: 'Cambiar en lote',
    alterarRecebimento: 'Cambiar recepción',
    analise: 'Análisis',
    analises: 'Análisis',
    aplicar: 'Aplicar',
    aprovada: 'Aprobada',
    aprovadas: 'Aprobadas',
    aprovadoComAlteracoes: 'Aprobado con cambios',
    ativa: 'Activa',
    ativacao: 'Activación',
    ativo: 'Activo',
    autorizado: 'Autorizado',
    autorizar: 'Autorizar',
    autorizarNovosDispositivos: 'Autorizar nuevos dispositivos',
    aviso: 'Aviso',
    bairro: 'Barrio',
    baixarPdf: 'Descargar PDF',
    banco: 'Banco',
    bid: 'Bid',
    bloquear: 'Bloquear',
    brasil: 'Brasil',
    busca: 'Buscar',
    buscar: 'Buscar',
    cadastrar: 'Registrar',
    cadastrarNovaPeca: 'Registrar nueva pieza',
    calculadora: 'Calculadora',
    camposComAsteriscoObrigatorios: 'Campos con * son obligatorios',
    cancelar: 'Cancelar',
    cancelarPedido: 'Cancelar pedido',
    catalisadoresPecas: 'Catalizadores - Piezas',
    catalisadoresPo: 'Catalizadores - Polvo',
    categoria: 'Categoría',
    categoriaUsuario: 'Categoría usuario',
    celular: 'Celular',
    cep: 'CEP',
    certezaRemoverGrupo: '¿Está seguro de que desea eliminar este grupo?',
    cidade: 'Ciudad',
    classe: 'Clase',
    cnpj: 'CNPJ',
    codigo: 'Código',
    codigoPostal: 'Código postal',
    codigos: 'Códigos',
    codigoSap: 'Código SAP',
    coluna: 'Columna',
    comercial: 'Comercial',
    complemento: 'Complemento',
    compradores: 'Compradores',
    configuracoes: 'Configuraciones',
    configuracoesTabela: 'Configuraciones de la tabla',
    confirmacaoSenha: 'Confirmación de contraseña',
    confirmar: 'Confirmar',
    confirmarRecebimento: 'Confirmar recepción',
    conta: 'Cuenta',
    controle: 'Control',
    controleAcesso: 'Control de acceso',
    conviteNaoEnviado: 'Invitación no enviada',
    cotacao: 'Cotización',
    cotacaoAtual: 'Cotización actual',
    cotacoes: 'Cotizaciones',
    cpf: 'CPF',
    criacao: 'Creación',
    criadoEm: 'Creado en',
    criadoPor: 'Creado por',
    dadosBancarios: 'Datos bancarios',
    dadosGerais: 'Datos generales',
    dadosPessoais: 'Datos personales',
    data: 'Fecha',
    dataAnalise: 'Fecha de análisis',
    dataUltimaAnalise: 'Fecha de último análisis',
    de: 'De',
    deduction: 'Deducción',
    desconhecida: 'Desconocida',
    desconhecido: 'Desconocido',
    descricao: 'Descripción',
    descricaoCatalogo: 'Descripción catálogo',
    desfazer: 'Deshacer',
    desfazerAprovacao: 'Deshacer aprobación',
    desfazerReprovacao: 'Deshacer desaprobación',
    detalhe: 'Detalle',
    detalhes: 'Detalles',
    dispositivo: 'Dispositivo',
    dispositivos: 'Dispositivos',
    documento: 'Documento',
    dolar: 'Dólar',
    email: 'Email',
    emailsSecundarios: 'Emails secundarios',
    emConstrucao: 'En construcción',
    endereco: 'Dirección',
    enderecoNaoCadastrado: 'Dirección no registrada',
    entrar: 'Entrar',
    enviar: 'Enviar',
    envio: 'Envío',
    espanhol: 'Español',
    estadoProvinciaRegiao: 'Estado/Provincia/Región',
    exibindo: 'Mostrando',
    existente: 'Existente',
    familia: 'Familia',
    familias: 'Familias',
    fechar: 'Cerrar',
    filtrar: 'Filtrar',
    fonte: 'Fuente',
    fornecedor: 'Proveedor',
    foto: 'Foto',
    fotos: 'Fotos',
    funcionalidade: 'Funcionalidad',
    funcionalidades: 'Funcionalidades',
    funcionario: 'Empleado',
    funcionarios: 'Empleados',
    g: 'g',
    grama: 'Grama',
    grupo: 'Grupo',
    grupoRemovidoComSucesso: 'Grupo eliminado con éxito',
    grupos: 'Grupos',
    historico: 'Histórico',
    historicoAlteracoes: 'Historial de cambios',
    id: 'ID',
    identificacao: 'Identificación',
    importar: 'Importar',
    importarPlanilha: 'Importar hoja de cálculo',
    impurezas: 'Impurezas',
    info: 'Info',
    informacao: 'Información',
    informacoes: 'Informaciones',
    ingles: 'Inglés',
    internacional: 'Internacional',
    item: 'Ítem',
    justificativa: 'Justificación',
    justificativaCancelamento: 'Justificación de cancelación',
    kg: 'kg',
    linhaEndereco: 'Línea de dirección',
    lista: 'Lista',
    listagem: 'Listado',
    logoutRealizadoComSucesso: 'Cierre de sesión realizado con éxito',
    logradouro: 'Calle',
    loja: 'Tienda',
    lojas: 'Tiendas',
    margem: 'Margen',
    margemComposta: 'Margen compuesto',
    margemMedia: 'Margen media',
    margemMinima: 'Margen mínima',
    margemPadrao: 'Margen estándar',
    margemPeca: 'Margen pieza',
    margemPecas: 'Margen piezas',
    margemPo: 'Margen polvo',
    margemResponsavel: 'Margen responsable',
    margemUsuario: 'Margen usuario',
    margens: 'Margenes',
    material: 'Material',
    metais: 'Metales',
    metal: 'Metal',
    modelo: 'Modelo',
    moedaSistema: process.env.VUE_APP_MOEDA_SISTEMA || 'BRL',
    moedaSistemaPorKg: `${process.env.VUE_APP_MOEDA_SISTEMA || 'BRL'}/kg`,
    montadora: 'Montadora',
    mp: 'MP',
    mu: 'MU',
    municipio: 'Municipio',
    nao: 'No',
    navegador: 'Navegador',
    nenhumaMargemCadastrada: 'Ningún margen registrado',
    nenhumCompradorVinculado: 'Ningún comprador vinculado',
    nome: 'Nombre',
    nomeSistema: process.env.VUE_APP_NOME_SISTEMA || 'Portal Administrativo',
    nova: 'Nueva',
    novasAnalises: 'Nuevos análisis',
    novo: 'Nuevo',
    numero: 'Número',
    observacoes: 'Observaciones',
    observacoesParceiro: 'Observaciones del socio',
    observacoesParceiroNegocios: 'Observaciones del socio de negocios',
    onca: 'Onza',
    ordenacaoCrescente: 'Ordenación creciente',
    ordenacaoDecrescente: 'Ordenación decreciente',
    outros: 'Otros',
    oz: 'Oz',
    painelDeControle: 'Panel de control',
    pais: 'País',
    paladio: 'Paladio',
    paraEnvioAtualizacoesPedido: 'Para envío de actualizaciones de los pedidos',
    parceiro: 'Socio',
    parceiroNegocios: 'Socio de negocios',
    parceiros: 'Socios',
    parceirosNegocios: 'Socios de negocios',
    pd: 'Pd',
    peca: 'Pieza',
    pecaCatalisador: 'Pieza de catalizador',
    pecaDanificada: 'Pieza dañada',
    pecaFaltante: 'Pieza faltante',
    pecaIncorreta: 'Pieza incorrecta',
    pecas: 'Piezas',
    pecasCatalisadores: 'Piezas de catalizadores',
    pedido: 'Pedido',
    pedidos: 'Pedidos',
    pendentes: 'Pendientes',
    permissao: 'Permiso',
    permissoes: 'Permisos',
    peso: 'Peso',
    pesoBruto: 'Peso bruto',
    pesoDaCeramica: 'Peso de la cerámica',
    pesoLiquido: 'Peso líquido',
    pesoSeco: 'Peso seco',
    pesoUn: 'Peso un',
    placa: 'Placa',
    placaEletronica: 'Placa electrónica',
    placas: 'Placas',
    placasEletronicas: 'Placas electrónicas',
    plataforma: 'Plataforma',
    platina: 'Platino',
    poCatalisadores: 'Polvo catalizadores',
    portugues: 'Portugués',
    ppm: 'ppm',
    precoMedio: 'Precio medio',
    preposto: 'Apoderado',
    prepostos: 'Apoderados',
    previaFoto: 'Vista previa de la foto',
    primeiroAcessoEm: 'Primer acceso en',
    principal: 'Principal',
    produto: 'Producto',
    produtos: 'Productos',
    prosseguir: 'Proseguir',
    pt: 'Pt',
    qtde: 'Ctd',
    qtdeItens: 'Ctd. ítems',
    quantidade: 'Cantidad',
    recebido: 'Recibido',
    recebimento: 'Recepción',
    ref: 'Ref',
    referencia: 'Referencia',
    registro: 'Registro',
    remover: 'Eliminar',
    removerGrupo: 'Eliminar grupo',
    removerOrdenacao: 'Eliminar ordenación',
    reprovada: 'Desaprobada',
    reprovadas: 'Desaprobadas',
    responsavel: 'Responsable',
    responsavelAdm: 'Responsable administrativo',
    responsavelComercial: 'Responsable comercial',
    rh: 'Rh',
    rodio: 'Rodio',
    sair: 'Salir',
    salvar: 'Guardar',
    selecionar: 'Seleccionar',
    selecione: 'Seleccione',
    selecionePais: 'Seleccione el país',
    semEndereco: 'Sin dirección',
    semFoto: 'Sin foto',
    senha: 'Contraseña',
    senhaCadastrada: 'Contraseña registrada',
    separarParaAnalise: 'Separar para análisis',
    sim: 'Sí',
    sobrenome: 'Apellido',
    status: 'Estado',
    telefone: 'Teléfono',
    telefoneFixo: 'Teléfono fijo',
    teor: 'Contenido',
    teores: 'Contenidos',
    teorPd: 'Contenido Pd',
    teorPt: 'Contenido Pt',
    teorRh: 'Contenido Rh',
    total: 'Total',
    uf: 'UF',
    ultimaAtualizacaoEm: 'Última actualización en',
    ultimaAtualizacaoPor: 'Última actualización por',
    ultimaModificacaoEm: 'Última modificación en',
    ultimaModificacaoPor: 'Última modificación por',
    ultimoAcessoEm: 'Último acceso en',
    umidade: 'Humedad',
    unidade: 'Unidad',
    usd: 'USD',
    usuario: 'Usuario',
    usuarioAlteradoComSucesso: 'Usuario cambiado con éxito',
    usuarioCriadoComSucesso: 'Usuario creado con éxito',
    usuarios: 'Usuarios',
    valor: 'Valor',
    valores: 'Valores',
    valorKg: 'Valor por kg',
    valorLiquido: 'Valor líquido',
    valorMedio: 'Valor medio',
    valorTotal: 'Valor total',
    valorUn: 'Valor un',
    verAnalises: 'Ver análisis',
    verificadas: 'Verificadas',
    verificarPecas: 'Verificar piezas',
    verPecas: 'Ver piezas',
    vinculo: 'Vínculo',
    visivel: 'Visible',
    visualizacao: 'Visualización',
    visualizar: 'Visualizar',
    voltar: 'Volver',
    vt: 'VT',
    vtMpMu: 'VT x MP x MU',
  },
  moeda: {
    BRL: 'Real brasileño',
    CLP: 'Peso chileno',
    COP: 'Peso colombiano',
    JPY: 'Yen japonés',
  },
};

export default messages;
