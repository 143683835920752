import { KatalisTranslation } from '../translations.interface';
import components from './es-components.translation';
import comum from './es-comum.translation';
import enums from './es-enums.translation';
import pages from './es-pages.translation';
import routes from './es-routes.translation';
import rules from './es-rules.translation';

const messages: KatalisTranslation = {
  ...components,
  ...comum,
  ...enums,
  ...pages,
  ...routes,
  ...rules,
};

export default messages;
