






import Vue from 'vue';

import UsuariosFormParceiro from '@/components/usuarios/UsuariosFormParceiro.vue';

export default Vue.extend({
  name: 'UsuariosCadastroParceiro',
  components: {
    UsuariosFormParceiro,
  },
});
