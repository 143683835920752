import { KatalisTranslationComponents } from '../translations.interface';

const messages: KatalisTranslationComponents = {
  // catalisadores-pecas
  CatalisadoresPecasDialogAtualizarLote: {
    alterarCatalisadoresPecasEmLote: 'Alterar peças de catalisadores em lote',
    quantidadePecasSelecionadas: 'Quantidade de peças selecionadas',
    mensagemSucesso: 'Peças de catalisadores alteradas com sucesso',
  },
  CatalisadoresPecasDialogInfo: {
    catalisadorPecaRemovidoComSucesso:
      'Peça de catalisador removida com sucesso',
    certezaRemoverCatalisadorPeca:
      'Tem certeza que deseja remover esta peça de catalisador?',
    removerCatalisadorPeca: 'Remover peça de catalisador',
  },
  CatalisadoresPecasFormCriarEditar: {
    cadastrarNovaMontadora: 'Cadastrar nova montadora',
    mensagemSucessoAlterar: 'Peça de catalisador alterada com sucesso',
    mensagemSucessoCriar: 'Peça de catalisador criada com sucesso',
  },
  CatalisadoresPecasInfo: {
    deAcordoComACotacaoAtual: 'De acordo com a cotação atual',
  },
  CatalisadoresPecasImportarStep1: {
    arquivoNaoSuportado: 'Arquivo não suportado',
    arrasteArquivoOuClique: 'Arraste um arquivo aqui ou clique',
    extensoesSuportadas: 'Extensões suportadas',
    solteArquivo: 'Solte o arquivo',
  },
  CatalisadoresPecasImportarStep2: {
    atributoPecaCatalisador: 'Atributo da peça de catalisador',
    colunaArquivo: 'Coluna do arquivo',
    colunasObrigatoriasNaoSelecionadas: 'Colunas obrigatórias não selecionadas',
    identificacaoDasColunas: 'Identificação das colunas',
  },
  CatalisadoresPecasImportarStep3: {
    dadosDoArquivo: 'Dados do arquivo',
    existeErro: 'Existem itens com erro',
    mostrarErro: 'Mostrar itens com erro',
    mostrarTodos: 'Mostrar todos os itens',
  },
  CatalisadoresPecasImportarStep4: {
    existeInconsistentes: 'Existem itens inconsistentes não resolvidos',
    mostrarInconsistentes: 'Mostrar itens não resolvidos',
    mostrarTodos: 'Mostrar todos os itens',
    nenhumInconsistente: 'Nenhum item inconsistente',
  },
  CatalisadoresPecasImportarStep5: {
    novasPecas: 'Novas peças',
    pecasQueSeraoAtualizadas: 'Peças que serão atualizadas',
  },
  CatalisadoresPecasImportarStep6: {
    importarNovoArquivo: 'Importar novo arquivo',
    naoFoiPossivelImportarAsSeguintesPecas:
      'Não foi possível importar as seguintes peças',
    pecasImportadasComSucesso: 'Peças importadas com sucesso',
    voltarParaListaDePecas: 'Voltar para lista de peças',
  },
  // catalisadores-po
  CatalisadoresPoDialogInfo: {
    catalisadorPoRemovidoComSucesso:
      'Classe de pó de catalisador removida com sucesso',
    certezaRemoverCatalisadorPo:
      'Tem certeza que deseja remover esta classe de pó de catalisador?',
    removerCatalisadorPo: 'Remover classe de pó de catalisador',
  },
  CatalisadoresPoFormCriarAlterar: {
    catalisadorPoAlteradoComSucesso:
      'Classe de pó de catalisador alterada com sucesso',
    catalisadorPoCriadoComSucesso:
      'Classe de pó de catalisador criada com sucesso',
  },
  // cotacoes
  CotacoesConfigIndex: {
    verPesosEm: 'Ver pesos em',
    verPrecosEm: 'Ver preços em',
  },
  CotacoesDialogInfo: {
    certezaRemoverCotacao: 'Tem certeza que deseja remover esta cotação?',
    cotacaoRemovidaComSucesso: 'Cotação removida com sucesso',
    removerCotacao: 'Remover cotação',
  },
  CotacoesFormCriar: {
    cotacaoCriadaComSucesso: 'Cotação criada com sucesso',
  },
  // enderecos
  EnderecosFormBrasil: {
    cepNaoEncontrado: 'CEP não encontrado',
  },
  // familias
  FamiliasDialogInfo: {
    certezaRemoverFamilia: 'Tem certeza que deseja remover esta família?',
    familiaRemovidaComSucesso: 'Família removida com sucesso',
    removerFamilia: 'Remover família',
  },
  FamiliasFormCriarAlterar: {
    familiaAlteradaComSucesso: 'Família alterada com sucesso',
    familiaCriadaComSucesso: 'Família criada com sucesso',
  },
  // grupos
  GruposDialogEdicao: {
    alterarGrupo: 'Alterar grupo',
  },
  GruposFormCriarAlterar: {
    grupoAlteradoComSucesso: 'Grupo alterado com sucesso',
    grupoCriadoComSucesso: 'Grupo criado com sucesso',
  },
  // lojas
  LojasForm: {
    lojaAlteradaComSucesso: 'Loja alterada com sucesso',
    lojaCriadaComSucesso: 'Loja criada com sucesso',
  },
  // placas
  PlacasDialogInfo: {
    certezaRemoverPlaca:
      'Tem certeza que deseja remover esta placa eletrônica?',
    placaRemovidaComSucesso: 'Placa eletrônica removida com sucesso',
    removerPlaca: 'Remover placa eletrônica',
  },
  PlacasFormCriarAlterar: {
    placaAlteradaComSucesso: 'Placa eletrônica alterada com sucesso',
    placaCriadaComSucesso: 'Placa eletrônica criada com sucesso',
  },
  // pedidos
  PedidosRecebimento: {
    avisoConfirmarRecebimento:
      'Atenção, para que os responsáveis por este pedido recebam um email com as alterações do recebimento, é imprescindível confirmar o recebimento!',
    nenhumCodigoEncontradoComOTermo: 'Nenhum código encontrado com o termo',
  },
  PedidosItemRecebimentoPeca: {
    certezaDesfazerAprovacao:
      'Tem certeza que deseja desfazer a aprovação desta peça?',
    certezaDesfazerReprovacao:
      'Tem certeza que deseja desfazer a reprovação desta peça? A justificativa será removida.',
  },
  // usuarios
  UsuariosFormFuncionario: {
    usuarioAlteradoComSucesso: 'Usuário alterado com sucesso',
    usuarioCriadoComSucesso: 'Usuário criado com sucesso',
  },
  UsuariosFormParceiro: {
    usuarioAlteradoComSucesso: 'Usuário alterado com sucesso',
    usuarioCriadoComSucesso: 'Usuário criado com sucesso',
  },
};

export default messages;
