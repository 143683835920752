




































































































import Vue from 'vue';

import requester from '@/requester';
import { debounce } from 'lodash';

import { Funcionalidade } from '../../../../back/src/models/funcionalidade.model';
import { Permissao } from '../../../../back/src/models/permissao.model';

import CustomVColInfo from '../CustomVColInfo.vue';
export default Vue.extend({
  name: 'FuncionalidadesDialogInfo',
  components: { CustomVColInfo },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    funcionalidadeId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    funcionalidade: {} as Funcionalidade,
    loading: {
      remover: false,
    },
  }),
  mounted() {
    this.buscaFuncionalidade();
  },
  computed: {
    permissoes() {
      return this.funcionalidade?.permissoes?.length > 0
        ? (this.funcionalidade?.permissoes as Permissao[])
        : [];
    },
  },

  methods: {
    fechar() {
      this.$emit('input', false);
    },
    async buscaFuncionalidade() {
      try {
        this.funcionalidade = await requester.funcionalidades.buscar(
          this.funcionalidadeId,
        );
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    removerDebounce: debounce(async function () {
      await this.remover();
    }, 200),
    async remover() {
      const confirm = await this.$root.$confirm(
        'Remover funcionalidade',
        'Tem certeza que deseja remover esta funcionalidade?',
      );
      if (!confirm) return;
      this.loading.remover = true;
      try {
        await requester.funcionalidades.remove(this.funcionalidade.id);
        this.$root.$emit(
          'toastSuccess',
          'Funcionalidade removida com sucesso.',
        );
        this.fechar();
        this.$emit('funcionalidade-removida');
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.remover = false;
      }
    },
  },
});
