import { RouteConfig } from 'vue-router';

import AuthAtivarConta from '@/pages/auth/AuthAtivarConta.vue';
import AuthEsqueciMinhaSenha from '@/pages/auth/AuthEsqueciMinhaSenha.vue';
import AuthLogin from '@/pages/auth/AuthLogin.vue';
import AuthRenovarSenha from '@/pages/auth/AuthRenovarSenha.vue';

const routes: RouteConfig[] = [
  {
    path: '/auth/ativar_conta/:token',
    name: 'AuthAtivarConta',
    component: AuthAtivarConta,
    meta: {
      layout: 'Public',
      public: true,
    },
  },
  {
    path: '/auth/esqueci_minha_senha',
    name: 'AuthEsqueciMinhaSenha',
    component: AuthEsqueciMinhaSenha,
    meta: {
      layout: 'Public',
      public: true,
    },
  },
  {
    path: '/auth/login',
    name: 'AuthLogin',
    component: AuthLogin,
    meta: {
      layout: 'Public',
      public: true,
    },
  },
  {
    path: '/auth/renovar_senha/:token',
    name: 'AuthRenovarSenha',
    component: AuthRenovarSenha,
    meta: {
      layout: 'Public',
      public: true,
    },
  },
];

export default routes;
