import { KatalisTranslationPages } from '../translations.interface';

const messages: KatalisTranslationPages = {
  // auth
  AuthAtivarConta: {
    aceitoTermosDeUsoPoliticaPrivacidade:
      'Acepto los Términos de Uso y Política de Privacidad',
    ativarConta: 'Activar cuenta',
    contaAtivadaComSucesso: 'Cuenta activada con éxito',
    definaUmaSenha: 'Defina una contraseña para su cuenta',
    seuEmaiDeAcesso: 'Su correo electrónico de acceso es',
    voltarParaLogin: 'Volver al inicio de sesión',
  },
  AuthEsqueciMinhaSenha: {
    esqueceuSuaSenha: '¿Olvidaste tu contraseña?',
    insiraSeuEmail:
      'Ingrese su correo electrónico y le enviaremos un enlace para que pueda renovar su contraseña',
    linkRenovacaoSenhaEnviado:
      'Enlace de renovación de contraseña enviado con éxito. Revise su bandeja de entrada.',
    solicitarRenovacaoSenha: 'Solicitar renovación de contraseña',
    voltarParaLogin: 'Volver al inicio de sesión',
  },
  AuthLogin: {
    acessoCatalogo: 'Acceso al catálogo',
    acessoLims: 'Acceso a LIMS',
    bemVindo: 'Bienvenido',
    entreComSuaConta: 'Inicie sesión con su cuenta',
    esqueceuSuaSenha: '¿Olvidaste tu contraseña?',
    voceNaoTemPermissao: 'No tienes permiso para acceder a este sistema',
  },
  AuthRenovarSenha: {
    digiteSuaNovaSenha: 'Ingrese su nueva contraseña y una confirmación',
    renovarSenha: 'Renovar contraseña',
    senhaRenovadaComSucesso: 'Contraseña renovada con éxito',
    solicitarLinkRenovacaoDeSenha:
      'Solicitar enlace para renovación de contraseña',
    voltarParaLogin: 'Volver al inicio de sesión',
  },
  // catalisadores-pecas
  CatalisadoresPecasImportar: {
    identificarColunas: 'Identificar columnas',
    selecionarArquivo: 'Seleccionar archivo',
    verificarDados: 'Verificar datos',
    verificarInconsistencias: 'Verificar inconsistencias',
  },
  // catalisadores-po
  CatalisadoresPoIndex: {
    nenhumaFamiliaCadastrada:
      'Ninguna familia de polvo de catalizador registrada',
  },
  // erro
  ErroNaoEncontrado: {
    caminhoNaoEncontrado:
      'La ruta <span class="text--secondary">"%{path}"</span> no se encontró en el sistema.',
    naoEncontrado: 'No encontrado',
    porFavorVerifique:
      'Por favor, verifique si escribió la dirección correctamente.',
    voltarPaginaInicial: 'Volver a la página de inicio',
  },
  // grupos
  GruposVer: {
    naoEPossivelRemoverGrupoComUsuarios:
      'No es posible eliminar un grupo con usuarios',
    nenhumUsuarioPertenceGrupo: 'Ningún usuario pertenece a este grupo',
  },
  // lojas
  LojasVer: {
    certezaRemoverLoja: '¿Seguro que quieres eliminar esta tienda?',
    lojaRemovidaComSucesso: 'Tienda eliminada con éxito',
    removerLoja: 'Eliminar tienda',
  },
  // usuarios
  UsuariosCadastroParceiro: {
    selecioneUfPrimeiro: 'Seleccione el UF primero',
    usuarioCriadoComSucesso: 'Usuario creado con éxito',
  },
  UsuariosEdicao: {
    usuarioAlteradoComSucesso: 'Usuario modificado con éxito',
  },
  UsuariosVer: {
    certezaConviteParaAtivarConta:
      'Se enviará un correo electrónico con un enlace para que el usuario establezca su contraseña y active su cuenta.',
    certezaRemoverUsuario: '¿Seguro que quieres eliminar a este usuario?',
    conviteEnviadoComSucesso: 'Invitación enviada con éxito',
    conviteNaoEnviado: 'Invitación no enviada',
    conviteParaAtivarConta: 'Invitación para activar cuenta',
    copiarLinkAtivacao: 'Copiar enlace de activación',
    enviarConviteAtivarConta: 'Enviar invitación para activar cuenta',
    linkCopiadoComSucesso: 'Enlace copiado al portapapeles con éxito',
    removerUsuario: 'Eliminar usuario',
    ultimoConviteEnviadoEm: 'Última invitación enviada en',
    usuarioRemovidoComSucesso: 'Usuario eliminado con éxito',
  },
};

export default messages;
