import { deburr, toLower } from 'lodash';

export const autocompleteFilterDeburr = (
  item: unknown,
  queryText: string,
  itemText: string,
): boolean => {
  const queryDeburr = deburr(toLower(queryText || ''));
  const itemDeburr = deburr(toLower(itemText || ''));
  return itemDeburr.indexOf(queryDeburr) > -1;
};
