import { RouteConfig } from 'vue-router';

import GruposIndex from '@/pages/grupos/GruposIndex.vue';
import GruposPermissoes from '@/pages/grupos/GruposPermissoes.vue';
import GruposVer from '@/pages/grupos/GruposVer.vue';
import { Rules as CaslRules } from '../../../back/src/casl/casl.constants';

const routes: RouteConfig[] = [
  {
    path: '/grupos',
    name: 'GruposIndex',
    component: GruposIndex,
    meta: {
      icon: 'mdi-account-group',
      rules: [CaslRules.Usuario.listar],
    },
  },
  {
    path: '/grupos/permissoes',
    name: 'GruposPermissoes',
    component: GruposPermissoes,
    meta: {
      icon: 'mdi-lock-open',
      rules: [
        CaslRules.Funcionalidade.listar,
        CaslRules.Grupo.desvincularFuncionalidade,
        CaslRules.Grupo.listar,
        CaslRules.Grupo.vincularFuncionalidade,
      ],
    },
  },
  {
    path: '/grupos/:grupoId',
    name: 'GruposVer',
    component: GruposVer,
    meta: {
      icon: 'mdi-account-group',
      rules: [CaslRules.Usuario.buscar],
    },
  },
];

export default routes;
