import { KatalisTranslationRules } from '../translations.interface';

const messages: KatalisTranslationRules = {
  rules: {
    campoObrigatorio: 'Mandatory field',
    cepInvalido: 'Invalid ZIP code',
    cnpjInvalido: 'Invalid CNPJ',
    confirmacaoSenha: 'Confirmation different from the entered password',
    cpfInvalido: 'Invalid CPF',
    emailInvalido: 'Invalid email',
    telefoneInvalido: 'Invalid phone number',
    inteiroPositivoMaiorZero: 'Number must be positive',
    inteiroPositivo: 'Number must be an integer, zero, or positive',
    dataInvalida: 'Invalid date',
    // ---
    numerico: 'Numeric field',
    numericoInteiro: 'Number must be an integer',
    numericoPositivoOuZero: 'Number must be positive or zero',
    numericoNegativoOuZero: 'Number must be negative or zero',
    numericoZero: 'Number must be zero',
    numericoPositivo: 'Number must be positive',
    numericoNegativo: 'Number must be negative',
    numericoIncorreto: 'Validation configured incorrectly',
  },
};

export default messages;
