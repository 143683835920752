import Vue from 'vue';

Vue.filter(
  'formatDecimal',
  (
    value: number,
    maximumFractionDigits = 3,
    minimumFractionDigits = 0,
    useGrouping = false,
  ) => {
    if (isNaN(Number(value))) return null;
    return new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits,
      maximumFractionDigits,
      style: 'decimal',
      useGrouping,
    }).format(value);
  },
);
