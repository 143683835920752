import axios, { AxiosResponse } from 'axios';
import { UsuarioDispositivo } from '../../../back/src/models/usuario-dispositivo.model';
import { ListarDispositivosQueryDto } from '../../../back/src/usuarios/dto/listar-dispositivos-query.dto';

const altera = async (usuarioId: number, formData?: any): Promise<any> => {
  const res = await axios.patch<any>(`usuarios/${usuarioId}`, formData);
  return res.data;
};

const busca = async (usuarioId: number, queryObject?: any): Promise<any[]> => {
  const res = await axios.get<any[]>(`usuarios/${usuarioId}`, {
    ...(queryObject ? { params: queryObject } : {}),
  });
  return res.data;
};

const buscaLinkAtivacaoConta = async (
  usuarioId: number,
): Promise<{ link: string }> => {
  const res = await axios.get(`usuarios/${usuarioId}/link_ativacao_conta`);
  return res.data;
};

const convidarAtivarConta = async (usuarioId: number): Promise<void> => {
  await axios.post(`usuarios/${usuarioId}/convidar_ativar_conta`);
};

const cria = async (formData?: any): Promise<any> => {
  const res = await axios.post<any>('usuarios', formData);
  return res.data;
};

const lista = async (queryObject?: any): Promise<any[]> => {
  const res = await axios.get<any[]>('usuarios', {
    ...(queryObject ? { params: queryObject } : {}),
  });
  return res.data;
};

const remove = async (usuarioId?: number): Promise<any> => {
  const res = await axios.delete<any>(`usuarios/${usuarioId}`);
  return res.data;
};

const listaDispositivos = async (
  queryDto: ListarDispositivosQueryDto,
): Promise<{ count: number; rows: UsuarioDispositivo[] }> => {
  const res = await axios.get<{ count: number; rows: UsuarioDispositivo[] }>(
    'usuarios/dispositivos',
    { params: queryDto },
  );
  return res.data;
};

const autorizaDispositivo = async (
  usuarioId: number,
  usuarioDispositivoId: number,
): Promise<UsuarioDispositivo> => {
  const res = await axios.post<void, AxiosResponse<UsuarioDispositivo>>(
    `usuarios/${usuarioId}/dispositivos/${usuarioDispositivoId}/autorizar`,
  );
  return res.data;
};

const bloqueiaDispositivo = async (
  usuarioId: number,
  usuarioDispositivoId: number,
): Promise<UsuarioDispositivo> => {
  const res = await axios.post<void, AxiosResponse<UsuarioDispositivo>>(
    `usuarios/${usuarioId}/dispositivos/${usuarioDispositivoId}/bloquear`,
  );
  return res.data;
};

export default {
  altera,
  busca,
  buscaLinkAtivacaoConta,
  convidarAtivarConta,
  cria,
  lista,
  remove,
  listaDispositivos,
  autorizaDispositivo,
  bloqueiaDispositivo,
};
