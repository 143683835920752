import en from '../translations/en/index';
import es from '../translations/es/index';
import pt from '../translations/pt/index';

const availableLocales = [
  {
    code: 'en',
    label: 'English',
    text: 'comum.ingles',
    order: 2,
    messages: en,
  },
  {
    code: 'es',
    label: 'Español',
    text: 'comum.espanhol',
    order: 1,
    messages: es,
  },
  {
    code: 'pt',
    label: 'Português',
    text: 'comum.portugues',
    order: 0,
    messages: pt,
  },
];

const locale = process.env.VUE_APP_I18N_LOCALE || 'pt';
// try {
//   // get browser default language
//   const { 0: browserLang } = navigator.language.split('-');
//   const supported = availableLocales.map((el) => el.code as string);
//   if (supported.includes(browserLang)) {
//     locale = browserLang;
//   }
// } catch (err) {
//   console.log(err);
// }

const fallbackLocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'pt';

export default {
  locale,
  fallbackLocale,
  availableLocales,
};
