























































import Vue from 'vue';
import { debounce } from 'lodash';

import requester from '@/requester';
import { rulesRequired } from '@/utils/input-rules';
import { inputPriceToNumber, maskCurrency } from '@/utils/intl-functions';

import CustomCancelarSalvar from '../custom/CustomCancelarSalvar.vue';

export default Vue.extend({
  name: 'CatalisadoresPoFormCriarAlterar',
  components: {
    CustomCancelarSalvar,
  },
  props: {
    catalisadorPo: {
      type: null || Object,
      default: null,
    },
    familia: {
      type: null || Object,
      default: null,
    },
  },
  data: () => ({
    loading: {
      submitForm: false,
    },
    form: {
      classe: null,
      valorKg: null,
    },
    rules: {
      required: rulesRequired,
    },
  }),
  mounted() {
    this.preencheForm();
  },
  methods: {
    maskCurrency,
    focusInput(inputRef: string) {
      (this.$refs[inputRef] as HTMLElement).focus();
    },
    preencheForm() {
      if (this.catalisadorPo) {
        this.form.classe = this.catalisadorPo.classe;
        this.form.valorKg = maskCurrency(this.catalisadorPo.valorKg.toFixed(2));
      }
    },
    cancelar() {
      this.form.classe = null;
      this.form.valorKg = null;
      this.$emit('cancelar');
    },
    submitFormDebounce: debounce(async function () {
      await this.submitForm();
    }, 200),
    async submitForm() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading.submitForm = true;
      try {
        const formData = {
          familiaId: this.familia?.id || 0,
          classe: this.form?.classe || '',
          valorKg: inputPriceToNumber(this.form.valorKg),
        };
        let catalisadorPo;
        if (this.catalisadorPo) {
          catalisadorPo = await requester.catalisadoresPo.atualiza(
            this.catalisadorPo.id,
            formData,
          );
          this.$root.$emit(
            'toastSuccess',
            this.$t(
              'CatalisadoresPoFormCriarAlterar.catalisadorPoAlteradoComSucesso',
            ),
          );
          this.$emit('catalisador-po-alterado', catalisadorPo);
        } else {
          catalisadorPo = await requester.catalisadoresPo.cria(formData);
          this.$root.$emit(
            'toastSuccess',
            this.$t(
              'CatalisadoresPoFormCriarAlterar.catalisadorPoCriadoComSucesso',
            ),
          );
          this.$emit('catalisador-po-criado', catalisadorPo);
        }
        this.form.classe = null;
        this.form.valorKg = null;
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.submitForm = false;
      }
    },
  },
});
