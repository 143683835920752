













































































































































































































































































import Vue from 'vue';
import { debounce } from 'lodash';

import requester from '@/requester';

import { Rules as CaslRules } from '../../../../back/src/casl/casl.constants';
import { Usuario } from '@models/usuario.model';
import { CategoriaUsuarioEnum } from '@/utils/constants/back.constants';
import UsuariosTabelaMargens from '@/components/usuarios/UsuariosTabelaMargens.vue';

export default Vue.extend({
  name: 'UsuariosVerFuncionario',
  components: {
    UsuariosTabelaMargens,
  },
  props: {
    usuario: {
      type: Object as () => Usuario,
      required: true,
    },
  },
  data: () => ({
    loading: {
      convidarAtivarConta: false,
      remover: false,
      usuario: true,
    },
    CategoriaUsuarioEnum,
  }),
  computed: {
    permissaoConvidarAtivarConta() {
      const rule = CaslRules.Usuario.convidarAtivarConta;
      return this.$ability.can(rule?.action, rule?.subject);
    },
    usuarioLogadoId() {
      return this.$store.getters?.usuario?.id || 0;
    },
    usuarioId() {
      return this.$route?.params?.usuarioId || 0;
    },
    loadingAll() {
      return this.loading.usuario;
    },
    permissaoVerGrupo() {
      const rule = CaslRules.Grupo.buscar;
      return this.$ability.can(rule?.action, rule?.subject);
    },
    permissaoListarUsuariosGrupo() {
      const rule = CaslRules.Grupo.listarUsuarios;
      return this.$ability.can(rule?.action, rule?.subject);
    },
    permissaoVerLoja() {
      const rule = CaslRules.Loja.buscar;
      return this.$ability.can(rule?.action, rule?.subject);
    },
  },
  methods: {
    buscarLinkAtivacaoContaDebounce: debounce(async function () {
      await this.buscarLinkAtivacaoConta();
    }, 200),
    async buscarLinkAtivacaoConta() {
      this.loading.linkAtivacaoConta = true;
      try {
        const { link } = await requester.usuarios.buscaLinkAtivacaoConta(
          this.usuario.id,
        );
        await navigator.clipboard.writeText(link);
        this.$root.$emit(
          'toastSuccess',
          this.$t('UsuariosVer.linkCopiadoComSucesso'),
        );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.linkAtivacaoConta = false;
      }
    },
    convidarAtivarContaDebounce: debounce(async function () {
      await this.convidarAtivarConta();
    }, 200),
    async convidarAtivarConta() {
      const confirm = await this.$root.$confirm(
        this.$t('UsuariosVer.conviteParaAtivarConta'),
        this.$t('UsuariosVer.certezaConviteParaAtivarConta'),
      );
      if (!confirm) return;
      this.loading.convidarAtivarConta = true;
      try {
        await requester.usuarios.convidarAtivarConta(this.usuario.id);
        this.$root.$emit(
          'toastSuccess',
          this.$t('UsuariosVer.conviteEnviadoComSucesso'),
        );
        this.$emit('atualiza-usuario');
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.convidarAtivarConta = false;
      }
    },
    removerDebounce: debounce(async function () {
      await this.remover();
    }, 200),
    async remover() {
      const confirm = await this.$root.$confirm(
        this.$t('UsuariosVer.removerUsuario'),
        this.$t('UsuariosVer.certezaRemoverUsuario'),
      );
      if (!confirm) return;
      this.loading.remover = true;
      try {
        await requester.usuarios.remove(this.usuario.id);
        this.$root.$emit(
          'toastSuccess',
          this.$t('UsuariosVer.usuarioRemovidoComSucesso'),
        );
        this.$router.push(this.routeVoltar);
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.remover = false;
      }
    },
  },
});
