






















































































































import Vue from 'vue';
import { debounce } from 'lodash';
import { subject } from '@casl/ability';

import requester from '@/requester';
import { Rules as CaslRules } from '../../../../back/src/casl/casl.constants';
import {
  CategoriaUsuarioEnum,
  UsuarioScopesEnum,
} from '../../../../back/src/usuarios/usuarios.constants';

import UsuariosVerFuncionario from '@/components/usuarios/UsuariosVerFuncionario.vue';
import UsuariosVerParceiro from '@/components/usuarios/UsuariosVerParceiro.vue';
import UsuariosTabelaResponsavel from '@/components/usuarios/UsuariosTabelaResponsavel.vue';
import UsuariosTabelaDispositivos from '@/components/usuarios/UsuariosTabelaDispositivos.vue';
import { UsuarioDispositivo } from '@models/usuario-dispositivo.model';

export default Vue.extend({
  name: 'UsuariosVer',
  components: {
    UsuariosVerFuncionario,
    UsuariosVerParceiro,
    UsuariosTabelaResponsavel,
    UsuariosTabelaDispositivos,
  },
  data: () => ({
    loading: {
      remover: false,
      usuario: true,
    },
    tab: 0,
    usuario: null,
    CategoriaUsuarioEnum,
  }),
  computed: {
    contentBadgeDispositivos() {
      return this.usuario?.dispositivos.length || 0;
    },
    colorBadgeDispositivos() {
      return this.contentBadgeDispositivos > 0 ? 'primary' : 'grey lighten-1';
    },
    contentBadgeResponsabilidade() {
      return this.usuario?.usuariosResponsavel.length || 0;
    },
    colorBadgeResponsabilidade() {
      return this.contentBadgeResponsabilidade > 0
        ? 'primary'
        : 'grey lighten-1';
    },
    usuarioLogadoId() {
      return this.$store.getters?.usuario?.id || 0;
    },
    usuarioId() {
      return this.$route?.params?.usuarioId || 0;
    },
    loadingAll() {
      return this.loading.usuario;
    },
    routeVoltar() {
      if (this.usuario) {
        if (this.usuario.categoria === CategoriaUsuarioEnum.FUNCIONARIO) {
          return { name: 'UsuariosIndex', query: { tab: 'funcionarios' } };
        }
        return { name: 'UsuariosIndex', query: { tab: 'parceiros' } };
      }
      return { name: 'UsuariosIndex' };
    },
    permissaoAlterar() {
      const rule = CaslRules.Usuario.atualizar;
      return this.$ability.can(
        rule?.action,
        this.usuario ? subject('Usuario', this.usuario) : rule?.subject,
      );
    },
    permissaoRemover() {
      const rule = CaslRules.Usuario.remover;
      return this.$ability.can(
        rule?.action,
        this.usuario ? subject('Usuario', this.usuario) : rule?.subject,
      );
    },
  },
  watch: {
    usuarioId() {
      this.buscaUsuario();
      this.tab = 0;
    },
  },
  mounted() {
    this.buscaUsuario();
  },
  methods: {
    dispositivoAlterado(dispositivo: UsuarioDispositivo) {
      const index = this.usuario.dispositivos.findIndex(
        (el) => el.id === dispositivo.id,
      );
      if (index < 0) return;
      this.$set(this.usuario.dispositivos, index, dispositivo);
    },
    async buscaUsuario() {
      this.loading.usuario = true;
      try {
        const queryObject = {
          scope: UsuarioScopesEnum.Completo,
        };
        this.usuario = await requester.usuarios.busca(
          this.usuarioId,
          queryObject,
        );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.usuario = false;
      }
    },
    removerDebounce: debounce(async function () {
      await this.remover();
    }, 200),
    async remover() {
      const confirm = await this.$root.$confirm(
        this.$t('UsuariosVer.removerUsuario'),
        this.$t('UsuariosVer.certezaRemoverUsuario'),
      );
      if (!confirm) return;
      this.loading.remover = true;
      try {
        await requester.usuarios.remove(this.usuario.id);
        this.$root.$emit(
          'toastSuccess',
          this.$t('UsuariosVer.usuarioRemovidoComSucesso'),
        );
        this.$router.push(this.routeVoltar);
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.remover = false;
      }
    },
  },
});
