

























































































import Vue from 'vue';

import { version } from '../../package.json';

import TheNavigationDrawer from '@/components/TheNavigationDrawer.vue';
import { getPaisEmojiFlag } from '@/utils/pais-helper';

export default Vue.extend({
  name: 'LayoutDefault',
  components: {
    TheNavigationDrawer,
  },
  data: () => ({
    drawer: false,
    version,
  }),
  computed: {
    usuario() {
      return this.$store.getters.usuario || null;
    },
    paisEmojiFlag() {
      return getPaisEmojiFlag();
    },
  },
});
