import { RouteConfig } from 'vue-router';

import FuncionalidadesIndex from '@/pages/funcionalidades/FuncionalidadesIndex.vue';
import FuncionalidadesPermissoesAlterar from '@/pages/funcionalidades/FuncionalidadesPermissoesAlterar.vue';
import { Rules as CaslRules } from '../../../back/src/casl/casl.constants';

const routes: RouteConfig[] = [
  {
    path: '/funcionalidades',
    name: 'FuncionalidadesIndex',
    component: FuncionalidadesIndex,
    meta: {
      icon: 'mdi-card-text',
      rules: [CaslRules.Funcionalidade.listar],
    },
  },
  {
    path: '/funcionalidades/permissoes/alterar/:funcionalidadeId',
    name: 'FuncionalidadesPermissoesAlterar',
    component: FuncionalidadesPermissoesAlterar,
    meta: {
      icon: 'mdi-pencil',
      rules: [CaslRules.Funcionalidade.buscar],
    },
  },
];

export default routes;
