import { RouteConfig } from 'vue-router';

import { Rules as CaslRules } from '../../../back/src/casl/casl.constants';

import PedidosIndex from '@/pages/pedidos/PedidosIndex.vue';
import PedidosRecebimento from '@/pages/pedidos/PedidosRecebimento.vue';
import PedidosRecebimentoIndex from '@/pages/pedidos/PedidosRecebimentoIndex.vue';

const routes: RouteConfig[] = [
  {
    path: '/pedidos',
    name: 'PedidosIndex',
    component: PedidosIndex,
    meta: {
      icon: 'mdi-clipboard-text',
      rules: [CaslRules.Pedido.listar],
    },
  },
  {
    path: '/pedidos/recebimento',
    name: 'PedidosRecebimentoIndex',
    component: PedidosRecebimentoIndex,
    meta: {
      icon: 'mdi-clipboard-text',
      rules: [CaslRules.Pedido.listarRecebimento],
    },
  },
  {
    path: '/pedidos/recebimento/:pedidoId',
    name: 'PedidosRecebimento',
    component: PedidosRecebimento,
    meta: {
      icon: 'mdi-clipboard-text',
      rules: [CaslRules.Pedido.buscar],
    },
  },
];

export default routes;
