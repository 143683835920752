












































































































import Vue from 'vue';
export default Vue.extend({
  name: 'CatalisadoresPecasImportarTabelaExistente',
  props: {
    itemExistente: {
      type: Object || null,
      default: null,
    },
    itemNovo: {
      type: Object || null,
      default: null,
    },
  },
});
