import { KatalisTranslation } from '../translations.interface';
import components from './en-components.translation';
import comum from './en-comum.translation';
import enums from './en-enums.translation';
import pages from './en-pages.translation';
import routes from './en-routes.translation';
import rules from './en-rules.translation';

const messages: KatalisTranslation = {
  ...components,
  ...comum,
  ...enums,
  ...pages,
  ...routes,
  ...rules,
};

export default messages;
