



































































































import Vue from 'vue';
import { deburr, maxBy, orderBy, toLower } from 'lodash';
import XLSX from 'xlsx';
import stringSimilarity from 'string-similarity';

export default Vue.extend({
  name: 'CatalisadoresPecasImportarStep2',
  data: () => ({
    file: null as File | null,
    workbook: null as XLSX.WorkBook | null,
    showColunasError: false,
    sheetJson: [],
    sheetHeaders: [],
    sheetHeadersTraduzidos: [],
  }),
  computed: {
    requiredOptions() {
      const atributosSelecionados = this.sheetHeaders.map((el) => el?.atributo);
      return this.optionsAtributos
        .filter((el) => el?.required)
        .filter((el) => !atributosSelecionados.includes(el.value))
        .map((el) => ` ${el.text}`);
    },
    optionsAtributos() {
      return orderBy(
        [
          {
            value: 'ref',
            text: this.$t('comum.ref'),
            required: true,
            order: 0,
          },
          {
            value: 'codigo',
            text: this.$t('comum.codigo'),
            required: true,
            order: 1,
          },
          {
            value: 'detalhe',
            text: this.$t('comum.detalhe'),
            required: false,
            order: 2,
          },
          {
            value: 'margemPeca',
            text: this.$t('comum.margemPeca'),
            required: false,
            order: 10,
          },
          {
            value: 'montadora',
            text: this.$t('comum.montadora'),
            required: false,
            order: 3,
          },
          {
            value: 'modelo',
            text: this.$t('comum.modelo'),
            required: false,
            order: 4,
          },
          {
            value: 'observacoes',
            text: this.$t('comum.observacoes'),
            required: false,
            order: 11,
          },
          {
            value: 'ptPpm',
            text: `${this.$t('comum.teorPt')} (${this.$t('comum.ppm')})`,
            required: true,
            order: 6,
          },
          {
            value: 'pdPpm',
            text: `${this.$t('comum.teorPd')} (${this.$t('comum.ppm')})`,
            required: true,
            order: 7,
          },
          {
            value: 'rhPpm',
            text: `${this.$t('comum.teorRh')} (${this.$t('comum.ppm')})`,
            required: true,
            order: 8,
          },
          {
            value: 'pesoKg',
            text: `${this.$t('comum.peso')} (${this.$t('comum.kg')})`,
            required: true,
            order: 9,
          },
          {
            value: 'dataUltimaAnalise',
            text: this.$t('comum.dataAnalise'),
            required: false,
            order: 5,
          },
        ],
        (el) => deburr(toLower(el.text)),
      );
    },
  },
  methods: {
    nextStep() {
      this.showColunasError = true;
      if (this.requiredOptions.length > 0) return;
      this.$emit('next-step', {
        headers: this.sheetHeadersTraduzidos,
        items: this.sheetJson,
      });
    },
    clean(string: string) {
      return toLower(deburr(string)).replace(/[\W_]+/, '');
    },
    inicio(file: File, workbook: XLSX.WorkBook) {
      this.file = file;
      this.workbook = workbook;
      this.sheetJson = this.montaSheetJson();
      this.sheetHeaders = this.montaSheetHeaders();
      this.deduzAtributos();
      this.atualizaSheetHeadersTraduzidos();
    },
    deduzAtributos() {
      this.sheetHeaders.forEach((el, index) => {
        const clean = this.clean(el.value);
        const mostLikely = maxBy(
          this.optionsAtributos as {
            value: string;
            text: string;
            required: boolean;
          }[],
          (opt) => {
            const cleanOpt = this.clean(opt.text);
            return stringSimilarity.compareTwoStrings(clean, cleanOpt);
          },
        );
        const indexExistente = this.sheetHeaders.findIndex(
          (item) => item.atributo === mostLikely.value,
        );
        if (indexExistente >= 0) {
          const cleanMostlikely = this.clean(mostLikely.text);
          const cleanExistente = this.clean(
            this.sheetHeaders[indexExistente].value,
          );
          const similarity = stringSimilarity.compareTwoStrings(
            clean,
            cleanMostlikely,
          );
          const similarityExistente = stringSimilarity.compareTwoStrings(
            cleanExistente,
            cleanMostlikely,
          );
          if (similarity > similarityExistente) {
            this.$set(this.sheetHeaders[indexExistente], 'atributo', null);
            this.$set(this.sheetHeaders[index], 'atributo', mostLikely.value);
          }
        } else {
          this.$set(this.sheetHeaders[index], 'atributo', mostLikely.value);
        }
      });
    },
    atualizaSheetHeadersTraduzidos(index: number, atributo: string) {
      this.sheetHeaders.forEach((header, key) => {
        if (header.atributo === atributo && key !== index) {
          this.$set(this.sheetHeaders[key], 'atributo', null);
        }
      });
      this.sheetHeadersTraduzidos = this.sheetHeaders.map((el) => {
        let text = '';
        if (el.atributo) {
          text =
            this.optionsAtributos.find((opt) => opt.value === el.atributo)
              ?.text || '';
        }
        return {
          ...el,
          ...(text ? { text } : {}),
        };
      });
    },
    montaSheetJson() {
      if (this.workbook) {
        return XLSX.utils.sheet_to_json(
          this.workbook.Sheets[this.workbook.SheetNames[0]],
          { defval: null },
        );
      }
      return [];
    },
    montaSheetHeaders() {
      if (this.sheetJson.length > 0) {
        return Object.keys(this.sheetJson[0]).map((el) => ({
          value: el,
          text: el,
          atributo: null,
          class: 'text-no-wrap',
        }));
      }
      return [];
    },
  },
});
