












































































































































































import Vue from 'vue';
import { debounce, deburr, orderBy, toUpper } from 'lodash';

import requester from '@/requester';

import { rulesNumeric, rulesRequired } from '@/utils/input-rules';

import InputNumber from '@/components/inputs/InputNumber.vue';

enum EscolhaEnum {
  FAMILIA = 'FAMILIA',
  MARGEM_PECA = 'MARGEM_PECA',
  ATIVO = 'ATIVO',
}

export default Vue.extend({
  name: 'CatalisadoresPecasDialogAtualizarLote',
  components: {
    InputNumber,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    familias: {
      required: true,
      type: Array,
    },
    catalisadoresPecas: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    loading: {
      submitForm: false,
    },
    window: 0,
    escolha: null as EscolhaEnum,
    ativo: false,
    form: {
      margemPeca: '',
      ativo: null,
      familiaId: null,
    },
    rules: {
      required: rulesRequired,
      numeric: rulesNumeric,
    },
  }),
  computed: {
    itemsFamilias() {
      return orderBy(
        this.familias.map((el) => ({
          value: el.id,
          text: `${el.codigo} - ${el.descricao}`,
        })),
        (el) => deburr(toUpper(el.text)),
      );
    },
    disabledSalvar() {
      switch (this.escolha) {
        case EscolhaEnum.FAMILIA:
          return !this.form.familiaId;
        case EscolhaEnum.MARGEM_PECA:
          return !this.form.margemPeca && this.form.margemPeca !== 0;
        case EscolhaEnum.ATIVO:
          return this.form.ativo !== true && this.form.ativo !== false;
        default:
          return false;
      }
    },
    EscolhaEnum() {
      return EscolhaEnum;
    },
  },
  methods: {
    voltar() {
      this.window = 0;
      this.form.familiaId = null;
      this.form.margemPeca = null;
      this.form.ativo = null;
    },
    onInput(input: boolean) {
      if (!input) {
        this.voltar();
      }
      this.$emit('input', input);
    },
    submitFormDebounce: debounce(async function () {
      await this.submitForm();
    }),
    async submitForm() {
      if (!this.$refs?.form?.validate()) {
        return;
      }
      this.loading.submitForm = true;
      try {
        const expansionsData = {};
        switch (this.escolha) {
          case EscolhaEnum.FAMILIA:
            Object.assign(expansionsData, { familiaId: this.form.familiaId });
            break;
          case EscolhaEnum.MARGEM_PECA:
            Object.assign(expansionsData, {
              margemPeca: Number(this.form.margemPeca),
            });
            break;
          case EscolhaEnum.ATIVO:
            Object.assign(expansionsData, { ativo: this.form.ativo });
            break;
          default:
            break;
        }

        const formData = {
          ids: this.catalisadoresPecas.map((el) => el.id),
          ...expansionsData,
        };

        await requester.catalisadoresPecas.atualizaLote(formData);
        this.$root.$emit(
          'toastSuccess',
          this.$t('CatalisadoresPecasDialogAtualizarLote.mensagemSucesso'),
        );
        this.$emit('catalisadores-pecas-atualizadas');
        this.voltar();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.submitForm = false;
      }
    },
  },
});
