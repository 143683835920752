























































































































































































































































































import {
  rulesIntegerPositive,
  rulesIntegerPositiveNonZero,
  rulesNumericPositive,
  rulesNumericPositiveNonZero,
  rulesRequired,
  rulesValidDate,
} from '@/utils/input-rules';
import { deburr, orderBy, toLower } from 'lodash';
import Vue from 'vue';

import InputData from '../inputs/InputData.vue';
import CatalisadoresPecasFormFotos from './CatalisadoresPecasFormFotos.vue';

export default Vue.extend({
  name: 'CatalisadoresPecasImportarStep3',
  components: {
    InputData,
    CatalisadoresPecasFormFotos,
  },
  data: () => ({
    dynamicKey: 0,
    headers: [],
    items: [],
    showOnlyError: false,
    rules: {
      integerPositive: rulesIntegerPositive,
      integerPositiveNonZero: rulesIntegerPositiveNonZero,
      numericPositive: rulesNumericPositive,
      numericPositiveNonZero: rulesNumericPositiveNonZero,
      required: rulesRequired,
      validDate: rulesValidDate,
    },
  }),
  computed: {
    optionsAtributos() {
      return orderBy(
        [
          {
            value: 'ref',
            text: this.$t('comum.ref'),
            required: false,
            rules: rulesIntegerPositiveNonZero,
            order: 0,
          },
          {
            value: 'codigo',
            text: this.$t('comum.codigo'),
            required: true,
            rules: rulesRequired,
            order: 1,
          },
          {
            value: 'detalhe',
            text: this.$t('comum.detalhe'),
            required: false,
            rules: [],
            order: 2,
          },
          {
            value: 'margemPeca',
            text: this.$t('comum.margemPeca'),
            required: false,
            rules: rulesIntegerPositive,
            order: 10,
          },
          {
            value: 'montadora',
            text: this.$t('comum.montadora'),
            required: false,
            rules: [],
            order: 3,
          },
          {
            value: 'modelo',
            text: this.$t('comum.modelo'),
            required: false,
            rules: [],
            order: 4,
          },
          {
            value: 'observacoes',
            text: this.$t('comum.observacoes'),
            required: false,
            rules: [],
            order: 11,
          },
          {
            value: 'ptPpm',
            text: `${this.$t('comum.teorPt')} (${this.$t('comum.ppm')})`,
            required: true,
            rules: rulesNumericPositive,
            order: 6,
          },
          {
            value: 'pdPpm',
            text: `${this.$t('comum.teorPd')} (${this.$t('comum.ppm')})`,
            required: true,
            rules: rulesNumericPositive,
            order: 7,
          },
          {
            value: 'rhPpm',
            text: `${this.$t('comum.teorRh')} (${this.$t('comum.ppm')})`,
            required: true,
            rules: rulesNumericPositive,
            order: 8,
          },
          {
            value: 'pesoKg',
            text: `${this.$t('comum.peso')} (${this.$t('comum.kg')})`,
            required: true,
            rules: rulesNumericPositiveNonZero,
            order: 9,
          },
          {
            value: 'dataUltimaAnalise',
            text: this.$t('comum.dataAnalise'),
            required: false,
            rules: rulesValidDate,
            order: 5,
          },
        ],
        (el) => deburr(toLower(el.text)),
      );
    },
    itemsError() {
      return this.items.filter(this.errorItem);
    },
  },
  methods: {
    removeItem(key: number) {
      const index = this.items.findIndex((el) => el.key === key);
      if (index >= 0) {
        this.$delete(this.items, index);
      }
      this.$nextTick(() => {
        if (this.itemsError.length === 0) {
          this.showOnlyError = false;
        }
      });
    },
    nextStep() {
      this.showOnlyError = false;
      if (this.itemsError.length > 0) {
        return;
      }
      this.$emit('next-step', { headers: this.headers, items: this.items });
    },
    errorItem(item) {
      let error = false;
      this.optionsAtributos.forEach((opt) => {
        error =
          error ||
          (opt?.rules || []).reduce(
            (acc, rule) => acc || rule(item[opt.value]) !== true,
            false,
          );
      });
      return error;
    },
    itemClass(item) {
      return this.errorItem(item) ? 'error lighten-4' : '';
    },
    inicio(headers, items) {
      this.headers = [
        ...orderBy(
          headers
            .filter((el) => !!el.atributo)
            .map((el) => ({ ...el, value: el.atributo })),
          (el) =>
            this.optionsAtributos.find((opt) => opt.value === el.value)?.order,
        ),
        {
          value: 'edit',
          text: this.$t('comum.alterar'),
          align: 'center',
        },
        {
          value: 'remove',
          text: this.$t('comum.remover'),
          align: 'center',
        },
      ];
      this.items = items.map((el) => {
        const item = {
          key: this.dynamicKey++,
          fotosArquivos: [],
          edit: false,
          utilizar: false,
        };
        Object.keys(el).forEach((key) => {
          const atributo = headers.find(
            (header) => header.value === key,
          )?.atributo;
          if (atributo) {
            if (atributo === 'margemPeca') {
              item[atributo] = Number(el[key]) * 100;
            } else {
              item[atributo] = String(el[key] || '').trim();
            }
          }
        });
        return item;
      });
    },
  },
});
