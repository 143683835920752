





































































import Vue from 'vue';

import requester from '@/requester';

import { Rules as CaslRules } from '../../../../back/src/casl/casl.constants';
import { LojaScopesEnum } from '@/utils/constants/back.constants';

import { VinculoLojaUsuarioEnum } from '../../../../back/src/lojas/lojas.constants';
import { Loja } from '../../../../back/src/models/loja.model';

export default Vue.extend({
  name: 'LojasIndex',
  data: () => ({
    loading: {
      lojas: true,
    },
    search: null,
    lojas: [],
  }),
  computed: {
    lojasComResponsaveis() {
      return this.lojas.map((loja) => ({
        ...loja,
        responsavelAdm: this.getResponsavel(
          loja,
          VinculoLojaUsuarioEnum.RESPONSAVEL_ADMINISTRATIVO,
        ),
        responsavelComercial: this.getResponsavel(
          loja,
          VinculoLojaUsuarioEnum.RESPONSAVEL_COMERCIAL,
        ),
      }));
    },
    headers() {
      return [
        {
          value: 'codigo',
          text: this.$t('comum.codigoSap'),
          class: 'text-no-wrap',
          filterable: true,
          sortable: true,
        },
        {
          value: 'endereco.ufSigla',
          text: this.$t('comum.uf'),
          class: 'text-no-wrap',
          filterable: true,
          sortable: true,
        },
        {
          value: 'endereco.municipioNome',
          text: this.$t('comum.municipio'),
          class: 'text-no-wrap',
          filterable: true,
          sortable: true,
        },
        {
          value: 'endereco.bairro',
          text: this.$t('comum.bairro'),
          class: 'text-no-wrap',
          filterable: true,
          sortable: true,
        },
        {
          value: 'documento',
          text: this.$t('comum.cnpj'),
          class: 'text-no-wrap',
          filterable: true,
          sortable: true,
        },
        {
          value: 'celular',
          text: this.$t('comum.celular'),
          class: 'text-no-wrap',
          filterable: true,
          sortable: true,
        },
        {
          value: 'telefone',
          text: this.$t('comum.telefone'),
          class: 'text-no-wrap',
          filterable: true,
          sortable: true,
        },
        {
          value: 'responsavelAdm.nomeCompleto',
          text: this.$t('comum.responsavelAdm'),
          class: 'text-no-wrap',
          filterable: true,
          sortable: true,
        },
        {
          value: 'responsavelComercial.nomeCompleto',
          text: this.$t('comum.responsavelComercial'),
          class: 'text-no-wrap',
          filterable: true,
          sortable: true,
        },
        {
          value: 'info',
          text: this.$t('comum.info'),
          class: 'text-no-wrap',
          filterable: false,
          sortable: false,
          align: 'center',
          width: 1,
        },
      ];
    },
    permissaoCriar() {
      const rule = CaslRules.Loja.criar;
      return this.$ability.can(rule?.action, rule?.subject);
    },
  },
  mounted() {
    this.listaLojas();
  },
  methods: {
    getResponsavel(loja: Loja, vinculo: VinculoLojaUsuarioEnum) {
      return loja?.usuarios.find(
        (el) => el?.JoinLojaUsuario?.vinculo === vinculo,
      );
    },
    async listaLojas() {
      this.loading.lojas = true;
      try {
        const queryObject = {
          scope: LojaScopesEnum.ComUsuarios,
        };
        this.lojas = await requester.lojas.lista(queryObject);
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.lojas = false;
      }
    },
  },
});
