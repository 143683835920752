




























import Vue from 'vue';

import requester from '@/requester';

import { PedidoScopesEnum } from '../../../../back/src/pedidos/pedidos.constants';
import { Pedido } from '../../../../back/src/models/pedido.model';

import PedidosRecebimentoInfo from './PedidosRecebimentoInfo.vue';

export default Vue.extend({
  name: 'PedidosRecebimentoDialogInfo',
  components: {
    PedidosRecebimentoInfo,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    pedidoId: {
      type: Number || null,
      default: null,
    },
  },
  data: () => ({
    loading: {
      pedido: false,
    },
    pedido: null as Pedido | null,
  }),
  watch: {
    pedidoId() {
      this.buscaPedido();
    },
    value(val) {
      if (val) {
        if (!this.$route.hash) {
          this.$router.push('#info');
        }
      } else {
        if (this.$route.hash) {
          this.$router.replace(this.$route.path);
        }
      }
    },
    '$route.hash'(newHash, oldHash) {
      if (oldHash === '#info') {
        this.onInput(false);
      }
    },
  },
  mounted() {
    this.buscaPedido();
  },
  methods: {
    onInput(event: boolean) {
      this.$emit('input', event);
    },
    onPedidoCancelado() {
      this.buscaPedido();
      this.$emit('pedido-alterado');
    },
    async buscaPedido() {
      if (!this.pedidoId) return;
      this.loading.pedido = true;
      try {
        this.pedido = await requester.pedidos.busca(this.pedidoId, {
          scope: PedidoScopesEnum.Info,
        });
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.pedido = false;
      }
    },
  },
});
