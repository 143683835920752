


















































































import Vue from 'vue';
import { debounce } from 'lodash';

import requester from '@/requester';
import { rulesRequired } from '@/utils/input-rules';

export default Vue.extend({
  name: 'AuthAtivarConta',
  data: () => ({
    loading: {
      verificaTokenUsuario: true,
      submitForm: false,
    },
    alertText: '',
    alertType: '',
    tokenValido: false,
    mostrarSenha: false,
    form: {
      senha: '',
      confirmacaoSenha: '',
      aceiteTermos: false,
    },
    rules: {
      required: rulesRequired,
    },
    usuario: null,
  }),
  computed: {
    token() {
      return this.$route?.params?.token || null;
    },
    appendIconSenha() {
      return this.mostrarSenha ? 'mdi-eye-off' : 'mdi-eye';
    },
    rulesConfirmacaoSenha() {
      return [
        ...rulesRequired,
        (v: string) =>
          v === this.form.senha || this.$t('rules.confirmacaoSenha'),
      ];
    },
  },
  mounted() {
    this.verificaTokenUsuario();
  },
  methods: {
    async verificaTokenUsuario() {
      this.loading.verificaTokenUsuario = true;
      try {
        this.usuario = await requester.auth.verificaTokenUsuario(this.token);
        this.tokenValido = true;
      } catch (err) {
        const data = err?.response?.data;
        if (data) {
          if (data?.statusCode === 404) {
            this.alertText = data.message;
            this.alertType = 'error';
          }
          if (data?.statusCode === 409) {
            this.alertText = data.message;
            this.alertType = 'warning';
          }
        } else {
          this.$root.$errorHandler(err);
        }
      } finally {
        this.loading.verificaTokenUsuario = false;
      }
    },
    submitFormDebounce: debounce(async function () {
      await this.submitForm();
    }, 200),
    async submitForm() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading.submitForm = true;
      const formData = {
        token: this.token,
        senha: this.form.senha,
        confirmacaoSenha: this.form.confirmacaoSenha,
        aceiteTermos: this.form.aceiteTermos,
      };
      try {
        await requester.auth.ativarConta(formData);
        this.$root.$emit(
          'toastSuccess',
          this.$t('AuthAtivarConta.contaAtivadaComSucesso'),
        );
        this.$router.push({ name: 'AuthLogin' });
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.submitForm = false;
      }
    },
  },
});
