





























































































































































































































































































































import Vue from 'vue';
import { debounce, orderBy } from 'lodash';
import { subject } from '@casl/ability';

import requester from '@/requester';

import { Loja } from '../../../../back/src/models/loja.model';
import { Rules as CaslRules } from '../../../../back/src/casl/casl.constants';
import {
  LojaScopesEnum,
  VinculoLojaUsuarioEnum,
} from '../../../../back/src/lojas/lojas.constants';

export default Vue.extend({
  name: 'LojasVer',
  data: () => ({
    loading: {
      loja: true,
    },
    loja: null as Loja,
  }),
  computed: {
    lojaId() {
      return this.$route?.params?.lojaId || 0;
    },
    permissaoAlterar() {
      const rule = CaslRules.Loja.atualizar;
      return this.$ability.can(
        rule?.action,
        this.loja ? subject('Loja', this.loja) : rule?.subject,
      );
    },
    permissaoRemover() {
      const rule = CaslRules.Loja.remover;
      return this.$ability.can(
        rule?.action,
        this.loja ? subject('Loja', this.loja) : rule?.subject,
      );
    },
    responsavelAdm() {
      return this.getResponsavel(
        VinculoLojaUsuarioEnum.RESPONSAVEL_ADMINISTRATIVO,
      );
    },
    responsavelComercial() {
      return this.getResponsavel(VinculoLojaUsuarioEnum.RESPONSAVEL_COMERCIAL);
    },
    compradores() {
      return orderBy(
        this.loja?.usuarios?.filter(
          (el) =>
            el?.JoinLojaUsuario?.vinculo === VinculoLojaUsuarioEnum.COMPRADOR,
        ) || [],
        (el) => el?.nomeCompleto,
      );
    },
  },
  mounted() {
    this.buscaLoja();
  },
  methods: {
    getResponsavel(vinculo: VinculoLojaUsuarioEnum) {
      return this.loja?.usuarios.find(
        (el) => el?.JoinLojaUsuario?.vinculo === vinculo,
      );
    },
    async buscaLoja() {
      this.loading.loja = true;
      try {
        const queryObject = {
          scope: LojaScopesEnum.ComUsuariosUsuarioCreatedUpdatedMargens,
        };
        this.loja = await requester.lojas.busca(this.lojaId, queryObject);
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.loja = false;
      }
    },
    removerDebounce: debounce(async function () {
      await this.remover();
    }, 200),
    async remover() {
      const confirm = await this.$root.$confirm(
        this.$t('LojasVer.removerLoja'),
        this.$t('LojasVer.certezaRemoverLoja'),
      );
      if (!confirm) return;
      this.loading.remover = true;
      try {
        await requester.lojas.remove(this.loja.id);
        this.$root.$emit(
          'toastSuccess',
          this.$t('LojasVer.lojaRemovidaComSucesso'),
        );
        this.$router.push({ name: 'LojasIndex' });
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.remover = false;
      }
    },
  },
});
