export enum LojaScopesEnum {
  ComEndereco = 'ComEndereco',
  ComMargens = 'ComMargens',
  ComUsuarios = 'ComUsuarios',
  ComUsuariosUsuarioCreatedUpdatedMargens = 'ComUsuariosUsuarioCreatedUpdatedMargens',
}

export enum VinculoLojaUsuarioEnum {
  COMPRADOR = 'COMPRADOR',
  RESPONSAVEL_ADMINISTRATIVO = 'RESPONSAVEL_ADMINISTRATIVO',
  RESPONSAVEL_COMERCIAL = 'RESPONSAVEL_COMERCIAL',
}
