





















































































































































































import { CONVERSION_TROY_OZ_TO_G } from '@/utils/constants/back.constants';
import Vue from 'vue';
export default Vue.extend({
  name: 'CotacoesResumoAtual',
  props: {
    cotacaoAtual: {
      type: null || Object,
      default: null,
    },
    cotacaoAnterior: {
      type: null || Object,
      default: null,
    },
    avatarSize: {
      type: Number,
      default: 32,
    },
    avatarColor: {
      type: String,
      default: 'grey lighten-1',
    },
    converterMoeda: {
      type: Boolean,
      default: false,
    },
    converterPeso: {
      type: Boolean,
      default: false,
    },
    painel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    diffPt() {
      if (!this.cotacaoAtual || !this.cotacaoAnterior) return null;
      const valorAtual = this.converteValor(
        this.cotacaoAtual.bidPt,
        this.cotacaoAtual.dolar,
      );
      const valorAnterior = this.converteValor(
        this.cotacaoAnterior.bidPt,
        this.cotacaoAnterior.dolar,
      );
      return ((valorAtual - valorAnterior) / valorAnterior) * 100;
    },
    diffPd() {
      if (!this.cotacaoAtual || !this.cotacaoAnterior) return null;
      const valorAtual = this.converteValor(
        this.cotacaoAtual.bidPd,
        this.cotacaoAtual.dolar,
      );
      const valorAnterior = this.converteValor(
        this.cotacaoAnterior.bidPd,
        this.cotacaoAnterior.dolar,
      );
      return ((valorAtual - valorAnterior) / valorAnterior) * 100;
    },
    diffRh() {
      if (!this.cotacaoAtual || !this.cotacaoAnterior) return null;
      const valorAtual = this.converteValor(
        this.cotacaoAtual.bidRh,
        this.cotacaoAtual.dolar,
      );
      const valorAnterior = this.converteValor(
        this.cotacaoAnterior.bidRh,
        this.cotacaoAnterior.dolar,
      );
      return ((valorAtual - valorAnterior) / valorAnterior) * 100;
    },
    diffDolar() {
      if (!this.cotacaoAtual || !this.cotacaoAnterior) return null;
      const valorAtual = this.cotacaoAtual.dolar;
      const valorAnterior = this.cotacaoAnterior.dolar;
      return ((valorAtual - valorAnterior) / valorAnterior) * 100;
    },
  },
  methods: {
    converteValor(valor: number, dolar: number) {
      const moeda = this.converterMoeda ? dolar : 1;
      const peso = this.converterPeso ? CONVERSION_TROY_OZ_TO_G : 1;
      return Math.floor((valor * moeda) / peso);
    },
  },
});
