





















































import Vue from 'vue';

import { version } from '../../package.json';
import { getPaisEmojiFlag } from '@/utils/pais-helper';

export default Vue.extend({
  name: 'LayoutPublic',
  data: () => ({
    version,
  }),
  computed: {
    paisEmojiFlag() {
      return getPaisEmojiFlag();
    },
  },
});
