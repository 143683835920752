import axios from 'axios';

import arquivos from './arquivos.requester';
import auth from './auth.requester';
import catalisadoresPecas from './catalisadores-pecas.requester';
import catalisadoresPo from './catalisadores-po.requester';
import cotacoes from './cotacoes.requester';
import externo from './externo';
import familias from './familias.requester';
import funcionalidades from './funcionalidades.requester';
import grupos from './grupos.requester';
import lojas from './lojas.requester';
import montadoras from './montadoras.requester';
import pedidos from './pedidos.requester';
import pedidosItemsPecas from './pedidos-items-pecas.requester';
import permissoes from './permissoes.requester';
import placas from './placas.requester';
import usuarios from './usuarios.requester';

// backend base URL
axios.defaults.baseURL = process.env.VUE_APP_URL_API;
// send authentication cookie
axios.defaults.withCredentials = true;
// query string serializer
// axios.defaults.paramsSerializer = (params) =>
//   new URLSearchParams(params).toString();

// force reload on version update
axios.interceptors.response.use((res) => {
  const version = res?.headers['katalis-version'];
  if (
    version &&
    res.config.method === 'get' &&
    version !== localStorage.getItem('katalis-version')
  ) {
    localStorage.setItem('katalis-version', version);
    window.location.reload();
  }
  return Promise.resolve(res);
});

export default {
  arquivos,
  auth,
  catalisadoresPecas,
  catalisadoresPo,
  cotacoes,
  externo,
  familias,
  funcionalidades,
  grupos,
  lojas,
  montadoras,
  pedidos,
  pedidosItemsPecas,
  permissoes,
  placas,
  usuarios,
};
