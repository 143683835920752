import { KatalisTranslationPages } from '../translations.interface';

const messages: KatalisTranslationPages = {
  // auth
  AuthAtivarConta: {
    aceitoTermosDeUsoPoliticaPrivacidade:
      'Aceito os Termos de Uso e Política de Privacidade',
    ativarConta: 'Ativar conta',
    contaAtivadaComSucesso: 'Conta ativada com sucesso',
    definaUmaSenha: 'Defina uma senha para sua conta',
    seuEmaiDeAcesso: 'Seu email de acesso é',
    voltarParaLogin: 'Voltar para o login',
  },
  AuthEsqueciMinhaSenha: {
    esqueceuSuaSenha: 'Esqueceu sua senha?',
    insiraSeuEmail:
      'Insira seu e-mail e enviaremos um link para que possa renovar sua senha ',
    linkRenovacaoSenhaEnviado:
      'Link de renovação de senha enviado com sucesso! Verifique sua caixa de entrada.',
    solicitarRenovacaoSenha: 'Solicitar renovação de senha',
    voltarParaLogin: 'Voltar para o login',
  },
  AuthLogin: {
    acessoCatalogo: 'Acesso Catálogo',
    acessoLims: 'Acesso LIMS',
    bemVindo: 'Bem-vindo',
    entreComSuaConta: 'Entre com sua conta',
    esqueceuSuaSenha: 'Esqueceu sua senha?',
    voceNaoTemPermissao: 'Você não tem permissão para acessar este sistema',
  },
  AuthRenovarSenha: {
    digiteSuaNovaSenha: 'Digite sua nova senha e uma confirmação',
    renovarSenha: 'Renovar senha',
    senhaRenovadaComSucesso: 'Senha renovada com sucesso',
    solicitarLinkRenovacaoDeSenha: 'Solicitar link para renovação de senha',
    voltarParaLogin: 'Voltar para o login',
  },
  // catalisadores-pecas
  CatalisadoresPecasImportar: {
    identificarColunas: 'Identificar colunas',
    selecionarArquivo: 'Selecionar arquivo',
    verificarDados: 'Verificar dados',
    verificarInconsistencias: 'Verificar inconsistências',
  },
  // catalisadores-po
  CatalisadoresPoIndex: {
    nenhumaFamiliaCadastrada: 'Nenhuma família de pó de catalisador cadastrada',
  },
  // erro
  ErroNaoEncontrado: {
    caminhoNaoEncontrado:
      'O caminho <span class="text--secondary">"%{path}"</span> não foi encontrado no sistema.',
    naoEncontrado: 'Não encontrado',
    porFavorVerifique:
      'Por favor, verifique se digitou o endereço corretamente.',
    voltarPaginaInicial: 'Voltar para a página inicial',
  },
  // grupos
  GruposVer: {
    naoEPossivelRemoverGrupoComUsuarios:
      'Não é possível remover um grupo com usuários',
    nenhumUsuarioPertenceGrupo: 'Nenhum usuário pertence a este grupo',
  },
  // lojas
  LojasVer: {
    certezaRemoverLoja: 'Tem certeza que deseja remover esta loja?',
    lojaRemovidaComSucesso: 'Loja removida com sucesso',
    removerLoja: 'Remover loja',
  },
  // usuarios
  UsuariosCadastroParceiro: {
    selecioneUfPrimeiro: 'Selecione a UF primeiro',
    usuarioCriadoComSucesso: 'Usuário criado com sucesso',
  },
  UsuariosEdicao: {
    usuarioAlteradoComSucesso: 'Usuário alterado com sucesso',
  },
  UsuariosVer: {
    certezaConviteParaAtivarConta:
      'Será enviado um email contendo um link para o usuário definir sua senha e ativar sua conta.',
    certezaRemoverUsuario: 'Tem certeza que deseja remover este usuário?',
    conviteEnviadoComSucesso: 'Convite enviado com sucesso',
    conviteNaoEnviado: 'Convite não enviado',
    conviteParaAtivarConta: 'Convite para ativar conta',
    copiarLinkAtivacao: 'Copiar link de ativação',
    enviarConviteAtivarConta: 'Enviar convite para ativar conta',
    linkCopiadoComSucesso:
      'Link copiado para área de transferência com sucesso',
    removerUsuario: 'Remover usuário',
    ultimoConviteEnviadoEm: 'Último convite enviado em',
    usuarioRemovidoComSucesso: 'Usuário removido com sucesso',
  },
};

export default messages;
