export enum StatusPedidoEnum {
  CANCELADO = 'CANCELADO',
  AGUARDANDO_APROVACAO = 'AGUARDANDO_APROVACAO',
  APROVADO_COM_ALTERACOES = 'APROVADO_COM_ALTERACOES',
  APROVADO = 'APROVADO',
}

export enum StatusPedidoItemPecaRecebimentoEnum {
  APROVADA = 'APROVADA',
  REPROVADA_DANIFICADA = 'REPROVADA_DANIFICADA',
  REPROVADA_FALTANTE = 'REPROVADA_FALTANTE',
  REPROVADA_INCORRETA = 'REPROVADA_INCORRETA',
  REPROVADA_OUTROS = 'REPROVADA_OUTROS',
}

export enum CategoriaPedidoItemEnum {
  CATALISADORES_PECAS = 'CATALISADORES_PECAS',
  CATALISADORES_PO = 'CATALISADORES_PO',
}

export enum PedidoScopesEnum {
  ComItems = 'ComItems',
  ComLojaFornecedorUsuarioCreatedByUpdatedBy = 'ComLojaFornecedorUsuarioCreatedByUpdatedBy',
  Info = 'Info',
  Recebimento = 'Recebimento',
}
