


























































































































import Vue from 'vue';
import { debounce, uniq } from 'lodash';

import requester from '@/requester';
import { rulesRequired } from '@/utils/input-rules';

import { Rules as CaslRules } from '../../../../back/src/casl/casl.constants';
import { Funcionalidade } from '../../../../back/src/models/funcionalidade.model';
import { Permissao } from '../../../../back/src/models/permissao.model';
import { AlteraFuncionalidadeDto } from '../../../../back/src/funcionalidades/dto/altera-funcionalidade.dto';

export default Vue.extend({
  name: 'FuncionalidadesPermissoesAlterar',
  data: () => ({
    loading: {
      funcionalidade: false,
      permissoes: false,
      submitForm: false,
    },
    search: '',
    funcionalidade: null as Funcionalidade,
    permissoes: [] as Permissao[],
    permissoesAssociadas: [] as Permissao[],
    filterSubject: null,
    showOnlyAtivos: null,
    form: {
      categoria: null,
      nome: null,
      descricao: null,
    },
    rules: {
      required: rulesRequired,
    },
  }),
  mounted() {
    this.listarPermissoes();
    this.buscaFuncionalidade();
  },
  computed: {
    headers() {
      return [
        {
          text: 'Action',
          value: 'action',
          groupable: false,
        },
        {
          text: 'Subject',
          value: 'subject',
        },
        {
          text: 'Conditions',
          value: 'conditions',
          groupable: false,
        },
      ];
    },
    itemsSubject() {
      return uniq(this.permissoes.map((el) => el.subject)).sort();
    },
    permissoesFiltered() {
      if (!this.filterSubject && !this.showOnlyAtivos) return this.permissoes;
      return this.permissoes.filter((el) => {
        let condition = true;
        if (this.showOnlyAtivos) {
          condition = !!this.funcionalidade.permissoes.find(
            (permissao) => permissao.id === el.id,
          );
        }
        if (this.filterSubject) {
          condition = condition && el.subject === this.filterSubject;
        }
        return condition;
      });
    },
    funcionalidadeId() {
      return Number(this.$route.params?.funcionalidadeId || 0);
    },
    permissaoAlterar() {
      const rule = CaslRules.Funcionalidade.alterar;
      return this.$ability.can(rule?.action, rule?.subject);
    },
  },
  methods: {
    associaPermissoes() {
      this.permissoesAssociadas =
        this.funcionalidade?.permissoes?.length > 0
          ? (this.funcionalidade?.permissoes as Permissao[])
          : [];
    },
    async buscaFuncionalidade() {
      this.loading.funcionalidade = true;
      try {
        this.funcionalidade = await requester.funcionalidades.buscar(
          this.funcionalidadeId,
        );
        this.form.categoria = this.funcionalidade.categoria;
        this.form.nome = this.funcionalidade.nome;
        this.form.descricao = this.funcionalidade.descricao;
        this.associaPermissoes();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.funcionalidade = false;
      }
    },
    async listarPermissoes() {
      this.loading.permissoes = true;
      try {
        this.permissoes = await requester.permissoes.listar();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.permissoes = false;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async onSelected(event: any) {
      const permissaoId = event.item?.id;
      try {
        if (event.value) {
          await requester.funcionalidades.associaPermissao(
            this.funcionalidadeId,
            permissaoId,
          );
          this.$root.$emit(
            'toastSuccess',
            `Permissão associada com sucesso`,
            1000,
          );
        } else {
          await requester.funcionalidades.removePermissao(
            this.funcionalidadeId,
            permissaoId,
          );
          this.$root.$emit(
            'toastSuccess',
            `Permissão removida com sucesso`,
            1000,
          );
        }
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    submitFormDebounce: debounce(function () {
      this.submitForm();
    }, 200),
    async submitForm() {
      if (!this.$refs?.form?.validate()) {
        return;
      }
      this.loading.submitForm = true;
      try {
        const dto: AlteraFuncionalidadeDto = {
          categoria: this.form.categoria?.trim() || null,
          nome: this.form.nome?.trim() || null,
          descricao: this.form.descricao?.trim() || null,
        };
        await requester.funcionalidades.alterar(this.funcionalidade.id, dto);
        this.$router.push({ name: 'FuncionalidadesIndex' });
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.submitForm = false;
      }
    },
  },
});
