




































































































import Vue from 'vue';

import {
  getIconBrowser,
  getIconDevice,
  getIconPlatform,
  getTypeDevice,
} from '@/utils/usuarios-dispositivos-helper';

import { UsuarioDispositivo } from '../../../../back/src/models/usuario-dispositivo.model';

import UsuariosDialogDispositivoInfo from './UsuariosDialogDispositivoInfo.vue';

export default Vue.extend({
  name: 'UsuariosTabelaDispositivos',
  components: {
    UsuariosDialogDispositivoInfo,
  },
  props: {
    dispositivos: {
      type: Array as () => UsuarioDispositivo[],
      default: [] as UsuarioDispositivo[],
    },
  },
  data: () => ({
    loading: {
      dispositivos: true,
    },
    dialogInfo: false,
    dispositivoSelecionado: null as null | UsuarioDispositivo,
  }),
  computed: {
    headers() {
      return [
        {
          value: 'createdAt',
          text: this.$t('comum.primeiroAcessoEm'),
          class: 'text-no-wrap',
        },
        {
          value: 'dispositivoInfo.isMobile',
          text: this.$t('comum.dispositivo'),
          class: 'text-no-wrap',
          align: 'center',
          sortable: false,
        },
        {
          value: 'dispositivoInfo.platform',
          text: this.$t('comum.plataforma'),
          class: 'text-no-wrap',
          align: 'center',
          sortable: false,
        },
        {
          value: 'dispositivoInfo.browser',
          text: this.$t('comum.navegador'),
          class: 'text-no-wrap',
          align: 'center',
          sortable: false,
        },
        {
          value: 'dispositivoInfo.source',
          text: this.$t('comum.identificacao'),
          class: 'text-no-wrap',
          sortable: false,
        },
        {
          value: 'dispositivoAutorizado',
          text: this.$t('comum.autorizado'),
          class: 'text-no-wrap',
        },
        {
          value: 'info',
          text: this.$t('comum.detalhes'),
          class: 'text-no-wrap',
          sortable: false,
        },
      ];
    },
  },
  methods: {
    getIconBrowser,
    getIconDevice,
    getIconPlatform,
    getTypeDevice,
    dispositivoAlterado(dispositivo: UsuarioDispositivo) {
      this.$emit('dispositivo-alterado', dispositivo);
      this.dispositivoSelecionado = dispositivo;
    },
    infoDialog(dispositivo: UsuarioDispositivo) {
      this.dispositivoSelecionado = dispositivo;
      this.dialogInfo = true;
    },
  },
});
