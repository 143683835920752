
























































































































































































































import Vue from 'vue';

import { CONVERSION_TROY_OZ_TO_G } from '@/utils/constants/back.constants';

export default Vue.extend({
  name: 'CotacoesInfo',
  props: {
    cotacao: {
      type: null || Object,
      default: null,
    },
  },
  data: () => ({
    expand: false,
  }),
  computed: {
    currency() {
      return process.env.VUE_APP_PAIS_CURRENCY || 'BRL';
    },
    locale() {
      return process.env.VUE_APP_PAIS_LOCALE || 'pt-BR';
    },
  },
  methods: {
    converteMoeda(valor: number) {
      return valor * this.cotacao.dolar;
    },
    convertePeso(valor: number) {
      return valor / CONVERSION_TROY_OZ_TO_G;
    },
    converteMoedaPeso(valor: number) {
      return (valor * this.cotacao.dolar) / CONVERSION_TROY_OZ_TO_G;
    },
  },
});
