





























import Vue from 'vue';

import requester from '@/requester';

import { PedidoScopesEnum } from '../../../../back/src/pedidos/pedidos.constants';
import { Pedido } from '../../../../back/src/models/pedido.model';

import PedidosInfo from './PedidosInfo.vue';

export default Vue.extend({
  name: 'PedidosDialogInfo',
  components: {
    PedidosInfo,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    pedidoId: {
      type: Number || null,
      default: null,
    },
  },
  data: () => ({
    loading: {
      pedido: false,
    },
    pedido: null as Pedido | null,
  }),
  watch: {
    pedidoId() {
      this.buscaPedido();
    },
  },
  mounted() {
    this.buscaPedido();
  },
  methods: {
    onInput(event: boolean) {
      this.$emit('input', event);
    },
    onPedidoCancelado() {
      this.buscaPedido();
      this.$emit('pedido-alterado');
    },
    async buscaPedido() {
      if (!this.pedidoId) return;
      this.loading.pedido = true;
      try {
        this.pedido = await requester.pedidos.busca(this.pedidoId, {
          scope: PedidoScopesEnum.Info,
        });
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.pedido = false;
      }
    },
  },
});
