import axios from 'axios';

const lista = async (): Promise<any> => {
  const res = await axios.get<any>(`montadoras`);
  return res.data;
};

export default {
  lista,
};
