





























import Vue from 'vue';

import GruposFormCriarAlterar from './GruposFormCriarAlterar.vue';

export default Vue.extend({
  name: 'GruposDialogEdicao',
  components: {
    GruposFormCriarAlterar,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    grupo: {
      type: Object,
      required: true,
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.$refs?.gruposFormCriarAlterar?.preencheForm();
      }
    },
  },
  methods: {
    fechar() {
      this.$refs?.gruposFormCriarAlterar?.cancelar() ||
        this.$emit('input', false);
    },
    grupoAlterado(grupo) {
      this.$emit('input', false);
      this.$emit('grupo-alterado', grupo);
    },
  },
});
