










































import Vue from 'vue';

import requester from '@/requester';
import { rulesRequired } from '@/utils/input-rules';
import { debounce } from 'lodash';

import CustomCancelarSalvar from '../custom/CustomCancelarSalvar.vue';

export default Vue.extend({
  name: 'PermissoesFormCadastro',
  components: { CustomCancelarSalvar },
  data: () => ({
    loading: {
      submitForm: false,
    },
    form: {
      action: null,
      subject: null,
      conditions: null,
    },
    rules: {
      required: rulesRequired,
    },
  }),
  methods: {
    cancelar() {
      this.$refs.form.reset();
      this.$emit('fechar');
    },
    submitFormDebounce: debounce(async function () {
      await this.submitForm();
    }, 200),
    async submitForm() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading.submitForm = true;
      try {
        const formData = {
          action: this.form?.action?.trim(),
          subject: this.form?.subject?.trim(),
          conditions: this.form?.conditions?.trim(),
        };
        await requester.permissoes.criar(formData);
        this.$root.$emit('toastSuccess', `Permissão criada com sucesso`);
        this.$refs.form.reset();
        this.$emit('fechar');
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.submitForm = false;
      }
    },
  },
});
