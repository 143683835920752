






































































import Vue from 'vue';
import { debounce, orderBy } from 'lodash';

import requester from '@/requester';
import { rulesRequired } from '@/utils/input-rules';
import { inputPriceToNumber, maskCurrency } from '@/utils/intl-functions';

import CustomCancelarSalvar from '../custom/CustomCancelarSalvar.vue';

export default Vue.extend({
  name: 'PlacasFormCriarAlterar',
  components: {
    CustomCancelarSalvar,
  },
  props: {
    placa: {
      type: null || Object,
      default: null,
    },
    familias: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    loading: {
      submitForm: false,
    },
    form: {
      familiaId: null,
      codigo: null,
      material: null,
      valorKg: null,
    },
    rules: {
      required: rulesRequired,
    },
  }),
  computed: {
    itemsFamilias() {
      return orderBy(
        this.familias.map((el) => ({
          value: el.id,
          text: `${el.codigo} - ${el.descricao}`,
        })),
        ['text'],
      );
    },
  },
  mounted() {
    this.preencheForm();
  },
  methods: {
    maskCurrency,
    focusInput(inputRef: string) {
      (this.$refs[inputRef] as HTMLElement).focus();
    },
    preencheForm() {
      if (this.placa) {
        this.form.familiaId = this.placa.familiaId;
        this.form.codigo = this.placa.codigo;
        this.form.material = this.placa.material;
        this.form.valorKg = maskCurrency(this.placa.valorKg.toFixed(2));
      }
    },
    cancelar() {
      this.$refs.form.reset();
      this.$emit('cancelar');
    },
    submitFormDebounce: debounce(async function () {
      await this.submitForm();
    }, 200),
    async submitForm() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading.submitForm = true;
      try {
        const formData = {
          familiaId: this.form?.familiaId || null,
          codigo: this.form?.codigo?.trim() || '',
          material: this.form?.material?.trim() || '',
          valorKg: inputPriceToNumber(this.form?.valorKg),
        };
        let placa;
        if (this.placa) {
          placa = await requester.placas.atualiza(this.placa.id, formData);
          this.$root.$emit(
            'toastSuccess',
            this.$t('PlacasFormCriarAlterar.placaAlteradaComSucesso'),
          );
          this.$emit('placa-alterada', placa);
        } else {
          placa = await requester.placas.cria(formData);
          this.$root.$emit(
            'toastSuccess',
            this.$t('PlacasFormCriarAlterar.placaCriadaComSucesso'),
          );
          this.$emit('placa-criada', placa);
        }
        this.$refs.form.reset();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.submitForm = false;
      }
    },
  },
});
