



















































































import Vue from 'vue';

export default Vue.extend({
  name: 'FamiliasInfo',
  props: {
    familia: {
      type: null || Object,
      default: null,
    },
  },
});
