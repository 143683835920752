































import Vue from 'vue';

import CatalisadoresPecasFormCriarEditar from './CatalisadoresPecasFormCriarEditar.vue';

export default Vue.extend({
  name: 'CatalisadoresPecasDialogCriar',
  components: {
    CatalisadoresPecasFormCriarEditar,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    montadoras: {
      type: Array,
      default: () => [],
    },
    familias: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({}),
});
