
































































import Vue from 'vue';
import { debounce, orderBy } from 'lodash';

import requester from '@/requester';
import { rulesRequired } from '@/utils/input-rules';

import CustomCancelarSalvar from '../custom/CustomCancelarSalvar.vue';
import { CategoriaFamiliaEnum } from '@/utils/constants/back.constants';

export default Vue.extend({
  name: 'FamiliasFormCriarAlterar',
  components: {
    CustomCancelarSalvar,
  },
  props: {
    familia: {
      type: null || Object,
      default: null,
    },
  },
  data: () => ({
    loading: {
      submitForm: false,
    },
    form: {
      categoria: null,
      codigo: null,
      descricao: null,
      descricaoCatalogo: null,
    },
    rules: {
      required: rulesRequired,
    },
  }),
  computed: {
    itemsCategorias() {
      return orderBy(
        Object.values(CategoriaFamiliaEnum).map((el) => ({
          value: el,
          text: this.$t(`CategoriaFamiliaEnum.${el}`),
        })),
        ['text'],
      );
    },
  },
  mounted() {
    this.preencheForm();
  },
  methods: {
    focusInput(inputRef: string) {
      (this.$refs[inputRef] as HTMLElement).focus();
    },
    preencheForm() {
      if (this.familia) {
        this.form.categoria = this.familia.categoria;
        this.form.codigo = this.familia.codigo;
        this.form.descricao = this.familia.descricao;
        this.form.descricaoCatalogo = this.familia.descricaoCatalogo;
      }
    },
    cancelar() {
      this.$refs.form.reset();
      this.$emit('cancelar');
    },
    submitFormDebounce: debounce(async function () {
      await this.submitForm();
    }, 200),
    async submitForm() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading.submitForm = true;
      try {
        const formData = {
          categoria: this.form?.categoria || null,
          codigo: this.form?.codigo?.trim() || '',
          descricao: this.form?.descricao?.trim() || '',
          descricaoCatalogo: this.form?.descricaoCatalogo?.trim() || null,
        };
        let familia;
        if (this.familia) {
          familia = await requester.familias.atualiza(
            this.familia.id,
            formData,
          );
          this.$root.$emit(
            'toastSuccess',
            this.$t('FamiliasFormCriarAlterar.familiaAlteradaComSucesso'),
          );
          this.$emit('familia-alterada', familia);
        } else {
          familia = await requester.familias.cria(formData);
          this.$root.$emit(
            'toastSuccess',
            this.$t('FamiliasFormCriarAlterar.familiaCriadaComSucesso'),
          );
          this.$emit('familia-criada', familia);
        }
        this.$refs.form.reset();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.submitForm = false;
      }
    },
  },
});
