









































































































































































































































import Vue from 'vue';

import requester from '@/requester';

import { PedidoItem } from '../../../../back/src/models/pedido-item.model';
import { StatusPedidoItemPecaRecebimentoEnum } from '../../../../back/src/pedidos/pedidos.constants';
import { PedidoItemPecaRecebimento } from '../../../../back/src/models/pedido-item-peca-recebimento.model';

import CatalisadoresPecasGaleria from '../catalisadores-pecas/CatalisadoresPecasGaleria.vue';
import TextOrNA from '../TextOrNA.vue';
import PedidosItemRecebimentoPeca from './PedidosItemRecebimentoPeca.vue';
import { debounce } from 'lodash';

export default Vue.extend({
  name: 'PedidosItemRecebimento',
  components: {
    CatalisadoresPecasGaleria,
    PedidosItemRecebimentoPeca,
    TextOrNA,
  },
  props: {
    pedidoItem: {
      type: Object as () => PedidoItem,
      required: true,
    },
  },
  data: () => ({
    loading: {
      fotosArquivos: false,
      aprovarMultiplas: false,
    },
    dialog: false,
    showGaleria: false,
    fotosArquivos: [],
    pecas: [],
    menuAprovarMultiplas: false,
    formAprovarMultiplas: 0,
  }),
  computed: {
    statusItem() {
      if (
        this.quantidadePecasReprovadas + this.quantidadePecasAprovadas <
        this.quantidadePecas
      ) {
        return 'aguardando_verificacao';
      }
      if (this.quantidadePecasAprovadas === this.quantidadePecas) {
        return 'aprovado';
      }
      return 'reprovado';
    },
    colorAvatar() {
      switch (this.statusItem) {
        case 'aprovado':
          return 'success lighten-4';
        case 'reprovado':
          return 'warning lighten-3';
        default:
          return 'grey lighten-3';
      }
    },
    colorIconAvatar() {
      switch (this.statusItem) {
        case 'aprovado':
          return 'success darken-3';
        case 'reprovado':
          return 'warning darken-3';
        default:
          return 'grey darken-1';
      }
    },
    iconAvatar() {
      switch (this.statusItem) {
        case 'aprovado':
          return 'mdi-check-bold';

        case 'reprovado':
          return 'mdi-alert';

        default:
          return 'mdi-eye';
      }
    },
    pecaId() {
      return this.pedidoItem?.pedidoItemPeca?.catalisadorPecaId || 0;
    },
    pecaCodigo() {
      return this.pedidoItem?.pedidoItemPeca?.catalisadorPecaDados?.codigo;
    },
    pecaDetalhe() {
      return this.pedidoItem?.pedidoItemPeca?.catalisadorPecaDados?.detalhe;
    },
    pecaMontadora() {
      return this.pedidoItem?.pedidoItemPeca?.catalisadorPecaDados
        ?.montadoraNome;
    },
    pecaModelo() {
      return this.pedidoItem?.pedidoItemPeca?.catalisadorPecaDados?.modelo;
    },
    pecaFamilia() {
      return `${this.pedidoItem?.pedidoItemPeca?.catalisadorPeca?.familia?.codigo} - ${this.pedidoItem?.pedidoItemPeca?.catalisadorPeca?.familia?.descricao}`;
    },
    pecaDescricaoCatalogo() {
      return this.pedidoItem?.pedidoItemPeca?.catalisadorPeca?.familia
        ?.descricaoCatalogo;
    },
    pecaDetalheEDescricaoCatalogo() {
      return this.pecaDetalhe
        ? `${this.pecaDetalhe} (${this.pecaDescricaoCatalogo || 'N/A'})`
        : `(${this.pecaDescricaoCatalogo || 'N/A'})`;
    },
    pecaPesoKg() {
      return this.pedidoItem?.pedidoItemPeca?.catalisadorPecaDados?.pesoKg;
    },
    pecaAviso() {
      return this.pedidoItem?.pedidoItemPeca?.catalisadorPeca?.aviso;
    },
    pecaFotoArquivoId() {
      return this.pedidoItem?.pedidoItemPeca?.catalisadorPeca?.fotoArquivoId;
    },
    classPecasAprovadas() {
      return this.quantidadePecasAprovadas > 0
        ? 'success--text'
        : 'text--disabled';
    },
    quantidadePecas() {
      return this.pedidoItem?.pedidoItemPeca?.quantidadeInicial;
    },
    quantidadePecasAprovadas() {
      return (
        this.pedidoItem?.pedidoItemPeca?.recebimentos?.filter(
          (el) => el.status === StatusPedidoItemPecaRecebimentoEnum.APROVADA,
        )?.length || 0
      );
    },
    quantidadePecasReprovadas() {
      return (
        this.pedidoItem?.pedidoItemPeca?.recebimentos?.filter(
          (el) => el.status !== StatusPedidoItemPecaRecebimentoEnum.APROVADA,
        )?.length || 0
      );
    },
    quantidadePecasAguardandoVerificacao() {
      return (
        this.quantidadePecas -
        this.quantidadePecasAprovadas -
        this.quantidadePecasReprovadas
      );
    },
    rulesAprovarMultiplas() {
      return [
        (v: string | number | null) =>
          (!isNaN(Number(v)) &&
            v !== '' &&
            Number(v) >= 0 &&
            Number(v) <= this.quantidadePecasAguardandoVerificacao) ||
          'Número inválido',
      ];
    },
  },
  watch: {
    menuAprovarMultiplas(val) {
      if (val) {
        this.formAprovarMultiplas = this.quantidadePecasAguardandoVerificacao;
      }
    },
    dialog(val) {
      if (val) {
        if (!this.$route.hash) {
          this.$router.push('#item');
        }
      } else {
        if (this.$route.hash) {
          this.$router.replace(this.$route.path);
        }
      }
    },
    '$route.hash'(newHash, oldHash) {
      if (oldHash === '#item') {
        this.dialog = false;
      }
    },
  },
  mounted() {
    const quantidadeInicial = this.pedidoItem.pedidoItemPeca.quantidadeInicial;
    const recebimentos = this.pedidoItem.pedidoItemPeca.recebimentos;
    this.pecas = recebimentos
      .map((el) => el)
      .concat(
        [...Array(quantidadeInicial - recebimentos.length)].map(
          (el, index) => ({
            id: -(quantidadeInicial + index),
            status: null,
          }),
        ),
      );
  },
  methods: {
    limitFormAprovarMultiplas(val) {
      if (val === '') {
        this.formAprovarMultiplas = this.quantidadePecasAguardandoVerificacao;
      } else if (Number(val) < 0) {
        this.formAprovarMultiplas = 0;
      } else if (Number(val) > this.quantidadePecasAguardandoVerificacao) {
        this.formAprovarMultiplas = this.quantidadePecasAguardandoVerificacao;
      }
    },
    recebimentoAtualizado(
      pecaId: number,
      recebimento: PedidoItemPecaRecebimento,
    ) {
      const index = this.pecas.findIndex((peca) => peca.id === pecaId);
      if (index < 0) return;
      this.$set(this.pecas, index, recebimento);
      this.$emit('recebimentos-atualizados', this.pecas);
      if (this.statusItem !== 'aguardando_verificacao') {
        this.dialog = false;
      }
    },
    recebimentoRemovido(recebimento: PedidoItemPecaRecebimento) {
      const index = this.pecas.findIndex((peca) => peca.id === recebimento.id);
      if (index < 0) return;
      this.$set(this.pecas, index, { id: -index, status: null });
      this.$emit('recebimentos-atualizados', this.pecas);
    },
    verFotos() {
      if (this.loading.fotosArquivos) return;
      this.showGaleria = !this.showGaleria;
      if (this.fotosArquivos.length > 0) return;
      this.buscaFotosArquivos(this.pecaId);
    },
    async buscaFotosArquivos(catalisadorPecaId: number) {
      if (!catalisadorPecaId) {
        this.fotosArquivos = [];
      }
      this.loading.fotosArquivos = true;
      try {
        this.fotosArquivos =
          await requester.catalisadoresPecas.buscaFotosArquivos(
            catalisadorPecaId,
          );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.fotosArquivos = false;
      }
    },
    aprovarMultiplasDebounce: debounce(function () {
      this.aprovarMultiplas();
    }, 200),
    async aprovarMultiplas() {
      this.loading.aprovarMultiplas = true;
      try {
        const quantidade = Number(this.formAprovarMultiplas);
        if (quantidade <= 0) return;
        const recebimentos =
          await requester.pedidosItemsPecas.aprovaMultiplasPedidoItemPecaRecebimento(
            this.pedidoItem?.pedidoItemPeca?.id,
            { quantidade },
          );
        recebimentos.forEach((recebimento) => {
          const index = this.pecas.findIndex((peca) => peca.id < 0);
          if (index < 0) return;
          this.$set(this.pecas, index, recebimento);
        });
        this.$emit('recebimentos-atualizados', this.pecas);
        this.menuAprovarMultiplas = false;
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.aprovarMultiplas = false;
      }
    },
  },
});
