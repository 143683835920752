import { Arquivo } from '../models/arquivo.model';
import { JoinArquivoCatalisadorPeca } from '../models/join-arquivo-catalisador-peca';

export enum AvisoCatalisadorPecaEnum {
  CHAMAR_COMERCIAL = 'CHAMAR_COMERCIAL',
  SEPARAR_PARA_ANALISE = 'SEPARAR_PARA_ANALISE',
}

export enum ViewCatalisadorPecaAtualScopesEnum {
  ComAnalisesUsuarios = 'ComAnalisesUsuarios',
  ComUsuarios = 'ComUsuarios',
}

export type ArquivoJoinCatalisadorPeca = Arquivo & {
  JoinArquivoCatalisadorPeca: JoinArquivoCatalisadorPeca;
};
