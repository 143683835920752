












































































































import Vue from 'vue';
import { Permissao } from '../../../../back/src/models/permissao.model';
export default Vue.extend({
  name: 'PermissoesDialogInfoSync',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    criadas: {
      type: Array as () => Permissao[],
      default: () => [],
    },
    removidas: {
      type: Array as () => Permissao[],
      default: () => [],
    },
  },
  data: () => ({}),
  methods: {
    fechar() {
      this.$emit('input', false);
    },
  },
});
