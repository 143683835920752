








































































import Vue from 'vue';
import { orderBy } from 'lodash';

import requester from '@/requester';

import { Rules as CaslRules } from '../../../../back/src/casl/casl.constants';
import { CategoriaFamiliaEnum } from '@/utils/constants/back.constants';

import PlacasDialogCadastro from '@/components/placas/PlacasDialogCadastro.vue';
import PlacasDialogInfo from '@/components/placas/PlacasDialogInfo.vue';

export default Vue.extend({
  name: 'PlacasIndex',
  components: {
    PlacasDialogCadastro,
    PlacasDialogInfo,
  },
  data: () => ({
    loading: {
      familias: true,
      placas: true,
    },
    search: '',
    dialogCadastro: false,
    dialogInfo: false,
    filterFamilia: null,
    placaInfoId: 0,
    familiasPlacas: [],
    placas: [],
  }),
  computed: {
    placasFiltradas() {
      return this.placas
        .filter((el) => {
          if (this.filterFamilia) {
            return el.familiaId === this.filterFamilia;
          }
          return true;
        })
        .map((el) => ({ ...el, familia: this.getFamilia(el.familiaId) }));
    },
    itemsFamilias() {
      return orderBy(
        this.familiasPlacas.map((el) => ({
          value: el.id,
          text: `${el.codigo} - ${el.descricao}`,
        })),
        ['text'],
      );
    },
    headers() {
      return [
        {
          value: 'codigo',
          text: this.$t('comum.codigo'),
          class: 'text-no-wrap',
          filterable: true,
          sortable: true,
        },
        {
          value: 'material',
          text: this.$t('comum.material'),
          class: 'text-no-wrap',
          filterable: true,
          sortable: true,
        },
        {
          value: 'familia.descricao',
          text: this.$t('comum.familia'),
          class: 'text-no-wrap',
          filterable: true,
          sortable: true,
        },
        {
          value: 'valorKg',
          text: `${this.$t('comum.valor')} (${this.$t(
            'comum.moedaSistema',
          )}/${this.$t('comum.kg')})`,
          class: 'text-no-wrap',
          filterable: true,
          sortable: true,
        },
        {
          value: 'info',
          text: this.$t('comum.info'),
          class: 'text-no-wrap',
          filterable: false,
          sortable: false,
          align: 'center',
          width: 1,
        },
      ];
    },
    permissaoCriar() {
      const rule = CaslRules.Placa.criar;
      return this.$ability.can(rule?.action, rule?.subject);
    },
  },
  mounted() {
    this.listaPlacas();
    this.listaFamiliasPlacas();
  },
  methods: {
    getFamilia(familiaId: number) {
      return this.familiasPlacas.find((el) => el.id === familiaId);
    },
    infoPlaca(placaId: number) {
      this.placaInfoId = placaId;
      this.dialogInfo = true;
    },
    placaCriada(placa) {
      this.placas.push(placa);
    },
    placaAlterada(placa) {
      const index = this.placas.findIndex((el) => el.id === placa.id);
      if (index >= 0) {
        this.$set(this.placas, index, placa);
      }
    },
    placaRemovida(placaId: number) {
      const index = this.placas.findIndex((el) => el.id === placaId);
      if (index >= 0) {
        this.$delete(this.placas, index);
      }
    },
    async listaPlacas() {
      this.loading.placas = true;
      try {
        this.placas = await requester.placas.lista();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.placas = false;
      }
    },
    async listaFamiliasPlacas() {
      this.loading.familias = true;
      try {
        const listarFamiliasQuery = {
          categoria: CategoriaFamiliaEnum.PLACAS,
        };
        this.familiasPlacas = await requester.familias.lista(
          listarFamiliasQuery,
        );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.familias = false;
      }
    },
  },
});
