var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"items":_vm.catalisadoresPecasAnalises,"headers":_vm.headers,"options":_vm.options,"loading":_vm.loading.catalisadoresPecasAnalises,"server-items-length":_vm.serverItemsLength,"item-key":"id","hide-default-header":"","mobile-breakpoint":"0"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('tr',{staticClass:"secondary--text text-body-2"},_vm._l((props.headers),function(header){return _c('th',{key:header.value,class:[
              header.class ? header.class : '',
              header.align ? ("text-" + (header.align)) : 'text-left',
              header.sortable || header.filterable ? 'pl-4' : '',
              'text-no-wrap py-2' ],staticStyle:{"border-bottom":"1pt solid #dedede"}},[(header.sortable || header.filterable)?_c('CatalisadoresPecasMenuHeader',{attrs:{"header":header,"items":header.value === 'montadoraNome' ? _vm.itemsMontadoras : []},on:{"sort-asc":function($event){return _vm.sortAsc(header.value)},"sort-desc":function($event){return _vm.sortDesc(header.value)},"apply-filter":function($event){return _vm.applyFilter(header.value, $event)}},model:{value:(header.menu),callback:function ($$v) {_vm.$set(header, "menu", $$v)},expression:"header.menu"}}):[_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]],2)}),0)]}},{key:"item.codigo",fn:function(ref){
            var item = ref.item;
return [_c('span',{class:[
            'font-weight-medium text-no-wrap',
            !item.ativo ? 'text--disabled' : '' ],staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.info(item.catalisadorPecaId)}}},[_vm._v(" "+_vm._s(item.codigo)+" ")])]}},{key:"item.detalhe",fn:function(ref){
          var item = ref.item;
return [(item.detalhe)?_c('span',{class:['text-no-wrap', !item.ativo ? 'text--disabled' : '']},[_vm._v(" "+_vm._s(item.detalhe)+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.montadoraNome",fn:function(ref){
          var item = ref.item;
return [(item.montadoraNome)?_c('span',{class:['text-no-wrap', !item.ativo ? 'text--disabled' : '']},[_vm._v(" "+_vm._s(item.montadoraNome)+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.modelo",fn:function(ref){
          var item = ref.item;
return [(item.modelo)?_c('span',{class:['text-no-wrap', !item.ativo ? 'text--disabled' : '']},[_vm._v(" "+_vm._s(item.modelo)+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.dataAnalise",fn:function(ref){
          var item = ref.item;
return [(item.dataAnalise)?_c('span',{class:['text-no-wrap', !item.ativo ? 'text--disabled' : '']},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.dataAnalise))+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.ptPpm",fn:function(ref){
          var item = ref.item;
return [_c('span',{class:['text-no-wrap', !item.ativo ? 'text--disabled' : '']},[_vm._v(" "+_vm._s(item.ptPpm)+" ")])]}},{key:"item.pdPpm",fn:function(ref){
          var item = ref.item;
return [_c('span',{class:['text-no-wrap', !item.ativo ? 'text--disabled' : '']},[_vm._v(" "+_vm._s(item.pdPpm)+" ")])]}},{key:"item.rhPpm",fn:function(ref){
          var item = ref.item;
return [_c('span',{class:['text-no-wrap', !item.ativo ? 'text--disabled' : '']},[_vm._v(" "+_vm._s(item.rhPpm)+" ")])]}},{key:"item.pesoKg",fn:function(ref){
          var item = ref.item;
return [_c('span',{class:['text-no-wrap', !item.ativo ? 'text--disabled' : '']},[_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.pesoKg,{ fractionDigits: 3 }))+" ")])]}},{key:"item.observacoes",fn:function(ref){
          var item = ref.item;
return [(item.observacoes)?_c('span',{class:!item.ativo ? 'text--disabled' : ''},[_vm._v(" "+_vm._s(item.observacoes)+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.ativo",fn:function(ref){
          var item = ref.item;
return [(item.ativo)?_c('span',{staticClass:"font-weight-medium success--text"},[_vm._v(" "+_vm._s(_vm.$t('comum.sim'))+" ")]):_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$t('comum.nao'))+" ")])]}},{key:"item.info",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.info(item.catalisadoPecaId)}}},[_c('v-icon',[_vm._v("mdi-information")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }