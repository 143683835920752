






import Vue from 'vue';

import UsuariosFormFuncionario from '@/components/usuarios/UsuariosFormFuncionario.vue';

export default Vue.extend({
  name: 'UsuariosCadastroFuncionario',
  components: {
    UsuariosFormFuncionario,
  },
});
