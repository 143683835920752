var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-column-reverse flex-sm-row align-sm-center"},[_c('v-text-field',{staticClass:"mb-2",attrs:{"placeholder":_vm.$t('comum.busca'),"append-icon":"mdi-magnify","clearable":"","filled":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.permissaoCriar)?_c('v-btn',{staticClass:"ml-0 ml-sm-2 mb-2",attrs:{"block":_vm.$vuetify.breakpoint.xsOnly,"depressed":"","color":"success","to":{ name: 'LojasCadastro' }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_c('span',[_vm._v(_vm._s(_vm.$t('comum.loja')))])],1):_vm._e()],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"search":_vm.search,"items":_vm.lojasComResponsaveis,"headers":_vm.headers,"loading":_vm.loading.lojas},scopedSlots:_vm._u([{key:"item.codigo",fn:function(ref){
var item = ref.item;
return [(item.codigo)?_c('span',[_vm._v(" "+_vm._s(item.codigo)+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.bairro",fn:function(ref){
var item = ref.item;
return [(item.bairro)?_c('span',[_vm._v(" "+_vm._s(item.bairro)+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.celular",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPhone")(item.celular))+" ")]}},{key:"item.telefone",fn:function(ref){
var item = ref.item;
return [(item.telefone)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatPhone")(item.telefone))+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"info","to":{ name: 'LojasVer', params: { lojaId: item.id } },"exact":""}},[_c('v-icon',[_vm._v("mdi-information")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }