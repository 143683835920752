import { KatalisTranslationComum } from '../translations.interface';

const messages: KatalisTranslationComum = {
  comum: {
    acao: 'Ação',
    acoes: 'Ações',
    acompanhamento: 'Acompanhamento',
    adicionar: 'Adicionar',
    administrativo: 'Administrativo',
    agencia: 'Agência',
    aguardandoCadastroSenha: 'Aguardando cadastro de senha',
    aguardandoVerificacao: 'Aguardando verificação',
    alterar: 'Alterar',
    alterarEmLote: 'Alterar em lote',
    alterarRecebimento: 'Alterar recebimento',
    analise: 'Análise',
    analises: 'Análises',
    aplicar: 'Aplicar',
    aprovada: 'Aprovada',
    aprovadas: 'Aprovadas',
    aprovadoComAlteracoes: 'Aprovado com alterações',
    ativa: 'Ativa',
    ativacao: 'Ativação',
    ativo: 'Ativo',
    autorizado: 'Autorizado',
    autorizar: 'Autorizar',
    autorizarNovosDispositivos: 'Autorizar novos dispositivos',
    aviso: 'Aviso',
    bairro: 'Bairro',
    baixarPdf: 'Baixar PDF',
    banco: 'Banco',
    bid: 'Bid',
    bloquear: 'Bloquear',
    brasil: 'Brasil',
    busca: 'Busca',
    buscar: 'Buscar',
    cadastrar: 'Cadastrar',
    cadastrarNovaPeca: 'Cadastrar nova peça',
    calculadora: 'Calculadora',
    camposComAsteriscoObrigatorios: 'Campos com * são obrigatórios',
    cancelar: 'Cancelar',
    cancelarPedido: 'Cancelar pedido',
    catalisadoresPecas: 'Catalisadores - Peças',
    catalisadoresPo: 'Catalisadores - Pó',
    categoria: 'Categoria',
    categoriaUsuario: ' Categoria usuário',
    celular: 'Celular',
    cep: 'CEP',
    certezaRemoverGrupo: 'Tem certeza que dejsea remover este grupo?',
    cidade: 'Cidade',
    classe: 'Classe',
    cnpj: 'CNPJ',
    codigo: 'Código',
    codigoPostal: 'Código postal',
    codigos: 'Códigos',
    codigoSap: 'Código SAP',
    coluna: 'Coluna',
    comercial: 'Comercial',
    complemento: 'Complemento',
    compradores: 'Compradores',
    configuracoes: 'Configurações',
    configuracoesTabela: 'Configurações da tabela',
    confirmacaoSenha: 'Confirmação da senha',
    confirmar: 'Confirmar',
    confirmarRecebimento: 'Confirmar recebimento',
    conta: 'Conta',
    controle: 'Controle',
    controleAcesso: 'Controle de acesso',
    conviteNaoEnviado: 'Convite não enviado',
    cotacao: 'Cotação',
    cotacaoAtual: 'Cotação atual',
    cotacoes: 'Cotações',
    cpf: 'CPF',
    criacao: 'Criação',
    criadoEm: 'Criado em',
    criadoPor: 'Criado por',
    dadosBancarios: 'Dados bancários',
    dadosGerais: 'Dados gerais',
    dadosPessoais: 'Dados pessoais',
    data: 'Data',
    dataAnalise: 'Data da análise',
    dataUltimaAnalise: 'Data da última análise',
    de: 'De',
    deduction: 'Deduction',
    desconhecida: 'Desconhecida',
    desconhecido: 'Desconhecido',
    descricao: 'Descrição',
    descricaoCatalogo: 'Descrição catálogo',
    desfazer: 'Desfazer',
    desfazerAprovacao: 'Desfazer aprovação',
    desfazerReprovacao: 'Desfazer reprovação',
    detalhe: 'Detalhe',
    detalhes: 'Detalhes',
    dispositivo: 'Dispositivo',
    dispositivos: 'Dispositivos',
    documento: 'Documento',
    dolar: 'Dólar',
    email: 'Email',
    emailsSecundarios: 'Emails secundários',
    emConstrucao: 'Em construção',
    endereco: 'Endereço',
    enderecoNaoCadastrado: 'Endereço não cadastrado',
    entrar: 'Entrar',
    enviar: 'Enviar',
    envio: 'Envio',
    espanhol: 'Espanhol',
    estadoProvinciaRegiao: 'Estado/Província/Região',
    exibindo: 'Exibindo',
    existente: 'Existente',
    familia: 'Família',
    familias: 'Famílias',
    fechar: 'Fechar',
    filtrar: 'Filtrar',
    fonte: 'Fonte',
    fornecedor: 'Fornecedor',
    foto: 'Foto',
    fotos: 'Fotos',
    funcionalidade: 'Funcionalidade',
    funcionalidades: 'Funcionalidades',
    funcionario: 'Funcionário',
    funcionarios: 'Funcionários',
    g: 'g',
    grama: 'Grama',
    grupo: 'Grupo',
    grupoRemovidoComSucesso: 'Grupo removido com sucesso',
    grupos: 'Grupos',
    historico: 'Histórico',
    historicoAlteracoes: 'Histórico de alterações',
    id: 'ID',
    identificacao: 'Identificação',
    importar: 'Importar',
    importarPlanilha: 'Importar planilha',
    impurezas: 'Impurezas',
    info: 'Info',
    informacao: 'Informação',
    informacoes: 'Informações',
    ingles: 'Inglês',
    internacional: 'Internacional',
    item: 'Item',
    justificativa: 'Justificativa',
    justificativaCancelamento: 'Justificativa de cancelamento',
    kg: 'kg',
    linhaEndereco: 'Linha de endereço',
    lista: 'Lista',
    listagem: 'Listagem',
    logoutRealizadoComSucesso: 'Logout realizado com sucesso',
    logradouro: 'Logradouro',
    loja: 'Loja',
    lojas: 'Lojas',
    margem: 'Margem',
    margemComposta: 'Margem composta',
    margemMedia: 'Margem média',
    margemMinima: 'Margem mínima',
    margemPadrao: 'Margem padrão',
    margemPeca: 'Margem peça',
    margemPecas: 'Margem peças',
    margemPo: 'Margem pó',
    margemResponsavel: 'Margem responsável',
    margemUsuario: 'Margem usuário',
    margens: 'Margens',
    material: 'Material',
    metais: 'Metais',
    metal: 'Metal',
    modelo: 'Modelo',
    moedaSistema: process.env.VUE_APP_MOEDA_SISTEMA || 'BRL',
    moedaSistemaPorKg: `${process.env.VUE_APP_MOEDA_SISTEMA || 'BRL'}/kg`,
    montadora: 'Montadora',
    mp: 'MP',
    mu: 'MU',
    municipio: 'Município',
    nao: 'Não',
    navegador: 'Navegador',
    nenhumaMargemCadastrada: 'Nenhuma margem cadastrada',
    nenhumCompradorVinculado: 'Nenhum comprador vinculado',
    nome: 'Nome',
    nomeSistema: process.env.VUE_APP_NOME_SISTEMA || 'Portal Administrativo',
    nova: 'Nova',
    novasAnalises: 'Novas análises',
    novo: 'Novo',
    numero: 'Número',
    observacoes: 'Observações',
    observacoesParceiro: 'Observações do parceiro',
    observacoesParceiroNegocios: 'Observações do parceiro de negócios',
    onca: 'Onça',
    ordenacaoCrescente: 'Ordenação crescente',
    ordenacaoDecrescente: 'Ordenação decrescente',
    outros: 'Outros',
    oz: 'Oz',
    painelDeControle: 'Painel de controle',
    pais: 'País',
    paladio: 'Paládio',
    paraEnvioAtualizacoesPedido: 'Para envio de atualizações dos pedidos',
    parceiro: 'Parceiro',
    parceiroNegocios: 'Parceiro de negócios',
    parceiros: 'Parceiros',
    parceirosNegocios: 'Parceiros de negócios',
    pd: 'Pd',
    peca: 'Peça',
    pecaCatalisador: 'Peça de catalisador',
    pecaDanificada: 'Peça danificada',
    pecaFaltante: 'Peça faltante',
    pecaIncorreta: 'Peça incorreta',
    pecas: 'Peças',
    pecasCatalisadores: 'Peças de catalisadores',
    pedido: 'Pedido',
    pedidos: 'Pedidos',
    pendentes: 'Pendentes',
    permissao: 'Permissão',
    permissoes: 'Permissões',
    peso: 'Peso',
    pesoBruto: 'Peso bruto',
    pesoDaCeramica: 'Peso da cerâmica',
    pesoLiquido: 'Peso líquido',
    pesoSeco: 'Peso seco',
    pesoUn: 'Peso un',
    placa: 'Placa',
    placaEletronica: 'Placa Eletrônica',
    placas: 'Placas',
    placasEletronicas: 'Placas Eletrônicas',
    plataforma: 'Plataforma',
    platina: 'Platina',
    poCatalisadores: 'Pó catalisadores',
    portugues: 'Português',
    ppm: 'ppm',
    precoMedio: 'Preço médio',
    preposto: 'Preposto',
    prepostos: 'Prepostos',
    previaFoto: 'Prévia foto',
    primeiroAcessoEm: 'Primeiro acesso em',
    principal: 'Principal',
    produto: 'Produto',
    produtos: 'Produtos',
    prosseguir: 'Prosseguir',
    pt: 'Pt',
    qtde: 'Qtde',
    qtdeItens: 'Qtde. itens',
    quantidade: 'Quantidade',
    recebido: 'Recebido',
    recebimento: 'Recebimento',
    ref: 'Ref',
    referencia: 'Referência',
    registro: 'Registro',
    remover: 'Remover',
    removerGrupo: 'Remover grupo',
    removerOrdenacao: 'Remover ordenação',
    reprovada: 'Reprovada',
    reprovadas: 'Reprovadas',
    responsavel: 'Responsável',
    responsavelAdm: 'Responsável administrativo',
    responsavelComercial: 'Responsável comercial',
    rh: 'Rh',
    rodio: 'Ródio',
    sair: 'Sair',
    salvar: 'Salvar',
    selecionar: 'Selecionar',
    selecione: 'Selecione',
    selecionePais: 'Selecione o país',
    semEndereco: 'Sem endereço',
    semFoto: 'Sem foto',
    senha: 'Senha',
    senhaCadastrada: 'Senha cadastrada',
    separarParaAnalise: 'Separar para análise',
    sim: 'Sim',
    sobrenome: 'Sobrenome',
    status: 'Status',
    telefone: 'Telefone',
    telefoneFixo: 'Telefone fixo',
    teor: 'Teor',
    teores: 'Teores',
    teorPd: 'Teor Pd',
    teorPt: 'Teor Pt',
    teorRh: 'Teor Rh',
    total: 'Total',
    uf: 'UF',
    ultimaAtualizacaoEm: 'Última atualização em',
    ultimaAtualizacaoPor: 'Última atualização por',
    ultimaModificacaoEm: 'Última modificação em',
    ultimaModificacaoPor: 'Última modificação por',
    ultimoAcessoEm: 'Ultimo acesso em',
    umidade: 'Umidade',
    unidade: 'Unidade',
    usd: 'USD',
    usuario: 'Usuário',
    usuarioAlteradoComSucesso: 'Usuário alterado com sucesso',
    usuarioCriadoComSucesso: 'Usuário criado com sucesso',
    usuarios: 'Usuários',
    valor: 'Valor',
    valores: 'Valores',
    valorKg: 'Valor por kg',
    valorLiquido: 'Valor líquido',
    valorMedio: 'Valor médio',
    valorTotal: 'Valor total',
    valorUn: 'Valor un',
    verAnalises: 'Ver análises',
    verificadas: 'Verificadas',
    verificarPecas: 'Verificar peças',
    verPecas: 'Ver peças',
    vinculo: 'Vínculo',
    visivel: 'Visível',
    visualizacao: 'Visualização',
    visualizar: 'Visualizar',
    voltar: 'Voltar',
    vt: 'VT',
    vtMpMu: 'VT x MP x MU',
  },
  moeda: {
    BRL: 'Real brasileiro',
    CLP: 'Peso chileno',
    COP: 'Peso colombiano',
    JPY: 'Iene japonês',
  },
};

export default messages;
