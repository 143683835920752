


























































import Vue from 'vue';
import { debounce } from 'lodash';

import requester from '@/requester';

import { Rules as CaslRules } from '../../../../back/src/casl/casl.constants';
import { FamiliaScopesEnum } from '@/utils/constants/back.constants';

import FamiliasFormCriarAlterar from './FamiliasFormCriarAlterar.vue';
import FamiliasInfo from './FamiliasInfo.vue';

export default Vue.extend({
  name: 'FamiliasDialogInfo',
  components: {
    FamiliasFormCriarAlterar,
    FamiliasInfo,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    familiaId: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    loading: {
      familia: false,
    },
    alterar: false,
    familia: null,
  }),
  computed: {
    permissaoAtualizar() {
      const rule = CaslRules.Familia.atualizar;
      return this.$ability.can(rule?.action, rule?.subject);
    },
    permissaoRemover() {
      const rule = CaslRules.Familia.remover;
      return this.$ability.can(rule?.action, rule?.subject);
    },
  },
  watch: {
    familiaId() {
      this.buscaFamilia();
    },
  },
  mounted() {
    this.buscaFamilia();
  },
  methods: {
    fechar() {
      this.$refs?.familiasFormCriarAlterar?.cancelar() ||
        this.$emit('input', false);
    },
    familiaCriada(familia) {
      this.$emit('input', false);
      this.$emit('familia-criada', familia);
    },
    familiaAlterada(familia) {
      this.alterar = false;
      this.buscaFamilia();
      this.$emit('familia-alterada', familia);
    },
    async buscaFamilia() {
      if (!this.familiaId) {
        return;
      }
      this.loading.familia = true;
      try {
        const buscarFamiliaQuery = {
          scope: FamiliaScopesEnum.ComUsuarioCreatedUpdated,
        };
        this.familia = await requester.familias.busca(
          this.familiaId,
          buscarFamiliaQuery,
        );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.familia = false;
      }
    },
    removerDebounce: debounce(async function () {
      await this.remover();
    }, 200),
    async remover() {
      const confirm = await this.$root.$confirm(
        this.$t('FamiliasDialogInfo.removerFamilia'),
        this.$t('FamiliasDialogInfo.certezaRemoverFamilia'),
      );
      if (!confirm) return;
      this.loading.remover = true;
      try {
        await requester.familias.remove(this.familia.id);
        this.$root.$emit(
          'toastSuccess',
          this.$t('FamiliasDialogInfo.familiaRemovidaComSucesso'),
        );
        this.$emit('familia-removida', this.familia.id);
        this.fechar();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.remover = false;
      }
    },
  },
});
