




























































import Vue from 'vue';
import { debounce } from 'lodash';

import requester from '@/requester';

import { Rules as CaslRules } from '../../../../back/src/casl/casl.constants';
import { CatalisadorPoScopesEnum } from '@/utils/constants/back.constants';

import CatalisadoresPoFormCriarAlterar from './CatalisadoresPoFormCriarAlterar.vue';
import CatalisadoresPoInfo from './CatalisadoresPoInfo.vue';

export default Vue.extend({
  name: 'CatalisadoresPoDialogInfo',
  components: {
    CatalisadoresPoFormCriarAlterar,
    CatalisadoresPoInfo,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    catalisadorPoId: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    loading: {
      catalisadorPo: false,
    },
    alterar: false,
    catalisadorPo: null,
  }),
  computed: {
    permissaoAtualizar() {
      const rule = CaslRules.CatalisadorPo.atualizar;
      return this.$ability.can(rule?.action, rule?.subject);
    },
    permissaoRemover() {
      const rule = CaslRules.CatalisadorPo.remover;
      return this.$ability.can(rule?.action, rule?.subject);
    },
  },
  watch: {
    catalisadorPoId() {
      this.buscaCatalisadorPo();
    },
  },
  mounted() {
    this.buscaCatalisadorPo();
  },
  methods: {
    fechar() {
      this.$refs?.catalisadoresPoFormCriarAlterar?.cancelar() ||
        this.$emit('input', false);
    },
    catalisadorPoCriado(catalisadorPo) {
      this.$emit('input', false);
      this.$emit('catalisador-po-criado', catalisadorPo);
    },
    catalisadorPoAlterado(catalisadorPo) {
      this.alterar = false;
      this.buscaCatalisadorPo();
      this.$emit('catalisador-po-alterado', catalisadorPo);
    },
    async buscaCatalisadorPo() {
      if (!this.catalisadorPoId) {
        return;
      }
      this.loading.catalisadorPo = true;
      try {
        const buscarCatalisadorPoQuery = {
          scope: CatalisadorPoScopesEnum.ComFamiliaUsuarioCreatedUpdated,
        };
        this.catalisadorPo = await requester.catalisadoresPo.busca(
          this.catalisadorPoId,
          buscarCatalisadorPoQuery,
        );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.catalisadorPo = false;
      }
    },
    removerDebounce: debounce(async function () {
      await this.remover();
    }, 200),
    async remover() {
      const confirm = await this.$root.$confirm(
        this.$t('CatalisadoresPoDialogInfo.removerCatalisadorPo'),
        this.$t('CatalisadoresPoDialogInfo.certezaRemoverCatalisadorPo'),
      );
      if (!confirm) return;
      this.loading.remover = true;
      try {
        await requester.catalisadoresPo.remove(this.catalisadorPo.id);
        this.$root.$emit(
          'toastSuccess',
          this.$t('CatalisadoresPoDialogInfo.catalisadorPoRemovidoComSucesso'),
        );
        this.$emit('catalisador-po-removido', this.catalisadorPo.id);
        this.fechar();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.remover = false;
      }
    },
  },
});
