import { RouteConfig } from 'vue-router';

import PlacasIndex from '@/pages/placas/PlacasIndex.vue';
import { Rules as CaslRules } from '../../../back/src/casl/casl.constants';

const routes: RouteConfig[] = [
  {
    path: '/placas',
    name: 'PlacasIndex',
    component: PlacasIndex,
    meta: {
      icon: 'mdi-chip',
      rules: [CaslRules.Placa.listar, CaslRules.Familia.listar],
    },
  },
];

export default routes;
