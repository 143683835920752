































































































































import Vue from 'vue';

import { UsuarioDispositivo } from '@models/usuario-dispositivo.model';

export default Vue.extend({
  name: 'UsuariosTabela',
  props: {
    usuarios: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showCategoria: {
      type: Boolean,
      default: false,
    },
    showGrupo: {
      type: Boolean,
      default: false,
    },
    showResponsavel: {
      type: Boolean,
      default: false,
    },
    showDispositivos: {
      type: Boolean,
      default: false,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    headers() {
      return [
        {
          value: 'nomeCompleto',
          text: this.$t('comum.nome'),
          class: 'text-no-wrap',
          filterable: true,
          sortable: true,
        },
        {
          value: 'email',
          text: this.$t('comum.email'),
          class: 'text-no-wrap',
          filterable: true,
          sortable: true,
        },
        {
          value: 'celular',
          text: this.$t('comum.celular'),
          class: 'text-no-wrap',
          filterable: true,
          sortable: true,
        },
        ...(this.showCategoria
          ? [
              {
                value: 'categoria',
                text: this.$t('comum.categoria'),
                class: 'text-no-wrap',
                filterable: true,
                sortable: true,
              },
            ]
          : []),
        ...(this.showGrupo
          ? [
              {
                value: 'grupo.nome',
                text: this.$t('comum.grupo'),
                class: 'text-no-wrap',
                filterable: false,
                sortable: true,
              },
            ]
          : []),
        ...(this.showResponsavel
          ? [
              {
                value: 'responsavel.nomeCompleto',
                text: this.$t('comum.responsavelComercial'),
                class: 'text-no-wrap',
                filterable: true,
                sortable: true,
              },
            ]
          : []),
        ...(this.showDispositivos
          ? [
              {
                value: 'dispositivos',
                text: this.$t('comum.dispositivos'),
                class: 'text-no-wrap',
                filterable: true,
                sortable: true,
              },
            ]
          : []),
        {
          value: 'ativo',
          text: this.$t('comum.ativo'),
          class: 'text-no-wrap',
          filterable: false,
          sortable: false,
        },
        {
          value: 'detalhes',
          text: this.$t('comum.detalhes'),
          class: 'text-no-wrap',
          filterable: false,
          sortable: false,
          align: 'center',
          width: 1,
        },
      ];
    },
  },
  methods: {
    countDispositivos(dispositivos: UsuarioDispositivo[], autorizado: boolean) {
      return dispositivos.filter(
        (el) => el.dispositivoAutorizado === autorizado,
      ).length;
    },
  },
});
