














































import Vue from 'vue';

import { rulesRequired } from '@/utils/input-rules';
import { inputPriceToNumber, maskCurrency } from '@/utils/intl-functions';

import InputData from '@/components/inputs/InputData.vue';

export default Vue.extend({
  name: 'CotacoesFormDolar',
  components: {
    InputData,
  },
  props: {
    cotacaoAtual: {
      type: Object || null,
      default: null,
    },
  },
  data: () => ({
    loading: {
      submitForm: false,
    },
    form: {
      dolar: null,
      dataDolar: new Date().toISOString().substr(0, 10),
      fonteDolar: null,
    },
    rules: {
      required: rulesRequired,
    },
  }),
  computed: {
    currency() {
      return process.env.VUE_APP_PAIS_CURRENCY || 'BRL';
    },
    fractionDigits() {
      return 4;
    },
  },
  mounted() {
    this.preencheForm();
  },
  methods: {
    maskCurrency,
    preencheForm() {
      if (!this.cotacaoAtual) return;
      this.form.fonteDolar = this.cotacaoAtual?.fonteDolar || null;
    },
    focusInput(inputRef: string) {
      (this.$refs[inputRef] as HTMLElement).focus();
    },
    getForm() {
      return {
        dolar: inputPriceToNumber(this.form.dolar, this.fractionDigits),
        dataDolar: String(this.form.dataDolar),
        fonteDolar: String(this.form.fonteDolar),
      };
    },
  },
});
