






























import Vue from 'vue';

import CotacoesFormCriar from './CotacoesFormCriar.vue';

export default Vue.extend({
  name: 'CotacoesDialogCadastro',
  components: {
    CotacoesFormCriar,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    cotacaoAtual: {
      type: Object || null,
      default: null,
    },
  },
  methods: {
    fechar() {
      this.$refs?.cotacoesFormCriar?.cancelar() || this.$emit('input', false);
    },
    cotacaoCriada(cotacao) {
      this.$emit('cotacao-criada', cotacao);
      this.$emit('input', false);
    },
  },
});
