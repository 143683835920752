






























import Vue from 'vue';

import CatalisadoresPecasFormAnalise from './CatalisadoresPecasFormAnalise.vue';

export default Vue.extend({
  name: 'CatalisadoresPecasDialogAnalise',
  components: {
    CatalisadoresPecasFormAnalise,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    montadoras: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({}),
});
