



































































import Vue from 'vue';

import CatalisadoresPecasImportarStep1 from '@/components/catalisadores-pecas/CatalisadoresPecasImportarStep1.vue';
import CatalisadoresPecasImportarStep2 from '@/components/catalisadores-pecas/CatalisadoresPecasImportarStep2.vue';
import CatalisadoresPecasImportarStep3 from '@/components/catalisadores-pecas/CatalisadoresPecasImportarStep3.vue';
import CatalisadoresPecasImportarStep4 from '@/components/catalisadores-pecas/CatalisadoresPecasImportarStep4.vue';
import CatalisadoresPecasImportarStep5 from '@/components/catalisadores-pecas/CatalisadoresPecasImportarStep5.vue';
import CatalisadoresPecasImportarStep6 from '@/components/catalisadores-pecas/CatalisadoresPecasImportarStep6.vue';

export default Vue.extend({
  name: 'CatalisadoresPecasImportar',
  components: {
    CatalisadoresPecasImportarStep1,
    CatalisadoresPecasImportarStep2,
    CatalisadoresPecasImportarStep3,
    CatalisadoresPecasImportarStep4,
    CatalisadoresPecasImportarStep5,
    CatalisadoresPecasImportarStep6,
  },
  data: () => ({
    step: 1,
  }),
  watch: {
    step() {
      window.scrollTo(0, 0);
    },
  },
  methods: {
    nextStep1(event: { file; workbook }) {
      this.$refs.step2?.inicio(event.file, event.workbook);
      this.step = 2;
    },
    previousStep2() {
      this.step = 1;
    },
    nextStep2(event: { headers; items }) {
      this.$refs.step3?.inicio(event.headers, event.items);
      this.step = 3;
    },
    previousStep3() {
      this.step = 2;
    },
    nextStep3(event: { headers; items }) {
      this.$refs.step4?.inicio(event.headers, event.items);
      this.step = 4;
    },
    previousStep4() {
      this.step = 3;
    },
    nextStep4(event: { headers; items }) {
      this.$refs.step5?.inicio(event.headers, event.items);
      this.step = 5;
    },
    previousStep5() {
      this.step = 4;
    },
    nextStep5(event: { headers; items }) {
      this.$refs.step6?.inicio(event.headers, event.items);
      this.step = 6;
    },
    previousStep6() {
      this.step = 1;
    },
  },
});
