import { StatusPedidoEnum } from '../../../back/src/pedidos/pedidos.constants';

export function getPedidoStatusHtml(status: StatusPedidoEnum): string {
  switch (status) {
    case StatusPedidoEnum.AGUARDANDO_APROVACAO:
      return '<span class="info--text">Aguardando aprovação</span>';
    case StatusPedidoEnum.APROVADO:
      return '<span class="success--text">Aprovado</span>';
    case StatusPedidoEnum.CANCELADO:
      return '<span class="error--text">Cancelado</span>';
    case StatusPedidoEnum.APROVADO_COM_ALTERACOES:
      return '<span class="warning--text">Aprovado com alterações</span>';
    default:
      return '<span class="text--disabled">N/A</span>';
  }
}
