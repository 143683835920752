import { RouteConfig } from 'vue-router';

import UsuariosCadastroFuncionario from '@/pages/usuarios/UsuariosCadastroFuncionario.vue';
import UsuariosCadastroParceiro from '@/pages/usuarios/UsuariosCadastroParceiro.vue';
import UsuariosEdicao from '@/pages/usuarios/UsuariosEdicao.vue';
import UsuariosIndex from '@/pages/usuarios/UsuariosIndex.vue';
import UsuariosVer from '@/pages/usuarios/UsuariosVer.vue';
import { Rules as CaslRules } from '../../../back/src/casl/casl.constants';

const routes: RouteConfig[] = [
  {
    path: '/usuarios/cadastro/funcionario',
    name: 'UsuariosCadastroFuncionario',
    component: UsuariosCadastroFuncionario,
    meta: {
      icon: 'mdi-account-plus',
      rules: [CaslRules.Usuario.criar],
    },
  },
  {
    path: '/usuarios/cadastro/parceiro',
    name: 'UsuariosCadastroParceiro',
    component: UsuariosCadastroParceiro,
    meta: {
      icon: 'mdi-account-plus',
      rules: [CaslRules.Usuario.criar],
    },
  },
  {
    path: '/usuarios/alterar/:usuarioId',
    name: 'UsuariosEdicao',
    component: UsuariosEdicao,
    meta: {
      icon: 'mdi-account-edit',
      rules: [CaslRules.Usuario.atualizar],
    },
  },
  {
    path: '/usuarios/:usuarioId',
    name: 'UsuariosVer',
    component: UsuariosVer,
    meta: {
      icon: 'mdi-account',
      rules: [CaslRules.Usuario.buscar],
    },
  },
  {
    path: '/usuarios',
    name: 'UsuariosIndex',
    component: UsuariosIndex,
    meta: {
      icon: 'mdi-account-multiple',
      rules: [CaslRules.Usuario.listar, CaslRules.Grupo.listar],
    },
  },
];

export default routes;
