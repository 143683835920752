



































































































import Vue from 'vue';
import { debounce } from 'lodash';

import requester from '@/requester';

import { Rules as CaslRules } from '../../../../back/src/casl/casl.constants';
import { ViewCatalisadorPecaAtualScopesEnum } from '../../../../back/src/catalisadores-pecas/catalisadores-pecas.constants';

import CatalisadoresPecasFormCriarEditar from './CatalisadoresPecasFormCriarEditar.vue';
import CatalisadoresPecasHistorico from './CatalisadoresPecasHistorico.vue';
import CatalisadoresPecasInfo from './CatalisadoresPecasInfo.vue';

export default Vue.extend({
  name: 'CatalisadoresPecasDialogInfo',
  components: {
    CatalisadoresPecasInfo,
    CatalisadoresPecasFormCriarEditar,
    CatalisadoresPecasHistorico,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    catalisadorPecaId: {
      type: Number || null,
      default: null,
    },
    montadoras: {
      type: Array,
      default: () => [],
    },
    familias: {
      type: Array,
      default: () => [],
    },
    margemUsuario: {
      type: Number,
      default: 30,
    },
  },
  data: () => ({
    loading: {
      catalisadorPeca: true,
      fotosArquivos: true,
      remover: false,
    },
    catalisadorPeca: null,
    fotosArquivos: [],
    alterar: false,
    historico: false,
  }),
  computed: {
    permissaoAtualizar() {
      const rule = CaslRules.CatalisadorPeca.atualizar;
      return this.$ability.can(rule?.action, rule?.subject);
    },
    permissaoRemover() {
      const rule = CaslRules.CatalisadorPeca.remover;
      return this.$ability.can(rule?.action, rule?.subject);
    },
  },
  mounted() {
    this.buscaCatalisadorPeca();
    this.buscaFotosArquivos();
  },
  watch: {
    catalisadorPecaId() {
      this.recarregaPecaInfo();
    },
  },
  methods: {
    // Esse método também é chamado pelo componente pai.
    recarregaPecaInfo() {
      this.buscaCatalisadorPeca();
      this.buscaFotosArquivos();
    },
    onInput(event: boolean) {
      if (!event) {
        this.historico = false;
        this.alterar = false;
      }
      this.$emit('input', event);
    },
    catalisadorPecaAlterada() {
      this.buscaCatalisadorPeca();
      this.buscaFotosArquivos();
      this.alterar = false;
      this.$emit('catalisador-peca-alterada');
    },
    async buscaCatalisadorPeca() {
      this.loading.catalisadorPeca = true;
      try {
        if (this.catalisadorPecaId) {
          this.catalisadorPeca = await requester.catalisadoresPecas.busca(
            this.catalisadorPecaId,
            {
              scope: ViewCatalisadorPecaAtualScopesEnum.ComAnalisesUsuarios,
            },
          );
        }
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.catalisadorPeca = false;
      }
    },
    async buscaFotosArquivos() {
      this.loading.fotosArquivos = true;
      try {
        if (this.catalisadorPecaId) {
          this.fotosArquivos =
            await requester.catalisadoresPecas.buscaFotosArquivos(
              this.catalisadorPecaId,
            );
        } else {
          this.fotosArquivos = [];
        }
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.fotosArquivos = false;
      }
    },
    removerDebounce: debounce(async function () {
      await this.remover();
    }, 200),
    async remover() {
      const confirm = await this.$root.$confirm(
        this.$t('CatalisadoresPecasDialogInfo.removerCatalisadorPeca'),
        this.$t('CatalisadoresPecasDialogInfo.certezaRemoverCatalisadorPeca'),
      );
      if (!confirm) return;
      this.loading.remover = true;
      try {
        await requester.catalisadoresPecas.remove(this.catalisadorPecaId);
        this.$root.$emit(
          'toastSuccess',
          this.$t(
            'CatalisadoresPecasDialogInfo.catalisadorPecaRemovidoComSucesso',
          ),
        );
        this.$emit('catalisador-peca-removida');
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.remover = false;
      }
    },
  },
});
