





















































































import Vue from 'vue';

export default Vue.extend({
  name: 'PlacasInfo',
  props: {
    placa: {
      type: null || Object,
      default: null,
    },
  },
});
