import { KatalisTranslationEnums } from '../translations.interface';

const messages: KatalisTranslationEnums = {
  AvisoCatalisadorPecaEnum: {
    CHAMAR_COMERCIAL: 'Contact Commercial',
    SEPARAR_PARA_ANALISE: 'Separate for Analysis',
  },
  CategoriaFamiliaEnum: {
    CATALISADORES_PECAS: 'Catalysts - Parts',
    CATALISADORES_PO: 'Catalysts - Powder',
    PLACAS: 'Electronic Boards',
  },
  CategoriaPedidoItemEnum: {
    CATALISADORES_PECAS: 'Catalysts - Parts',
    CATALISADORES_PO: 'Catalysts - Powder',
  },
  CategoriaUsuarioEnum: {
    FUNCIONARIO: 'Employee',
    PARCEIRO: 'Business Partner',
    PREPOSTO: 'Representative',
  },
  StatusPedidoEnum: {
    AGUARDANDO_APROVACAO: 'Waiting for Approval',
    APROVADO: 'Approved',
    CANCELADO: 'Canceled',
    APROVADO_COM_ALTERACOES: 'Approved with Changes',
  },
  StatusPedidoItemPecaRecebimentoEnum: {
    APROVADA: 'Approved Piece | Approved Pieces',
    REPROVADA_DANIFICADA: 'Damaged Piece | Damaged Pieces',
    REPROVADA_FALTANTE: 'Missing Piece | Missing Pieces',
    REPROVADA_INCORRETA: 'Incorrect Piece | Incorrect Pieces',
    REPROVADA_OUTROS: 'Other Justification',
  },
  VinculoLojaUsuarioEnum: {
    COMPRADOR: 'Buyer',
    RESPONSAVEL_ADMINISTRATIVO: 'Administrative Responsible',
    RESPONSAVEL_COMERCIAL: 'Commercial Responsible',
  },
};

export default messages;
