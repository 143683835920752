



























































































































































































































































































import Vue from 'vue';
import _, { debounce, deburr, maxBy, orderBy } from 'lodash';

import requester from '@/requester';
import { relativeTime } from '@/utils/intl-functions';

import { Pedido } from '../../../../back/src/models/pedido.model';
import {
  PedidoScopesEnum,
  StatusPedidoEnum,
  StatusPedidoItemPecaRecebimentoEnum,
} from '../../../../back/src/pedidos/pedidos.constants';
import { ReceberPedidoDto } from '../../../../back/src/pedidos/dto/receber-pedido.dto';
import { PedidoLog } from '@models/pedido-log.model';
import { PedidoItemPecaRecebimento } from '@models/pedido-item-peca-recebimento.model';

import PedidosItemRecebimento from '@/components/pedidos/PedidosItemRecebimento.vue';
import { PedidoItem } from '@models/pedido-item.model';

export default Vue.extend({
  name: 'PedidosRecebimento',
  components: {
    PedidosItemRecebimento,
  },
  data: () => ({
    loading: {
      pedido: true,
      receber: false,
    },
    pedido: null as null | Pedido,
    form: {
      observacoes: null,
    },
    search: null,
  }),
  computed: {
    pedidoId() {
      return this.$route?.params?.pedidoId || 0;
    },
    logAtual() {
      return maxBy(this.pedido?.log || [], (el: PedidoLog) => el.createdAt);
    },
    statusAtual() {
      return this.logAtual?.status;
    },
    recebimentoMaisRecente() {
      return maxBy(
        (this.pedido?.pedidoItems || []).flatMap(
          (el) => el?.pedidoItemPeca?.recebimentos || [],
        ),
        (el: PedidoItemPecaRecebimento) => el.updatedAt,
      );
    },
    showAvisoConfirmarRecebimento() {
      return (
        this.statusAtual !== StatusPedidoEnum.AGUARDANDO_APROVACAO &&
        this.recebimentoMaisRecente?.updatedAt > this.logAtual?.createdAt
      );
    },
    totalCodigos() {
      return (this.pedido?.pedidoItems || []).length;
    },
    totalPecasAprovadas() {
      return (this.pedido?.pedidoItems || []).reduce(
        (acc, el) =>
          acc +
          (el.pedidoItemPeca.recebimentos || []).reduce(
            (acc2, el2) =>
              acc2 +
              (el2.status === StatusPedidoItemPecaRecebimentoEnum.APROVADA
                ? 1
                : 0),
            0,
          ),
        0,
      );
    },
    totalPecasAprovadasGroupedByFamilia() {
      const pecas = {};
      for (const group of Object.keys(this.pedidoItemsGroupedByFamilia)) {
        pecas[group] = (this.pedidoItemsGroupedByFamilia[group] || []).reduce(
          (acc, el) =>
            acc +
            (el.pedidoItemPeca.recebimentos || []).reduce(
              (acc2, el2) =>
                acc2 +
                (el2.status === StatusPedidoItemPecaRecebimentoEnum.APROVADA
                  ? 1
                  : 0),
              0,
            ),
          0,
        );
      }
      return pecas;
    },
    totalPecasReprovadas() {
      return (this.pedido?.pedidoItems || []).reduce(
        (acc, el) =>
          acc +
          (el.pedidoItemPeca.recebimentos || []).reduce(
            (acc2, el2) =>
              acc2 +
              (el2.status !== StatusPedidoItemPecaRecebimentoEnum.APROVADA
                ? 1
                : 0),
            0,
          ),
        0,
      );
    },
    totalPecasReprovadasGroupedByFamilia() {
      const pecas = {};
      for (const group of Object.keys(this.pedidoItemsGroupedByFamilia)) {
        pecas[group] = (this.pedidoItemsGroupedByFamilia[group] || []).reduce(
          (acc, el) =>
            acc +
            (el.pedidoItemPeca.recebimentos || []).reduce(
              (acc2, el2) =>
                acc2 +
                (el2.status !== StatusPedidoItemPecaRecebimentoEnum.APROVADA
                  ? 1
                  : 0),
              0,
            ),
          0,
        );
      }
      return pecas;
    },
    totalPecasAguardandoAprovacao() {
      return (
        this.totalPecas - this.totalPecasAprovadas - this.totalPecasReprovadas
      );
    },
    totalPecasAguardandoAprovacaoGroupedByFamilia() {
      const pecas = {};
      for (const group of Object.keys(this.pedidoItemsGroupedByFamilia)) {
        pecas[group] =
          this.totalPecasGroupedByFamilia[group] -
          this.totalPecasAprovadasGroupedByFamilia[group] -
          this.totalPecasReprovadasGroupedByFamilia[group];
      }
      return pecas;
    },
    totalPecas() {
      return (this.pedido?.pedidoItems || []).reduce(
        (acc, el) => acc + el.pedidoItemPeca.quantidadeInicial,
        0,
      );
    },
    totalPecasGroupedByFamilia() {
      const pecas = {};
      for (const group of Object.keys(this.pedidoItemsGroupedByFamilia)) {
        pecas[group] = (this.pedidoItemsGroupedByFamilia[group] || []).reduce(
          (acc, el) => acc + el.pedidoItemPeca.quantidadeInicial,
          0,
        );
      }
      return pecas;
    },
    cleanSearch() {
      return deburr((this.search || '').trim().toLowerCase());
    },
    pedidoItemsFiltered(): PedidoItem[] {
      if (!this.cleanSearch) return this.pedidoItemsOrdered;
      return this.pedidoItemsOrdered.filter((el) => {
        const identificacao = deburr(
          el?.pedidoItemPeca?.catalisadorPecaDados?.identificacaoAlfanumerico ||
            '',
        )
          .trim()
          .toLowerCase();
        return identificacao.includes(this.cleanSearch);
      });
    },
    pedidoItemsGroupedByFamilia() {
      return _.groupBy(
        this.pedidoItemsFiltered,
        (pedidoItem) =>
          pedidoItem?.pedidoItemPeca?.catalisadorPeca?.familia.codigo,
      );
    },
    pedidoItemsOrdered(): PedidoItem[] {
      return orderBy(
        this.pedido?.pedidoItems || [],
        (el) =>
          `${
            el.pedidoItemPeca.recebimentos.length ===
            el.pedidoItemPeca.quantidadeInicial
              ? '1'
              : '0'
          }_${
            el.pedidoItemPeca.catalisadorPecaDados.identificacaoAlfanumerico
          }`,
      );
    },
  },
  mounted() {
    this.buscaPedido();
  },
  methods: {
    relativeTime,
    familiaTitulo(codigoFamilia) {
      return `${codigoFamilia} - ${
        this.pedidoItemsGroupedByFamilia[codigoFamilia][0]?.pedidoItemPeca
          ?.catalisadorPeca?.familia?.descricao || ''
      }`;
    },
    indexSubheader(pedidoItems) {
      return pedidoItems.findIndex(
        (el) =>
          el.pedidoItemPeca.recebimentos.length ===
          el.pedidoItemPeca.quantidadeInicial,
      );
    },
    recebimentosAtualizados(pedidoItemId: number, pecas: any[]) {
      const index = (this.pedido?.pedidoItems || []).findIndex(
        (el) => el.id === pedidoItemId,
      );
      if (index < 0) return;
      this.$set(
        this.pedido.pedidoItems[index].pedidoItemPeca,
        'recebimentos',
        pecas.filter((el) => el.id > 0),
      );
    },
    async buscaPedido() {
      this.loading.pedido = true;
      try {
        this.pedido = await requester.pedidos.busca(this.pedidoId, {
          scope: PedidoScopesEnum.Recebimento,
        });
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.pedido = false;
      }
    },
    recebePedidoDebounce: debounce(function () {
      this.recebePedido();
    }, 200),
    async recebePedido() {
      this.loading.receber = true;
      const recebimentosRealizados = (this.pedido?.pedidoItems || []).every(
        (pedidoItem) =>
          pedidoItem?.pedidoItemPeca?.recebimentos?.length ===
          pedidoItem?.pedidoItemPeca?.quantidadeInicial,
      );
      if (!recebimentosRealizados) {
        return;
      }
      try {
        const dto: ReceberPedidoDto = {
          observacoes: this.form.observacoes?.trim() || null,
        };
        this.pedido = await requester.pedidos.recebe(this.pedidoId, dto);
        this.$root.$emit('toastSuccess', 'Pedido recebido com sucesso');
        this.$router.push({ name: 'PedidosRecebimentoIndex' });
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.receber = false;
      }
    },
  },
});
