import { RouteConfig } from 'vue-router';

import CotacoesIndex from '@/pages/cotacoes/CotacoesIndex.vue';
import { Rules as CaslRules } from '../../../back/src/casl/casl.constants';

const routes: RouteConfig[] = [
  {
    path: '/cotacoes',
    name: 'CotacoesIndex',
    component: CotacoesIndex,
    meta: {
      icon: 'mdi-currency-usd',
      rules: [CaslRules.Cotacao.listar],
    },
  },
];

export default routes;
