
































































































































































































































import requester from '@/requester';
import {
  CategoriaUsuarioEnum,
  UsuarioScopesEnum,
} from '@/utils/constants/back.constants';
import InputNumber from '@/components/inputs/InputNumber.vue';
import { VinculoLojaUsuarioEnum } from '../../../../back/src/lojas/lojas.constants';
import { CategoriaPedidoItemEnum } from '../../../../back/src/pedidos/pedidos.constants';
import { autocompleteFilterDeburr } from '@/utils/filter-functions';
import {
  rulesCnpj,
  rulesIntegerPositive,
  rulesRequired,
} from '@/utils/input-rules';
import { debounce, orderBy } from 'lodash';
import Vue from 'vue';

import EnderecosFormBrasil from '../enderecos/EnderecosFormBrasil.vue';
import InputTelefone from '../inputs/InputTelefone.vue';

export default Vue.extend({
  name: 'LojasForm',
  components: {
    EnderecosFormBrasil,
    InputTelefone,
    InputNumber,
  },
  props: {
    loja: {
      type: Object || null,
      default: null,
    },
  },
  data: () => ({
    loading: {
      categorias: true,
      usuarios: true,
      submitForm: false,
    },
    categorias: [],
    itemsCategorias: [],
    usuarios: [],
    form: {
      codigo: null,
      documento: null,
      celular: null,
      telefone: null,
      responsavelAdmId: null,
      responsavelComercialId: null,
    },
    rules: {
      required: rulesRequired,
      cnpj: [...rulesRequired, ...rulesCnpj],
      integerPositive: rulesIntegerPositive,
    },
  }),
  computed: {
    endereco() {
      return this.loja?.endereco || null;
    },
    itemsFuncionarios() {
      return orderBy(
        this.usuarios
          .filter((el) => el.categoria === CategoriaUsuarioEnum.FUNCIONARIO)
          .map((el) => ({
            value: el.id,
            text: `${el.nomeCompleto} ${el.email} ${el.grupo?.nome || ''}`,
            nomeCompleto: el.nomeCompleto,
            email: el.email,
            grupoNome: el.grupo?.nome || null,
          })),
        ['nomeCompleto'],
      );
    },
    headers() {
      return [
        {
          value: 'categoria',
          text: this.$t('comum.categoria'),
          sortable: false,
        },
        {
          value: 'margemMinima',
          text: this.$t('comum.margemMinima'),
          sortable: false,
        },
        {
          value: 'margem',
          text: this.$t('comum.margem'),
          sortable: false,
        },
        {
          value: 'ativar',
          text: '',
          sortable: false,
          width: 1,
        },
      ];
    },
  },
  async mounted() {
    await Promise.all([this.listaUsuarios(), this.listaCategorias()]);
    this.preencheForm();
  },
  methods: {
    autocompleteFilterDeburr,
    getResponsavel(vinculo: VinculoLojaUsuarioEnum) {
      return this.loja?.usuarios?.find(
        (el) => el?.JoinLojaUsuario?.vinculo === vinculo,
      );
    },
    preencheForm() {
      if (!this.loja) return;
      this.form.codigo = this.loja?.codigo || null;
      this.form.documento = this.loja?.documento || null;
      this.form.celular = this.loja?.celular || null;
      this.form.telefone = this.loja?.telefone || null;
      this.form.responsavelAdmId =
        this.getResponsavel(VinculoLojaUsuarioEnum.RESPONSAVEL_ADMINISTRATIVO)
          ?.id || null;
      this.form.responsavelComercialId =
        this.getResponsavel(VinculoLojaUsuarioEnum.RESPONSAVEL_COMERCIAL)?.id ||
        null;
      this.itemsCategorias.forEach((item, key) => {
        const lojaMargem = this.loja?.margens?.find(
          (el) => el.categoria === item.categoria,
        );
        const margem = Number(lojaMargem?.margem) * 100;
        const margemMinima = Number(lojaMargem?.margemMinima) * 100;
        if (Number.isFinite(margem)) {
          this.$set(this.itemsCategorias[key], 'margem', margem);
          this.$set(this.itemsCategorias[key], 'margemMinima', margemMinima);
          this.$set(this.itemsCategorias[key], 'temMargem', true);
        }
      });
    },
    async listaUsuarios() {
      this.loading.usuarios = true;
      try {
        const queryObject = {
          scope: UsuarioScopesEnum.ComGrupo,
        };
        this.usuarios = await requester.usuarios.lista(queryObject);
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.usuarios = false;
      }
    },
    async listaCategorias() {
      this.loading.categorias = true;
      try {
        this.categorias = Object.values(CategoriaPedidoItemEnum); // temporario
        this.itemsCategorias = this.categorias.map((el) => ({
          categoria: el,
          margem: null,
          temMargem: false,
        }));
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.categorias = false;
      }
    },
    submitFormDebounce: debounce(async function () {
      await this.submitForm();
    }, 200),
    async submitForm() {
      const validForm = this.$refs?.form?.validate();
      const validEnderecoForm = this.$refs?.enderecosFormBrasil?.validate();
      if (!validForm || !validEnderecoForm) {
        return;
      }
      const formDataEndereco = this.$refs?.enderecosFormBrasil?.getFormData();
      this.loading.submitForm = true;
      try {
        const formData = {
          codigo: this.form.codigo?.trim() || null,
          documento: this.form.documento?.trim() || null,
          celular: this.form.celular?.trim() || null,
          telefone: this.form.telefone?.trim() || null,
          responsavelAdmId: this.form.responsavelAdmId || null,
          responsavelComercialId: this.form.responsavelComercialId || null,
          endereco: formDataEndereco,
          margens: this.itemsCategorias
            .filter((el) => el.temMargem)
            .map((el) => ({
              categoria: el.categoria,
              margem: Number(el.margem) / 100,
              margemMinima: Number(el.margemMinima) / 100,
            })),
        };
        let loja;
        if (this.loja) {
          loja = await requester.lojas.atualiza(this.loja.id, formData);
          this.$root.$emit(
            'toastSuccess',
            this.$t('LojasForm.lojaAlteradaComSucesso'),
          );
        } else {
          loja = await requester.lojas.cria(formData);
          this.$root.$emit(
            'toastSuccess',
            this.$t('LojasForm.lojaCriadaComSucesso'),
          );
        }
        this.$router.push({ name: 'LojasVer', params: { lojaId: loja.id } });
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.submitForm = false;
      }
    },
  },
});
