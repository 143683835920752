






















import Vue from 'vue';

export default Vue.extend({
  name: 'CustomVColInfo',
  props: {
    classCol: {
      type: String || Array || Object,
      default: 'pt-1 pb-2',
    },
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    condition: {
      type: Boolean,
      default: true,
    },
    cols: {
      type: String || Number,
      default: '12',
    },
    sm: {
      type: String || Number,
      default: '',
    },
    md: {
      type: String || Number,
      default: '',
    },
    lg: {
      type: String || Number,
      default: '',
    },
    xl: {
      type: String || Number,
      default: '',
    },
  },
  computed: {
    slotDefaultEmptyText() {
      if (this.$slots?.default?.length === 1) {
        const element = this.$slots?.default[0];
        if (element?.text?.trim() === '') return true;
      }
      return false;
    },
    smComputed() {
      return this.sm || this.cols;
    },
    mdComputed() {
      return this.md || this.smComputed;
    },
    lgComputed() {
      return this.lg || this.mdComputed;
    },
    xlComputed() {
      return this.xl || this.lgComputed;
    },
  },
});
