




























import Vue from 'vue';

import GruposFormCriarAlterar from './GruposFormCriarAlterar.vue';

export default Vue.extend({
  name: 'GruposDialogCadastro',
  components: {
    GruposFormCriarAlterar,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    fechar() {
      this.$refs?.gruposFormCriarAlterar?.cancelar() ||
        this.$emit('input', false);
    },
    grupoCriado(grupo) {
      this.$emit('input', false);
      this.$emit('grupo-criado', grupo);
    },
  },
});
