import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    locale: 'pt-BR',
    usuario: null,
  },
  getters: {
    locale: (state) => state.locale || 'pt-BR',
    usuario: (state) => state.usuario,
  },
  mutations: {
    setLocale(state, locale) {
      state.locale = locale;
    },
    setUsuario(state, usuario) {
      state.usuario = usuario;
    },
  },
  actions: {
    atualizaLocale(context, locale) {
      context.commit('setLocale', locale);
    },
    login(context, usuario) {
      context.commit('setUsuario', usuario);
    },
    logout(context) {
      context.commit('setUsuario', null);
    },
  },
  modules: {},
});
