





























































import Vue from 'vue';

import requester from '@/requester';

import CatalisadoresPoDialogCadastro from './CatalisadoresPoDialogCadastro.vue';
import CatalisadoresPoDialogInfo from './CatalisadoresPoDialogInfo.vue';
import { Rules as CaslRules } from '../../../../back/src/casl/casl.constants';

export default Vue.extend({
  name: 'CatalisadoresPoTabela',
  components: {
    CatalisadoresPoDialogCadastro,
    CatalisadoresPoDialogInfo,
  },
  props: {
    familia: {
      type: null || Object,
      required: true,
    },
  },
  data: () => ({
    loading: {
      catalisadoresPo: true,
    },
    dialogCadastro: false,
    dialogInfo: false,
    search: null,
    catalisadorPoInfoId: null,
    catalisadoresPo: [],
  }),
  computed: {
    permissaoCriar() {
      const rule = CaslRules.CatalisadorPo.criar;
      return this.$ability.can(rule?.action, rule?.subject);
    },
    headers() {
      return [
        {
          value: 'classe',
          text: this.$t('comum.classe'),
          class: 'text-no-wrap',
          filterable: true,
          sortable: true,
        },
        {
          value: 'valorKg',
          text: `${this.$t('comum.valor')} (${this.$t(
            'comum.moedaSistema',
          )}/${this.$t('comum.kg')})`,
          class: 'text-no-wrap',
          filterable: false,
          sortable: true,
          align: 'right',
          width: 1,
        },
        {
          value: 'info',
          text: this.$t('comum.info'),
          class: 'text-no-wrap',
          filterable: false,
          sortable: false,
          align: 'center',
          width: 1,
        },
      ];
    },
  },
  mounted() {
    this.listaCatalisadoresPoFamilia();
  },
  methods: {
    infoCatalisadorPo(catalisadorPoId: number) {
      this.catalisadorPoInfoId = catalisadorPoId;
      this.dialogInfo = true;
    },
    catalisadorPoCriado(catalisadorPo) {
      this.catalisadoresPo.push(catalisadorPo);
    },
    catalisadorPoAlterado(catalisadorPo) {
      const index = this.catalisadoresPo.findIndex(
        (el) => el.id === catalisadorPo.id,
      );
      if (index >= 0) {
        this.$set(this.catalisadoresPo, index, catalisadorPo);
      }
    },
    catalisadorPoRemovido(catalisadorPoId: number) {
      const index = this.catalisadoresPo.findIndex(
        (el) => el.id === catalisadorPoId,
      );
      if (index >= 0) {
        this.$delete(this.catalisadoresPo, index);
      }
    },
    async listaCatalisadoresPoFamilia() {
      this.loading.catalisadoresPo = true;
      try {
        this.catalisadoresPo = await requester.familias.listaCatalisadoresPo(
          this.familia.id,
        );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.catalisadoresPo = false;
      }
    },
  },
});
