


















































































import Vue from 'vue';

export default Vue.extend({
  name: 'CatalisadoresPoInfo',
  props: {
    catalisadorPo: {
      type: null || Object,
      default: null,
    },
  },
});
