































import Vue from 'vue';

import CatalisadoresPoFormCriarAlterar from './CatalisadoresPoFormCriarAlterar.vue';

export default Vue.extend({
  name: 'CatalisadoresPoDialogCadastro',
  components: {
    CatalisadoresPoFormCriarAlterar,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    familia: {
      type: null || Object,
      default: null,
    },
  },
  methods: {
    fechar() {
      this.$refs?.catalisadoresPoFormCriarAlterar?.cancelar() ||
        this.$emit('input', false);
    },
    catalisadorPoCriado(catalisadorPo) {
      this.$emit('input', false);
      this.$emit('catalisador-po-criado', catalisadorPo);
    },
  },
});
