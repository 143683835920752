export function getPaisEmojiFlag(hideBR = false): string {
  switch (process.env.VUE_APP_PAIS_ISO2) {
    case 'CL':
      return '🇨🇱';
    case 'CO':
      return '🇨🇴';
    case 'JP':
      return '🇯🇵';
    case 'BR':
      return hideBR ? '' : '🇧🇷';
    default:
      return '';
  }
}

export function getPaisCurrency(): string {
  return process.env.VUE_APP_PAIS_CURRENCY || 'BRL';
}

export function getPaisLocale(): string {
  return process.env.VUE_APP_PAIS_LOCALE || 'pt-BR';
}
