var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-simple-table',{attrs:{"dense":_vm.dense}},[_c('thead',[_c('tr',[_c('th',{class:[_vm.thClass]},[_vm._v(_vm._s(_vm.$t('comum.item')))]),_c('th',{class:[_vm.thClass]},[_vm._v(_vm._s(_vm.$t('comum.familia')))]),_c('th',{class:[_vm.thClass]},[_vm._v(_vm._s(_vm.$t('comum.peca')))]),_c('th',{class:[_vm.thClass, 'text-right']},[_vm._v(" "+_vm._s(_vm.$t('comum.pesoDaCeramica'))+" ("+_vm._s(_vm.$t('comum.kg'))+") ")]),_c('th',{class:[_vm.thClass, 'text-center']},[_vm._v("Qtde.")]),(_vm.showRecebido)?_c('th',{class:[_vm.thClass, 'text-center']},[_vm._v(" "+_vm._s(_vm.$t('comum.recebido'))+" ")]):_vm._e(),(!_vm.hideValores)?_c('th',{class:[_vm.thClass, 'text-center']},[_vm._v(" "+_vm._s(_vm.$t('comum.margemPeca'))+" ")]):_vm._e(),(!_vm.hideValores)?_c('th',{class:[_vm.thClass, 'text-right']},[_vm._v(" "+_vm._s(_vm.$t('comum.valor'))+" ("+_vm._s(_vm.currency)+"/"+_vm._s(_vm.$t('comum.peca'))+") ")]):_vm._e(),(!_vm.hideValores)?_c('th',{class:[_vm.thClass, 'text-right']},[_vm._v(" "+_vm._s(_vm.$t('comum.valorLiquido'))+" ("+_vm._s(_vm.currency)+") ")]):_vm._e()])]),_c('tbody',[_vm._l((_vm.itemsMapped),function(item,index){return [_c('tr',{key:item.id},[_c('td',{class:[_vm.tdClass]},[_vm._v("# "+_vm._s(item.index))]),_c('td',{class:[_vm.tdClass]},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('TextOrNA',{attrs:{"text":item.familiaCodigo}})],1)]}}],null,true)},[_c('TextOrNA',{attrs:{"text":item.familiaDescricao}})],1)],1),_c('td',{staticClass:"py-1"},[_c('div',{staticClass:"text-no-wrap"},[_c('span',[_vm._v(_vm._s(item.codigo))]),(item.detalhe)?_c('span',{domProps:{"textContent":_vm._s((" - " + (item.detalhe)))}}):_vm._e(),_c('span',{domProps:{"textContent":_vm._s((" (" + (item.familiaDescricaoCatalogo || 'N/A') + ")"))}})]),_c('div',{class:['text-no-wrap text-caption text--secondary']},[_c('span',[_vm._v(_vm._s(item.montadoraNome))]),(item.modelo)?_c('span',{domProps:{"textContent":_vm._s((" - " + (item.modelo)))}}):_vm._e()])]),_c('td',{class:[_vm.tdClass, 'text-right']},[_c('span',[_vm._v(" "+_vm._s(_vm._f("formatPeso")(item.pesoCeramicaKg))+" ")])]),_c('td',{class:[
              _vm.tdClass,
              'text-center',
              item.quantidadeInicial > item.quantidade
                ? 'error--text'
                : _vm.showRecebido
                ? 'text--disabled'
                : '' ]},[_vm._v(" "+_vm._s(item.quantidadeInicial)+" ")]),(_vm.showRecebido)?_c('td',{class:[
              _vm.tdClass,
              'text-center',
              item.quantidadeInicial > item.quantidade
                ? 'error--text font-weight-bold'
                : '' ]},[_vm._v(" "+_vm._s(item.quantidade)+" ")]):_vm._e(),(!_vm.hideValores)?_c('td',{class:[_vm.tdClass, 'text-center']},[_vm._v(" "+_vm._s(_vm._f("formatPercent")(item.margemPeca))+" ")]):_vm._e(),(!_vm.hideValores)?_c('td',{class:[_vm.tdClass, 'text-right']},[_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.valor))+" ")]):_vm._e(),(!_vm.hideValores)?_c('td',{class:[_vm.tdClass, 'text-right']},[_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.valorLiquido))+" ")]):_vm._e()]),(item.recebimentosReprovados.length > 0)?_c('tr',{key:("reprovado_" + index),staticClass:"error lighten-5"},[_c('td',[_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("mdi-subdirectory-arrow-right")])],1),_c('td',{attrs:{"colspan":_vm.colspanRecebimentosReprovados}},[_c('div',{staticClass:"d-flex flex-column"},[_vm._l((item.recebimentosReprovados),function(textRecebimento,index){return [_c('span',{key:index,staticClass:"\n                    font-weight-medium\n                    text-caption\n                    error--text\n                    text-lowercase\n                  "},[_vm._v(" "+_vm._s(textRecebimento)+" ")])]})],2)])]):_vm._e()]})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }