import axios from 'axios';

const atualiza = async (
  catalisadorPecaId: number,
  formData: any,
): Promise<any> => {
  const res = await axios.patch<any>(
    `catalisadores_pecas/${catalisadorPecaId}`,
    formData,
  );
  return res.data;
};

const atualizaLote = async (formData: any): Promise<any[]> => {
  const res = await axios.post<any[]>(
    `catalisadores_pecas/atualizar_lote`,
    formData,
  );
  return res.data;
};

const busca = async (
  catalisadorPecaId: number,
  buscarCatalisadoresPecasQuery: any,
): Promise<any> => {
  const res = await axios.get<any>(`catalisadores_pecas/${catalisadorPecaId}`, {
    ...(buscarCatalisadoresPecasQuery
      ? { params: buscarCatalisadoresPecasQuery }
      : {}),
  });
  return res.data;
};

const buscaAudit = async (catalisadorPecaId: number): Promise<any[]> => {
  const res = await axios.get<any[]>(
    `catalisadores_pecas/${catalisadorPecaId}/audit`,
  );
  return res.data;
};

const buscaFotosArquivos = async (
  catalisadorPecaId: number,
): Promise<any[]> => {
  const res = await axios.get<any[]>(
    `catalisadores_pecas/${catalisadorPecaId}/fotos_arquivos`,
  );
  return res.data;
};

const cria = async (formData: any): Promise<any> => {
  const res = await axios.post<any>('catalisadores_pecas', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.data;
};

const lista = async (listarCatalisadoresPecasQuery: any): Promise<any> => {
  const res = await axios.get<any>(`catalisadores_pecas`, {
    ...(listarCatalisadoresPecasQuery
      ? { params: listarCatalisadoresPecasQuery }
      : {}),
  });
  return res.data;
};

const listaAnalises = async (
  listarCatalisadoresPecasQuery: any,
): Promise<any> => {
  const res = await axios.get<any>(`catalisadores_pecas/analises`, {
    ...(listarCatalisadoresPecasQuery
      ? { params: listarCatalisadoresPecasQuery }
      : {}),
  });
  return res.data;
};

const remove = async (catalisadorPecaId: number): Promise<any> => {
  const res = await axios.delete<any>(
    `catalisadores_pecas/${catalisadorPecaId}`,
  );
  return res.data;
};

export default {
  atualiza,
  atualizaLote,
  busca,
  buscaAudit,
  buscaFotosArquivos,
  cria,
  lista,
  listaAnalises,
  remove,
};
