































































import PermissoesDialogCadastro from '@/components/permissoes/PermissoesDialogCadastro.vue';
import PermissoesDialogInfo from '@/components/permissoes/PermissoesDialogInfo.vue';
import PermissoesDialogInfoSync from '@/components/permissoes/PermissoesDialogInfoSync.vue';
import requester from '@/requester';
import Vue from 'vue';
export default Vue.extend({
  components: {
    PermissoesDialogCadastro,
    PermissoesDialogInfo,
    PermissoesDialogInfoSync,
  },
  name: 'PermissoesIndex',
  data: () => ({
    loading: {
      permissoes: false,
    },
    search: '',
    permissoes: [],
    dialogCadastro: false,
    dialogInfo: false,
    dialogSync: false,
    permissaoId: null,
    permissoesCriadas: [],
    permissoesRemovidas: [],
  }),
  mounted() {
    this.listarPermissoes();
  },
  computed: {
    headers() {
      return [
        {
          text: 'Action',
          value: 'action',
          groupable: false,
        },
        {
          text: 'Subject',
          value: 'subject',
        },
        {
          text: 'Conditions',
          value: 'conditions',
          groupable: false,
        },
      ];
    },
  },
  methods: {
    async listarPermissoes() {
      this.loading.permissoes = true;
      try {
        this.permissoes = await requester.permissoes.listar();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.permissoes = false;
      }
    },
    openDialogInfo(id: number) {
      this.permissaoId = id;
      this.dialogInfo = true;
    },
    async sincronizar() {
      this.loading.permissoes = true;
      try {
        const permissoesSincronizadas =
          await requester.permissoes.sincronizar();
        this.permissoesCriadas = permissoesSincronizadas?.criadas;
        this.permissoesRemovidas = permissoesSincronizadas?.removidas;

        this.dialogSync = true;
        this.listarPermissoes();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.permissoes = false;
      }
    },
  },
});
