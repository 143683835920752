import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import en from 'vuetify/src/locale/en';
import es from 'vuetify/src/locale/es';
import pt from 'vuetify/src/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#054446',
        surface: '#ddd',
      },
    },
  },
  lang: {
    locales: { en, es, pt },
    current: 'pt',
  },
});
