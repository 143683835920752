















































































































































import Vue from 'vue';

import {
  rulesNumeric,
  rulesNumericPositive,
  rulesRequired,
} from '@/utils/input-rules';
import { inputPriceToNumber, maskCurrency } from '@/utils/intl-functions';

import InputData from '../inputs/InputData.vue';
import CustomCancelarSalvar from '../custom/CustomCancelarSalvar.vue';

export default Vue.extend({
  name: 'CatalisadoresPecasFormAnalise',
  components: {
    InputData,
    CustomCancelarSalvar,
  },
  data: () => ({
    form: {
      ref: '',
      dataAnalise: null,
      ptPpm: '',
      pdPpm: '',
      rhPpm: '',
      pesoKg: null,
    },
    rules: {
      required: rulesRequired,
      numeric: rulesNumeric,
      numericPositive: rulesNumericPositive,
    },
  }),
  methods: {
    maskCurrency,
    resetForm() {
      this.$refs?.form?.reset();
      this.form.dataAnalise = null;
    },
    cancelar() {
      this.$emit('cancelar');
      this.resetForm();
    },
    adicionar() {
      if (!this.$refs?.form?.validate()) {
        return;
      }
      this.$emit('adicionar-analise', {
        ref: this.form.ref?.trim() || null,
        dataAnalise: this.form.dataAnalise || null,
        pesoKg: inputPriceToNumber(this.form.pesoKg, 3) || 0,
        ptPpm: Number(this.form.ptPpm) || 0,
        pdPpm: Number(this.form.pdPpm) || 0,
        rhPpm: Number(this.form.rhPpm) || 0,
        ativo: true,
      });
      this.resetForm();
    },
  },
});
