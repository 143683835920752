import axios, { AxiosResponse } from 'axios';
import { Funcionalidade } from '../../../back/src/models/funcionalidade.model';
import { AlteraFuncionalidadeDto } from '../../../back/src/funcionalidades/dto/altera-funcionalidade.dto';
import { CriaFuncionalidadeDto } from '../../../back/src/funcionalidades/dto/cria-funcionalidade.dto';

const listar = async (): Promise<Funcionalidade[]> => {
  const res = await axios.get<Funcionalidade[]>('funcionalidades');
  return res.data;
};
const criar = async (dto: CriaFuncionalidadeDto): Promise<Funcionalidade> => {
  const res = await axios.post<
    CriaFuncionalidadeDto,
    AxiosResponse<Funcionalidade>
  >('funcionalidades', dto);
  return res.data;
};

const alterar = async (
  funcionalidadeId: number,
  dto: AlteraFuncionalidadeDto,
): Promise<Funcionalidade> => {
  const res = await axios.put<
    AlteraFuncionalidadeDto,
    AxiosResponse<Funcionalidade>
  >(`funcionalidades/${funcionalidadeId}`, dto);
  return res.data;
};

const buscar = async (id: number): Promise<Funcionalidade> => {
  const res = await axios.get<Funcionalidade>(`funcionalidades/${id}`);
  return res.data;
};
const remove = async (funcionalidadeId: number): Promise<Funcionalidade> => {
  const res = await axios.delete<Funcionalidade>(
    `funcionalidades/${funcionalidadeId}`,
  );
  return res.data;
};

const associaPermissao = async (
  funcionalidadeId: number,
  permissaoId: number,
): Promise<Funcionalidade> => {
  const res = await axios.post<Funcionalidade>(
    `funcionalidades/${funcionalidadeId}/permissoes/${permissaoId}`,
  );
  return res.data;
};
const removePermissao = async (
  funcionalidadeId: number,
  permissaoId: number,
): Promise<Funcionalidade> => {
  const res = await axios.delete<Funcionalidade>(
    `funcionalidades/${funcionalidadeId}/permissoes/${permissaoId}`,
  );
  return res.data;
};

export default {
  alterar,
  associaPermissao,
  buscar,
  criar,
  listar,
  remove,
  removePermissao,
};
