import axios, { AxiosResponse } from 'axios';

import { ViewPedidoAtual } from '../../../back/src/models/views/view-pedido-atual.model';
import { BuscarPedidoQueryDto } from '../../../back/src/pedidos/dto/buscar-fornecedor-query.dto';
import { ListarPedidosQueryDto } from '../../../back/src/pedidos/dto/listar-pedidos-query.dto';
import { ReceberPedidoDto } from '../../../back/src/pedidos/dto/receber-pedido.dto';
import { Pedido } from '../../../back/src/models/pedido.model';
import { ListarRecebimentoQueryDto } from '../../../back/src/pedidos/dto/listar-recebimento-query.dto';

const cancela = async (pedidoId: number, formData: any): Promise<any> => {
  const res = await axios.post<any>(`pedidos/${pedidoId}/cancelar`, formData);
  return res.data;
};

const cria = async (formData: any): Promise<any[]> => {
  const res = await axios.post<any[]>('pedidos', formData);
  return res.data;
};

const busca = async (
  pedidoId: number,
  buscarPedidoQueryDto: BuscarPedidoQueryDto,
): Promise<Pedido> => {
  const res = await axios.get<Pedido>(`pedidos/${pedidoId}`, {
    ...(buscarPedidoQueryDto ? { params: buscarPedidoQueryDto } : {}),
  });
  return res.data;
};

const geraPdf = async (pedidoId: number): Promise<any> => {
  const res = await axios.get<any>(`pedidos/${pedidoId}/gerar_pdf`);
  return res.data;
};

const lista = async (
  listarPedidosQueryDto?: ListarPedidosQueryDto,
): Promise<{ count: number; rows: ViewPedidoAtual[] }> => {
  const res = await axios.get<{ count: number; rows: ViewPedidoAtual[] }>(
    'pedidos',
    {
      ...(listarPedidosQueryDto ? { params: listarPedidosQueryDto } : {}),
    },
  );
  return res.data;
};

const listaPedidosRecebimento = async (
  queryDto?: ListarRecebimentoQueryDto,
): Promise<{ count: number; rows: ViewPedidoAtual[] }> => {
  const res = await axios.get<{ count: number; rows: ViewPedidoAtual[] }>(
    'pedidos/recebimento',
    {
      ...(queryDto ? { params: queryDto } : {}),
    },
  );
  return res.data;
};

const recebe = async (
  pedidoId: number,
  dto: ReceberPedidoDto,
): Promise<Pedido> => {
  const res = await axios.post<ReceberPedidoDto, AxiosResponse<Pedido>>(
    `pedidos/${pedidoId}/receber`,
    dto,
  );
  return res.data;
};

export default {
  busca,
  cancela,
  cria,
  geraPdf,
  lista,
  listaPedidosRecebimento,
  recebe,
};
