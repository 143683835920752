var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loadingAll)?_c('Loading'):_vm._e(),_c('v-expansion-panels',{attrs:{"focusable":"","multiple":""},model:{value:(_vm.expand),callback:function ($$v) {_vm.expand=$$v},expression:"expand"}},_vm._l((_vm.funcionalidadesPorCategoria),function(arrayFuncionalidades,categoria){return _c('v-expansion-panel',{key:categoria},[_c('v-expansion-panel-header',{attrs:{"ripple":""}},[_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(categoria))])]),_c('v-expansion-panel-content',[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.$t('comum.funcionalidade')))]),_vm._l((_vm.gruposOrdenados),function(grupo){return _c('th',{key:grupo.id,staticClass:"text-no-wrap text-center"},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-account-group")]),_c('span',[_vm._v(_vm._s(grupo.nome))])],1)})],2)]),_c('tbody',_vm._l((arrayFuncionalidades),function(funcionalidade){return _c('tr',{key:funcionalidade.id},[_c('th',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(funcionalidade.nome)+" ")]),_vm._l((_vm.gruposOrdenados),function(grupo){return _c('td',{key:grupo.id,staticClass:"text-center"},[(grupo.admin)?[_c('v-btn',{attrs:{"icon":"","color":"success","disabled":""}},[_c('v-icon',[_vm._v("mdi-lock-open")])],1)]:(
                    _vm.getFuncionalidadeGrupo(funcionalidade.id, grupo.id)
                  )?[_c('v-btn',{attrs:{"icon":"","color":"success","loading":!!_vm.loading.funcionalidadeGrupo[
                        ("funcionalidade" + (funcionalidade.id) + "_grupo" + (grupo.id))
                      ]},on:{"click":function($event){return _vm.desvinculaFuncionalidadeGrupoDebounce(
                        funcionalidade.id,
                        grupo.id
                      )}}},[_c('v-icon',[_vm._v("mdi-lock-open")])],1)]:[_c('v-btn',{attrs:{"icon":"","color":"error","loading":!!_vm.loading.funcionalidadeGrupo[
                        ("funcionalidade" + (funcionalidade.id) + "_grupo" + (grupo.id))
                      ]},on:{"click":function($event){return _vm.vinculaFuncionalidadeGrupoDebounce(
                        funcionalidade.id,
                        grupo.id
                      )}}},[_c('v-icon',[_vm._v("mdi-lock")])],1)]],2)})],2)}),0)])],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }