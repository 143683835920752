import axios from 'axios';

const atualiza = async (
  catalisadorPoId: number,
  formData: any,
): Promise<any> => {
  const res = await axios.patch<any>(
    `catalisadores_po/${catalisadorPoId}`,
    formData,
  );
  return res.data;
};

const busca = async (
  catalisadorPoId: number,
  buscarCatalisadorPoQuery?: any,
): Promise<any> => {
  const res = await axios.get<any>(`catalisadores_po/${catalisadorPoId}`, {
    ...(buscarCatalisadorPoQuery ? { params: buscarCatalisadorPoQuery } : {}),
  });
  return res.data;
};

const cria = async (formData: any): Promise<any> => {
  const res = await axios.post<any>('catalisadores_po', formData);
  return res.data;
};

const remove = async (catalisadorPoId: number): Promise<any> => {
  const res = await axios.delete<any>(`catalisadores_po/${catalisadorPoId}`);
  return res.data;
};

export default {
  atualiza,
  busca,
  cria,
  remove,
};
