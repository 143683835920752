var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(Object.keys(_vm.dadosInconsistentes).length === 0)?_c('div',{staticClass:"\n      d-flex\n      align-center\n      justify-center\n      py-4\n      text--disabled text-subtitle-1\n    "},[_vm._v(" "+_vm._s(_vm.$t('CatalisadoresPecasImportarStep4.nenhumInconsistente'))+" ")]):_vm._e(),_vm._l((_vm.dadosInconsistentes),function(dados,codigo){return _c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.showOnlyError ? _vm.nenhumItemSelecionado(dados) : true),expression:"showOnlyError ? nenhumItemSelecionado(dados) : true"}],key:codigo,staticClass:"mb-2",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(_vm._s(codigo))]),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{attrs:{"items":dados,"headers":_vm.headers,"disable-sort":"","disable-filtering":"","disable-pagination":"","hide-default-footer":"","item-class":function (el) { return (el.utilizar ? 'success lighten-4' : ''); }},scopedSlots:_vm._u([{key:"item.utilizar",fn:function(ref){
var item = ref.item;
return [(!item.utilizar)?_c('v-btn',{attrs:{"icon":"","color":"secondary"},on:{"click":function($event){dados.forEach(function (el) {
                el.utilizar = false;
              });
              item.utilizar = true;}}},[_c('v-icon',[_vm._v("mdi-radiobox-blank")])],1):_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){item.utilizar = false}}},[_c('v-icon',[_vm._v("mdi-radiobox-marked")])],1)]}},{key:"item.codigo",fn:function(ref){
              var item = ref.item;
return [(item.codigo)?_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.codigo)+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.detalhe",fn:function(ref){
              var item = ref.item;
return [(item.detalhe)?_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.detalhe)+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.observacoes",fn:function(ref){
              var item = ref.item;
return [(item.observacoes)?_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.observacoes)+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.montadora",fn:function(ref){
              var item = ref.item;
return [(item.montadora)?_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.montadora.toUpperCase())+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.modelo",fn:function(ref){
              var item = ref.item;
return [(item.modelo)?_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.modelo)+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.margemPeca",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(Number(item.margemPeca) || 0)+"%")])]}},{key:"item.ref",fn:function(ref){
              var item = ref.item;
return [(item.ref)?_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(Number(item.ref))+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.dataUltimaAnalise",fn:function(ref){
              var item = ref.item;
return [(item.dataUltimaAnalise)?_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.dataUltimaAnalise))+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.ptPpm",fn:function(ref){
              var item = ref.item;
return [(item.ptPpm)?_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(Number(item.ptPpm))+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.pdPpm",fn:function(ref){
              var item = ref.item;
return [(item.pdPpm)?_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(Number(item.pdPpm))+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.rhPpm",fn:function(ref){
              var item = ref.item;
return [(item.rhPpm)?_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(Number(item.rhPpm))+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.pesoKg",fn:function(ref){
              var item = ref.item;
return [(item.pesoKg)?_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(Number(item.pesoKg))+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}}],null,true)})],1)],1)}),(_vm.errorInconsistentes)?_c('v-alert',{staticClass:"mt-3",attrs:{"color":"error"}},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"color":"white","left":""}},[_vm._v("mdi-alert")]),_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.$t('CatalisadoresPecasImportarStep4.existeInconsistentes'))+" ")])],1),_c('v-btn',{attrs:{"outlined":"","color":"white"},on:{"click":function($event){_vm.showOnlyError = !_vm.showOnlyError}}},[_vm._v(" "+_vm._s(_vm.showOnlyError ? _vm.$t('CatalisadoresPecasImportarStep4.mostrarTodos') : _vm.$t('CatalisadoresPecasImportarStep4.mostrarInconsistentes'))+" ")])],1)]):_vm._e(),_c('v-divider',{staticClass:"my-3"}),_c('div',{staticClass:"d-flex justify-space-between align-center mt-2"},[_c('v-btn',{attrs:{"color":"secondary","outlined":""},on:{"click":function($event){return _vm.$emit('previous-step')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left")]),_c('span',[_vm._v(_vm._s(_vm.$t('comum.voltar')))])],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.nextStep()}}},[_c('span',[_vm._v(_vm._s(_vm.$t('comum.prosseguir')))]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-arrow-right")])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }