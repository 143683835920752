


















































































































































import Vue from 'vue';

import { Fornecedor } from '../../../../back/src/models/fornecedor.model';
import { PessoaFornecedorEnum } from '../../../../back/src/fornecedores/fornecedores.constants';
import TextOrNA from '../TextOrNA.vue';

export default Vue.extend({
  name: 'FornecedoresInfo',
  components: {
    TextOrNA,
  },
  props: {
    fornecedor: {
      type: (Object as () => Fornecedor) || null,
      default: null,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    PessoaFornecedorEnum,
  }),
});
