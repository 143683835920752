














import Vue from 'vue';
export default Vue.extend({
  name: 'ErroNaoEncontrado',
  computed: {
    path() {
      return this.$route?.path || '';
    },
  },
});
