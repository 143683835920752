import { RouteConfig } from 'vue-router';

import { Rules as CaslRules } from '../../../back/src/casl/casl.constants';
import CatalisadoresPoIndex from '@/pages/catalisadores-po/CatalisadoresPoIndex.vue';
import CatalisadoresPoCalculadora from '@/pages/catalisadores-po/CatalisadoresPoCalculadora.vue';

const routes: RouteConfig[] = [
  {
    path: '/catalisadores_po',
    name: 'CatalisadoresPoIndex',
    component: CatalisadoresPoIndex,
    meta: {
      icon: 'mdi-table-large',
      rules: [
        CaslRules.Familia.listar,
        CaslRules.Familia.listarCatalisadoresPo,
      ],
    },
  },
  {
    path: '/catalisadores_po/calculadora',
    name: 'CatalisadoresPoCalculadora',
    component: CatalisadoresPoCalculadora,
    meta: {
      icon: 'mdi-table-large',
      rules: [CaslRules.Cotacao.listar],
    },
  },
];

export default routes;
