


































































































































































































import Vue from 'vue';
import { Usuario } from '@models/usuario.model';
import { CategoriaPedidoItemEnum } from '../../../../back/src/pedidos/pedidos.constants';
import { orderBy } from 'lodash';
import { UsuarioMargem } from '@models/usuario-margem.model';
import { calculaMargemComposta } from '@/utils/usuarios-margens-helper';
import { CategoriaUsuarioEnum } from '../../../../back/src/usuarios/usuarios.constants';
import UsuariosTabelaMargens from '@/components/usuarios/UsuariosTabelaMargens.vue';

interface UsuarioComMargem extends Usuario {
  margemPecas: UsuarioMargem;
  margemPo: UsuarioMargem;
  usuariosComMargens: UsuarioComMargem[];
}

export default Vue.extend({
  name: 'UsuariosTabelaResponsavel',
  components: {
    UsuariosTabelaMargens,
  },
  props: {
    usuario: {
      type: Object as () => Usuario,
      required: true,
    },
  },
  data: () => ({
    expanded: [],
  }),
  computed: {
    usuarios() {
      return this.usuario?.usuariosResponsavel || [];
    },
    usuariosComMargens(): UsuarioComMargem[] {
      return orderBy(
        this.usuarios.map((el1) => ({
          ...el1,
          margemPecas: this.getMargemPecas(el1.margens),
          margemPo: this.getMargemPo(el1.margens),
          usuariosComMargens: orderBy(
            (el1.usuariosResponsavel || []).map((el2) => ({
              ...el2,
              margemPecas: this.getMargemPecas(el2.margens),
              margemPo: this.getMargemPo(el2.margens),
            })),
            ['nomeCompleto'],
          ),
        })),
        ['nomeCompleto'],
      );
    },
  },
  methods: {
    calculaMargemComposta,
    isParceiro(usuario) {
      return usuario.categoria === CategoriaUsuarioEnum.PARCEIRO;
    },
    isPreposto(usuario) {
      return usuario.categoria === CategoriaUsuarioEnum.PREPOSTO;
    },
    margemResponsavel(responsavel, categoria) {
      return responsavel?.margens?.find((margem) => {
        return margem.categoria === categoria;
      })?.margem;
    },
    getMargemPecas(margens) {
      return margens.find(
        (el) => el.categoria === CategoriaPedidoItemEnum.CATALISADORES_PECAS,
      );
    },
    getMargemPo(margens) {
      return margens.find(
        (el) => el.categoria === CategoriaPedidoItemEnum.CATALISADORES_PO,
      );
    },
  },
});
