



























import Vue from 'vue';

import PedidosTabela from '@/components/pedidos/PedidosTabela.vue';

export default Vue.extend({
  components: { PedidosTabela },
  name: 'PedidosIndex',
  data: () => ({
    tab: 0,
  }),
});
