









































































































import Vue from 'vue';

interface Menu {
  title: string;
  icon: string;
  to: { name: string };
  children?: Menu[];
}

export default Vue.extend({
  name: 'TheNavigationDrawer',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({}),
  computed: {
    urlLims() {
      return process.env.VUE_APP_URL_LIMS;
    },
    urlCatalogo() {
      return process.env.VUE_APP_URL_CATALOGO;
    },
    showLims() {
      return process.env.VUE_APP_SHOW_LIMS === 'true';
    },
    usuario() {
      return this.$store.getters.usuario || null;
    },
    menu(): Menu[] {
      return this.usuario ? this.menuLoggedIn : this.menuLoggedOut;
    },
    menuLoggedIn() {
      return [
        {
          title: this.$t('comum.painelDeControle'),
          icon: 'mdi-view-dashboard',
          to: { name: 'PainelDeControle' },
        },
        {
          title: this.$t('comum.cotacoes'),
          icon: 'mdi-currency-usd',
          to: { name: 'CotacoesIndex' },
        },
        {
          title: this.$t('comum.familias'),
          icon: 'mdi-view-list',
          to: { name: 'FamiliasIndex' },
        },
        {
          title: this.$t('comum.catalisadoresPecas'),
          icon: 'mdi-car-back',
          to: { name: 'CatalisadoresPecasIndex' },
        },
        {
          title: this.$t('comum.catalisadoresPo'),
          icon: 'mdi-table-large',
          to: { name: 'CatalisadoresPoCalculadora' },
        },
        {
          title: this.$t('comum.placasEletronicas'),
          icon: 'mdi-chip',
          to: { name: 'PlacasIndex' },
        },
        {
          title: this.$t('comum.pedidos'),
          icon: 'mdi-clipboard-text',
          children: [
            {
              title: this.$t('comum.listagem'),
              to: { name: 'PedidosIndex' },
            },
            {
              title: this.$t('comum.recebimento'),
              to: { name: 'PedidosRecebimentoIndex' },
            },
          ],
        },
        {
          title: this.$t('comum.lojas'),
          icon: 'mdi-store',
          to: { name: 'LojasIndex' },
        },
        {
          title: this.$t('comum.usuarios'),
          icon: 'mdi-account-multiple',
          to: { name: 'UsuariosIndex' },
        },
        {
          title: this.$t('comum.grupos'),
          icon: 'mdi-account-group',
          to: { name: 'GruposIndex' },
        },
        {
          title: this.$t('comum.permissoes'),
          icon: 'mdi-lock-open',
          to: { name: 'GruposPermissoes' },
        },
      ];
    },
    menuLoggedOut() {
      return [
        {
          title: this.$t('comum.entrar'),
          icon: 'mdi-login',
          to: { name: 'AuthLogin' },
        },
      ];
    },
  },
  methods: {
    showItem(routeName: string) {
      const route = this.$router.options.routes.find(
        (el) => el.name === routeName,
      );
      const rules = route?.meta?.rules || [];
      return rules.every((rule) =>
        this.$ability.can(rule?.action, rule?.subject),
      );
    },
    showGroup(children: any[]) {
      return !!children.find((el) => this.showItem(el.to.name));
    },
  },
});
