import axios from 'axios';

const busca = async (
  cotacaoId: number,
  buscarCotacaoQuery?: any,
): Promise<any> => {
  const res = await axios.get<any>(`cotacoes/${cotacaoId}`, {
    ...(buscarCotacaoQuery ? { params: buscarCotacaoQuery } : {}),
  });
  return res.data;
};

const buscaAtual = async (): Promise<any> => {
  const res = await axios.get<any>('cotacoes/atual');
  return res.data;
};

const cria = async (formData: any): Promise<any> => {
  const res = await axios.post<any>('cotacoes', formData);
  return res.data;
};

const lista = async (paginationQuery: any): Promise<any> => {
  const res = await axios.get<any>('cotacoes', {
    params: paginationQuery,
  });
  return res.data;
};

const remove = async (cotacaoId: number): Promise<any> => {
  const res = await axios.delete<any>(`cotacoes/${cotacaoId}`);
  return res.data;
};

export default {
  busca,
  buscaAtual,
  cria,
  lista,
  remove,
};
