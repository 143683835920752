













import Vue from 'vue';

import requester from '@/requester';
import { CategoriaUsuarioEnum } from '@/utils/constants/back.constants';

import { UsuarioScopesEnum } from '../../../../back/src/usuarios/usuarios.constants';

import UsuariosFormParceiro from '@/components/usuarios/UsuariosFormParceiro.vue';
import UsuariosFormFuncionario from '@/components/usuarios/UsuariosFormFuncionario.vue';

export default Vue.extend({
  name: 'UsuariosEdicao',
  components: {
    UsuariosFormParceiro,
    UsuariosFormFuncionario,
  },
  data: () => ({
    loading: {
      usuario: true,
    },
    usuario: null,
    CategoriaUsuarioEnum,
  }),
  computed: {
    usuarioId() {
      return this.$route?.params?.usuarioId || 0;
    },
  },
  mounted() {
    this.buscaUsuario();
  },
  methods: {
    async buscaUsuario() {
      this.loading.usuario = true;
      try {
        this.usuario = await requester.usuarios.busca(this.usuarioId, {
          scope: UsuarioScopesEnum.ComLojasMargensFornecedorEndereco,
        });
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.usuario = false;
      }
    },
  },
});
