var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isParceiro(_vm.usuario))?[_c('div',{staticClass:"text-h6 mt-4"},[_vm._v(" "+_vm._s(_vm.$t('comum.margens'))+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('UsuariosTabelaMargens',{staticClass:"mb-6 mt-2",attrs:{"usuario":_vm.usuario}})],1)],1)]:_vm._e(),_c('v-card',{attrs:{"outlined":""}},[(_vm.usuariosComMargens.length === 0)?_c('v-card-text',[_vm._v(" Este usuário não é responsável comercial de nenhum outro usuário ")]):_c('v-simple-table',[_c('thead',{staticClass:"grey lighten-4"},[_c('tr',[_c('th',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.$t('comum.nome')))]),_c('th',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.$t('comum.email')))]),_c('th',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.$t('comum.categoria')))]),_c('th',{staticClass:"text-center text-no-wrap",attrs:{"width":"1"}},[_vm._v(" "+_vm._s(_vm.$t('comum.margemPecas'))+" ")]),_c('th',{staticClass:"text-center text-no-wrap",attrs:{"width":"1"}},[_vm._v(" "+_vm._s(_vm.$t('comum.margemComposta'))+" ")]),_c('th'),_c('th',{staticClass:"text-center text-no-wrap",attrs:{"width":"1"}},[_vm._v(" "+_vm._s(_vm.$t('comum.margemPo'))+" ")]),_c('th',{staticClass:"text-center text-no-wrap",attrs:{"width":"1"}},[_vm._v(" "+_vm._s(_vm.$t('comum.margemComposta'))+" ")]),_c('th',{staticClass:"text-center text-no-wrap"},[_vm._v(_vm._s(_vm.$t('comum.info')))])])]),_c('tbody',[_vm._l((_vm.usuariosComMargens),function(item1){return [_c('tr',{key:item1.id},[_c('td',{staticClass:"text-no-wrap"},[_c('router-link',{staticClass:"text-decoration-none font-weight-medium text--primary",attrs:{"to":{
                  name: 'UsuariosVer',
                  params: { usuarioId: item1.id },
                }}},[_vm._v(" "+_vm._s(item1.nomeCompleto)+" ")])],1),_c('td',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item1.email))]),_c('td',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.$t(("CategoriaUsuarioEnum." + (item1.categoria))))+" ")]),_c('td',{staticClass:"text-center text-no-wrap"},[(item1.margemPecas)?[_vm._v(" "+_vm._s(_vm._f("formatPercent")(item1.margemPecas.margem,2, 2))+" ")]:_c('span',{staticClass:"text--disabled",domProps:{"textContent":_vm._s('N/A')}})],2),_c('td',{staticClass:"text-center text-no-wrap"},[(_vm.isPreposto(item1))?[(item1.margemPecas)?[_vm._v(" "+_vm._s(_vm._f("formatPercent")(_vm.calculaMargemComposta( _vm.margemResponsavel( item1.responsavel, item1.margemPecas.categoria ), item1.margemPecas.margem ),2, 2))+" ")]:_c('span',{staticClass:"text--disabled",domProps:{"textContent":_vm._s('N/A')}})]:_vm._e()],2),_c('td'),_c('td',{staticClass:"text-center text-no-wrap"},[(item1.margemPo)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatPercent")(item1.margemPo.margem,2, 2))+" ")]):_c('span',{staticClass:"text--disabled",domProps:{"textContent":_vm._s('N/A')}})]),_c('td',{staticClass:"text-center text-no-wrap"},[(_vm.isPreposto(item1))?[(item1.margemPo)?[_vm._v(" "+_vm._s(_vm._f("formatPercent")(_vm.calculaMargemComposta( _vm.margemResponsavel( item1.responsavel, item1.margemPo.categoria ), item1.margemPo.margem ),2, 2))+" ")]:_c('span',{staticClass:"text--disabled",domProps:{"textContent":_vm._s('N/A')}})]:_vm._e()],2),_c('td',{staticClass:"text-center text-no-wrap"},[_c('v-btn',{attrs:{"icon":"","color":"info","to":{
                  name: 'UsuariosVer',
                  params: { usuarioId: item1.id },
                },"exact":""}},[_c('v-icon',[_vm._v("mdi-information")])],1)],1)]),_vm._l((item1.usuariosComMargens),function(item2){return _c('tr',{key:item2.id,staticClass:"text--disabled"},[_c('td',{staticClass:"d-flex align-center text-no-wrap"},[_c('router-link',{staticClass:"text-decoration-none font-weight-medium text--disabled",attrs:{"to":{
                  name: 'UsuariosVer',
                  params: { usuarioId: item2.id },
                }}},[_c('v-icon',{attrs:{"left":"","small":"","color":"grey"}},[_vm._v(" mdi-subdirectory-arrow-right ")]),_c('span',[_vm._v(_vm._s(item2.nomeCompleto))])],1)],1),_c('td',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item2.email))]),_c('td',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.$t(("CategoriaUsuarioEnum." + (item2.categoria))))+" ")]),_c('td',{staticClass:"text-center text-no-wrap"},[(item2.margemPecas)?[_vm._v(" "+_vm._s(_vm._f("formatPercent")(item2.margemPecas.margem,2, 2))+" ")]:_c('span',{staticClass:"text--disabled",domProps:{"textContent":_vm._s('N/A')}})],2),_c('td',{staticClass:"text-center text-no-wrap"},[(item2.margemPecas)?[_vm._v(" "+_vm._s(_vm._f("formatPercent")(_vm.calculaMargemComposta( _vm.margemResponsavel(item1, item2.margemPecas.categoria), item2.margemPecas.margem ),2, 2))+" ")]:_c('span',{staticClass:"text--disabled",domProps:{"textContent":_vm._s('N/A')}})],2),_c('td'),_c('td',{staticClass:"text-center text-no-wrap"},[(item2.margemPo)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatPercent")(item2.margemPo.margem,2, 2))+" ")]):_c('span',{staticClass:"text--disabled",domProps:{"textContent":_vm._s('N/A')}})]),_c('td',{staticClass:"text-center text-no-wrap"},[(item2.margemPo)?[_vm._v(" "+_vm._s(_vm._f("formatPercent")(_vm.calculaMargemComposta( _vm.margemResponsavel(item1, item2.margemPo.categoria), item2.margemPo.margem ),2, 2))+" ")]:_c('span',{staticClass:"text--disabled",domProps:{"textContent":_vm._s('N/A')}})],2),_c('td',{staticClass:"text-center text-no-wrap"},[_c('v-btn',{attrs:{"icon":"","color":"info","to":{
                  name: 'UsuariosVer',
                  params: { usuarioId: item2.id },
                },"exact":""}},[_c('v-icon',[_vm._v("mdi-information")])],1)],1)])})]})],2)])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }