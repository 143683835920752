var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.value,"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"persistent":"","width":"600"},on:{"input":function($event){return _vm.onInput($event)}}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-checkbox-multiple-marked-outline")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t( 'CatalisadoresPecasDialogAtualizarLote.alterarCatalisadoresPecasEmLote' ))+" ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.onInput(false)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('div',{staticClass:"text-subtitle-1 my-3"},[_vm._v(" "+_vm._s(_vm.$t( 'CatalisadoresPecasDialogAtualizarLote.quantidadePecasSelecionadas' ))+": "+_vm._s(_vm.catalisadoresPecas.length)+" ")]),_c('v-window',{model:{value:(_vm.window),callback:function ($$v) {_vm.window=$$v},expression:"window"}},[_c('v-window-item',{attrs:{"value":0}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-alert',{staticStyle:{"cursor":"pointer"},attrs:{"color":hover ? 'grey lighten-1' : 'grey lighten-3',"border":"left"},on:{"click":function($event){_vm.window = 1;
                _vm.escolha = _vm.EscolhaEnum.FAMILIA;}}},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('span',{staticClass:"secondary--text pl-2"},[_vm._v(" "+_vm._s(_vm.$t('comum.familia'))+" ")]),_c('v-icon',[_vm._v("mdi-arrow-right")])],1)])]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-alert',{staticStyle:{"cursor":"pointer"},attrs:{"color":hover ? 'grey lighten-1' : 'grey lighten-3',"border":"left"},on:{"click":function($event){_vm.window = 1;
                _vm.escolha = _vm.EscolhaEnum.MARGEM_PECA;}}},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('span',{staticClass:"secondary--text pl-2"},[_vm._v(" "+_vm._s(_vm.$t('comum.margemPeca'))+" ")]),_c('v-icon',[_vm._v("mdi-arrow-right")])],1)])]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-alert',{staticStyle:{"cursor":"pointer"},attrs:{"color":hover ? 'grey lighten-1' : 'grey lighten-3',"border":"left"},on:{"click":function($event){_vm.window = 1;
                _vm.escolha = _vm.EscolhaEnum.ATIVO;}}},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('span',{staticClass:"secondary--text pl-2"},[_vm._v(" "+_vm._s(_vm.$t('comum.ativo'))+" ")]),_c('v-icon',[_vm._v("mdi-arrow-right")])],1)])]}}])})],1),_c('v-window-item',{attrs:{"value":1}},[_c('v-form',{ref:"form",staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();}}},[(_vm.escolha === _vm.EscolhaEnum.FAMILIA)?[_c('v-autocomplete',{attrs:{"label":_vm.$t('comum.familia'),"items":_vm.itemsFamilias,"rules":_vm.rules.required,"clearable":"","outlined":"","validate-on-blur":""},model:{value:(_vm.form.familiaId),callback:function ($$v) {_vm.$set(_vm.form, "familiaId", $$v)},expression:"form.familiaId"}})]:_vm._e(),(_vm.escolha === _vm.EscolhaEnum.MARGEM_PECA)?[_c('InputNumber',{attrs:{"label":_vm.$t('comum.margemPeca'),"outlined":"","suffix":"%","required":"","allow-zero":"","allow-negative":""},model:{value:(_vm.form.margemPeca),callback:function ($$v) {_vm.$set(_vm.form, "margemPeca", $$v)},expression:"form.margemPeca"}})]:_vm._e(),(_vm.escolha === _vm.EscolhaEnum.ATIVO)?[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-alert',{staticClass:"mb-4",attrs:{"outlined":"","color":hover ? 'secondary' : 'grey'}},[_c('v-radio-group',{staticClass:"my-0 py-0",attrs:{"row":"","hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"\n                          secondary--text\n                          text--lighten-1 text-body-1\n                          mr-3\n                        "},[_vm._v(" "+_vm._s(_vm.$t('comum.ativo'))+" ")])]},proxy:true}],null,true),model:{value:(_vm.form.ativo),callback:function ($$v) {_vm.$set(_vm.form, "ativo", $$v)},expression:"form.ativo"}},[_c('v-radio',{attrs:{"value":true,"label":_vm.$t('comum.sim')}}),_c('v-radio',{attrs:{"value":false,"label":_vm.$t('comum.nao')}})],1)],1)]}}],null,false,2151639393)})]:_vm._e()],2),_c('v-divider',{staticClass:"mb-4"}),_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-btn',{attrs:{"outlined":"","loading":_vm.loading.submitForm},on:{"click":function($event){return _vm.voltar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left")]),_vm._v(" "+_vm._s(_vm.$t('comum.voltar'))+" ")],1),_c('v-btn',{attrs:{"color":"success","loading":_vm.loading.submitForm,"disabled":_vm.disabledSalvar},on:{"click":_vm.submitFormDebounce}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-floppy")]),_c('span',[_vm._v(_vm._s(_vm.$t('comum.salvar')))])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }