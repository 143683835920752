































































import Vue from 'vue';

export default Vue.extend({
  name: 'InputFoto',
  props: {
    aspectRatio: {
      type: Number,
      default: () => 4 / 3,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: {
      arquivoSignedUrl: true,
    },
    dragFileType: null,
    supportedTypes: ['image/jpeg', 'image/jpg', 'image/png'],
    arquivoSignedUrl: null,
    removerArquivo: false,
  }),
  computed: {
    notSupported() {
      return !this.supportedTypes.includes(this.dragFileType);
    },
    supportedTypesString() {
      return this.supportedTypes
        .map((el) => ` ${el.split('/')?.[1]}`)
        .toString()
        .trim();
    },
  },
  methods: {
    onClick() {
      this.$refs?.inputFile?.click();
    },
    dragOver(event: DragEvent) {
      event.preventDefault();
      this.dragFileType = event?.dataTransfer?.items[0].type || null;
    },
    dragLeave(event: DragEvent) {
      event.preventDefault();
      this.dragFileType = null;
    },
    drop(event: DragEvent) {
      event.preventDefault();
      this.dragFileType = null;
      const files = event?.dataTransfer?.files || [];
      this.addFiles(files);
    },
    onChangeFile(event: Event) {
      const files = (event.target as HTMLInputElement).files || [];
      this.addFiles(files);
    },
    addFiles(files: FileList) {
      const inputFiles = [];
      for (let i = 0; i < files.length; i++) {
        if (['image/jpeg', 'image/jpg', 'image/png'].includes(files[i].type)) {
          inputFiles.push(files[i]);
        }
      }
      this.$emit('add-files', inputFiles);
    },
  },
});
