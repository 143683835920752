export enum CategoriaUsuarioEnum {
  FUNCIONARIO = 'FUNCIONARIO',
  PARCEIRO = 'PARCEIRO',
  PREPOSTO = 'PREPOSTO',
}

export enum UsuarioScopesEnum {
  Auth = 'Auth',
  ComGrupo = 'ComGrupo',
  ComGrupoLojas = 'ComGrupoLojas',
  ComGrupoMargens = 'ComGrupoMargens',
  ComGrupoResponsavel = 'ComGrupoResponsavel',
  ComGrupoResponsavelDispositivos = 'ComGrupoResponsavelDispositivos',
  ComLojasMargensFornecedorEndereco = 'ComLojasMargensFornecedorEndereco',
  ComMargens = 'ComMargens',
  Completo = 'Completo',
  ParceirosComMargens = 'ParceirosComMargens',
  ParceirosOuPrepostoComResponsavelMargens = 'ParceirosOuPrepostoComResponsavelMargens',
}
