








































































import Vue from 'vue';
import { assign, debounce, groupBy, map } from 'lodash';

import requester from '@/requester';
import CatalisadoresPecasImportarTabelaNovas from './CatalisadoresPecasImportarTabelaNovas.vue';
import CatalisadoresPecasImportarTabelaExistente from './CatalisadoresPecasImportarTabelaExistente.vue';

export default Vue.extend({
  components: {
    CatalisadoresPecasImportarTabelaNovas,
    CatalisadoresPecasImportarTabelaExistente,
  },
  name: 'CatalisadoresPecasImportarStep5',
  data: () => ({
    loading: {
      catalisadoresPecas: false,
      submitForms: false,
      submitForm: {},
    },
    catalisadoresPecas: {
      ref: [],
      codigo: [],
    },
    headers: [],
    items: [],
    itemsAgrupados: [],
    itemsError: [],
  }),
  computed: {
    codigosExistentes() {
      return this.catalisadoresPecas.codigo.map((el) => el.codigo);
    },
    itemsCriar() {
      return this.items.filter(
        (el) => !this.codigosExistentes.includes(el.codigo),
      );
    },
    itemsAtualizar() {
      return this.items.filter((el) =>
        this.codigosExistentes.includes(el.codigo),
      );
    },
    dadosAtualizar() {
      return this.codigosExistentes.map((codigo) => ({
        existente: assign(
          this.catalisadoresPecas.codigo.find((el) => el.codigo === codigo),
          { identificacao: this.$t('comum.existente') },
        ),
        novo: assign(
          this.itemsAtualizar.find((el) => el.codigo === codigo),
          { identificacao: this.$t('comum.nova') },
        ),
      }));
    },
  },
  methods: {
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async inicio(headers, items) {
      this.headers = headers;
      this.items = map(items, (pecas) => {
        const analises = pecas.map((el) => ({
          ref: el?.ref?.trim() || null,
          dataAnalise: el?.dataUltimaAnalise || null,
          ptPpm: el?.ptPpm || 0,
          pdPpm: el?.pdPpm || 0,
          rhPpm: el?.rhPpm || 0,
          pesoKg: el?.pesoKg || 0,
          ativo: true,
        }));
        const pecaUtilizar = pecas.find((el) => !!el.utilizar) || pecas[0];
        const pecasObservacoes = pecas.filter((el) => !!el.observacoes);
        const observacoes =
          pecasObservacoes.length <= 1
            ? pecasObservacoes?.[0]?.observacoes || null
            : pecasObservacoes.reduce(
                (acc, el) => `${acc}\nRef ${el.ref}: ${el.observacoes}`,
                '',
              );
        return {
          codigo: pecaUtilizar?.codigo || null,
          detalhe: pecaUtilizar?.detalhe || null,
          montadora: pecaUtilizar?.montadora || null,
          modelo: pecaUtilizar?.modelo || null,
          observacoes,
          analises,
        };
      });
      await this.listaCatalisadoresPecas();
    },
    async listaCatalisadoresPecas() {
      this.loading.catalisadoresPecas = true;
      try {
        const set = this.items.map((el) => el.codigo);
        const setLength = set.length;
        const setSlices = [];
        for (let i = 0; i < setLength; i += 50) {
          setSlices.push(set.slice(i, i + 50 > setLength ? setLength : i + 50));
        }
        const resSlices = await Promise.all(
          setSlices.map((setSlice) => {
            setSlice;
            const listarCatalisadoresPecasQuery = {
              codigoSet: setSlice,
            };
            return requester.catalisadoresPecas.lista(
              listarCatalisadoresPecasQuery,
            );
          }),
        );
        this.catalisadoresPecas.codigo = resSlices.flatMap((el) => el.rows);
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.catalisadoresPecas = false;
      }
    },
    submitFormsDebounce: debounce(async function () {
      await this.submitForms();
    }),
    async submitForms() {
      this.itemsError = [];
      this.loading.submitForms = true;
      // await Promise.all([
      //   ...this.itemsCriar.map(async (item) => await this.submitForm(item)),
      //   ...this.dadosAtualizar.map(
      //     async (item) => await this.submitForm(item.novo, item.existente),
      //   ),
      // ]);
      for (let i = 0; i < this.itemsCriar.length; i++) {
        const item = this.itemsCriar[i];
        await this.delay(100);
        await this.submitForm(item);
      }
      // TODO: FIX THIS BEFORE
      const dadosAtualizar = map(
        groupBy(this.dadosAtualizar, (el) => el.existente.id),
        (el) => el?.[0],
      );
      for (let i = 0; i < dadosAtualizar.length; i++) {
        const item = dadosAtualizar[i];
        await this.delay(100);
        await this.submitForm(item.novo, item.existente);
      }
      this.loading.submitForms = false;
      this.$emit('next-step', {
        headers: this.headers,
        items: this.itemsError,
      });
    },
    async submitForm(item, catalisadorPeca = null) {
      this.loading.submitForm[item.key] = true;
      try {
        const formData = new FormData();
        formData.append('montadoraNome', item.montadora?.trim() || '');
        formData.append('codigo', item.codigo?.trim() || '');
        formData.append('detalhe', item.detalhe?.trim() || '');
        formData.append('modelo', item.modelo?.trim() || '');
        formData.append(
          'observacoes',
          (catalisadorPeca
            ? `${catalisadorPeca?.observacoes?.trim()}\n${item.observacoes?.trim()}`
            : item.observacoes?.trim()) || '',
        );
        formData.append('margemPeca', String(Number(item.margemPeca) || 0));
        formData.append('analises', JSON.stringify(item.analises));
        if (catalisadorPeca) {
          await requester.catalisadoresPecas.atualiza(
            catalisadorPeca.id,
            formData,
          );
        } else {
          await requester.catalisadoresPecas.cria(formData);
        }
      } catch (err) {
        this.itemsError.push(item);
        this.$root.$errorHandler(err);
      } finally {
        this.loading.submitForm[item.key] = false;
      }
    },
  },
});
