





























































































































































import Vue from 'vue';

import requester from '@/requester';
import { debounce } from 'lodash';
import { ListarRecebimentoQueryDto } from '../../../../back/src/pedidos/dto/listar-recebimento-query.dto';
import { ViewPedidoAtual } from '../../../../back/src/models/views/view-pedido-atual.model';
import { relativeTime } from '@/utils/intl-functions';
import { StatusPedidoEnum } from '../../../../back/src/pedidos/pedidos.constants';
import { DateTime } from 'luxon';
import InputData from '../inputs/InputData.vue';

export default Vue.extend({
  components: { InputData },
  name: 'PedidosTabRecebimento',
  data: () => ({
    loading: {
      pedidos: true,
    },
    search: null,
    page: 1,
    itemsPerPage: 10,
    sortDesc: true,
    pedidos: [] as ViewPedidoAtual[],
    serverItemsLength: 0,
    menuDatas: false,
    filterMinDate: null,
    filterMaxDate: null,
    showMinDate: false,
    showMaxDate: false,
  }),
  computed: {
    paginationLength() {
      return Math.ceil(this.serverItemsLength / this.itemsPerPage);
    },
  },
  watch: {
    page() {
      this.listaPedidosRecebimento(false);
    },
    itemsPerPage() {
      this.listaPedidosRecebimento(true);
    },
    sortDesc() {
      this.listaPedidosRecebimento(true);
    },
    search() {
      this.loading.pedidos = true;
      this.listaPedidosRecebimentoDebounce(true);
    },
    filterMinDate() {
      this.listaPedidosRecebimento(true);
      if (this.filterMinDate && this.filterMaxDate) this.menuDatas = false;
    },
    filterMaxDate() {
      this.listaPedidosRecebimento(true);
      if (this.filterMinDate && this.filterMaxDate) this.menuDatas = false;
    },
  },
  mounted() {
    this.listaPedidosRecebimento(true);
  },
  methods: {
    relativeTime,
    montaQueryDto(resetPage = false): ListarRecebimentoQueryDto {
      if (resetPage) {
        this.page = 1;
      }
      const page = this.page || 1;
      const itemsPerPage = this.itemsPerPage || 10;
      const limit = String(itemsPerPage);
      const offset = String((page - 1) * itemsPerPage);
      const orderBy = ['pedidoLogAtualCreatedAt'];
      const orderDesc = [String(this.sortDesc)];
      const search = this.search?.trim() || null;
      const pedidoLogAtualStatus = [StatusPedidoEnum.AGUARDANDO_APROVACAO];
      const dataMin = this.filterMinDate
        ? DateTime.fromISO(this.filterMinDate).toString()
        : null;

      const dataMax = this.filterMaxDate
        ? DateTime.fromISO(this.filterMaxDate)
            .plus({
              days: 1,
            })
            .toString()
        : null;
      return {
        limit,
        offset,
        orderBy,
        orderDesc,
        ...(search ? { search } : {}),
        pedidoLogAtualStatus,
        ...(dataMin ? { dataMin } : {}),
        ...(dataMax ? { dataMax } : {}),
      } as ListarRecebimentoQueryDto;
    },
    listaPedidosRecebimentoDebounce: debounce(function (resetPage = false) {
      this.listaPedidosRecebimento(resetPage);
    }, 250),
    async listaPedidosRecebimento(resetPage = false) {
      this.loading.pedidos = true;
      try {
        const queryDto = this.montaQueryDto(resetPage);
        const resData = await requester.pedidos.listaPedidosRecebimento(
          queryDto,
        );
        this.pedidos = resData?.rows || [];
        this.serverItemsLength = resData?.count || 0;
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.pedidos = false;
      }
    },
  },
});
