































































































































import Vue from 'vue';
import { orderBy } from 'lodash';

import { PedidoItem } from '../../../../back/src/models/pedido-item.model';

import TextOrNA from '../TextOrNA.vue';
import {
  StatusPedidoEnum,
  StatusPedidoItemPecaRecebimentoEnum,
} from '../../../../back/src/pedidos/pedidos.constants';

interface Item {
  id: number;
  index: number;
  familiaCodigo: string;
  familiaDescricao: string;
  familiaDescricaoCatalogo: string;
  codigo: string;
  detalhe: string;
  montadoraNome: string;
  modelo: string;
  pesoCeramicaKg: number;
  margemPeca: number;
  quantidadeInicial: number;
  quantidade: number;
  valor: number;
  valorLiquido: number;
  recebimentosReprovados: string[];
}

export default Vue.extend({
  name: 'PedidosTabelaItemsPecas',
  components: { TextOrNA },
  props: {
    items: {
      type: Array as () => PedidoItem[],
      default: [] as PedidoItem[],
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hideValores: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String as () => StatusPedidoEnum,
      required: true,
    },
  },
  data: () => ({
    pesoDetalhado: false,
  }),
  computed: {
    currency() {
      return process.env.VUE_APP_PAIS_CURRENCY || 'BRL';
    },
    quantidadeInicialTotal() {
      return this.items.reduce(
        (acc, el) => acc + (el?.pedidoItemPeca?.quantidadeInicial || 0),
        0,
      );
    },
    quantidadeTotal() {
      return this.items.reduce(
        (acc, el) => acc + (el?.pedidoItemPeca?.quantidade || 0),
        0,
      );
    },
    showRecebido() {
      return ![
        StatusPedidoEnum.AGUARDANDO_APROVACAO,
        StatusPedidoEnum.CANCELADO,
      ].includes(this.status);
    },
    colspanRecebimentosReprovados() {
      let colspan = 4;
      if (this.showRecebido) {
        colspan += 1;
      }
      if (!this.hideValores) {
        colspan += 3;
      }
      return colspan;
    },
    thClass() {
      return 'text-no-wrap';
    },
    tdClass() {
      return 'text-no-wrap';
    },
    itemsMapped(): Item[] {
      return orderBy(
        this.items.map((item: PedidoItem) => {
          const quantidadeInicial = item?.pedidoItemPeca?.quantidadeInicial;
          const quantidade = item?.pedidoItemPeca?.quantidade;
          const catalisadorPecaDados =
            item?.pedidoItemPeca?.catalisadorPecaDados;
          const recebimentos = item?.pedidoItemPeca?.recebimentos;

          const pecasDanificadas = recebimentos.filter(
            (recebimento) =>
              recebimento.status ===
              StatusPedidoItemPecaRecebimentoEnum.REPROVADA_DANIFICADA,
          );
          const pecasFaltantes = recebimentos.filter(
            (recebimento) =>
              recebimento.status ===
              StatusPedidoItemPecaRecebimentoEnum.REPROVADA_FALTANTE,
          );
          const pecasIncorretas = recebimentos.filter(
            (recebimento) =>
              recebimento.status ===
              StatusPedidoItemPecaRecebimentoEnum.REPROVADA_INCORRETA,
          );

          const recebimentosReprovadosOutros = recebimentos.filter(
            (recebimento) =>
              recebimento.status ===
              StatusPedidoItemPecaRecebimentoEnum.REPROVADA_OUTROS,
          );

          const recebimentosReprovados = [];
          if (pecasDanificadas.length > 0) {
            recebimentosReprovados.push(
              `- ${pecasDanificadas.length} ${this.$tc(
                `StatusPedidoItemPecaRecebimentoEnum.${StatusPedidoItemPecaRecebimentoEnum.REPROVADA_DANIFICADA}`,
                pecasDanificadas.length,
              )}`,
            );
          }
          if (pecasFaltantes.length > 0) {
            recebimentosReprovados.push(
              `- ${pecasFaltantes.length} ${this.$tc(
                `StatusPedidoItemPecaRecebimentoEnum.${StatusPedidoItemPecaRecebimentoEnum.REPROVADA_FALTANTE}`,
                pecasFaltantes.length,
              )}`,
            );
          }
          if (pecasIncorretas.length > 0) {
            recebimentosReprovados.push(
              `- ${pecasIncorretas.length} ${this.$tc(
                `StatusPedidoItemPecaRecebimentoEnum.${StatusPedidoItemPecaRecebimentoEnum.REPROVADA_INCORRETA}`,
                pecasIncorretas.length,
              )}`,
            );
          }
          recebimentosReprovadosOutros.forEach((recebimento) => {
            recebimentosReprovados.push(
              `- 1 ${this.$t(
                `StatusPedidoItemPecaRecebimentoEnum.${StatusPedidoItemPecaRecebimentoEnum.REPROVADA_OUTROS}`,
              )}: ${recebimento.observacoes}`,
            );
          });

          const valorPeca =
            (1 - (catalisadorPecaDados?.margemPeca || 0)) *
            (1 - (item?.margemComposta || 0)) *
            catalisadorPecaDados.valorTotalKg *
            catalisadorPecaDados.pesoKg *
            catalisadorPecaDados.cotacaoDolar;

          return {
            id: item.id,
            index: item?.index,
            familiaCodigo:
              item?.pedidoItemPeca?.catalisadorPeca?.familia?.codigo,
            familiaDescricao:
              item?.pedidoItemPeca?.catalisadorPeca?.familia?.descricao,
            familiaDescricaoCatalogo:
              item?.pedidoItemPeca?.catalisadorPeca?.familia?.descricaoCatalogo,
            codigo: catalisadorPecaDados?.codigo,
            detalhe: catalisadorPecaDados?.detalhe,
            montadoraNome: catalisadorPecaDados?.montadoraNome,
            modelo: catalisadorPecaDados?.modelo,
            pesoCeramicaKg: catalisadorPecaDados?.pesoKg,
            margemPeca: catalisadorPecaDados?.margemPeca || 0,
            quantidadeInicial,
            quantidade,
            valor: valorPeca,
            valorLiquido: item?.valor,
            recebimentosReprovados,
          } as Item;
        }),
        ['index'],
      );
    },
  },
});
