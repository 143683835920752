








































import requester from '@/requester';
import { rulesRequired } from '@/utils/input-rules';
import { debounce } from 'lodash';
import Vue from 'vue';
import CustomCancelarSalvar from '../custom/CustomCancelarSalvar.vue';
export default Vue.extend({
  name: 'FuncionalidadesFormCadastro',
  components: { CustomCancelarSalvar },
  data: () => ({
    loading: {
      submitForm: false,
    },
    form: {
      categoria: null,
      nome: null,
      descricao: null,
    },
    rules: {
      required: rulesRequired,
    },
  }),
  methods: {
    cancelar() {
      this.$refs.form.reset();
      this.$emit('fechar');
    },
    submitFormDebounce: debounce(async function () {
      await this.submitForm();
    }, 200),
    async submitForm() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading.submitForm = true;
      try {
        const formData = {
          categoria: this.form?.categoria?.trim(),
          nome: this.form?.nome?.trim(),
          descricao: this.form?.descricao?.trim(),
        };
        await requester.funcionalidades.criar(formData);
        this.$root.$emit('toastSuccess', `Funcionalidade criada com sucesso`);
        this.$refs.form.reset();
        this.$emit('fechar');
        this.$emit('funcionalidade-cadastrada');
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.submitForm = false;
      }
    },
  },
});
