




































import Vue from 'vue';

import requester from '@/requester';
import { debounce } from 'lodash';

import { Rules as CaslRules } from '../../../../back/src/casl/casl.constants';
import { CotacaoScopesEnum } from '@/utils/constants/back.constants';

import CotacoesInfo from './CotacoesInfo.vue';

export default Vue.extend({
  name: 'CotacoesDialogInfo',
  components: {
    CotacoesInfo,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    cotacaoId: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    loading: {
      cotacao: true,
      remover: false,
    },
    cotacao: null,
  }),
  computed: {
    permissaoRemover() {
      const rule = CaslRules.Cotacao.remover;
      return this.$ability.can(rule?.action, rule?.subject);
    },
  },
  watch: {
    cotacaoId() {
      this.buscaCotacao();
    },
  },
  methods: {
    fechar() {
      this.$emit('input', false);
    },
    async buscaCotacao() {
      if (!this.cotacaoId) {
        return;
      }
      this.loading.cotacao = true;
      try {
        const buscarCotacaoQuery = {
          scope: CotacaoScopesEnum.ComUsuarioCreatedUpdated,
        };
        this.cotacao = await requester.cotacoes.busca(
          this.cotacaoId,
          buscarCotacaoQuery,
        );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.cotacao = false;
      }
    },
    removerDebounce: debounce(async function () {
      await this.remover();
    }, 200),
    async remover() {
      const confirm = await this.$root.$confirm(
        this.$t('CotacoesDialogInfo.removerCotacao'),
        this.$t('CotacoesDialogInfo.certezaRemoverCotacao'),
      );
      if (!confirm) return;
      this.loading.remover = true;
      try {
        await requester.cotacoes.remove(this.cotacao.id);
        this.$root.$emit(
          'toastSuccess',
          this.$t('CotacoesDialogInfo.cotacaoRemovidaComSucesso'),
        );
        this.$emit('cotacao-removida');
        this.fechar();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.remover = false;
      }
    },
  },
});
