























































































































































































































































import Vue from 'vue';
import { debounce } from 'lodash';

import requester from '@/requester';

import { UsuarioDispositivo } from '../../../../back/src/models/usuario-dispositivo.model';
import { Rules as CaslRules } from '../../../../back/src/casl/casl.constants';
import {
  getIconBrowser,
  getIconDevice,
  getIconPlatform,
  getTypeDevice,
} from '@/utils/usuarios-dispositivos-helper';

export default Vue.extend({
  name: 'UsuariosDialogDispositivoInfo',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    usuarioDispositivo: {
      type: null || (Object as () => UsuarioDispositivo),
      default: null as null | UsuarioDispositivo,
    },
    hideUsuario: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: {
      autorizaDispositivo: false,
      bloqueiaDispositivo: false,
    },
    showInfo: false,
  }),
  computed: {
    permissaoAutorizarDispositivo() {
      const rule = CaslRules.Usuario.autorizarDispositivo;
      return this.$ability.can(rule?.action, rule?.subject);
    },
    permissaoBloquearDispositivo() {
      const rule = CaslRules.Usuario.autorizarDispositivo;
      return this.$ability.can(rule?.action, rule?.subject);
    },
  },
  watch: {
    value() {
      this.showInfo = false;
    },
  },
  methods: {
    getIconBrowser,
    getIconDevice,
    getIconPlatform,
    getTypeDevice,
    onInput(event: boolean) {
      this.$emit('input', event);
    },
    autorizaDispositivoDebounce: debounce(function () {
      this.autorizaDispositivo();
    }, 200),
    async autorizaDispositivo() {
      if (!this.usuarioDispositivo) return;
      this.loading.autorizaDispositivo = true;
      try {
        const dispositivo = await requester.usuarios.autorizaDispositivo(
          this.usuarioDispositivo.usuarioId,
          this.usuarioDispositivo.id,
        );
        this.$emit('dispositivo-autorizado', dispositivo);
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.autorizaDispositivo = false;
      }
    },
    bloqueiaDispositivoDebounce: debounce(function () {
      this.bloqueiaDispositivo();
    }, 200),
    async bloqueiaDispositivo() {
      this.loading.bloqueiaDispositivo = true;
      try {
        const dispositivo = await requester.usuarios.bloqueiaDispositivo(
          this.usuarioDispositivo.usuarioId,
          this.usuarioDispositivo.id,
        );
        this.$emit('dispositivo-bloqueado', dispositivo);
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.bloqueiaDispositivo = false;
      }
    },
  },
});
