






























import Vue from 'vue';
import FuncionalidadesFormCadastro from './FuncionalidadesFormCadastro.vue';
export default Vue.extend({
  components: { FuncionalidadesFormCadastro },
  name: 'FuncionalidadesDialogCadastro',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    fechar() {
      this.$emit('input', false);
    },
  },
});
