


























































































import Vue from 'vue';
import { debounce } from 'lodash';

import requester from '@/requester';

import { PedidoItem } from '../../../../back/src/models/pedido-item.model';
import { CreatePedidoItemPecaRecebimento } from '../../../../back/src/pedidos-items-pecas/dto/create-pedido-item-peca-recebimento.dto';
import { StatusPedidoItemPecaRecebimentoEnum } from '../../../../back/src/pedidos/pedidos.constants';
import { rulesRequired } from '@/utils/input-rules';
import { UpdatePedidoItemPecaRecebimento } from '../../../../back/src/pedidos-items-pecas/dto/update-pedido-item-peca-recebimento.dto';

export default Vue.extend({
  name: 'PedidosItemRecebimentoPeca',
  props: {
    pedidoItem: {
      type: Object as () => PedidoItem,
      required: true,
    },
    peca: {
      type: Object,
      required: true,
    },
    ordem: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    loading: {
      pecaRecebimento: false,
    },
    dialogDesfazerAprovacao: false,
    dialogDesfazerReprovacao: false,
    dialogJustificarReprovacao: false,
    form: {
      status: null,
      observacoes: null,
    },
    rules: {
      required: rulesRequired,
    },
  }),
  computed: {
    pedidoId() {
      return this.pedidoItem?.pedidoId || 0;
    },
    pedidoItemId() {
      return this.pedidoItem?.id || 0;
    },
    pedidoItemPecaId() {
      return this.pedidoItem?.pedidoItemPeca?.id || 0;
    },
    pedidoItemPecaRecebimentoId() {
      return this.peca?.id || 0;
    },
    itemsReprovar() {
      return [
        {
          value: StatusPedidoItemPecaRecebimentoEnum.REPROVADA_DANIFICADA,
          text: this.$t('comum.pecaDanificada'),
        },
        {
          value: StatusPedidoItemPecaRecebimentoEnum.REPROVADA_FALTANTE,
          text: this.$t('comum.pecaFaltante'),
        },
        {
          value: StatusPedidoItemPecaRecebimentoEnum.REPROVADA_INCORRETA,
          text: this.$t('comum.pecaIncorreta'),
        },
        {
          value: StatusPedidoItemPecaRecebimentoEnum.REPROVADA_OUTROS,
          text: this.$t('comum.outros'),
        },
      ];
    },
    showFormObservacoes() {
      return (
        this.form.status ===
        StatusPedidoItemPecaRecebimentoEnum.REPROVADA_OUTROS
      );
    },
    colorBg() {
      if (!this.peca?.status) return '';
      if (this.peca?.status === StatusPedidoItemPecaRecebimentoEnum.APROVADA) {
        return 'success lighten-5';
      }
      return 'error lighten-5';
    },
    pecaStatusText() {
      const item = this.itemsReprovar.find(
        (el) => el.value === this.peca?.status,
      );
      return item?.text || null;
    },
    pecaAprovada() {
      return this.peca?.status === StatusPedidoItemPecaRecebimentoEnum.APROVADA;
    },
    pecaReprovada() {
      return (
        this.peca?.status &&
        this.peca.status !== StatusPedidoItemPecaRecebimentoEnum.APROVADA
      );
    },
    colorAprovar() {
      return this.pecaAprovada ? 'success' : 'grey';
    },
    colorReprovar() {
      return this.pecaReprovada ? 'error' : 'grey';
    },
  },
  methods: {
    // CLICK APROVAR
    async onClickAprovar() {
      // se peça já estivar aprovada, remove aprovacao
      if (this.pecaAprovada) {
        this.removeRecebimentoDebounce();
        return;
      }
      // se peça já estivar reprovada, verifica se tem certeza que quer desfazer a reprovacao
      if (this.pecaReprovada) {
        this.alteraRecebimentoDebounce(true);
      }
      // se peça não tem status definido , aprova peça
      this.criaRecebimentoDebounce(true);
    },
    // CLICK REPROVAR
    async onClickReprovar() {
      // se peça já estivar aprovada, verifica se tem certeza que quer desfazer a aprovacao
      if (this.pecaAprovada) {
        const confirm = await this.$root.$confirm(
          this.$t('comum.desfazerAprovacao'),
          this.$t('PedidosItemRecebimentoPeca.certezaDesfazerAprovacao'),
        );
        if (!confirm) {
          return;
        }
      }
      // se peça já estivar reprovada, verifica se tem certeza que quer desfazer a reprovacao
      if (this.pecaReprovada) {
        const confirm = await this.$root.$confirm(
          this.$t('comum.desfazerReprovacao'),
          this.$t('PedidosItemRecebimentoPeca.certezaDesfazerReprovacao'),
        );
        if (confirm) {
          this.removeRecebimentoDebounce();
        }
        return;
      }
      // se peça não tem status definido, abre dialog para justificar reprovacao
      this.$refs?.form?.reset();
      this.dialogJustificarReprovacao = true;
    },
    // CLICK CONFIRMAR REPROVACAO
    async onClickConfirmarReprovacao() {
      if (this.pedidoItemPecaRecebimentoId) {
        this.alteraRecebimentoDebounce(false);
      }
      this.criaRecebimentoDebounce(false);
    },
    // ALTERA RECEBIMENTO
    alteraRecebimentoDebounce: debounce(function (aprovar: boolean) {
      this.alteraRecebimento(aprovar);
    }, 200),
    async alteraRecebimento(aprovar: boolean) {
      if (this.pedidoItemPecaRecebimentoId <= 0) {
        return;
      }
      if (aprovar) {
        if (this.pecaAprovada) {
          return;
        }
        const confirm = await this.$root.$confirm(
          this.$t('comum.desfazerReprovacao'),
          this.$t('PedidosItemRecebimentoPeca.certezaDesfazerReprovacao'),
        );
        if (!confirm) {
          return;
        }
      } else {
        if (!this.$refs.form.validate()) {
          return;
        }
      }
      this.loading.pecaRecebimento = true;
      const dto: UpdatePedidoItemPecaRecebimento = {
        status: aprovar
          ? StatusPedidoItemPecaRecebimentoEnum.APROVADA
          : this.form.status,
        observacoes: aprovar ? null : this.form.observacoes?.trim() || null,
      };
      this.loading.pecaRecebimento = true;
      try {
        const recebimento =
          await requester.pedidosItemsPecas.alteraPedidoItemPecaRecebimento(
            this.pedidoItemPecaId,
            this.pedidoItemPecaRecebimentoId,
            dto,
          );
        this.dialogJustificarReprovacao = false;
        this.$refs?.form?.reset();
        this.$emit('recebimento-alterado', recebimento);
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.pecaRecebimento = false;
      }
    },
    // CRIA RECEBIMENTO
    criaRecebimentoDebounce: debounce(function (aprovar: boolean) {
      this.criaRecebimento(aprovar);
    }, 200),
    async criaRecebimento(aprovar: boolean) {
      if (!aprovar && !this.$refs.form.validate()) {
        return;
      }
      if (this.pedidoItemPecaRecebimentoId > 0) {
        return;
      }
      this.loading.pecaRecebimento = true;
      const dto: CreatePedidoItemPecaRecebimento = {
        status: aprovar
          ? StatusPedidoItemPecaRecebimentoEnum.APROVADA
          : this.form.status,
        observacoes: aprovar ? null : this.form.observacoes?.trim() || null,
      };
      try {
        const recebimento =
          await requester.pedidosItemsPecas.criaPedidoItemPecaRecebimento(
            this.pedidoItemPecaId,
            dto,
          );
        this.dialogJustificarReprovacao = false;
        this.$refs?.form?.reset();
        this.$emit('recebimento-criado', recebimento);
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.pecaRecebimento = false;
      }
    },
    // REMOVE RECEBIMENTO
    removeRecebimentoDebounce: debounce(function () {
      this.removeRecebimento();
    }, 200),
    async removeRecebimento() {
      if (this.pedidoItemPecaRecebimentoId <= 0) {
        return;
      }
      this.loading.pecaRecebimento = true;
      try {
        await requester.pedidosItemsPecas.removePedidoItemPecaRecebimento(
          this.pedidoItemPecaId,
          this.pedidoItemPecaRecebimentoId,
        );
        this.$emit('recebimento-removido', this.peca);
        this.dialogDesfazerAprovacao = false;
        this.dialogDesfazerReprovacao = false;
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.pecaRecebimento = false;
      }
    },
  },
});
