







import Vue from 'vue';

import requester from '@/requester';
import { LojaScopesEnum } from '@/utils/constants/back.constants';

import LojasForm from '@/components/lojas/LojasForm.vue';

export default Vue.extend({
  name: 'LojasEdicao',
  components: {
    LojasForm,
  },
  data: () => ({
    loading: {
      loja: true,
    },
    loja: null,
  }),
  computed: {
    lojaId() {
      return this.$route?.params?.lojaId || 0;
    },
  },
  mounted() {
    this.buscaLoja();
  },
  methods: {
    async buscaLoja() {
      this.loading.loja = true;
      try {
        const queryObject = {
          scope: LojaScopesEnum.ComUsuariosUsuarioCreatedUpdatedMargens,
        };
        this.loja = await requester.lojas.busca(this.lojaId, queryObject);
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.loja = false;
      }
    },
  },
});
