























































































import Vue from 'vue';
export default Vue.extend({
  name: 'CatalisadoresPecasImportarTabelaNovas',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
});
