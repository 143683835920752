














































import Vue from 'vue';
import { DateTime } from 'luxon';
import { rulesRequired } from '@/utils/input-rules';

export default Vue.extend({
  name: 'InputData',
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    max: {
      type: String,
      default: '',
    },
    min: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    menuNudgeRight: {
      type: Number,
      default: 40,
    },
    menuNudgeLeft: {
      type: Number,
      default: 0,
    },
    menuBottom: {
      type: Boolean,
      default: true,
    },
    menuLeft: {
      type: Boolean,
      default: false,
    },
    menuRight: {
      type: Boolean,
      default: false,
    },
    menuTop: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    menu: false,
    rules: {
      required: rulesRequired,
    },
  }),
  computed: {
    dataFormatada() {
      if (!this.value) return '';
      const date = DateTime.fromISO(this.value);
      if (date.isValid) {
        return date.toFormat('dd/MM/yyyy');
      }
      return '';
    },
  },
  methods: {
    isValid(value) {
      return DateTime?.fromISO(value)?.isValid;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    inputDatePicker(event: any) {
      this.menu = false;
      this.$emit('input', event);
    },
    focus() {
      this.$refs.inputDataFormatada.focus();
      this.menu = true;
    },
  },
});
