var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"items":_vm.usuarios,"headers":_vm.headers,"search":_vm.search,"loading":_vm.loading,"item-key":"id","sort-by":"nomeCompleto","must-sort":"","disable-pagination":_vm.disablePagination,"hide-default-footer":_vm.hideDefaultFooter},scopedSlots:_vm._u([{key:"item.nomeCompleto",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none font-weight-medium text--primary",attrs:{"to":{ name: 'UsuariosVer', params: { usuarioId: item.id } }}},[_vm._v(" "+_vm._s(item.nomeCompleto)+" ")])]}},{key:"item.celular",fn:function(ref){
var item = ref.item;
return [(item.celular)?_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm._f("formatPhone")(item.celular))+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.categoria",fn:function(ref){
var item = ref.item;
return [(item.categoria)?_c('span',{class:'text-no-wrap'},[_vm._v(" "+_vm._s(_vm.$t(("CategoriaUsuarioEnum." + (item.categoria))))+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.responsavel.nomeCompleto",fn:function(ref){
var item = ref.item;
return [(item.responsavel)?_c('span',[_vm._v(" "+_vm._s(item.responsavel.nomeCompleto)+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.dispositivos",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(_vm.countDispositivos(item.dispositivos, true) > 0)?[_c('v-chip',{staticClass:"mr-1",attrs:{"color":"success lighten-1","outlined":"","small":""}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',{attrs:{"color":"success lighten-1","small":""}},[_vm._v(" mdi-shield-lock-open-outline ")])],1),_c('span',{staticClass:"\n                success--text\n                text-lighten-1 text-body-2\n                font-weight-medium\n              ",domProps:{"textContent":_vm._s(_vm.countDispositivos(item.dispositivos, true))}})],1)]:[_c('v-chip',{staticClass:"mr-1",attrs:{"color":"grey lighten-1","outlined":"","small":""}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',{attrs:{"color":"grey lighten-1","small":""}},[_vm._v(" mdi-shield-lock-open-outline ")])],1),_c('span',{staticClass:"grey--text text-lighten-1 text-body-2",domProps:{"textContent":_vm._s(0)}})],1)],(_vm.countDispositivos(item.dispositivos, false) > 0)?[_c('v-chip',{staticClass:"ml-1",attrs:{"color":"error lighten-1","outlined":"","small":""}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',{attrs:{"color":"error lighten-1","small":""}},[_vm._v("mdi-shield-lock")])],1),_c('span',{staticClass:"\n                error--text\n                text-lighten-1 text-body-2\n                font-weight-medium\n              ",domProps:{"textContent":_vm._s(_vm.countDispositivos(item.dispositivos, false))}})],1)]:[_c('v-chip',{staticClass:"ml-1",attrs:{"color":"grey lighten-1","outlined":"","small":""}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',{attrs:{"color":"grey lighten-1","small":""}},[_vm._v("mdi-shield-lock")])],1),_c('span',{staticClass:"grey--text text-lighten-1 text-body-2",domProps:{"textContent":_vm._s(0)}})],1)]],2)]}},{key:"item.ativo",fn:function(ref){
var item = ref.item;
return [(!item.senhaCadastrada && !item.ultimoEnvioEmailAtivacao)?_c('span',{staticClass:"text--disabled"},[_vm._v(" "+_vm._s(_vm.$t('comum.conviteNaoEnviado'))+" ")]):(!item.senhaCadastrada)?_c('span',{staticClass:"secondary--text text--lighten-2"},[_vm._v(" "+_vm._s(_vm.$t('comum.aguardandoCadastroSenha'))+" ")]):(item.ativo)?_c('span',{staticClass:"font-weight-medium success--text"},[_vm._v(" "+_vm._s(_vm.$t('comum.sim'))+" ")]):_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.$t('comum.nao')))])]}},{key:"item.detalhes",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"info","to":{ name: 'UsuariosVer', params: { usuarioId: item.id } },"exact":""}},[_c('v-icon',[_vm._v("mdi-information")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }