





















import { findLastIndex } from 'lodash';
import Vue from 'vue';
export default Vue.extend({
  name: 'InputNumber',
  props: {
    value: {
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    suffix: {
      type: String,
      default: '',
    },
    separator: {
      type: String,
      default: ',',
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    integerOnly: {
      type: Boolean,
      default: false,
    },
    allowZero: {
      type: Boolean,
      default: false,
    },
    allowPositive: {
      type: Boolean,
      default: true,
    },
    allowNegative: {
      type: Boolean,
      default: false,
    },
    customStyle: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    formValue: '',
  }),
  computed: {
    rules() {
      const rules = [];
      if (this.required) {
        rules.push(
          () =>
            (this.formValue !== null && this.formValue !== undefined) ||
            this.$t('rules.campoObrigatorio'),
        );
      }
      const value = Number(
        String(this.formValue || '')?.replace(this.separator, '.'),
      );
      rules.push(() => !Number.isNaN(value) || this.$t('rules.numerico'));
      if (this.integerOnly) {
        rules.push(
          () => Number.isInteger(value) || this.$t('rules.numericoInteiro'),
        );
      }
      if (this.allowZero) {
        if (this.allowPositive && !this.allowNegative) {
          rules.push(
            () => value >= 0 || this.$t('rules.numericoPositivoOuZero'),
          );
        } else if (!this.allowPositive && this.allowNegative) {
          rules.push(
            () => value <= 0 || this.$t('rules.numericoNegativoOuZero'),
          );
        } else {
          () => value === 0 || this.$t('rules.numericoZero');
        }
      } else {
        if (this.allowPositive && !this.allowNegative) {
          rules.push(() => value > 0 || this.$t('rules.numericoPositivo'));
        } else if (!this.allowPositive && this.allowNegative) {
          rules.push(() => value < 0 || this.$t('rules.numericoNegativo'));
        } else {
          () => this.$t('rules.numericoIncorreto');
        }
      }
      return rules;
    },
  },
  watch: {
    value() {
      this.formValue = String(this.value ?? '')?.replace('.', this.separator);
    },
    formValue() {
      if ([null, undefined, ''].includes(this.formValue)) {
        this.$emit('input', this.formValue);
        return;
      }
      this.$emit(
        'input',
        Number(String(this.formValue || '')?.replace(this.separator, '.')),
      );
    },
  },
  mounted() {
    this.formValue = String(this.value ?? '')?.replace('.', this.separator);
  },
  methods: {
    onPaste(e) {
      this.onKeyUp(e.clipboardData.getData('text'));
    },
    onKeyUp(formNumber) {
      const value = String(formNumber || '');
      const preNumber = value.replace(/[^\d,.-]/g, '');
      const index = findLastIndex(
        preNumber,
        (char: string) => char === '.' || char === this.separator,
      );
      const number = value.replace(/[^\d-]/g, '');
      const diff = preNumber.length - index;
      this.formValue =
        index < 0
          ? number
          : `${number.substring(
              0,
              number.length - diff + 1,
            )},${number.substring(number.length - diff + 1, number.length)}`;
    },
  },
});
