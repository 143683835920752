

















































import Vue from 'vue';

import requester from '@/requester';
import { debounce } from 'lodash';

import { Permissao } from '../../../../back/src/models/permissao.model';

import CustomVColInfo from '../../../../front_lims/src/components/CustomVColInfo.vue';
export default Vue.extend({
  name: 'PermissoesDialogInfo',
  components: { CustomVColInfo },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    permissaoId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    permissao: {} as Permissao,
    loading: {
      remover: false,
    },
  }),
  mounted() {
    this.buscaPermissao();
  },
  methods: {
    fechar() {
      this.$emit('input', false);
    },
    async buscaPermissao() {
      try {
        this.permissao = await requester.permissoes.buscar(this.permissaoId);
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    removerDebounce: debounce(async function () {
      await this.remover();
    }, 200),
    async remover() {
      const confirm = await this.$root.$confirm(
        'Remover permissão',
        'Tem certeza que deseja remover esta permissão?',
      );
      if (!confirm) return;
      this.loading.remover = true;
      try {
        await requester.permissoes.remove(this.permissao.id);
        this.$root.$emit('toastSuccess', 'Permissão removida com sucesso.');
        this.fechar();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.remover = false;
      }
    },
  },
});
