import Vue from 'vue';
import { DateTime } from 'luxon';

Vue.filter('formatDateTime', (value: string, filterFormat: string) => {
  return (
    DateTime?.fromISO(value)
      ?.toLocal()
      ?.toFormat(filterFormat || 'dd/MM/yyyy - HH:mm') || ''
  );
});
