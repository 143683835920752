




















































































import Vue from 'vue';
import { uniq } from 'lodash';

import requester from '@/requester';

import { Funcionalidade } from '../../../../back/src/models/funcionalidade.model';

import FuncionalidadesDialogCadastro from '@/components/funcionalidades/FuncionalidadesDialogCadastro.vue';
import FuncionalidadesDialogInfo from '@/components/funcionalidades/FuncionalidadesDialogInfo.vue';

export default Vue.extend({
  name: 'FuncionalidadesIndex',
  components: {
    FuncionalidadesDialogCadastro,
    FuncionalidadesDialogInfo,
  },
  data: () => ({
    loading: {
      funcionalidades: false,
    },
    funcionalidades: [] as Funcionalidade[],
    search: '',
    dialogCadastro: false,
    dialogInfo: false,
    funcionalidadeId: null,
    filterCategoria: null,
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Categoria',
          value: 'categoria',
          class: 'text-no-wrap',
          cellClass: 'text-no-wrap',
          width: 1,
        },
        {
          text: 'Nome',
          value: 'nome',
          class: 'text-no-wrap',
          groupable: false,
        },
        {
          text: 'Descrição',
          value: 'descricao',
          class: 'text-no-wrap',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Detalhes',
          value: 'detalhes',
          class: 'text-no-wrap',
          sortable: false,
          groupable: false,
          align: 'center',
          width: 1,
        },
      ];
    },
    funcionalidadesFiltered() {
      if (!this.filterCategoria) return this.funcionalidades;
      return this.funcionalidades.filter(
        (el) => el.categoria === this.filterCategoria,
      );
    },
    itemsCategoria() {
      return uniq(this.funcionalidades.map((el) => el.categoria)).sort();
    },
  },
  mounted() {
    this.listarFuncionalidades();
  },
  methods: {
    async listarFuncionalidades() {
      this.loading.funcionalidades = true;
      try {
        this.funcionalidades = await requester.funcionalidades.listar();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.funcionalidades = false;
      }
    },
    openDialogInfo(id: number) {
      this.funcionalidadeId = id;
      this.dialogInfo = true;
    },
  },
});
