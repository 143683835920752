












































































































































































import Vue from 'vue';

interface CotacoesConfig {
  converterMoeda: boolean;
  converterPeso: boolean;
  teorPt: number;
  teorPd: number;
  teorRh: number;
}

export default Vue.extend({
  name: 'CotacoesConfigIndex',
  props: {
    config: {
      type: Object as () => CotacoesConfig,
      required: true,
    },
  },
  data: () => ({
    menu: false,
    converterMoeda: false,
    converterPeso: false,
    teorPt: 250,
    teorPd: 1000,
    teorRh: 130,
  }),
  methods: {
    cancelar() {
      this.menu = false;
      this.converterMoeda = this.config?.converterMoeda || false;
      this.converterPeso = this.config?.converterPeso || false;
      this.teorPt = this.config?.teorPt || 250;
      this.teorPd = this.config?.teorPd || 1000;
      this.teorRh = this.config?.teorRh || 130;
    },
    aplicar() {
      this.$emit('aplicar', {
        converterMoeda: this.converterMoeda,
        converterPeso: this.converterPeso,
        teorPt: this.teorPt,
        teorPd: this.teorPd,
        teorRh: this.teorRh,
      });
      this.menu = false;
    },
  },
});
