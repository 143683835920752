




























































import Vue from 'vue';
import XLSX from 'xlsx';

export default Vue.extend({
  name: 'CatalisadoresPecasImportarStep1',
  data: () => ({
    dragFileType: null as string | null,
    acceptedTypes: [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
      'text/csv',
    ],
  }),
  methods: {
    clickInputFile() {
      this.$refs?.fileInput?.click();
    },
    onDragOver(event: DragEvent) {
      event.preventDefault();
      this.dragFileType = event?.dataTransfer?.items[0].type || null;
    },
    onDragLeave(event: DragEvent) {
      event.preventDefault();
      this.dragFileType = null;
    },
    onDrop(event: DragEvent) {
      event.preventDefault();
      this.dragFileType = null;
      const file = event?.dataTransfer?.files[0] || null;
      this.loadWorkbook(file);
    },
    onChangeFile(event: Event) {
      const file = (event.target as HTMLInputElement).files?.[0] || null;
      this.loadWorkbook(file);
    },
    loadWorkbook(file: File | null) {
      if (!file || !this.acceptedTypes.includes(file.type)) {
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e?.target?.result as ArrayBufferLike);
        const workbook = XLSX.read(data, { type: 'array' });
        this.$emit('next-step', { file, workbook });
      };
      reader.readAsArrayBuffer(file);
    },
  },
});
