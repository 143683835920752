















































import Vue from 'vue';

import requester from '@/requester';

import { UsuarioScopesEnum } from '../../../../back/src/usuarios/usuarios.constants';
import { Rules as CaslRules } from '../../../../back/src/casl/casl.constants';

import UsuariosTabFuncionarios from '@/components/usuarios/UsuariosTabFuncionarios.vue';
import UsuariosTabParceiros from '@/components/usuarios/UsuariosTabParceiros.vue';
import UsuariosTabDispositivos from '@/components/usuarios/UsuariosTabDispositivos.vue';

export default Vue.extend({
  name: 'UsuariosIndex',
  components: {
    UsuariosTabFuncionarios,
    UsuariosTabParceiros,
    UsuariosTabDispositivos,
  },
  data: () => ({
    loading: {
      grupos: true,
      usuarios: true,
    },
    tab: 0,
    grupos: [],
    usuarios: [],
  }),
  computed: {
    queryTab() {
      return this.$route?.query?.tab || '';
    },
    permissaoListarDispositivos() {
      const rule = CaslRules.Usuario.listarDispositivos;
      return this.$ability.can(rule?.action, rule?.subject);
    },
  },
  mounted() {
    switch (this.queryTab) {
      case 'parceiros':
        this.tab = 1;
        break;
      case 'dispositivos':
        this.tab = 2;
        break;
      default:
        this.tab = 0;
        break;
    }
    this.listaGrupos();
    this.listaUsuarios();
  },
  methods: {
    async listaGrupos() {
      this.loading.grupos = true;
      try {
        this.grupos = await requester.grupos.lista();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.grupos = false;
      }
    },
    async listaUsuarios() {
      this.loading.usuarios = true;
      try {
        const queryObject = {
          scope: UsuarioScopesEnum.ComGrupoResponsavelDispositivos,
        };
        this.usuarios = await requester.usuarios.lista(queryObject);
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.usuarios = false;
      }
    },
  },
});
