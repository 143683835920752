/**
 * Recebe as margens do responsável e do preposto e devolve a margem composta do preposto. A margem calculada
 * é um número entre 0 e 1. Esta função assume que nenhuma porcentagem é maior que 1 (i.e. 100%).
 * @param {number} margemResponsavel Margem do Responsável pelo preposto. Número deve ser um real entre 0 e 1
 * @param {number} margemPreposto Margem do preposto. Número deve ser um real entre 0 e 1
 * @return {number | undefined}: Margem composta calculada. Se um dos argumentos não for um número, undefined é
 *  devolvido.
 */
export function calculaMargemComposta(
  margemResponsavel: number,
  margemPreposto: number,
): number | undefined {
  if (!Number.isFinite(margemResponsavel) || !Number.isFinite(margemPreposto)) {
    return undefined;
  }
  return 1 - (1 - margemResponsavel) * (1 - margemPreposto);
}
