
























































































import Vue from 'vue';

import requester from '@/requester';
import { autocompleteFilterDeburr } from '@/utils/filter-functions';
import { debounce, deburr, toLower } from 'lodash';
import { rulesCep, rulesRequired } from '@/utils/input-rules';

export default Vue.extend({
  name: 'EnderecosFormBrasil',
  props: {
    endereco: {
      type: Object || null,
      default: null,
    },
  },
  data: () => ({
    loading: {
      cep: false,
      municipios: false,
      ufs: false,
    },
    infoCep: {},
    municipios: {},
    ufs: [],
    form: {
      codigoPostal: null,
      logradouro: null,
      numero: null,
      complemento: null,
      bairro: null,
      municipioCodigoIbge: null,
      ufSigla: null,
      latitude: null,
      longitude: null,
    },
    rules: {
      required: rulesRequired,
      cep: [...rulesRequired, ...rulesCep],
    },
  }),
  computed: {
    errorCep() {
      const info = this.infoCep?.[this.form.codigoPostal];
      return !!info && !!info?.error;
    },
    hintCep() {
      return this.errorCep
        ? this.$t('EnderecosFormBrasil.cepNaoEncontrado')
        : '';
    },
    itemsUfs() {
      return this.ufs.map((el) => ({
        value: el?.sigla,
        text: el?.sigla,
      }));
    },
    itemsPaises() {
      return this.paises.map((el) => ({
        value: el?.id?.['ISO-ALPHA-3'],
        text: el?.nome,
      }));
    },
  },
  async mounted() {
    await this.buscaUfs();
    this.preencheForm();
  },
  methods: {
    preencheForm() {
      if (!this.endereco) return;
      this.form.codigoPostal = this.endereco?.codigoPostal || null;
      this.form.logradouro = this.endereco?.logradouro || null;
      this.form.numero = this.endereco?.numero || null;
      this.form.complemento = this.endereco?.complemento || null;
      this.form.bairro = this.endereco?.bairro || null;
      this.form.municipioCodigoIbge = this.endereco?.municipioCodigoIbge
        ? Number(this.endereco?.municipioCodigoIbge)
        : null;
      this.form.ufSigla = this.endereco?.ufSigla || null;
      this.buscaMunicipios(this.form.ufSigla);
      this.form.latitude = this.endereco?.latitude || null;
      this.form.longitude = this.endereco?.longitude || null;
    },
    validate() {
      return this.$refs?.form?.validate();
    },
    getFormData() {
      return {
        ...(this.endereco ? { id: this.endereco.id } : {}),
        codigoPostal: this.form?.codigoPostal?.trim() || null,
        logradouro: this.form?.logradouro?.trim() || null,
        numero: this.form?.numero?.trim() || null,
        complemento: this.form?.complemento?.trim() || null,
        bairro: this.form?.bairro?.trim() || null,
        municipioCodigoIbge: this.form?.municipioCodigoIbge?.toString() || null,
        municipioNome:
          this.municipios?.[this.form?.ufSigla]
            ?.find((el) => el.id === this.form?.municipioCodigoIbge)
            ?.nome?.trim() || null,
        ufSigla: this.form?.ufSigla || null,
        ufNome:
          this.ufs
            ?.find((el) => el.sigla === this.form?.ufSigla)
            ?.nome?.trim() || null,
        paisIsoAlpha3: 'BRA',
        paisNome: 'Brasil',
        latitude: Number(this.form?.latitude) || null,
        longitude: Number(this.form?.longitude) || null,
      };
    },
    autocompleteFilterDeburr,
    async applyCep(info) {
      this.form.logradouro = info?.street || null;
      this.form.bairro = info?.neighborhood || null;
      this.form.ufSigla = info?.state || null;
      if (this.form.ufSigla) {
        this.form.municipioNome = info?.city || null;
        const municipioDeburr = deburr(toLower(this.form.municipioNome || ''))
          .replace(/[^A-Za-z]+/g, '')
          .trim();
        await this.buscaMunicipios(this.form.ufSigla);
        this.form.municipioCodigoIbge =
          this.municipios[this.form.ufSigla].find(
            (el) =>
              deburr(toLower(el.nome || ''))
                .replace(/[^A-Za-z]+/g, '')
                .trim() === municipioDeburr,
          )?.id || null;
      }
      this.form.latitude = info?.location?.coordinates?.latitude || null;
      this.form.longitude = info?.location?.coordinates?.longitude || null;
      this.$refs?.form?.resetValidation();
    },
    buscaCepDebounce: debounce(async function (cep: string) {
      await this.buscaCep(cep);
    }, 500),
    async buscaCep(cep: string) {
      const cleanCep = cep?.replace(/\D*/g, '')?.trim() || '';
      if (cleanCep.length !== 8) {
        this.applyCep(null);
        return;
      }
      if (this.infoCep?.[cep]) {
        this.applyCep(this.infoCep[cep]);
        return;
      }
      this.loading.cep = true;
      try {
        this.$set(
          this.infoCep,
          cep,
          await requester.externo.brasilApi.cep(cleanCep),
        );
      } catch (err) {
        this.$set(this.infoCep, cep, {
          error: { message: err?.message, status: err?.status },
        });
      } finally {
        await this.applyCep(this.infoCep[cep]);
        this.loading.cep = false;
      }
    },
    async buscaMunicipios(ufSigla: string) {
      if (this.municipios?.[ufSigla]) return;
      this.loading.municipios = true;
      try {
        this.$set(
          this.municipios,
          ufSigla,
          await requester.externo.ibge.municipios(ufSigla),
        );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.municipios = false;
      }
    },
    async buscaUfs() {
      this.loading.ufs = true;
      try {
        this.ufs = await requester.externo.ibge.ufs();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.ufs = false;
      }
    },
  },
});
