export enum HeaderItemFilterType {
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  BOOLEAN = 'BOOLEAN',
  DATE_MIN_MAX = 'DATE_MIN_MAX',
  NUMBER_MIN_MAX = 'NUMBER_MIN_MAX',
  PERCENT_MIN_MAX = 'PERCENT_MIN_MAX',
  SEARCH = 'SEARCH',
}

export interface HeaderItem {
  value: string;
  text: string;
  unit?: string;
  align?: string;
  class?: string;
  show: boolean;
  draggable: boolean;
  menu: boolean;
  sortable: boolean;
  sortDesc: boolean;
  filterable: boolean;
  filterItems?: { value: number; text: string }[];
  filterType: HeaderItemFilterType | null;
  filterValue:
    | { min: string | number | null; max: string | number | null }
    | string
    | number
    | null;
}
