










































































































































































































































































































































































































































































import Vue from 'vue';

import { HeaderItem, HeaderItemFilterType } from '@/utils/types/custom.types';

import InputData from '@/components/inputs/InputData.vue';

export default Vue.extend({
  name: 'CatalisadoresPecasMenuHeader',
  components: {
    InputData,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    header: {
      type: Object as () => HeaderItem,
      required: true,
    },
    items: {
      type: Array as () => { value: number; text: string }[],
      default: [],
    },
  },
  data: () => ({
    filterSearch: null,
    filterBoolean: null,
    filterAutocomplete: null,
    filterMin: null,
    filterMax: null,
    HeaderItemFilterType,
  }),
  methods: {
    getItemText(itemValue: number) {
      return this.items.find((el) => el.value === itemValue)?.text || '';
    },
  },
});
