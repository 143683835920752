






















































import Vue from 'vue';

import requester from '@/requester';

import GruposDialogCadastro from '@/components/grupos/GruposDialogCadastro.vue';
import { Rules as CaslRules } from '../../../../back/src/casl/casl.constants';

export default Vue.extend({
  name: 'GruposIndex',
  components: {
    GruposDialogCadastro,
  },
  data: () => ({
    loading: {
      grupos: true,
    },
    search: '',
    dialogCadastro: false,
    grupos: [],
  }),
  computed: {
    headers() {
      return [
        {
          value: 'nome',
          text: this.$t('comum.nome'),
          class: 'text-no-wrap',
          filterable: true,
          sortable: true,
        },
        ...(this.permissaoVerGrupo && this.permissaoListarUsuariosGrupo
          ? [
              {
                value: 'info',
                text: this.$t('comum.info'),
                class: 'text-no-wrap',
                filterable: false,
                sortable: false,
                align: 'center',
                width: 1,
              },
            ]
          : []),
      ];
    },
    permissaoCriar() {
      const rule = CaslRules.Usuario.criar;
      return this.$ability.can(rule?.action, rule?.subject);
    },
    permissaoVerGrupo() {
      const rule = CaslRules.Grupo.buscar;
      return this.$ability.can(rule?.action, rule?.subject);
    },
    permissaoListarUsuariosGrupo() {
      const rule = CaslRules.Grupo.listarUsuarios;
      return this.$ability.can(rule?.action, rule?.subject);
    },
  },
  mounted() {
    this.listaGrupos();
  },
  methods: {
    grupoCriado(grupo) {
      this.grupos.push(grupo);
    },
    async listaGrupos() {
      this.loading.grupos = true;
      try {
        this.grupos = await requester.grupos.lista();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.grupos = false;
      }
    },
  },
});
