
































































































import Vue from 'vue';
import { debounce } from 'lodash';

import requester from '@/requester';
import { rulesRequired } from '@/utils/input-rules';

import { CategoriaUsuarioEnum } from '../../../../back/src/usuarios/usuarios.constants';
import { LoginAuthDto } from '../../../../back/src/auth/dto/login-auth.dto';

export default Vue.extend({
  name: 'AuthLogin',
  data: () => ({
    mostrarSenha: false,
    form: {
      email: '',
      senha: '',
    },
    rules: {
      required: rulesRequired,
    },
  }),
  computed: {
    redirect() {
      const redirect = this.$route.query?.redirect;
      if (redirect === this.$route.path) return '/';
      return redirect || '/';
    },
    urlLims() {
      return process.env.VUE_APP_URL_LIMS;
    },
    urlCatalogo() {
      return process.env.VUE_APP_URL_CATALOGO;
    },
    showLims() {
      return process.env.VUE_APP_SHOW_LIMS === 'true';
    },
    appendIconSenha() {
      return this.mostrarSenha ? 'mdi-eye-off' : 'mdi-eye';
    },
  },
  methods: {
    onKeypressEnterInputEmail() {
      if (this.form.senha) {
        this.submitFormDebounce();
      } else {
        this.$refs?.inputSenha?.focus();
      }
    },
    submitFormDebounce: debounce(async function () {
      await this.submitForm();
    }, 200),
    async submitForm() {
      if (!this.$refs?.formAuthLogin?.validate()) {
        return;
      }
      const dto: LoginAuthDto = {
        email: this.form.email?.trim(),
        senha: this.form.senha,
      };
      try {
        const usuario = await requester.auth.login(dto);
        if (usuario.categoria !== CategoriaUsuarioEnum.FUNCIONARIO) {
          throw new Error(this.$t('comum.voceNaoTemPermissao'));
        }
        this.$ability.update(usuario.permissoes);
        this.$store.dispatch('login', usuario);
        this.$router.push(this.redirect);
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
  },
});
