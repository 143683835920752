






























































































































import Vue from 'vue';

import requester from '@/requester';

import { Rules as CaslRules } from '../../../../back/src/casl/casl.constants';
import { GrupoScopesEnum } from '@/utils/constants/back.constants';

import GruposDialogEdicao from '@/components/grupos/GruposDialogEdicao.vue';
import UsuariosTabela from '@/components/usuarios/UsuariosTabela.vue';

export default Vue.extend({
  name: 'GruposVer',
  components: {
    GruposDialogEdicao,
    UsuariosTabela,
  },
  data: () => ({
    loading: {
      grupo: true,
      usuarios: true,
    },
    dialogEdicao: false,
    grupo: null,
    usuarios: [],
  }),
  computed: {
    grupoId() {
      return this.$route?.params?.grupoId || 0;
    },
    loadingAll() {
      return this.loading.grupo && this.loading.usuarios;
    },
    permissaoAlterar() {
      const rule = CaslRules.Grupo.atualizar;
      return this.$ability.can(rule?.action, rule?.subject);
    },
    permissaoRemover() {
      const rule = CaslRules.Grupo.remover;
      return this.$ability.can(rule?.action, rule?.subject);
    },
  },
  mounted() {
    this.buscaGrupo();
    this.listaUsuariosGrupo();
  },
  methods: {
    grupoAlterado() {
      this.buscaGrupo();
    },
    async remover() {
      const confirm = await this.$root.$confirm(
        this.$t('comum.removerGrupo'),
        this.$t('comum.certezaRemoverGrupo'),
      );
      if (!confirm) return;
      try {
        await requester.grupos.remove(this.grupo.id);
        this.$router.push({ name: 'GruposIndex' });
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.grupo = false;
      }
    },
    async buscaGrupo() {
      this.loading.grupo = true;
      try {
        const queryObject = {
          scope: GrupoScopesEnum.ComUsuarioCreatedUpdated,
        };
        this.grupo = await requester.grupos.busca(this.grupoId, queryObject);
      } catch (err) {
        this.$root.$errorHandler(err, true);
      } finally {
        this.loading.grupo = false;
      }
    },
    async listaUsuariosGrupo() {
      this.loading.usuarios = true;
      try {
        this.usuarios = await requester.grupos.listaUsuarios(this.grupoId);
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.usuarios = false;
      }
    },
  },
});
