import Vue from 'vue';
import { maximumFractionDigitsCurrency } from '@/utils/intl-functions';

/**
 * Filtro para formatar preco para pais
 * @param {number} value Valor numérico
 * @param {Pais} pais País do banco
 * @returns {string} Valor formatado
 */
const filterFormatCurrency = (value: number, pais?: any) => {
  const currency = pais?.currency || 'USD';
  return new Intl.NumberFormat(pais?.locale || 'en-US', {
    currency,
    style: 'currency',
    minimumFractionDigits: maximumFractionDigitsCurrency(currency),
    maximumFractionDigits: maximumFractionDigitsCurrency(currency),
  }).format(value || 0);
};

Vue.filter('formatCurrency', filterFormatCurrency);
