import { DeviceUUID } from 'device-uuid';

import {
  geraDispositivoUuid,
  getIdentificacaoFromSource,
} from '../../../back/src/utils/usuarios-dispositivos-helper';

interface DispositivoInfo {
  browser?: string;
  platform?: string;
  isDesktop?: boolean;
  isMobile?: boolean;
  isTablet?: boolean;
}

export const itemsBrowser = [
  'Chrome',
  'Chromium',
  'Edge',
  'Firefox',
  'Opera',
  'Safari',
  'Outro',
];

const iconBrowser = {
  Chrome: 'mdi-google-chrome',
  Chromium: 'mdi-google-chrome',
  Edge: 'mdi-microsoft-edge',
  Firefox: 'mdi-firefox',
  Opera: 'mdi-opera',
  Safari: 'mdi-apple-safari',
};

export const getIconBrowser = (dispositivoInfo: DispositivoInfo): string => {
  return iconBrowser?.[dispositivoInfo?.browser] || 'mdi-application-outline';
};

export const itemsPlatform = [
  'Android',
  'Apple Mac',
  'iPhone',
  'Linux',
  'Microsoft Windows',
  'Outra',
];

const iconPlatform = {
  Android: 'mdi-android',
  'Apple Mac': 'mdi-apple',
  iPhone: 'mdi-apple-ios',
  Linux: 'mdi-linux',
  'Microsoft Windows': 'mdi-microsoft-windows',
};

export const getIconPlatform = (dispositivoInfo: DispositivoInfo): string => {
  return iconPlatform?.[dispositivoInfo?.platform] || 'mdi-web';
};

export const itemsDevice = ['Desktop', 'Celular', 'Tablet', 'Outros'];

export const getTypeDevice = (dispositivoInfo: DispositivoInfo): string => {
  if (dispositivoInfo?.isTablet) return 'Tablet';
  if (dispositivoInfo?.isMobile) return 'Celular';
  if (dispositivoInfo?.isDesktop) return 'Desktop';
  return 'Outros';
};

export const getIconDevice = (dispositivoInfo: DispositivoInfo): string => {
  if (dispositivoInfo?.isTablet) return 'mdi-tablet';
  if (dispositivoInfo?.isMobile) return 'mdi-cellphone';
  if (dispositivoInfo?.isDesktop) return 'mdi-monitor';
  return 'mdi-devices';
};

export const getDispositivoUuidInfo = (): {
  dispositivoUuid: string;
  dispositivoIdentificacao: string;
  dispositivoInfo: unknown;
} => {
  const du = new DeviceUUID().parse();
  const identificacao = getIdentificacaoFromSource(du.source);
  const uuid = geraDispositivoUuid(du);

  return {
    dispositivoUuid: uuid,
    dispositivoIdentificacao: identificacao,
    dispositivoInfo: du,
  };
};
