import { RouteConfig } from 'vue-router';

import { Rules as CaslRules } from '../../../back/src/casl/casl.constants';
import CatalisadoresPecasImportar from '@/pages/catalisadores-pecas/CatalisadoresPecasImportar.vue';
import CatalisadoresPecasIndex from '@/pages/catalisadores-pecas/CatalisadoresPecasIndex.vue';

const routes: RouteConfig[] = [
  {
    path: '/catalisadores_pecas',
    name: 'CatalisadoresPecasIndex',
    component: CatalisadoresPecasIndex,
    meta: {
      icon: 'mdi-car-back',
      rules: [CaslRules.CatalisadorPeca.listar],
    },
  },
  {
    path: '/catalisadores_pecas/importar',
    name: 'CatalisadoresPecasImportar',
    component: CatalisadoresPecasImportar,
    meta: {
      icon: 'mdi-file-upload',
      rules: [CaslRules.CatalisadorPeca.criar],
    },
  },
];

export default routes;
