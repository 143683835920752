import Vue from 'vue';

Vue.filter(
  'formatPercent',
  (value: number, maximumFractionDigits = 2, minimumFractionDigits = 0) => {
    if (isNaN(Number(value))) return null;
    return new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits,
      maximumFractionDigits,
      style: 'percent',
    }).format(value);
  },
);
