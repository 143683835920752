var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading.audit)?_c('Loading'):_vm._e(),_c('v-expansion-panels',_vm._l((_vm.audit),function(item,index){return _c('v-expansion-panel',{key:item.uuid},[_c('v-expansion-panel-header',[_c('div',[_c('span',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.updatedAt)))]),(index === 0)?_c('span',{staticClass:"text--disabled ml-1"},[_vm._v(" ("+_vm._s(_vm.$t('comum.criacao'))+") ")]):_vm._e()]),_c('div',{staticClass:"d-flex align-center justify-end mr-3"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account")]),_c('span',[_vm._v(_vm._s(item.usuarioUpdatedBy.nomeCompleto))])],1)]),_c('v-expansion-panel-content',_vm._l((_vm.categories),function(category){return _c('div',{key:category.label,attrs:{"outlined":""}},[_c('div',{staticClass:"d-flex align-center text-subtitle-1 mt-2 mb-1"},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(_vm._s(category.icon))]),_c('span',[_vm._v(_vm._s(_vm.$t(category.label)))])],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-simple-table',[_c('tbody',_vm._l((category.rows),function(row){return _c('tr',{key:row.key,class:_vm.has(_vm.diffs[index], row.key) ? 'warning lighten-5' : ''},[_c('th',{class:[
                      _vm.has(_vm.diffs[index], row.key)
                        ? ''
                        : index === 0
                        ? 'text--primary'
                        : 'text--secondary',
                      'text-no-wrap' ],attrs:{"width":"25%"}},[_vm._v(" "+_vm._s(_vm.$t(row.label))+" ")]),_c('td',{staticClass:"text--disabled"},[(_vm.has(_vm.diffs[index], row.key))?_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"text--primary font-weight-medium"},[(
                            row.type !== 'percent' &&
                            !_vm.get(item, row.keyValue, null)
                          )?_c('span',{staticClass:"text--disabled"},[_vm._v(" N/A ")]):[(row.type && row.type === 'date')?[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.get(item, row.keyValue, null)))+" ")]:(row.type && row.type === 'percent')?[_vm._v(" "+_vm._s(_vm.get(item, row.keyValue, 0) * 100)+"% ")]:[_vm._v(" "+_vm._s(_vm.get(item, row.keyValue, null))+" ")],(row.unit)?_c('span',[_vm._v(_vm._s(_vm.$t(row.unit)))]):_vm._e()]],2),_c('div',{staticClass:"text-decoration-line-through ml-2"},[(!_vm.get(_vm.diffs[index], row.keyValue, null))?_c('span',{staticClass:"text--disabled"},[_vm._v(" N/A ")]):[(row.type && row.type === 'date')?[_c('span',[_vm._v(" ("+_vm._s(_vm._f("formatDate")(_vm.get(_vm.diffs[index], row.keyValue, null)))+_vm._s(row.unit ? _vm.$t(row.unit) : '')+") ")])]:(row.type && row.type === 'percent')?[_c('span',[_vm._v(" ("+_vm._s(_vm.get(_vm.diffs[index], row.keyValue, 0) * 100)+"%"+_vm._s(row.unit ? _vm.$t(row.unit) : '')+") ")])]:[_c('span',[_vm._v(" ("+_vm._s(_vm.get(_vm.diffs[index], row.keyValue, null))+_vm._s(row.unit ? _vm.$t(row.unit) : '')+") ")])]]],2)]):_c('div',{class:index === 0 ? 'text--primary' : 'text--secondary'},[(
                          row.type !== 'percent' &&
                          !_vm.get(item, row.keyValue, null)
                        )?_c('span',{staticClass:"text--disabled"},[_vm._v(" N/A ")]):[(row.type && row.type === 'date')?[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.get(item, row.keyValue, null)))+" ")]:(row.type && row.type === 'percent')?[_vm._v(" "+_vm._s(_vm.get(item, row.keyValue, 0) * 100)+"% ")]:(row.type && row.type === 'boolean')?[(_vm.get(item, row.keyValue, true))?_c('span',[_vm._v(" "+_vm._s(_vm.$t('comum.sim'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('comum.nao'))+" ")])]:[_vm._v(" "+_vm._s(_vm.get(item, row.keyValue, null))+" ")],(row.unit)?_c('span',[_vm._v(_vm._s(_vm.$t(row.unit)))]):_vm._e()]],2)])])}),0)])],1)],1)}),0)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }