



















































































import { orderBy, uniqBy } from 'lodash';
import Vue from 'vue';

import { Rules as CaslRules } from '../../../../back/src/casl/casl.constants';
import { Usuario } from '../../../../back/src/models/usuario.model';
import { CategoriaUsuarioEnum } from '../../../../back/src/usuarios/usuarios.constants';

import UsuariosTabela from './UsuariosTabela.vue';

export default Vue.extend({
  name: 'UsuariosTabParceiros',
  components: {
    UsuariosTabela,
  },
  props: {
    usuarios: {
      type: Array as () => Usuario[],
      default: () => [] as Usuario[],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    search: '',
    filterAtivo: null,
    filterCategoria: null,
    filterResponsavelId: null,
  }),
  computed: {
    usuariosParceiros() {
      return this.usuarios.filter((el) => {
        let conditionAtivo = 'nao';
        if (!el.senhaCadastrada && !el.ultimoEnvioEmailAtivacao) {
          conditionAtivo = 'conviteNaoEnviado';
        } else if (!el.senhaCadastrada) {
          conditionAtivo = 'aguardandoCadastroSenha';
        } else if (el.ativo) {
          conditionAtivo = 'sim';
        }
        return (
          el.categoria !== CategoriaUsuarioEnum.FUNCIONARIO &&
          (this.filterAtivo !== null
            ? conditionAtivo === this.filterAtivo
            : true) &&
          (this.filterCategoria
            ? el.categoria === this.filterCategoria
            : true) &&
          (this.filterResponsavelId
            ? el.responsavelId === this.filterResponsavelId
            : true)
        );
      });
    },
    itemsResponsaveis() {
      return orderBy(
        uniqBy(
          this.usuarios
            .filter((el) => !!el.responsavel)
            .map((el) => el.responsavel),
          (el: Usuario) => el.id,
        ),
        ['nomeCompleto'],
      );
    },
    itemsCategorias() {
      return [
        {
          value: CategoriaUsuarioEnum.PARCEIRO,
          text: this.$t(
            `CategoriaUsuarioEnum.${CategoriaUsuarioEnum.PARCEIRO}`,
          ),
        },
        {
          value: CategoriaUsuarioEnum.PREPOSTO,
          text: this.$t(
            `CategoriaUsuarioEnum.${CategoriaUsuarioEnum.PREPOSTO}`,
          ),
        },
      ];
    },
    itemsAtivo() {
      return [
        {
          value: 'aguardandoCadastroSenha',
          text: this.$t('comum.aguardandoCadastroSenha'),
        },
        {
          value: 'conviteNaoEnviado',
          text: this.$t('comum.conviteNaoEnviado'),
        },
        {
          value: 'nao',
          text: this.$t('comum.nao'),
        },
        {
          value: 'sim',
          text: this.$t('comum.sim'),
        },
      ];
    },
    permissaoCriar() {
      const rule = CaslRules.Usuario.criar;
      return this.$ability.can(rule?.action, rule?.subject);
    },
  },
});
