import { KatalisTranslationPages } from '../translations.interface';

const messages: KatalisTranslationPages = {
  // auth
  AuthAtivarConta: {
    aceitoTermosDeUsoPoliticaPrivacidade:
      'I accept the Terms of Service and Privacy Policy',
    ativarConta: 'Activate account',
    contaAtivadaComSucesso: 'Account activated successfully',
    definaUmaSenha: 'Set a password for your account',
    seuEmaiDeAcesso: 'Your access email is',
    voltarParaLogin: 'Back to login',
  },
  AuthEsqueciMinhaSenha: {
    esqueceuSuaSenha: 'Forgot your password?',
    insiraSeuEmail:
      'Enter your email, and we will send a link for you to reset your password',
    linkRenovacaoSenhaEnviado:
      'Reset password link sent successfully! Check your inbox.',
    solicitarRenovacaoSenha: 'Request password reset',
    voltarParaLogin: 'Back to login',
  },
  AuthLogin: {
    acessoCatalogo: 'Access Catalog',
    acessoLims: 'Access LIMS',
    bemVindo: 'Welcome',
    entreComSuaConta: 'Log in with your account',
    esqueceuSuaSenha: 'Forgot your password?',
    voceNaoTemPermissao: 'You do not have permission to access this system',
  },
  AuthRenovarSenha: {
    digiteSuaNovaSenha: 'Enter your new password and confirmation',
    renovarSenha: 'Renew password',
    senhaRenovadaComSucesso: 'Password renewed successfully',
    solicitarLinkRenovacaoDeSenha: 'Request password renewal link',
    voltarParaLogin: 'Back to login',
  },
  // catalisadores-pecas
  CatalisadoresPecasImportar: {
    identificarColunas: 'Identify columns',
    selecionarArquivo: 'Select file',
    verificarDados: 'Check data',
    verificarInconsistencias: 'Check inconsistencies',
  },
  // catalisadores-po
  CatalisadoresPoIndex: {
    nenhumaFamiliaCadastrada: 'No registered catalyst powder family',
  },
  // erro
  ErroNaoEncontrado: {
    caminhoNaoEncontrado:
      'The path <span class="text--secondary">"%{path}"</span> was not found in the system.',
    naoEncontrado: 'Not found',
    porFavorVerifique: 'Please check if you entered the address correctly.',
    voltarPaginaInicial: 'Back to home page',
  },
  // grupos
  GruposVer: {
    naoEPossivelRemoverGrupoComUsuarios:
      'It is not possible to remove a group with users',
    nenhumUsuarioPertenceGrupo: 'No user belongs to this group',
  },
  // lojas
  LojasVer: {
    certezaRemoverLoja: 'Are you sure you want to remove this store?',
    lojaRemovidaComSucesso: 'Store removed successfully',
    removerLoja: 'Remove store',
  },
  // usuarios
  UsuariosCadastroParceiro: {
    selecioneUfPrimeiro: 'Select UF first',
    usuarioCriadoComSucesso: 'User created successfully',
  },
  UsuariosEdicao: {
    usuarioAlteradoComSucesso: 'User edited successfully',
  },
  UsuariosVer: {
    certezaConviteParaAtivarConta:
      'An email will be sent with a link for the user to set their password and activate their account.',
    certezaRemoverUsuario: 'Are you sure you want to remove this user?',
    conviteEnviadoComSucesso: 'Invitation sent successfully',
    conviteNaoEnviado: 'Invitation not sent',
    conviteParaAtivarConta: 'Invitation to activate account',
    copiarLinkAtivacao: 'Copy activation link',
    enviarConviteAtivarConta: 'Send invitation to activate account',
    linkCopiadoComSucesso: 'Link copied to clipboard successfully',
    removerUsuario: 'Remove user',
    ultimoConviteEnviadoEm: 'Last invitation sent on',
    usuarioRemovidoComSucesso: 'User removed successfully',
  },
};

export default messages;
