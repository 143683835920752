














import Vue from 'vue';
import { Route } from 'vue-router';

import TheConfirmDialog from './components/TheConfirmDialog.vue';
import TheToastSnackbar from './components/TheToastSnackbar.vue';
import LayoutDefault from './layouts/LayoutDefault.vue';
import LayoutPublic from './layouts/LayoutPublic.vue';
import requester from './requester';

export default Vue.extend({
  name: 'App',
  components: {
    TheConfirmDialog,
    TheToastSnackbar,
    LayoutDefault,
    LayoutPublic,
  },
  data: () => ({
    loading: {
      logout: false,
    },
  }),
  computed: {
    currentLayout() {
      return `Layout${this.$route?.meta?.layout || 'Default'}`;
    },
    isRouterLoaded() {
      return this.$route.name !== null;
    },
    usuario() {
      return this.$store.getters.usuario;
    },
  },
  watch: {
    $route(to: Route) {
      this.updateTitle(to);
    },
  },
  created() {
    this.updateTitle(this.$route);
    this.$root.$on('logout', this.logout);
  },
  mounted() {
    // define global function para dialog de confirmação
    this.$root.$confirm = this.$refs.theConfirmDialog.open;
    // define global function para errorHandling
    this.$root.$errorHandler = this.errorHandler;
  },
  methods: {
    updateTitle(route: Route) {
      document.title = route.name
        ? `${this.$t(`routes.${route.name}`)} | ${this.$t('comum.nomeSistema')}`
        : this.$t('comum.nomeSistema');
    },
    async logout() {
      this.loading.logout = true;
      try {
        await requester.auth.logout();
        this.$store.dispatch('logout');
        this.$root.$emit(
          'toastSuccess',
          this.$t('comum.logoutRealizadoComSucesso'),
        );
        this.$router.push({ name: 'AuthLogin' });
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.logout = false;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errorHandler(err: any, redirectOn404 = false) {
      if (err?.response) {
        const { message } = err.response?.data;
        this.$root.$emit('toastError', message || err.message);
        if (err.response?.status === 403) {
          this.$router.push({ name: 'AuthLogin' });
        } else if (redirectOn404 && err.response?.status === 404) {
          this.$router.push({ name: 'PainelDeControle' });
        }
      } else {
        this.$root.$emit('toastError', err.message);
      }
    },
  },
});
