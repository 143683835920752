









































































































import Vue from 'vue';

import requester from '@/requester';
import { debounce, groupBy, orderBy } from 'lodash';
import { GrupoScopesEnum } from '@/utils/constants/back.constants';

export default Vue.extend({
  name: 'GruposPermissoes',
  data: () => ({
    loading: {
      funcionalidades: true,
      grupos: true,
      funcionalidadeGrupo: {},
    },
    expand: [],
    funcionalidades: [],
    grupos: [],
  }),
  computed: {
    loadingAll() {
      return this.loading.funcionalidades && this.loading.grupos;
    },
    gruposOrdenados() {
      return orderBy(this.grupos, ['admin', 'nome'], ['desc', 'asc']);
    },
    funcionalidadesPorCategoria() {
      return groupBy(
        orderBy(this.funcionalidades, ['categoria', 'nome']),
        (el) => el.categoria,
      );
    },
  },
  async mounted() {
    await Promise.all([this.listaFuncionalidades(), this.listaGrupos()]);
    this.expand = Object.keys(this.funcionalidadesPorCategoria).map(
      (key, index) => index,
    );
  },
  methods: {
    getFuncionalidadeGrupo(funcionalidadeId: number, grupoId: number) {
      const grupo = this.grupos.find((el) => el.id === grupoId);
      if (!grupo) return false;
      const funcionalidade = grupo?.funcionalidades?.find(
        (el) => el.id === funcionalidadeId,
      );
      return !!funcionalidade;
    },
    async listaFuncionalidades() {
      this.loading.funcionalidades = true;
      try {
        this.funcionalidades = await requester.funcionalidades.listar();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.funcionalidades = false;
      }
    },
    async listaGrupos() {
      this.loading.funcionalidades = true;
      try {
        const queryObject = {
          scope: GrupoScopesEnum.ComFuncionalidades,
        };
        this.grupos = await requester.grupos.lista(queryObject);
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.grupos = false;
      }
    },
    vinculaFuncionalidadeGrupoDebounce: debounce(async function (
      funcionalidadeId: number,
      grupoId: number,
    ) {
      await this.vinculaFuncionalidadeGrupo(funcionalidadeId, grupoId);
    },
    300),
    async vinculaFuncionalidadeGrupo(
      funcionalidadeId: number,
      grupoId: number,
    ) {
      this.$set(
        this.loading.funcionalidadeGrupo,
        `funcionalidade${funcionalidadeId}_grupo${grupoId}`,
        true,
      );
      try {
        const formData = {
          funcionalidadeId,
        };
        const funcionalidade = await requester.grupos.vinculaFuncionalidade(
          grupoId,
          formData,
        );
        const grupoIndex = this.grupos.findIndex((el) => el.id === grupoId);
        this.grupos[grupoIndex].funcionalidades.push(funcionalidade);
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.$set(
          this.loading.funcionalidadeGrupo,
          `funcionalidade${funcionalidadeId}_grupo${grupoId}`,
          false,
        );
      }
    },
    desvinculaFuncionalidadeGrupoDebounce: debounce(async function (
      funcionalidadeId: number,
      grupoId: number,
    ) {
      await this.desvinculaFuncionalidadeGrupo(funcionalidadeId, grupoId);
    },
    300),
    async desvinculaFuncionalidadeGrupo(
      funcionalidadeId: number,
      grupoId: number,
    ) {
      this.$set(
        this.loading.funcionalidadeGrupo,
        `funcionalidade${funcionalidadeId}_grupo${grupoId}`,
        true,
      );
      try {
        await requester.grupos.desvinculaFuncionalidade(
          grupoId,
          funcionalidadeId,
        );
        const grupoIndex = this.grupos.findIndex((el) => el.id === grupoId);
        const funcionalidadeIndex = this.grupos[
          grupoIndex
        ].funcionalidades.findIndex((el) => el.id === funcionalidadeId);
        this.$delete(
          this.grupos[grupoIndex].funcionalidades,
          funcionalidadeIndex,
        );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.$set(
          this.loading.funcionalidadeGrupo,
          `funcionalidade${funcionalidadeId}_grupo${grupoId}`,
          false,
        );
      }
    },
  },
});
