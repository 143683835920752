




































import Vue from 'vue';
import CatalisadoresPecasImportarTabela from './CatalisadoresPecasImportarTabelaNovas.vue';
export default Vue.extend({
  name: 'CatalisadoresPecasImportarStep6',
  components: {
    CatalisadoresPecasImportarTabela,
  },
  data: () => ({
    headers: [],
    items: [],
  }),
  methods: {
    inicio(headers, items) {
      this.headers = headers;
      this.items = items;
    },
  },
});
