import axios from 'axios';

const atualiza = async (placaId: number, formData: any): Promise<any> => {
  const res = await axios.patch<any>(`placas/${placaId}`, formData);
  return res.data;
};

const busca = async (placaId: number, buscarPlacaQuery?: any): Promise<any> => {
  const res = await axios.get<any>(`placas/${placaId}`, {
    ...(buscarPlacaQuery ? { params: buscarPlacaQuery } : {}),
  });
  return res.data;
};

const cria = async (formData: any): Promise<any> => {
  const res = await axios.post<any>('placas', formData);
  return res.data;
};

const lista = async (): Promise<any[]> => {
  const res = await axios.get<any[]>('placas');
  return res.data;
};

const remove = async (placaId: number): Promise<any> => {
  const res = await axios.delete<any>(`placas/${placaId}`);
  return res.data;
};

export default {
  atualiza,
  busca,
  cria,
  lista,
  remove,
};
