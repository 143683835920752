

























































import Vue from 'vue';

import requester from '@/requester';

import CotacoesResumoAtual from '@/components/cotacoes/CotacoesResumoAtual.vue';
import { Rules as CaslRules } from '../../../back/src/casl/casl.constants';

export default Vue.extend({
  name: 'PainelDeControle',
  components: {
    CotacoesResumoAtual,
  },
  data: () => ({
    loading: {
      cotacaoAtual: true,
    },
    cotacaoAtual: null,
    cotacaoAnterior: null,
  }),
  computed: {
    permissaoCotacao() {
      const rule = CaslRules.Cotacao.listar;
      return this.$ability.can(rule?.action, rule?.subject);
    },
    urlLims() {
      return process.env.VUE_APP_URL_LIMS;
    },
    urlCatalogo() {
      return process.env.VUE_APP_URL_CATALOGO;
    },
    showLims() {
      return process.env.VUE_APP_SHOW_LIMS === 'true';
    },
  },
  mounted() {
    if (this.permissaoCotacao) {
      this.buscaCotacaoAtualEAnterior();
    }
  },
  methods: {
    async buscaCotacaoAtualEAnterior() {
      this.loading.cotacaoAtual = true;
      try {
        const paginationQuery = {
          limit: 2,
          offset: 0,
          orderBy: ['createdAt'],
          orderDesc: [true],
        };
        const resData = await requester.cotacoes.lista(paginationQuery);
        this.cotacaoAtual = resData?.rows[0] || null;
        this.cotacaoAnterior = resData?.rows[1] || null;
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.cotacaoAtual = false;
      }
    },
  },
});
