




























import Vue from 'vue';

import requester from '@/requester';

import CatalisadoresPecasGaleria from './CatalisadoresPecasGaleria.vue';

export default Vue.extend({
  name: 'CatalisadoresPecasDialogFotos',
  components: {
    CatalisadoresPecasGaleria,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    catalisadorPecaId: {
      type: Number || null,
      default: null,
    },
  },
  data: () => ({
    loading: {
      fotosArquivos: true,
    },
    fotosArquivos: [],
  }),
  mounted() {
    this.buscaFotosArquivos();
  },
  watch: {
    catalisadorPecaId() {
      this.buscaFotosArquivos();
    },
  },
  methods: {
    async buscaFotosArquivos() {
      this.loading.fotosArquivos = true;
      try {
        if (this.catalisadorPecaId) {
          this.fotosArquivos =
            await requester.catalisadoresPecas.buscaFotosArquivos(
              this.catalisadorPecaId,
            );
        } else {
          this.fotosArquivos = [];
        }
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.fotosArquivos = false;
      }
    },
  },
});
