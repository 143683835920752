
















































import Vue from 'vue';
import { debounce } from 'lodash';

import requester from '@/requester';

import CustomCancelarSalvar from '../custom/CustomCancelarSalvar.vue';
import { rulesRequired } from '@/utils/input-rules';

export default Vue.extend({
  name: 'PedidosDialogCancelar',
  components: {
    CustomCancelarSalvar,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    pedido: {
      type: Object || null,
      default: null,
    },
  },
  data: () => ({
    loading: {
      submitForm: false,
    },
    form: {
      justificativaCancelamento: null,
    },
    rules: {
      required: rulesRequired,
    },
  }),
  methods: {
    onInput(event: boolean) {
      if (!event) {
        this.$refs?.form?.reset();
      }
      this.$emit('input', event);
    },
    submitFormDebounce: debounce(async function () {
      await this.submitForm();
    }, 200),
    async submitForm() {
      if (!this.$refs?.form?.validate()) return;
      this.loading.submitForm = true;
      try {
        const formData = {
          justificativaCancelamento:
            this.form.justificativaCancelamento?.trim() || null,
        };
        const pedido = await requester.pedidos.cancela(
          this.pedido.id,
          formData,
        );
        this.$emit('pedido-cancelado', pedido);
        this.$root.$emit('toastSuccess', 'Pedido cancelado com sucesso');
        this.$refs?.form?.reset();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.submitForm = false;
      }
    },
  },
});
