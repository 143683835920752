
























import Vue from 'vue';
import PermissoesFormCadastro from './PermissoesFormCadastro.vue';
export default Vue.extend({
  components: { PermissoesFormCadastro },
  name: 'PermissoesDialogCadastro',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    fechar() {
      this.$emit('input', false);
    },
  },
});
