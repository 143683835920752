



























































import Vue from 'vue';
import { debounce } from 'lodash';

import requester from '@/requester';

import { Rules as CaslRules } from '../../../../back/src/casl/casl.constants';
import { PlacaScopesEnum } from '@/utils/constants/back.constants';

import PlacasFormCriarAlterar from './PlacasFormCriarAlterar.vue';
import PlacasInfo from './PlacasInfo.vue';

export default Vue.extend({
  name: 'PlacasDialogInfo',
  components: {
    PlacasFormCriarAlterar,
    PlacasInfo,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    placaId: {
      type: Number,
      default: 0,
    },
    familias: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    loading: {
      placa: false,
    },
    alterar: false,
    placa: null,
  }),
  computed: {
    permissaoAtualizar() {
      const rule = CaslRules.Placa.atualizar;
      return this.$ability.can(rule?.action, rule?.subject);
    },
    permissaoRemover() {
      const rule = CaslRules.Placa.remover;
      return this.$ability.can(rule?.action, rule?.subject);
    },
  },
  watch: {
    placaId() {
      this.buscaPlaca();
    },
  },
  mounted() {
    this.buscaPlaca();
  },
  methods: {
    fechar() {
      this.$refs?.familiasFormCriarAlterar?.cancelar() ||
        this.$emit('input', false);
    },
    placaCriada(placa) {
      this.$emit('input', false);
      this.$emit('placa-criada', placa);
    },
    placaAlterada(placa) {
      this.alterar = false;
      this.buscaPlaca();
      this.$emit('placa-alterada', placa);
    },
    async buscaPlaca() {
      if (!this.placaId) {
        return;
      }
      this.loading.placa = true;
      try {
        const buscarPlacaQuery = {
          scope: PlacaScopesEnum.ComFamiliaUsuarioCreatedUpdated,
        };
        this.placa = await requester.placas.busca(
          this.placaId,
          buscarPlacaQuery,
        );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.placa = false;
      }
    },
    removerDebounce: debounce(async function () {
      await this.remover();
    }, 200),
    async remover() {
      const confirm = await this.$root.$confirm(
        this.$t('PlacasDialogInfo.removerPlaca'),
        this.$t('PlacasDialogInfo.certezaRemoverPlaca'),
      );
      if (!confirm) return;
      this.loading.remover = true;
      try {
        await requester.placas.remove(this.placa.id);
        this.$root.$emit(
          'toastSuccess',
          this.$t('PlacasDialogInfo.placaRemovidaComSucesso'),
        );
        this.$emit('placa-removida', this.placa.id);
        this.fechar();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.remover = false;
      }
    },
  },
});
