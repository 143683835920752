















































































































































































































































































































































































































































































































































import Vue from 'vue';
import { debounce, deburr, orderBy, sortBy, toUpper } from 'lodash';

import requester from '@/requester';
import { HeaderItem, HeaderItemFilterType } from '@/utils/types/custom.types';
import { maximumFractionDigitsCurrency } from '@/utils/intl-functions';
import { Rules as CaslRules } from '../../../../back/src/casl/casl.constants';

import CatalisadoresPecasDialogAtualizarLote from '@/components/catalisadores-pecas/CatalisadoresPecasDialogAtualizarLote.vue';
import CatalisadoresPecasDialogCriar from '@/components/catalisadores-pecas/CatalisadoresPecasDialogCriar.vue';
import CatalisadoresPecasDialogFotos from '@/components/catalisadores-pecas/CatalisadoresPecasDialogFotos.vue';
import CatalisadoresPecasDialogInfo from '@/components/catalisadores-pecas/CatalisadoresPecasDialogInfo.vue';
import CatalisadoresPecasMenuHeader from '@/components/catalisadores-pecas/CatalisadoresPecasMenuHeader.vue';
import CatalisadoresPecasSelectUsuario from '@/components/catalisadores-pecas/CatalisadoresPecasSelectUsuario.vue';
import CatalisadoresPecasTabelaAnalises from '@/components/catalisadores-pecas/CatalisadoresPecasTabelaAnalises.vue';

import { ListarFamiliaQueryDto } from '../../../../back/src/familias/dto/listar-familia-query.dto';
import { CategoriaFamiliaEnum } from '../../../../back/src/familias/familias.constants';

import draggable from 'vuedraggable';
import { AvisoCatalisadorPecaEnum } from '../../../../back/src/catalisadores-pecas/catalisadores-pecas.constants';

export default Vue.extend({
  name: 'CatalisadoresPecasIndex',
  components: {
    draggable,
    CatalisadoresPecasDialogAtualizarLote,
    CatalisadoresPecasDialogCriar,
    CatalisadoresPecasDialogFotos,
    CatalisadoresPecasDialogInfo,
    CatalisadoresPecasMenuHeader,
    CatalisadoresPecasSelectUsuario,
    CatalisadoresPecasTabelaAnalises,
  },
  data: () => ({
    loading: {
      catalisadoresPecas: true,
      montadoras: true,
      familias: true,
    },
    showAnalises: false,
    dialogFotos: false,
    dialogCriar: false,
    dialogInfo: false,
    dialogAtualizarLote: false,
    dialogConfigTabela: false,
    usuarioInfo: null,
    margemUsuario: 0,
    fotoArquivoSelecionadoId: null,
    catalisadorPecaSelecionadoId: null,
    search: '',
    options: {},
    queryOrder: [],
    catalisadoresPecas: [],
    montadoras: [],
    familias: [],
    serverItemsLength: 0,
    selected: [],
    headersList: [
      'data-table-select',
      'codigo',
      'detalhe',
      'montadoraNome',
      'modelo',
      'familiaCodigo',
      'ptPpm',
      'pdPpm',
      'rhPpm',
      'pesoKg',
      'valorTotal',
      'margemPeca',
      'margemUsuario',
      'valorMargem',
      'valorKg',
      'aviso',
      'observacoes',
      'ativo',
      'fotoArquivoId',
      'info',
    ],
    headers: [
      {
        value: 'codigo',
        text: 'comum.codigo',
        show: true,
        draggable: true,
        menu: false,
        sortable: true,
        sortDesc: null,
        filterable: true,
        filterType: HeaderItemFilterType.SEARCH,
        filterValue: null,
      },
      {
        value: 'detalhe',
        text: 'comum.detalhe',
        show: true,
        draggable: true,
        menu: false,
        sortable: true,
        sortDesc: null,
        filterable: true,
        filterType: HeaderItemFilterType.SEARCH,
        filterValue: null,
      },
      {
        value: 'montadoraNome',
        text: 'comum.montadora',
        show: true,
        draggable: true,
        menu: false,
        sortable: true,
        sortDesc: null,
        filterable: true,
        filterType: HeaderItemFilterType.AUTOCOMPLETE,
        filterValue: null,
      },
      {
        value: 'modelo',
        text: 'comum.modelo',
        show: true,
        draggable: true,
        menu: false,
        sortable: true,
        sortDesc: null,
        filterable: true,
        filterType: HeaderItemFilterType.SEARCH,
        filterValue: null,
      },
      {
        value: 'familiaCodigo',
        text: 'comum.familia',
        show: true,
        draggable: true,
        menu: false,
        sortable: true,
        sortDesc: null,
        filterable: true,
        filterType: HeaderItemFilterType.AUTOCOMPLETE,
        filterValue: null,
      },
      {
        value: 'ptPpm',
        text: 'comum.pt',
        unit: 'comum.ppm',
        show: true,
        draggable: true,
        menu: false,
        sortable: true,
        sortDesc: null,
        filterable: true,
        filterType: HeaderItemFilterType.NUMBER_MIN_MAX,
        filterValue: { min: null, max: null },
      },
      {
        value: 'pdPpm',
        text: 'comum.pd',
        unit: 'comum.ppm',
        show: true,
        draggable: true,
        menu: false,
        sortable: true,
        sortDesc: null,
        filterable: true,
        filterType: HeaderItemFilterType.NUMBER_MIN_MAX,
        filterValue: { min: null, max: null },
      },
      {
        value: 'rhPpm',
        text: 'comum.rh',
        unit: 'comum.ppm',
        show: true,
        draggable: true,
        menu: false,
        sortable: true,
        sortDesc: null,
        filterable: true,
        filterType: HeaderItemFilterType.NUMBER_MIN_MAX,
        filterValue: { min: null, max: null },
      },
      {
        value: 'pesoKg',
        text: 'comum.peso',
        unit: 'kg',
        show: true,
        draggable: true,
        menu: false,
        sortable: true,
        sortDesc: null,
        filterable: true,
        filterType: HeaderItemFilterType.NUMBER_MIN_MAX,
        filterValue: { min: null, max: null },
      },
      {
        value: 'valorTotal',
        text: 'comum.vt',
        show: true,
        draggable: true,
        menu: false,
        sortable: true,
        sortDesc: null,
        filterable: true,
        filterType: HeaderItemFilterType.NUMBER_MIN_MAX,
        filterValue: { min: null, max: null },
      },
      {
        value: 'margemPeca',
        text: 'comum.mp',
        align: 'center',
        show: true,
        draggable: true,
        menu: false,
        sortable: true,
        sortDesc: null,
        filterable: true,
        filterType: HeaderItemFilterType.PERCENT_MIN_MAX,
        filterValue: { min: null, max: null },
      },
      {
        value: 'margemUsuario',
        text: 'comum.mu',
        align: 'center',
        show: true,
        draggable: true,
        menu: false,
        sortable: false,
        sortDesc: null,
        filterable: false,
        filterType: null,
        filterValue: null,
      },
      {
        value: 'valorMargem',
        text: 'comum.vtMpMu',
        show: true,
        draggable: true,
        menu: false,
        sortable: true,
        sortDesc: null,
        filterable: true,
        filterType: HeaderItemFilterType.NUMBER_MIN_MAX,
        filterValue: { min: null, max: null },
      },
      {
        value: 'valorKg',
        text: 'comum.valorKg',
        show: true,
        draggable: true,
        menu: false,
        sortable: true,
        sortDesc: null,
        filterable: true,
        filterType: HeaderItemFilterType.NUMBER_MIN_MAX,
        filterValue: { min: null, max: null },
      },
      {
        value: 'aviso',
        text: 'comum.aviso',
        show: true,
        draggable: true,
        menu: false,
        sortable: true,
        sortDesc: null,
        filterable: true,
        filterType: HeaderItemFilterType.AUTOCOMPLETE,
        filterValue: null,
      },
      {
        value: 'observacoes',
        text: 'comum.observacoes',
        show: true,
        draggable: true,
        menu: false,
        sortable: true,
        sortDesc: null,
        filterable: true,
        filterType: HeaderItemFilterType.SEARCH,
        filterValue: null,
      },
      {
        value: 'ativo',
        text: 'comum.ativo',
        show: true,
        draggable: true,
        menu: false,
        sortable: false,
        sortDesc: null,
        filterable: true,
        filterType: HeaderItemFilterType.BOOLEAN,
        filterValue: null,
      },
      {
        value: 'fotoArquivoId',
        text: 'comum.foto',
        align: 'center',
        show: true,
        draggable: true,
        menu: false,
        sortable: false,
        sortDesc: null,
        filterable: true,
        filterType: HeaderItemFilterType.BOOLEAN,
        filterValue: null,
      },
      {
        value: 'info',
        text: 'comum.info',
        align: 'center',
        show: true,
        draggable: false,
        menu: false,
        sortable: false,
        sortDesc: null,
        filterable: false,
        filterType: null,
        filterValue: null,
      },
    ] as HeaderItem[],
  }),
  computed: {
    locale() {
      return process.env.VUE_APP_PAIS_LOCALE || 'pt-BR';
    },
    currency() {
      return process.env.VUE_APP_PAIS_CURRENCY || 'BRL';
    },
    permissaoCriar() {
      const rule = CaslRules.CatalisadorPeca.criar;
      return this.$ability.can(rule?.action, rule?.subject);
    },
    permissaoAtualizarLote() {
      const rule = CaslRules.CatalisadorPeca.atualizarLote;
      return this.$ability.can(rule?.action, rule?.subject);
    },
    selectedLength() {
      return this.selected.length;
    },
    selectAll() {
      return this.selectedLength === this.catalisadoresPecas.length;
    },
    headersSorted() {
      return sortBy(this.headers, (el) =>
        this.headersList.findIndex((header) => el.value === header),
      );
    },
    headersFiltered() {
      return this.headersSorted.filter((el) => el.show);
    },
    itemsMontadoras() {
      return orderBy(
        this.montadoras.map((el) => ({ value: el.id, text: el.nome })),
        (el) => deburr(toUpper(el.text)),
      );
    },
    itemsAviso() {
      return Object.values(AvisoCatalisadorPecaEnum).map((el) => ({
        value: el,
        text: this.$t(`AvisoCatalisadorPecaEnum.${el}`),
      }));
    },
    itemsFamilias() {
      return orderBy(
        this.familias.map((el) => ({
          value: el.id,
          text: `${el.codigo} - ${el.descricao}`,
        })),
        (el) => el.text,
      );
    },
  },
  watch: {
    options() {
      this.listaCatalisadoresPecas(false);
    },
    margemUsuario() {
      this.listaCatalisadoresPecas(false);
    },
    usuarioInfo() {
      this.margemUsuario = this.usuarioInfo?.isPreposto
        ? this.usuarioInfo?.margemComposta || 0
        : this.usuarioInfo?.margemUsuario || 0;
      this.listaCatalisadoresPecas(false);
    },
  },
  mounted() {
    this.listaMontadoras();
    this.listaFamilias();
  },
  methods: {
    maximumFractionDigitsCurrency,
    getHeaderItems(headerValue) {
      switch (headerValue) {
        case 'montadoraNome':
          return this.itemsMontadoras;
        case 'aviso':
          return this.itemsAviso;
        case 'familiaCodigo':
          return this.itemsFamilias;
        default:
          return [];
      }
    },
    selectAllHeaders() {
      this.headers.forEach((header, key) => {
        this.$set(this.headers[key], 'show', true);
      });
    },
    catalisadoresPecasAtualizadas() {
      this.listaCatalisadoresPecas(false);
      this.$refs.catalisadoresPecasDialogInfoRef.recarregaPecaInfo();
      this.dialogAtualizarLote = false;
    },
    verFotos(catalisadorPecaId: number) {
      this.catalisadorPecaSelecionadoId = catalisadorPecaId;
      this.dialogFotos = true;
    },
    info(catalisadorPecaId: number) {
      this.catalisadorPecaSelecionadoId = catalisadorPecaId;
      this.dialogInfo = true;
    },
    catalisadorPecaCriada() {
      this.listaCatalisadoresPecas(true);
      this.listaMontadoras();
      this.dialogCriar = false;
    },
    catalisadorPecaAlterada() {
      this.listaCatalisadoresPecas(false);
      this.listaMontadoras();
    },
    catalisadorPecaRemovida() {
      this.listaCatalisadoresPecas(false);
      this.dialogInfo = false;
    },
    onSelectAll() {
      if (this.selectedLength === this.catalisadoresPecas.length) {
        this.selected = [];
      } else {
        this.selected = this.catalisadoresPecas;
      }
    },
    searchDebounce: debounce(function () {
      this.listaCatalisadoresPecas(true);
    }, 600),
    sortColumn(headerValue: string, desc: boolean) {
      const index = this.headers.findIndex((el) => el.value === headerValue);
      if (index < 0) return;
      const sortDesc = this.headers[index].sortDesc === desc ? null : desc;
      const indexOrder = this.queryOrder.findIndex(
        (el: { column: string; desc: boolean }) => el.column === headerValue,
      );
      if (sortDesc === null) {
        if (indexOrder >= 0) {
          this.$delete(this.queryOrder, indexOrder);
        }
      } else {
        if (indexOrder >= 0) {
          this.$set(this.queryOrder, indexOrder, {
            column: headerValue,
            desc,
          });
        } else {
          this.queryOrder.push({ column: headerValue, desc });
        }
      }
      this.$set(this.headers[index], 'sortDesc', sortDesc);
      this.$set(this.headers[index], 'menu', false);
      this.listaCatalisadoresPecas(true);
    },
    sortAsc(headerValue: string) {
      this.sortColumn(headerValue, false);
    },
    sortDesc(headerValue: string) {
      this.sortColumn(headerValue, true);
    },
    applyFilter(
      headerValue: string,
      filterValue:
        | { min: string | number | null; max: string | number | null }
        | string
        | number
        | null,
    ) {
      const index = this.headers.findIndex((el) => el.value === headerValue);
      if (index >= 0) {
        this.$set(this.headers[index], 'filterValue', filterValue);
        this.$set(this.headers[index], 'menu', false);
        this.listaCatalisadoresPecas(true);
      }
    },
    getQueryObject(resetPage: boolean) {
      const orderBy = this.queryOrder.map((el) => el.column);
      const orderDesc = this.queryOrder.map((el) =>
        el.desc ? 'true' : 'false',
      );

      const filterValues = {};
      // AUTOCOMPLETE E SEARCH

      const familiaHeader = this.headers.find(
        (el) => el.value === 'familiaCodigo',
      );
      const familiaId = familiaHeader.filterValue;
      if (familiaId) {
        Object.assign(filterValues, { familiaId });
      }

      this.headers
        .filter((el) => {
          if (
            [
              HeaderItemFilterType.AUTOCOMPLETE,
              HeaderItemFilterType.SEARCH,
            ].includes(el?.filterType)
          ) {
            if (el.value === 'familiaCodigo') return false;
            return !!el.filterValue;
          }
          return false;
        })
        .forEach((el) => {
          Object.assign(filterValues, { [el.value]: el.filterValue });
        });
      // BOOLEAN
      this.headers
        .filter((el) => {
          if ([HeaderItemFilterType.BOOLEAN].includes(el?.filterType)) {
            return el?.filterValue === true || el?.filterValue === false;
          }
          return false;
        })
        .forEach((el) => {
          Object.assign(filterValues, { [el.value]: el.filterValue });
        });
      // MIN_MAX
      this.headers
        .filter((el) => {
          if (
            [
              HeaderItemFilterType.DATE_MIN_MAX,
              HeaderItemFilterType.NUMBER_MIN_MAX,
              HeaderItemFilterType.PERCENT_MIN_MAX,
            ].includes(el?.filterType)
          ) {
            return !!el?.filterValue?.min || !!el?.filterValue?.max;
          }
          return false;
        })
        .forEach((el) => {
          if (el?.filterValue?.min) {
            let min = el.filterValue.min;
            if (el.filterType === HeaderItemFilterType.PERCENT_MIN_MAX) {
              min /= 100;
            }
            Object.assign(filterValues, {
              [`${el.value}Min`]: min,
            });
          }
          if (el?.filterValue?.max) {
            let max = el.filterValue.max;
            if (el.filterType === HeaderItemFilterType.PERCENT_MIN_MAX) {
              max /= 100;
            }
            Object.assign(filterValues, {
              [`${el.value}Max`]: max,
            });
          }
        });
      if (resetPage) {
        this.$set(this.options, 'page', 1);
      }
      const { itemsPerPage = 10, page = 1 } = this.options;
      return {
        ...(itemsPerPage > 0
          ? { limit: itemsPerPage, offset: itemsPerPage * (page - 1) }
          : {}),
        ...(orderBy.length > 0 ? { orderBy } : {}),
        ...(orderDesc.length > 0 ? { orderDesc } : {}),
        ...(this.search ? { search: this.search } : {}),
        ...filterValues,
        margemUsuario: Number(this.margemUsuario) || 0,
      };
    },
    async listaCatalisadoresPecas(resetPage: boolean) {
      this.loading.catalisadoresPecas = true;
      try {
        const listarCatalisadoresPecasQuery = this.getQueryObject(resetPage);
        const { count, rows } = await requester.catalisadoresPecas.lista(
          listarCatalisadoresPecasQuery,
        );
        this.serverItemsLength = count;
        this.catalisadoresPecas = rows;
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.catalisadoresPecas = false;
      }
    },
    async listaMontadoras() {
      this.loading.montadoras = true;
      try {
        this.montadoras = await requester.montadoras.lista();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.montadoras = false;
      }
    },
    async listaFamilias() {
      const somenteCatalisador: ListarFamiliaQueryDto = {
        categoria: CategoriaFamiliaEnum.CATALISADORES_PECAS,
      };
      this.loading.familias = true;
      try {
        this.familias = await requester.familias.lista(somenteCatalisador);
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.familias = false;
      }
    },
  },
});
