





















































































































































import Vue from 'vue';
import { debounce } from 'lodash';

import requester from '@/requester';
import { HeaderItem, HeaderItemFilterType } from '@/utils/types/custom.types';
import CatalisadoresPecasMenuHeader from './CatalisadoresPecasMenuHeader.vue';

export default Vue.extend({
  name: 'CatalisadoresPecasTabelaAnalises',
  components: {
    CatalisadoresPecasMenuHeader,
  },
  props: {
    itemsMontadoras: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    loading: {
      catalisadoresPecasAnalises: true,
    },
    options: {},
    queryOrder: [],
    catalisadoresPecasAnalises: [],
    serverItemsLength: 0,
    headers: [
      {
        value: 'ref',
        text: 'comum.ref',
        menu: false,
        sortable: true,
        sortDesc: null,
        filterable: true,
        filterType: HeaderItemFilterType.SEARCH,
        filterValue: null,
      },
      {
        value: 'codigo',
        text: 'comum.codigo',
        menu: false,
        sortable: true,
        sortDesc: null,
        filterable: true,
        filterType: HeaderItemFilterType.SEARCH,
        filterValue: null,
      },
      {
        value: 'detalhe',
        text: 'comum.detalhe',
        menu: false,
        sortable: true,
        sortDesc: null,
        filterable: true,
        filterType: HeaderItemFilterType.SEARCH,
        filterValue: null,
      },
      {
        value: 'montadoraNome',
        text: 'comum.montadora',
        menu: false,
        sortable: true,
        sortDesc: null,
        filterable: true,
        filterType: HeaderItemFilterType.AUTOCOMPLETE,
        filterValue: null,
      },
      {
        value: 'modelo',
        text: 'comum.modelo',
        menu: false,
        sortable: true,
        sortDesc: null,
        filterable: true,
        filterType: HeaderItemFilterType.SEARCH,
        filterValue: null,
      },
      {
        value: 'dataAnalise',
        text: 'comum.dataAnalise',
        menu: false,
        sortable: true,
        sortDesc: null,
        filterable: true,
        filterType: HeaderItemFilterType.DATE_MIN_MAX,
        filterValue: { min: null, max: null },
      },
      {
        value: 'ptPpm',
        text: 'comum.pt',
        unit: 'comum.ppm',
        menu: false,
        sortable: true,
        sortDesc: null,
        filterable: true,
        filterType: HeaderItemFilterType.NUMBER_MIN_MAX,
        filterValue: { min: null, max: null },
      },
      {
        value: 'pdPpm',
        text: 'comum.pd',
        unit: 'comum.ppm',
        menu: false,
        sortable: true,
        sortDesc: null,
        filterable: true,
        filterType: HeaderItemFilterType.NUMBER_MIN_MAX,
        filterValue: { min: null, max: null },
      },
      {
        value: 'rhPpm',
        text: 'comum.rh',
        unit: 'comum.ppm',
        menu: false,
        sortable: true,
        sortDesc: null,
        filterable: true,
        filterType: HeaderItemFilterType.NUMBER_MIN_MAX,
        filterValue: { min: null, max: null },
      },
      {
        value: 'pesoKg',
        text: 'comum.peso',
        unit: 'kg',
        menu: false,
        sortable: true,
        sortDesc: null,
        filterable: true,
        filterType: HeaderItemFilterType.NUMBER_MIN_MAX,
        filterValue: { min: null, max: null },
      },
      {
        value: 'ativo',
        text: 'comum.ativa',
        menu: false,
        sortable: false,
        sortDesc: null,
        filterable: true,
        filterType: HeaderItemFilterType.BOOLEAN,
        filterValue: null,
      },
      {
        value: 'info',
        text: 'comum.info',
        align: 'center',
        menu: false,
        sortable: false,
        sortDesc: null,
        filterable: false,
        filterType: null,
        filterValue: null,
      },
    ] as HeaderItem[],
  }),
  watch: {
    options() {
      this.listaCatalisadoresPecasAnalises(false);
    },
    search() {
      this.searchDebounce();
    },
  },
  mounted() {
    this.listaCatalisadoresPecasAnalises();
  },
  methods: {
    selectAllHeaders() {
      this.headers.forEach((header, key) => {
        this.$set(this.headers[key], 'show', true);
      });
    },
    info(catalisadorPecaId: number) {
      this.$emit('info', catalisadorPecaId);
    },
    catalisadorPecaCriada() {
      this.listaCatalisadoresPecasAnalises(true);
      this.listaMontadoras();
      this.dialogCriar = false;
    },
    catalisadorPecaAlterada() {
      this.listaCatalisadoresPecasAnalises(false);
      this.listaMontadoras();
    },
    catalisadorPecaRemovida() {
      this.listaCatalisadoresPecasAnalises(false);
      this.dialogInfo = false;
    },
    searchDebounce: debounce(function () {
      this.listaCatalisadoresPecasAnalises(true);
    }, 600),
    sortColumn(headerValue: string, desc: boolean) {
      const index = this.headers.findIndex((el) => el.value === headerValue);
      if (index < 0) return;
      const sortDesc = this.headers[index].sortDesc === desc ? null : desc;
      const indexOrder = this.queryOrder.findIndex(
        (el: { column: string; desc: boolean }) => el.column === headerValue,
      );
      if (sortDesc === null) {
        if (indexOrder >= 0) {
          this.$delete(this.queryOrder, indexOrder);
        }
      } else {
        if (indexOrder >= 0) {
          this.$set(this.queryOrder, indexOrder, {
            column: headerValue,
            desc,
          });
        } else {
          this.queryOrder.push({ column: headerValue, desc });
        }
      }
      this.$set(this.headers[index], 'sortDesc', sortDesc);
      this.$set(this.headers[index], 'menu', false);
      this.listaCatalisadoresPecasAnalises(true);
    },
    sortAsc(headerValue: string) {
      this.sortColumn(headerValue, false);
    },
    sortDesc(headerValue: string) {
      this.sortColumn(headerValue, true);
    },
    applyFilter(
      headerValue: string,
      filterValue:
        | { min: string | number | null; max: string | number | null }
        | string
        | number
        | null,
    ) {
      const index = this.headers.findIndex((el) => el.value === headerValue);
      if (index >= 0) {
        this.$set(this.headers[index], 'filterValue', filterValue);
        this.$set(this.headers[index], 'menu', false);
        this.listaCatalisadoresPecasAnalises(true);
      }
    },
    getQueryObject(resetPage: boolean) {
      const orderBy = this.queryOrder.map((el) => el.column);
      const orderDesc = this.queryOrder.map((el) =>
        el.desc ? 'true' : 'false',
      );
      const filterValues = {};
      // AUTOCOMPLETE E SEARCH
      this.headers
        .filter((el) => {
          if (
            [
              HeaderItemFilterType.AUTOCOMPLETE,
              HeaderItemFilterType.SEARCH,
            ].includes(el?.filterType)
          ) {
            return !!el.filterValue;
          }
          return false;
        })
        .forEach((el) => {
          Object.assign(filterValues, { [el.value]: el.filterValue });
        });
      // BOOLEAN
      this.headers
        .filter((el) => {
          if ([HeaderItemFilterType.BOOLEAN].includes(el?.filterType)) {
            return el?.filterValue === true || el?.filterValue === false;
          }
          return false;
        })
        .forEach((el) => {
          Object.assign(filterValues, { [el.value]: el.filterValue });
        });
      // MIN_MAX
      this.headers
        .filter((el) => {
          if (
            [
              HeaderItemFilterType.DATE_MIN_MAX,
              HeaderItemFilterType.NUMBER_MIN_MAX,
              HeaderItemFilterType.PERCENT_MIN_MAX,
            ].includes(el?.filterType)
          ) {
            return !!el?.filterValue?.min || !!el?.filterValue?.max;
          }
          return false;
        })
        .forEach((el) => {
          if (el?.filterValue?.min) {
            let min = el.filterValue.min;
            if (el.filterType === HeaderItemFilterType.PERCENT_MIN_MAX) {
              min /= 100;
            }
            Object.assign(filterValues, {
              [`${el.value}Min`]: min,
            });
          }
          if (el?.filterValue?.max) {
            let max = el.filterValue.max;
            if (el.filterType === HeaderItemFilterType.PERCENT_MIN_MAX) {
              max /= 100;
            }
            Object.assign(filterValues, {
              [`${el.value}Max`]: max,
            });
          }
        });
      if (resetPage) {
        this.$set(this.options, 'page', 1);
      }
      const { itemsPerPage = 10, page = 1 } = this.options;
      return {
        ...(itemsPerPage > 0
          ? { limit: itemsPerPage, offset: itemsPerPage * (page - 1) }
          : {}),
        ...(orderBy.length > 0 ? { orderBy } : {}),
        ...(orderDesc.length > 0 ? { orderDesc } : {}),
        ...(this.search ? { search: this.search } : {}),
        ...filterValues,
      };
    },
    async listaCatalisadoresPecasAnalises(resetPage: boolean) {
      this.loading.catalisadoresPecasAnalises = true;
      try {
        const listarCatalisadoresPecasQuery = this.getQueryObject(resetPage);
        const { count, rows } =
          await requester.catalisadoresPecas.listaAnalises(
            listarCatalisadoresPecasQuery,
          );
        this.serverItemsLength = count;
        this.catalisadoresPecasAnalises = rows;
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.catalisadoresPecasAnalises = false;
      }
    },
  },
});
