






















































import Vue from 'vue';
import { debounce, deburr, orderBy, toLower } from 'lodash';

import requester from '@/requester';

import { Rules as CaslRules } from '../../../../back/src/casl/casl.constants';
import { UsuarioScopesEnum } from '../../../../back/src/usuarios/usuarios.constants';
import { CategoriaPedidoItemEnum } from '../../../../back/src/pedidos/pedidos.constants';

import InputNumber from '../inputs/InputNumber.vue';

export default Vue.extend({
  name: 'CatalisadoresPecasSelectUsuario',
  components: {
    InputNumber,
  },
  props: {
    value: {
      type: Object || null,
      default: null,
    },
  },
  data: () => ({
    loading: {
      usuarios: false,
    },
    usuarioSelecionado: null,
    margemUsuarioInput: 0,
    usuarios: [],
  }),
  computed: {
    permissaoListarUsuarios() {
      const rule = CaslRules.Usuario.listar;
      return this.$ability.can(rule?.action, rule?.subject);
    },
    isPreposto() {
      if (!this.usuarioSelecionado) return false;
      return this.usuarioSelecionado?.isPreposto;
    },
    margemResponsavel() {
      if (!this.usuarioSelecionado) return null;
      return (
        this.usuarioSelecionado?.responsavel?.margens?.find(
          (el) => el.categoria === CategoriaPedidoItemEnum.CATALISADORES_PECAS,
        )?.margem || null
      );
    },
    margemUsuario() {
      // Se houver um usuário selecionado, devolve simplesmente a margem dele (se for preposto, nao devolve a composta)
      // Se não houver usuário selecionado, devolve o que foi inserido no textfield.
      if (!this.usuarioSelecionado) {
        return Number(this.margemUsuarioInput) / 100 || 0;
      }

      return Number(this.margemUsuarioSelecionado) || 0;
    },
    margemUsuarioSelecionadoPrepostoOuNao() {
      // Se usuario for preposto, devolve a margem composta.
      // Se usuario for parceiro, devolve simplesmente a margem dele.
      if (!this.usuarioSelecionado) return 0;

      const usuarioMargem = this.margemUsuarioSelecionado;
      if (!usuarioMargem) return null;
      return this.usuarioSelecionado.isPreposto
        ? this.margemComposta
        : usuarioMargem;
    },
    margemUsuarioSelecionado() {
      // Devolve simplesmente a margem do usuario selecionado. Não calucula a margem composta.
      if (!this.usuarioSelecionado) return 0;
      return (
        this.usuarioSelecionado?.margens?.find(
          (el) => el.categoria === CategoriaPedidoItemEnum.CATALISADORES_PECAS,
        )?.margem || 0
      );
    },
    margemComposta() {
      // Somente usada quando usuário selecionado for um preposto
      if (!this.usuarioSelecionado) return 0;
      if (!this.usuarioSelecionado.isPreposto) return 0;
      const margemPreposto = this.margemUsuarioSelecionado;
      return 1 - (1 - this.margemResponsavel) * (1 - margemPreposto);
    },
    itemsUsuarios() {
      return orderBy(
        this.usuarios.map((el) => ({ value: el, text: el.nomeCompleto })),
        (el) => deburr(toLower(el.text)),
      );
    },
  },
  mounted() {
    this.margemUsuarioInput = this.value?.margem || 0;
    this.listaUsuarios();
  },
  methods: {
    emitInput() {
      const usuarioInfo = {
        margemResponsavel: this.margemResponsavel,
        margemComposta: this.margemComposta, // Só faz sentido quando for preposto
        margemUsuario: this.margemUsuario, // Margem crua do usuário, nunca a composta
        isPreposto: this.isPreposto,
      };
      this.$emit('input', usuarioInfo);
    },
    changeUsuario() {
      this.emitInput();
    },
    inputMargemUsuarioDebounce: debounce(function () {
      this.emitInput();
    }, 500),
    async listaUsuarios() {
      if (!this.permissaoListarUsuarios) return;
      this.loading.usuarios = true;
      try {
        const queryObject = {
          scope: UsuarioScopesEnum.ParceirosOuPrepostoComResponsavelMargens,
        };
        const todosUsuarios = await requester.usuarios.lista(queryObject);
        this.usuarios = todosUsuarios.filter((usuario) =>
          usuario.margens.find(
            (margem) =>
              margem.categoria === CategoriaPedidoItemEnum.CATALISADORES_PECAS,
          ),
        );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.usuarios = false;
      }
    },
  },
});
