













































































































































































































































































import Vue from 'vue';
import { debounce, deburr, orderBy, toUpper } from 'lodash';

import requester from '@/requester';
import { maskCurrency } from '@/utils/intl-functions';
import {
  rulesNumeric,
  rulesNumericPositive,
  rulesRequired,
} from '@/utils/input-rules';

import { AvisoCatalisadorPecaEnum } from '../../../../back/src/catalisadores-pecas/catalisadores-pecas.constants';

import CatalisadoresPecasDialogAnalise from './CatalisadoresPecasDialogAnalise.vue';
import CatalisadoresPecasFormFotos from './CatalisadoresPecasFormFotos.vue';
import CustomCancelarSalvar from '../custom/CustomCancelarSalvar.vue';

import { CreateCatalisadorPecaDto } from '../../../../back/src/catalisadores-pecas/dto/create-catalisador-peca.dto';
import { UpdateCatalisadorPecaDto } from '../../../../back/src/catalisadores-pecas/dto/update-catalisador-peca.dto';
import InputNumber from '@/components/inputs/InputNumber.vue';

export default Vue.extend({
  name: 'CatalisadoresPecasFormCriarEditar',
  components: {
    CatalisadoresPecasDialogAnalise,
    CatalisadoresPecasFormFotos,
    CustomCancelarSalvar,
    InputNumber,
  },
  props: {
    catalisadorPeca: {
      type: Object || null,
      default: null,
    },
    fotosArquivos: {
      type: Array,
      default: () => [],
    },
    montadoras: {
      type: Array,
      default: () => [],
    },
    familias: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    loading: {
      submitForm: false,
    },
    dynamicKey: 0,
    tabFoto: false,
    dialogPreviewFoto: false,
    dialogAdicionarAnalise: false,
    criarMontadora: false,
    removerArquivo: false,
    errorAnalises: false,
    form: {
      codigo: null,
      fotosArquivos: [],
      detalhe: null,
      margemPeca: '',
      montadoraId: null,
      montadoraNome: null,
      modelo: null,
      familiaId: null,
      aviso: null,
      observacoes: null,
      ativo: true,
      analises: [],
    },
    rules: {
      required: rulesRequired,
      numeric: rulesNumeric,
      numericPositive: rulesNumericPositive,
    },
  }),
  computed: {
    analisesAtivas() {
      return this.form.analises.filter((el) => el.ativo && !el.remover);
    },
    analiseMedia() {
      const analiseMedia = { ptPpm: 0, pdPpm: 0, rhPpm: 0, pesoKg: 0 };
      if (this.analisesAtivas.length === 0) return analiseMedia;
      analiseMedia.ptPpm =
        this.analisesAtivas.reduce((acc, el) => acc + el.ptPpm, 0) /
        this.analisesAtivas.length;
      analiseMedia.pdPpm =
        this.analisesAtivas.reduce((acc, el) => acc + el.pdPpm, 0) /
        this.analisesAtivas.length;
      analiseMedia.rhPpm =
        this.analisesAtivas.reduce((acc, el) => acc + el.rhPpm, 0) /
        this.analisesAtivas.length;
      analiseMedia.pesoKg =
        this.analisesAtivas.reduce((acc, el) => acc + el.pesoKg, 0) /
        this.analisesAtivas.length;
      return analiseMedia;
    },
    headersAnalises() {
      return [
        {
          value: 'ref',
          text: this.$t('comum.ref'),
          sortable: false,
        },
        {
          value: 'dataAnalise',
          text: this.$t('comum.dataAnalise'),
          sortable: false,
        },
        {
          value: 'ptPpm',
          text: this.$t('comum.teorPt'),
          sortable: false,
        },
        {
          value: 'pdPpm',
          text: this.$t('comum.teorPd'),
          sortable: false,
        },
        {
          value: 'rhPpm',
          text: this.$t('comum.teorRh'),
          sortable: false,
        },
        {
          value: 'pesoKg',
          text: this.$t('comum.peso'),
          sortable: false,
        },
        {
          value: 'ativo',
          text: this.$t('comum.ativa'),
          align: 'center',
          width: 1,
          sortable: false,
        },
        {
          value: 'remover',
          text: this.$t('comum.remover'),
          align: 'center',
          width: 1,
          sortable: false,
        },
      ];
    },
    itemsMontadoras() {
      return orderBy(
        this.montadoras.map((el) => ({ value: el.id, text: el.nome })),
        (el) => deburr(toUpper(el.text)),
      );
    },
    itemsFamilias() {
      return orderBy(
        this.familias.map((el) => ({
          value: el.id,
          text: `${el.codigo} - ${el.descricao}`,
        })),
        (el) => deburr(toUpper(el.text)),
      );
    },
    itemsAviso() {
      return Object.values(AvisoCatalisadorPecaEnum).map((el) => ({
        value: el,
        text: this.$t(`AvisoCatalisadorPecaEnum.${el}`),
      }));
    },
  },
  mounted() {
    this.preencheForm();
  },
  methods: {
    maskCurrency,
    preencheForm() {
      if (!this.catalisadorPeca) return;
      this.$set(this.form, 'codigo', this.catalisadorPeca?.codigo || null);
      this.$set(this.form, 'detalhe', this.catalisadorPeca?.detalhe || null);
      this.$set(
        this.form,
        'familiaId',
        this.catalisadorPeca?.familiaId || null,
      );
      this.$set(
        this.form,
        'margemPeca',
        String(this.catalisadorPeca?.margemPeca * 100 || 0),
      );
      this.$set(
        this.form,
        'montadoraId',
        this.catalisadorPeca?.montadoraId || null,
      );
      this.$set(this.form, 'modelo', this.catalisadorPeca?.modelo || null);
      this.$set(this.form, 'aviso', this.catalisadorPeca?.aviso || null);
      this.$set(
        this.form,
        'observacoes',
        this.catalisadorPeca?.observacoes || null,
      );
      this.$set(this.form, 'ativo', this.catalisadorPeca?.ativo ?? true);
      (this.catalisadorPeca?.analises || []).forEach((analise) => {
        this.form.analises.push({
          key: this.dynamicKey++,
          id: analise?.id || null,
          ref: analise?.ref?.trim() || null,
          dataAnalise: analise?.dataAnalise || null,
          ptPpm: analise?.ptPpm || null,
          pdPpm: analise?.pdPpm || null,
          rhPpm: analise?.rhPpm || null,
          pesoKg: analise?.pesoKg || null,
          ativo: typeof analise?.ativo === 'boolean' ? analise?.ativo : null,
          remover: false,
        });
      });
      this.$set(
        this.form,
        'fotosArquivos',
        this.fotosArquivos.map((el, index) => ({
          key: -1 * index,
          file: null,
          fotoArquivo: el,
          remover: false,
        })),
      );
    },
    itemClassAnalise(analise) {
      if (analise?.remover) {
        return 'error lighten-3 text-decoration-line-through text--disabled';
      }
      if (!analise.ativo) {
        return 'error lighten-5 text--secondary';
      }
      return 'success lighten-5 text--primary';
    },
    adicionarAnalise(analise) {
      this.form.analises.push({
        ...analise,
        ativa: true,
        key: this.dynamicKey++,
      });
      this.dialogAdicionarAnalise = false;
    },
    removerAnalise(key: number) {
      const index = this.form.analises.findIndex((el) => el.key === key);
      if (index < 0) return;
      if (!this.form.analises[index]?.id) {
        this.$delete(this.form.analises, index);
      } else {
        this.$set(
          this.form.analises[index],
          'remover',
          !this.form.analises[index].remover,
        );
      }
    },
    cancelar() {
      this.$emit('cancelar');
      this.resetForm();
    },
    resetForm() {
      this.errorAnalises = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    submitFormDebounce: debounce(async function () {
      await this.submitForm();
    }, 200),
    async submitForm() {
      if (!this.$refs.form.validate() || this.analisesAtivas.length === 0) {
        this.errorAnalises = true;
        return;
      }
      this.loading.submitForm = true;
      try {
        const formData = new FormData();
        if (this.form.fotosArquivos.length > 0) {
          this.form.fotosArquivos
            .filter((el) => !!el.file)
            .forEach((el) => {
              formData.append('files', el.file);
            });
        }
        let fotosArquivos = [];
        if (this.catalisadorPeca) {
          fotosArquivos = this.form.fotosArquivos
            .map((el, index) => ({
              arquivoId: el?.fotoArquivo?.id || null,
              ordem: index,
              remover: el.remover,
            }))
            .filter((el) => !!el.arquivoId);
        }

        const formDataDto = {
          codigo: this.form.codigo?.trim() || null,
          detalhe: this.form.detalhe?.trim() || null,
          modelo: this.form.modelo?.trim() || null,
          aviso: this.form.aviso || null,
          observacoes: this.form.observacoes?.trim() || null,
          ativo: this.form.ativo ?? true,
          familiaId: this.form.familiaId,
          margemPeca: Number(this.form.margemPeca) || 0,
          analises: this.form.analises,
          ...(this.catalisadorPeca ? { fotosArquivos } : {}),
        } as CreateCatalisadorPecaDto | UpdateCatalisadorPecaDto;

        if (this.form.montadoraId) {
          formDataDto.montadoraId = this.form.montadoraId;
        } else {
          formDataDto.montadoraNome = this.form.montadoraNome?.trim() || null;
        }

        formData.append('data', JSON.stringify(formDataDto));

        if (this.catalisadorPeca) {
          await requester.catalisadoresPecas.atualiza(
            this.catalisadorPeca.id,
            formData,
          );
          this.$root.$emit(
            'toastSuccess',
            this.$t('CatalisadoresPecasFormCriarEditar.mensagemSucessoAlterar'),
          );
          this.$emit('catalisador-peca-alterada');
        } else {
          await requester.catalisadoresPecas.cria(formData);
          this.$root.$emit(
            'toastSuccess',
            this.$t('CatalisadoresPecasFormCriarEditar.mensagemSucessoCriar'),
          );
          this.$emit('catalisador-peca-criada');
        }
        this.resetForm();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.submitForm = false;
      }
    },
  },
});
