






































































































import Vue from 'vue';
import { maxBy } from 'lodash';

import { getPedidoStatusHtml } from '@/utils/pedidos-helper';

import { Pedido } from '../../../../back/src/models/pedido.model';
import { PedidoLog } from '../../../../back/src/models/pedido-log.model';
import { Rules as CaslRules } from '../../../../back/src/casl/casl.constants';
import { StatusPedidoEnum } from '../../../../back/src/pedidos/pedidos.constants';

import PedidosTabelaItemsPecas from '../pedidos/PedidosTabelaItemsPecas.vue';
import PedidosTabelaItemsPo from '../pedidos/PedidosTabelaItemsPo.vue';
import PedidosTabelaLog from '../pedidos/PedidosTabelaLog.vue';
import PedidosTabelaValores from '../pedidos/PedidosTabelaValores.vue';

export default Vue.extend({
  name: 'PedidosRecebimentoInfo',
  components: {
    PedidosTabelaItemsPo,
    PedidosTabelaLog,
    PedidosTabelaItemsPecas,
    PedidosTabelaValores,
  },
  props: {
    pedido: {
      type: (Object as () => Pedido) || null,
      default: null,
    },
  },
  data: () => ({
    dialogCancelar: false,
  }),
  computed: {
    permissaoGerarPdf() {
      const rule = CaslRules.Pedido.gerarPdf;
      return this.$ability.can(rule?.action, rule?.subject);
    },
    permissaoCancelar() {
      const rule = CaslRules.Pedido.cancelar;
      return (
        this.$ability.can(rule?.action, rule?.subject) &&
        this.pedidoStatusAtual !== StatusPedidoEnum.CANCELADO
      );
    },
    hrefGerarPdfPedido() {
      return `${process.env.VUE_APP_URL_API}pedidos/${this.pedido?.id}/gerar_pdf`;
    },
    itemsPecas() {
      return this.pedido.pedidoItems.filter((el) => !!el?.pedidoItemPeca);
    },
    itemsPo() {
      return this.pedido.pedidoItems.filter((el) => !!el?.pedidoItemPo);
    },
    pedidoLogAtual() {
      return maxBy(this.pedido?.log || [], (el: PedidoLog) => el.createdAt);
    },
    pedidoStatusAtual() {
      return this.pedidoLogAtual?.status;
    },
    lojaIdentificacao() {
      const endereco = this.pedido?.loja?.endereco;
      return endereco
        ? `${endereco?.municipioNome} - ${endereco.bairro}`
        : null;
    },
  },
  methods: {
    getPedidoStatusHtml,
    pedidoCancelado() {
      this.dialogCancelar = false;
      this.$emit('pedido-cancelado');
    },
  },
});
