

































































































































import Vue from 'vue';
import { groupBy, map, orderBy } from 'lodash';

import { PedidoItem } from '../../../../back/src/models/pedido-item.model';

import TextOrNA from '../TextOrNA.vue';
import { StatusPedidoEnum } from '../../../../back/src/pedidos/pedidos.constants';
import { CategoriaUsuarioEnum } from '../../../../back/src/usuarios/usuarios.constants';

interface Item {
  familiaId: number;
  familiaCodigo: string;
  familiaDescricao: string;
  familiaDescricaoCatalogo: string;
  quantidadeNoPedido: number;
  quantidadeRecebida: number;
  margemEfetiva: number;
  margemComposta: number;
  margemResponsavel: number;
  margemUsuario: number;
  pesoSeco: number;
  valorLiquido: number;
  valorMedio: number;
}

export default Vue.extend({
  name: 'PedidosTabelaValores',
  components: { TextOrNA },
  props: {
    items: {
      type: Array as () => PedidoItem[],
      default: [],
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hideValores: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String as () => StatusPedidoEnum,
      required: true,
    },
    categoriaUsuario: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    pesoDetalhado: false,
    CategoriaUsuarioEnum,
  }),
  computed: {
    currency() {
      return process.env.VUE_APP_PAIS_CURRENCY || 'BRL';
    },
    showRecebido() {
      return ![
        StatusPedidoEnum.AGUARDANDO_APROVACAO,
        StatusPedidoEnum.CANCELADO,
      ].includes(this.status);
    },
    thClass() {
      return 'text-no-wrap';
    },
    tdClass() {
      return 'text-no-wrap';
    },
    quantidadeTotal() {
      return this.itemsMapped?.reduce(
        (acc, el) => acc + el?.quantidadeNoPedido || 0,
        0,
      );
    },
    quantidadeTotalRecebida() {
      return this.itemsMapped?.reduce(
        (acc, el) => acc + el?.quantidadeRecebida || 0,
        0,
      );
    },
    pesoTotal() {
      return this.itemsMapped?.reduce((acc, el) => acc + el?.pesoSeco || 0, 0);
    },
    /**
     * Devolve a média das margens por peso.
     */
    porcentageMediaPorPeso() {
      /*
      Tome um pedido com 2 itens, sendo p_i a porcentagem para o item i, P_i o peso do item i e
      PT o peso total (i.e. a soma P1 + P2).
      Então devemos fazer (p1*P1 + p2*P2) / PT para ter a porcentagem média por peso.

      Note que min(p1,p2) <= (p1*P1 + p2*P2) / PT <= max(p1,p2)

      Se n = 1, então, caímos no caso trivial
      min(p1) <= p1*P1 <= max(p1) <=> p1 <= p1 <= p1
                    P1
       */
      return (
        this.itemsMapped.reduce((acc, el) => {
          return acc + el?.pesoSeco * el.margemEfetiva;
        }, 0) / this.pesoTotal
      );
    },
    // troquei por media da margem efetiva
    margemMediaTotal() {
      return (
        1 -
        this.itemsMapped?.reduce(
          (acc, el) => acc + el.valorLiquido * (1 - el.margemEfetiva),
          0,
        ) /
          this.valorTotal
      );
    },
    valorTotal() {
      return this.itemsMapped?.reduce(
        (acc, el) => acc + el?.valorLiquido || 0,
        0,
      );
    },
    itemsMapped(): Item[] {
      const itemsGrouped = groupBy(this.items as PedidoItem[], (pedidoItem) => {
        if (pedidoItem.pedidoItemPeca) {
          return pedidoItem.pedidoItemPeca?.catalisadorPeca?.familiaId;
        }
        if (pedidoItem.pedidoItemPo) {
          return pedidoItem.pedidoItemPo?.familiaId;
        }
        throw new Error(`Item ${pedidoItem} não é peça nem pó.`);
      });
      return orderBy(
        map(itemsGrouped, (itemsFamilia) => {
          const pesoSeco = itemsFamilia.reduce((acc, el) => {
            if (el?.pedidoItemPo) {
              return acc + (el.pedidoItemPo?.pesoSeco || 0);
            } else if (el?.pedidoItemPeca) {
              return (
                acc +
                (el.pedidoItemPeca?.catalisadorPecaDados?.pesoKg || 0) *
                  (el.pedidoItemPeca?.quantidade || 0)
              );
            }
            return acc;
          }, 0);
          const quantidadeNoPedido = itemsFamilia.reduce((acc, el) => {
            if (el?.pedidoItemPo) {
              return acc + 1;
            } else if (el?.pedidoItemPeca) {
              return acc + (el.pedidoItemPeca?.quantidadeInicial || 0);
            }
            return acc;
          }, 0);
          const quantidadeRecebida = itemsFamilia.reduce((acc, el) => {
            if (el?.pedidoItemPo) {
              return acc + 1;
            } else if (el?.pedidoItemPeca) {
              return acc + (el.pedidoItemPeca?.quantidade || 0);
            }
            return acc;
          }, 0);
          const valorLiquido = itemsFamilia.reduce(
            (acc, el) => acc + el?.valor || 0,
            0,
          );
          const valorMedio = pesoSeco > 0 ? valorLiquido / pesoSeco : 0;
          let familia;
          if (itemsFamilia?.[0].pedidoItemPeca) {
            familia = itemsFamilia[0]?.pedidoItemPeca?.catalisadorPeca?.familia;
          } else {
            familia = itemsFamilia[0]?.pedidoItemPo?.familia;
          }
          // margem efetiva
          let valorSemMargem = 0;
          itemsFamilia
            .filter((item) => !!item?.pedidoItemPeca)
            .forEach((item) => {
              const catalisadorPecaDados =
                item.pedidoItemPeca.catalisadorPecaDados;
              valorSemMargem +=
                catalisadorPecaDados.valorTotalKg *
                catalisadorPecaDados.cotacaoDolar *
                catalisadorPecaDados.pesoKg *
                item.pedidoItemPeca.quantidade;
            });
          let margemEfetiva: number;
          if (valorSemMargem === 0) {
            margemEfetiva = itemsFamilia?.[0]?.margemComposta;
          } else {
            margemEfetiva = 1 - valorLiquido / valorSemMargem;
          }
          return {
            familiaId: familia.id,
            familiaCodigo: familia.codigo,
            familiaDescricao: familia.descricao,
            familiaDescricaoCatalogo: familia.descricaoCatalogo,
            quantidadeNoPedido,
            quantidadeRecebida,
            margemEfetiva,
            margemComposta: itemsFamilia?.[0]?.margemComposta,
            margemResponsavel: itemsFamilia?.[0]?.margemResponsavel,
            margemUsuario: itemsFamilia?.[0]?.margemUsuario,
            pesoSeco,
            valorLiquido,
            valorMedio,
          };
        }),
        ['familiaCodigo'],
      );
    },
  },
});
