export const CONVERSION_TROY_OZ_TO_G = 31.1034768;

/**
 * @deprecated Esse enum não deve ser usado. Importe do backend "back/src/usuarios/usuarios.constants"
 */
export enum CategoriaUsuarioEnum {
  FUNCIONARIO = 'FUNCIONARIO',
  PARCEIRO = 'PARCEIRO',
}

export enum CategoriaFamiliaEnum {
  CATALISADORES_PECAS = 'CATALISADORES_PECAS',
  CATALISADORES_PO = 'CATALISADORES_PO',
  PLACAS = 'PLACAS',
}

export enum StatusPedidoEnum {
  CANCELADO = 'CANCELADO',
  AGUARDANDO_APROVACAO = 'AGUARDANDO_APROVACAO',
  APROVADO_COM_ALTERACOES = 'APROVADO_COM_ALTERACOES',
  APROVADO = 'APROVADO',
}

export enum CatalisadorPoScopesEnum {
  ComFamiliaUsuarioCreatedUpdated = 'ComFamiliaUsuarioCreatedUpdated',
}

export enum CotacaoScopesEnum {
  ComUsuarioCreatedUpdated = 'ComUsuarioCreatedUpdated',
}

export enum FamiliaScopesEnum {
  ComUsuarioCreatedUpdated = 'ComUsuarioCreatedUpdated',
}

export enum GrupoScopesEnum {
  ComFuncionalidades = 'ComFuncionalidades',
  ComUsuarioCreatedUpdated = 'ComUsuarioCreatedUpdated',
}

export enum LojaScopesEnum {
  ComUsuarios = 'ComUsuarios',
  ComUsuariosUsuarioCreatedUpdatedMargens = 'ComUsuariosUsuarioCreatedUpdatedMargens',
}

export enum PedidoScopesEnum {
  ComLojaFornecedorUsuarioCreatedByUpdatedBy = 'ComLojaFornecedorUsuarioCreatedByUpdatedBy',
}

export enum PlacaScopesEnum {
  ComFamiliaUsuarioCreatedUpdated = 'ComFamiliaUsuarioCreatedUpdated',
}

export enum UsuarioScopesEnum {
  ComGrupoLojaFornecedorMargensUsuarioCreatedByUpdatedBy = 'ComGrupoLojaFornecedorMargensUsuarioCreatedByUpdatedBy',
  ComGrupoLojas = 'ComGrupoLojas',
  ComGrupo = 'ComGrupo',
  ComGrupoResponsavel = 'ComGrupoResponsavel',
  ComMargens = 'ComMargens',
  ComLojasMargensFornecedorEndereco = 'ComLojasMargensFornecedorEndereco',
  ParceirosComMargens = 'ParceirosComMargens',
}

export enum ArquivoKeyEnum {
  RAW = 'raw',
  WATERMARK = 'watermark',
  THUMBNAIL = 'thumbnail',
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}
