import axios, { AxiosResponse } from 'axios';

import { AprovaMultiplasPedidoItemPecaRecebimento } from '../../../back/src/pedidos-items-pecas/dto/aprova-multiplas-pedido-item-peca-recebimento.dto';
import { CreatePedidoItemPecaRecebimento } from '../../../back/src/pedidos-items-pecas/dto/create-pedido-item-peca-recebimento.dto';
import { UpdatePedidoItemPecaRecebimento } from '../../../back/src/pedidos-items-pecas/dto/update-pedido-item-peca-recebimento.dto';

import { PedidoItemPecaRecebimento } from '../../../back/src/models/pedido-item-peca-recebimento.model';

const alteraPedidoItemPecaRecebimento = async (
  pedidoItemPecaId: number,
  pedidoItemPecaRecebimentoId: number,
  dto: UpdatePedidoItemPecaRecebimento,
): Promise<PedidoItemPecaRecebimento> => {
  const res = await axios.put<
    UpdatePedidoItemPecaRecebimento,
    AxiosResponse<PedidoItemPecaRecebimento>
  >(
    `pedidos_items_pecas/${pedidoItemPecaId}/pedidos_items_pecas_recebimento/${pedidoItemPecaRecebimentoId}`,
    dto,
  );
  return res.data;
};

const aprovaMultiplasPedidoItemPecaRecebimento = async (
  pedidoItemPecaId: number,
  dto: AprovaMultiplasPedidoItemPecaRecebimento,
): Promise<PedidoItemPecaRecebimento[]> => {
  const res = await axios.post<
    AprovaMultiplasPedidoItemPecaRecebimento,
    AxiosResponse<PedidoItemPecaRecebimento[]>
  >(
    `pedidos_items_pecas/${pedidoItemPecaId}/pedidos_items_pecas_recebimento/aprovar_multiplas`,
    dto,
  );
  return res.data;
};

const criaPedidoItemPecaRecebimento = async (
  pedidoItemPecaId: number,
  dto: CreatePedidoItemPecaRecebimento,
): Promise<PedidoItemPecaRecebimento> => {
  const res = await axios.post<
    CreatePedidoItemPecaRecebimento,
    AxiosResponse<PedidoItemPecaRecebimento>
  >(
    `pedidos_items_pecas/${pedidoItemPecaId}/pedidos_items_pecas_recebimento`,
    dto,
  );
  return res.data;
};

const removePedidoItemPecaRecebimento = async (
  pedidoItemPecaId: number,
  pedidoItemPecaRecebimentoId: number,
): Promise<PedidoItemPecaRecebimento> => {
  const res = await axios.delete<
    CreatePedidoItemPecaRecebimento,
    AxiosResponse<PedidoItemPecaRecebimento>
  >(
    `pedidos_items_pecas/${pedidoItemPecaId}/pedidos_items_pecas_recebimento/${pedidoItemPecaRecebimentoId}`,
  );
  return res.data;
};

export default {
  alteraPedidoItemPecaRecebimento,
  aprovaMultiplasPedidoItemPecaRecebimento,
  criaPedidoItemPecaRecebimento,
  removePedidoItemPecaRecebimento,
};
