import { KatalisTranslationRoutes } from '../translations.interface';

const messages: KatalisTranslationRoutes = {
  routes: {
    PainelDeControle: 'Panel de Control',
    // auth
    AuthAtivarConta: 'Activar cuenta',
    AuthEsqueciMinhaSenha: 'Olvidé mi contraseña',
    AuthLogin: 'Iniciar sesión en el sistema',
    AuthRenovarSenha: 'Renovar contraseña',
    // catalisadores-pecas
    CatalisadoresPecasImportar: 'Importar piezas de catalizadores',
    CatalisadoresPecasIndex: 'Catalizadores - Piezas',
    // catalisadores-po
    CatalisadoresPoCalculadora: 'Catalizadores - Polvo',
    CatalisadoresPoIndex: 'Catalizadores - Polvo',
    // cotacoes
    CotacoesIndex: 'Cotizaciones',
    // erro
    ErroNaoEncontrado: 'No encontrado',
    // familias
    FamiliasIndex: 'Familias',
    // funcionalidades
    FuncionalidadesIndex: 'Funcionalidades',
    FuncionalidadesPermissoesAlterar: 'Cambiar Funcionalidad',
    //permissoes
    PermissoesIndex: 'Permisos',
    // grupos
    GruposIndex: 'Grupos',
    GruposPermissoes: 'Permisos',
    GruposVer: 'Grupo',
    // lojas
    LojasCadastro: 'Registro de Tienda',
    LojasEdicao: 'Modificar Tienda',
    LojasIndex: 'Tiendas',
    LojasVer: 'Tienda',
    // pedidos
    PedidosIndex: 'Pedidos',
    PedidosRecebimento: 'Recepción',
    PedidosRecebimentoIndex: 'Recepción',
    // placas
    PlacasIndex: 'Placas Electrónicas',
    // usuarios
    UsuariosCadastroFuncionario: 'Registro de Empleado',
    UsuariosCadastroParceiro: 'Registro de Socio Comercial',
    UsuariosDispositivosIndex: 'Dispositivos',
    UsuariosEdicao: 'Modificar Usuario',
    UsuariosIndex: 'Usuarios',
    UsuariosVer: 'Usuario',
  },
};

export default messages;
