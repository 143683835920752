import { RouteConfig } from 'vue-router';

import LojasCadastro from '@/pages/lojas/LojasCadastro.vue';
import LojasEdicao from '@/pages/lojas/LojasEdicao.vue';
import LojasIndex from '@/pages/lojas/LojasIndex.vue';
import LojasVer from '@/pages/lojas/LojasVer.vue';
import { Rules as CaslRules } from '../../../back/src/casl/casl.constants';

const routes: RouteConfig[] = [
  {
    path: '/lojas/cadastro',
    name: 'LojasCadastro',
    component: LojasCadastro,
    meta: {
      icon: 'mdi-plus',
      rules: [CaslRules.Loja.criar, CaslRules.Usuario.listar],
    },
  },
  {
    path: '/lojas/alterar/:lojaId',
    name: 'LojasEdicao',
    component: LojasEdicao,
    meta: {
      icon: 'mdi-pencil',
      rules: [CaslRules.Loja.atualizar, CaslRules.Usuario.listar],
    },
  },
  {
    path: '/lojas',
    name: 'LojasIndex',
    component: LojasIndex,
    meta: {
      icon: 'mdi-store',
      rules: [CaslRules.Loja.listar],
    },
  },
  {
    path: '/lojas/:lojaId',
    name: 'LojasVer',
    component: LojasVer,
    meta: {
      icon: 'mdi-store',
      rules: [CaslRules.Loja.buscar],
    },
  },
];

export default routes;
