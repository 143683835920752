export const Rules = {
  // AMOSTRA
  Amostra: {
    alterarFotoAmostra: { action: 'alterar_foto_amostra', subject: 'Amostra' },
    adicionarFotoAmostra: {
      action: 'adicionar_foto_amostra',
      subject: 'Amostra',
    },
    buscar: { action: 'buscar', subject: 'Amostra' },
    buscarArquivo: { action: 'buscar_arquivo', subject: 'Amostra' },
    buscarEnsaioPorTipo: {
      action: 'buscar_ensaio_por_tipo',
      subject: 'Amostra',
    },
    criarEnsaio: { action: 'criar_ensaio', subject: 'Amostra' },
    criarLaudo: { action: 'criar_laudo', subject: 'Amostra' },
    listarLaboratorio: {
      action: 'listar_laboratorio',
      subject: 'Amostra',
    },
    listarLaboratorioCatalisadoresAtivas: {
      action: 'listar_laboratorio_catalisadores_ativas',
      subject: 'Amostra',
    },
    listarLaboratorioGeralAtivas: {
      action: 'listar_laboratorio_geral_ativas',
      subject: 'Amostra',
    },
    listarAmostrasFinalizadas: {
      action: 'listar_amostras_finalizadas',
      subject: 'Amostra',
    },
    listarLaboratorioGuarulhos: {
      action: 'listar_laboratorio_guarulhos',
      subject: 'Amostra',
    },
    listarProducaoGuarulhos: {
      action: 'listar_producao_guarulhos',
      subject: 'Amostra',
    },
    listarAcompanhamento: {
      action: 'listar_acompanhamento',
      subject: 'Amostra',
    },
    listarFireAssay: {
      action: 'listar_fire_assay',
      subject: 'Amostra',
    },
    enviarLaboratorio: {
      action: 'enviar_laboratorio',
      subject: 'Amostra',
    },
    receberLaboratorio: {
      action: 'receber_laboratorio',
      subject: 'Amostra',
    },
    receberLaboratorioGuarulhos: {
      action: 'receber_laboratorio_guarulhos',
      subject: 'Amostra',
    },
    remover: { action: 'remover', subject: 'Amostra' },
    removerAnexo: { action: 'remover_anexo', subject: 'Amostra' },
    solicitarAnalise: { action: 'solicitar_analise', subject: 'Amostra' },
    voltarEtapa: { action: 'voltar_etapa', subject: 'Amostra' },
    gerarEtiquetaPdf: { action: 'gerar_etiqueta_pdf', subject: 'Amostra' },
    gerarLaudoPdf: { action: 'gerar_laudo_pdf', subject: 'Amostra' },
    listarBags: { action: 'listar_bags', subject: 'Amostra' },
    finalizarAnalise: { action: 'finalizar_analise', subject: 'Amostra' },
    buscaLaudoAtual: { action: 'busca_laudo_atual', subject: 'Amostra' },
  },
  // AMOSTRA CATALISADOR
  AmostraCatalisador: {
    criar: { action: 'criar', subject: 'AmostraCatalisador' },
    criarPecas: { action: 'criar_pecas', subject: 'AmostraCatalisador' },
    coletarLaboratorio: {
      action: 'coletar_laboratorio',
      subject: 'AmostraCatalisador',
    },
    listarProducao: {
      action: 'listar_producao',
      subject: 'AmostraCatalisador',
    },
    listarAcompanhamento: {
      action: 'listar_acompanhamento',
      subject: 'AmostraCatalisador',
    },
    remover: { action: 'remover', subject: 'AmostraCatalisador' },
  },
  // AMOSTRA COMERCIAL
  AmostraComercial: {
    alterar: { action: 'alterar', subject: 'AmostraComercial' },
    alterarFotoAmostra: {
      action: 'alterar_foto_amostra',
      subject: 'AmostraComercial',
    },
    buscar: { action: 'buscar', subject: 'AmostraComercial' },
    buscarArquivo: { action: 'buscar_arquivo', subject: 'AmostraComercial' },
    criar: { action: 'criar', subject: 'AmostraComercial' },
    enviarLaboratorio: {
      action: 'enviar_laboratorio',
      subject: 'AmostraComercial',
    },
    enviarLaboratorioGuarulhos: {
      action: 'enviar_laboratorio_guarulhos',
      subject: 'AmostraComercial',
    },
    listarAcompanhamento: {
      action: 'listar_acompanhamento',
      subject: 'AmostraComercial',
    },
    remover: { action: 'remover', subject: 'AmostraComercial' },
  },
  // AMOSTRA EXPORTAÇÃO
  AmostraExportacao: {
    alterar: { action: 'alterar', subject: 'AmostraExportacao' },
    buscar: { action: 'buscar', subject: 'AmostraExportacao' },
    criar: { action: 'criar', subject: 'AmostraExportacao' },
    enviarLaboratorio: {
      action: 'enviar_laboratorio',
      subject: 'AmostraExportacao',
    },
    listarAcompanhamento: {
      action: 'listar_acompanhamento',
      subject: 'AmostraExportacao',
    },
    remover: { action: 'remover', subject: 'AmostraExportacao' },
  },
  // AMOSTRA PLACA
  AmostraPlaca: {
    buscar: { action: 'buscar', subject: 'AmostraPlaca' },
    criar: { action: 'criar', subject: 'AmostraPlaca' },
    listarProducao: {
      action: 'listar_producao',
      subject: 'AmostraPlaca',
    },
    listarAcompanhamento: {
      action: 'listar_acompanhamento',
      subject: 'AmostraPlaca',
    },
    solicitarAnalise: {
      action: 'solicitar_analise',
      subject: 'AmostraPlaca',
    },
    remover: { action: 'remover', subject: 'AmostraPlaca' },
  },
  // AMOSTRA UTINGA
  AmostraUtinga: {
    buscar: { action: 'buscar', subject: 'AmostraUtinga' },
    criar: { action: 'criar', subject: 'AmostraUtinga' },
    enviarLaboratorioGuarulhos: {
      action: 'enviar_laboratorio_guarulhos',
      subject: 'AmostraUtinga',
    },
    listarProducao: {
      action: 'listar_producao',
      subject: 'AmostraUtinga',
    },
    listarAcompanhamento: {
      action: 'listar_acompanhamento',
      subject: 'AmostraUtinga',
    },
    remover: { action: 'remover', subject: 'AmostraUtinga' },
  },
  // BAGS
  Bags: {
    buscar: { action: 'buscar', subject: 'Bags' },
    buscarArquivo: { action: 'buscar_arquivo', subject: 'Bags' },
    remover: { action: 'remover', subject: 'Bags' },
  },
  Balancas: {
    listar: { action: 'listar', subject: 'Balancas' },
    buscar: { action: 'buscar', subject: 'Balancas' },
    criar: { action: 'criar', subject: 'Balancas' },
    alterar: { action: 'alterar', subject: 'Balancas' },
    remover: { action: 'remover', subject: 'Balancas' },
  },
  // CATALISADOR PECA
  CatalisadorPeca: {
    atualizar: { action: 'atualizar', subject: 'CatalisadorPeca' },
    atualizarLote: { action: 'atualizar_lote', subject: 'CatalisadorPeca' },
    buscar: { action: 'buscar', subject: 'CatalisadorPeca' },
    buscarAudit: { action: 'buscar_audit', subject: 'CatalisadorPeca' },
    buscarFotosArquivos: {
      action: 'buscar_fotos_arquivos',
      subject: 'CatalisadorPeca',
    },
    criar: { action: 'criar', subject: 'CatalisadorPeca' },
    listar: { action: 'listar', subject: 'CatalisadorPeca' },
    remover: { action: 'remover', subject: 'CatalisadorPeca' },
  },
  // CATALISADOR PO
  CatalisadorPo: {
    atualizar: { action: 'atualizar', subject: 'CatalisadorPo' },
    buscar: { action: 'buscar', subject: 'CatalisadorPo' },
    criar: { action: 'criar', subject: 'CatalisadorPo' },
    remover: { action: 'remover', subject: 'CatalisadorPo' },
  },
  // CATEGORIAS
  Categorias: {
    listar: { action: 'listar', subject: 'Categorias' },
  },
  // COTACAO
  Cotacao: {
    buscar: { action: 'buscar', subject: 'Cotacao' },
    buscarAtual: { action: 'buscar_atual', subject: 'Cotacao' },
    criar: { action: 'criar', subject: 'Cotacao' },
    listar: { action: 'listar', subject: 'Cotacao' },
    remover: { action: 'remover', subject: 'Cotacao' },
  },
  //ENSAIO
  Ensaio: {
    cancelarEnsaio: {
      action: 'cancelar_ensaio',
      subject: 'Ensaio',
    },
    listarEnsaiosPorTipo: {
      action: 'listar_ensaios_por_tipo',
      subject: 'Ensaio',
    },
    registrarResultados: { action: 'registrar_resultados', subject: 'Ensaio' },
    registrarIcpIcpCobre: {
      action: 'registrar_icp_icp_cobre',
      subject: 'Ensaio',
    },
  },
  //ENSAIO ALIQUOTA
  EnsaioAliquota: {
    listarLogResultado: {
      action: 'listar_log_resultado',
      subject: 'EnsaioAliquota',
    },
    listarAliquotasIcpIcpCobre: {
      action: 'listar_aliquotas_icp_icp_cobre',
      subject: 'EnsaioAliquota',
    },
    remover: {
      action: 'remover',
      subject: 'EnsaioAliquota',
    },
  },

  //ENSAIO ALIQUOTA LOG RESULTADO
  EnsaioAliquotaLogResultados: {
    buscarArquivo: {
      action: 'buscar_arquivo',
      subject: 'EnsaioAliquotaLogResultados',
    },
  },
  //ENSAIO MÉTODO
  EnsaioMetodo: {
    listar: {
      action: 'listar',
      subject: 'EnsaioMetodo',
    },
  },
  // FAMILIA
  Familia: {
    atualizar: { action: 'atualizar', subject: 'Familia' },
    buscar: { action: 'buscar', subject: 'Familia' },
    criar: { action: 'criar', subject: 'Familia' },
    listar: { action: 'listar', subject: 'Familia' },
    remover: { action: 'remover', subject: 'Familia' },
    listarCatalisadoresPo: {
      action: 'listar_catalisadores_po',
      subject: 'Familia',
    },
  },
  // FORNECEDOR
  Fornecedor: {
    atualizar: { action: 'atualizar', subject: 'Fornecedor' },
    buscar: { action: 'buscar', subject: 'Fornecedor' },
    criar: { action: 'criar', subject: 'Fornecedor' },
    listar: { action: 'listar', subject: 'Fornecedor' },
    remover: { action: 'remover', subject: 'Fornecedor' },
  },
  // FORNECEDOR SAP
  FornecedorSap: {
    buscar: { action: 'buscar', subject: 'FornecedorSap' },
    criar: { action: 'criar', subject: 'FornecedorSap' },
    listar: { action: 'listar', subject: 'FornecedorSap' },
    listarPedidos: { action: 'listar_pedidos', subject: 'FornecedorSap' },
    remover: { action: 'remover', subject: 'FornecedorSap' },
    atualizar: { action: 'atualizar', subject: 'FornecedorSap' },
  },
  // FUNCIONALIDADE
  Funcionalidade: {
    alterar: { action: 'alterar', subject: 'Funcionalidade' },
    buscar: { action: 'buscar', subject: 'Funcionalidade' },
    criar: { action: 'criar', subject: 'Funcionalidade' },
    listar: { action: 'listar', subject: 'Funcionalidade' },
    remover: { action: 'remover', subject: 'Funcionalidade' },
  },
  // GRUPO
  Grupo: {
    atualizar: { action: 'atualizar', subject: 'Grupo' },
    buscar: { action: 'buscar', subject: 'Grupo' },
    criar: { action: 'criar', subject: 'Grupo' },
    listar: { action: 'listar', subject: 'Grupo' },
    listarUsuarios: { action: 'listar_usuarios', subject: 'Grupo' },
    remover: { action: 'remover', subject: 'Grupo' },
    vincularFuncionalidade: {
      action: 'vincular_funcionalidade',
      subject: 'Grupo',
    },
    desvincularFuncionalidade: {
      action: 'desvincular_funcionalidade',
      subject: 'Grupo',
    },
  },
  // LOJA
  Loja: {
    atualizar: { action: 'atualizar', subject: 'Loja' },
    buscar: { action: 'buscar', subject: 'Loja' },
    buscarFornecedor: { action: 'buscar_fornecedor', subject: 'Loja' },
    buscarPedido: { action: 'buscar_pedido', subject: 'Loja' },
    criar: { action: 'criar', subject: 'Loja' },
    listar: { action: 'listar', subject: 'Loja' },
    listarCatalisadoresPecas: {
      action: 'listar_catalisadores_pecas',
      subject: 'Loja',
    },
    listarFornecedores: { action: 'listar_fornecedores', subject: 'Loja' },
    listarMargens: { action: 'listar_margens', subject: 'Loja' },
    listarPedidos: { action: 'listar_pedidos', subject: 'Loja' },
    remover: { action: 'remover', subject: 'Loja' },
  },
  // MONTADORA
  Montadora: {
    listar: { action: 'listar', subject: 'Montadora' },
  },
  // PEDIDO
  Pedido: {
    atualizar: { action: 'atualizar', subject: 'Pedido' },
    buscar: { action: 'buscar', subject: 'Pedido' },
    cancelar: { action: 'cancelar', subject: 'Pedido' },
    criar: { action: 'criar', subject: 'Pedido' },
    gerarPdf: { action: 'gerar_pdf', subject: 'Pedido' },
    listar: { action: 'listar', subject: 'Pedido' },
    listarRecebimento: { action: 'listar_recebimento', subject: 'Pedido' },
    receber: { action: 'receber', subject: 'Pedido' },
    remover: { action: 'remover', subject: 'Pedido' },
  },
  // PEDIDO ITEM PECA
  PedidoItemPeca: {
    criarPedidoItemPecaRecebimento: {
      action: 'criar_pedido_item_peca_recebimento',
      subject: 'PedidoItemPeca',
    },
    aprovarMultiplasPedidoItemPecaRecebimento: {
      action: 'aprovar_multiplas_pedido_item_peca_recebimento',
      subject: 'PedidoItemPeca',
    },
    atualizarPedidoItemPecaRecebimento: {
      action: 'atualizar_pedido_item_peca_recebimento',
      subject: 'PedidoItemPeca',
    },
    removerPedidoItemPecaRecebimento: {
      action: 'remover_pedido_item_peca_recebimento',
      subject: 'PedidoItemPeca',
    },
  },
  // PEDIDO SAP
  PedidoSap: {
    atualizar: { action: 'atualizar', subject: 'PedidoSap' },
    buscar: { action: 'buscar', subject: 'PedidoSap' },
    criar: { action: 'criar', subject: 'PedidoSap' },
    criarBag: { action: 'criar_bag', subject: 'PedidoSap' },
    gerarLaudoMultiploPdf: {
      action: 'gerar_laudo_multiplo_pdf',
      subject: 'PedidoSap',
    },
    listar: { action: 'listar', subject: 'PedidoSap' },
    listarAmostras: { action: 'listar_amostras', subject: 'PedidoSap' },
    listarBags: { action: 'listar_bags', subject: 'PedidoSap' },
    listarNotaFiscalEntrada: {
      action: 'listar_nota_fiscal_entrada',
      subject: 'PedidoSap',
    },
    listarResumo: { action: 'listar_resumo', subject: 'PedidoSap' },
    registrarPedidoCompra: {
      action: 'registrar_pedido_compra',
      subject: 'PedidoSap',
    },
    remover: { action: 'remover', subject: 'PedidoSap' },
  },
  // PLACA
  Placa: {
    atualizar: { action: 'atualizar', subject: 'Placa' },
    buscar: { action: 'buscar', subject: 'Placa' },
    criar: { action: 'criar', subject: 'Placa' },
    listar: { action: 'listar', subject: 'Placa' },
    remover: { action: 'remover', subject: 'Placa' },
  },
  // USUARIO
  Usuario: {
    atualizar: { action: 'atualizar', subject: 'Usuario' },
    buscar: { action: 'buscar', subject: 'Usuario' },
    criar: { action: 'criar', subject: 'Usuario' },
    listar: { action: 'listar', subject: 'Usuario' },
    remover: { action: 'remover', subject: 'Usuario' },
    convidarAtivarConta: {
      action: 'convidar_ativar_conta',
      subject: 'Usuario',
    },
    listarDispositivos: {
      action: 'listar_dispositivos',
      subject: 'Usuario',
    },
    autorizarDispositivo: {
      action: 'autorizar_dispositivo',
      subject: 'Usuario',
    },
    bloquearDispositivo: {
      action: 'bloquear_dispositivo',
      subject: 'Usuario',
    },
  },
};
