import axios from 'axios';

// IBGE

interface IbgeMunicipio {
  id: number;
  nome: string;
}

interface IbgeUF {
  id: number;
  sigla: string;
  nome: string;
}

interface IbgePais {
  id: {
    M49: number;
    ['ISO-ALPHA-2']: string;
    ['ISO-ALPHA-3']: string;
  };
  nome: string;
}

const urlIbge = 'https://servicodados.ibge.gov.br/api/v1/localidades';

const municipios = async (UF: string): Promise<IbgeMunicipio[]> => {
  const res = await axios.get<IbgeMunicipio[]>(
    `${urlIbge}/estados/${UF}/municipios`,
    {
      params: {
        orderBy: 'nome',
      },
      withCredentials: false,
    },
  );
  return res.data;
};

const ufs = async (): Promise<IbgeUF[]> => {
  const res = await axios.get<IbgeUF[]>(`${urlIbge}/estados`, {
    params: {
      orderBy: 'nome',
    },
    withCredentials: false,
  });
  return res.data;
};

const paises = async (): Promise<IbgePais[]> => {
  const res = await axios.get<IbgePais[]>(`${urlIbge}/paises`, {
    params: {
      orderBy: 'nome',
    },
    withCredentials: false,
  });
  return res.data;
};

// BrasilAPI

interface BrasilApiBank {
  ispb: string;
  name: string;
  code: number;
  fullName: string;
}

interface BrasilApiCepV2 {
  cep: string;
  state: string;
  city: string;
  neighborhood: string;
  street: string;
  service: string;
  location: {
    type: string;
    coordinates: {
      longitude: string;
      latitude: string;
    };
  };
}

interface BrasilApiCnpj {
  cnpj: string;
  identificador_matriz_filial: number;
  descricao_matriz_filial: string;
  razao_social: string;
  nome_fantasia: string;
  situacao_cadastral: number;
  descricao_situacao_cadastral: string;
  data_situacao_cadastral: string;
  motivo_situacao_cadastral: number;
  nome_cidade_exterior: string | null;
  codigo_natureza_juridica: number;
  data_inicio_atividade: string;
  cnae_fiscal: number;
  cnae_fiscal_descricao: string;
  descricao_tipo_logradouro: string;
  logradouro: string;
  numero: string;
  complemento: string;
  bairro: string;
  cep: number;
  uf: string;
  codigo_municipio: number;
  municipio: string;
  ddd_telefone_1: string;
  ddd_telefone_2: string | null;
  ddd_fax: string | null;
  qualificacao_do_responsavel: number;
  capital_social: number;
  porte: number;
  descricao_porte: string;
  opcao_pelo_simples: boolean;
  data_opcao_pelo_simples: string | null;
  data_exclusao_do_simples: string | null;
  opcao_pelo_mei: boolean;
  situacao_especial: string | null;
  data_situacao_especial: string | null;
  cnaes_secundarios: {
    codigo: number;
    descricao: string;
  }[];
  qsa: {
    identificador_de_socio: number;
    nome_socio: string;
    cnpj_cpf_do_socio: string;
    codigo_qualificacao_socio: number;
    percentual_capital_social: number;
    data_entrada_sociedade: string;
    cpf_representante_legal: string | null;
    nome_representante_legal: string | null;
    codigo_qualificacao_representante_legal: string | null;
  }[];
}

const urlBrasilApi = 'https://brasilapi.com.br/api';

const bancos = async (): Promise<BrasilApiBank[]> => {
  const res = await axios.get<BrasilApiBank[]>(`${urlBrasilApi}/banks/v1`, {
    withCredentials: false,
  });
  return res.data;
};

const cep = async (cep: string): Promise<BrasilApiCepV2[]> => {
  const res = await axios.get<BrasilApiCepV2[]>(
    `${urlBrasilApi}/cep/v2/${cep}`,
    {
      withCredentials: false,
    },
  );
  return res.data;
};

const cnpj = async (cnpj: string): Promise<BrasilApiCnpj[]> => {
  const res = await axios.get<BrasilApiCnpj[]>(
    `${urlBrasilApi}/cnpj/v1/${cnpj}`,
    {
      withCredentials: false,
    },
  );
  return res.data;
};

export default {
  ibge: {
    municipios,
    ufs,
    paises,
  },
  brasilApi: {
    bancos,
    cep,
    cnpj,
  },
};
