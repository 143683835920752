


































































































































































import Vue from 'vue';

import requester from '@/requester';
import { get, has } from 'lodash';

export default Vue.extend({
  name: 'CatalisadoresPecasHistorico',
  props: {
    catalisadorPecaId: {
      type: Number || null,
      default: null,
    },
  },
  data: () => ({
    loading: {
      audit: true,
    },
    audit: [],
    showAll: true,
    categories: [
      {
        icon: 'mdi-note',
        label: 'comum.dadosGerais',
        rows: [
          {
            key: 'codigo',
            keyValue: 'codigo',
            label: 'comum.codigo',
            unit: null,
            type: null,
          },
          {
            key: 'detalhe',
            keyValue: 'detalhe',
            label: 'comum.detalhe',
            unit: null,
          },
          {
            key: 'montadoraId',
            keyValue: 'montadora.nome',
            label: 'comum.montadora',
            unit: null,
            type: null,
          },
          {
            key: 'modelo',
            keyValue: 'modelo',
            label: 'comum.modelo',
            unit: null,
            type: null,
          },
          {
            key: 'margemPeca',
            keyValue: 'margemPeca',
            label: 'comum.margemPeca',
            unit: null,
            type: 'percent',
          },
          {
            key: 'observacoes',
            keyValue: 'observacoes',
            label: 'comum.observacoes',
            unit: null,
            type: null,
          },
          {
            key: 'ativo',
            keyValue: 'ativo',
            label: 'comum.ativo',
            unit: null,
            type: 'boolean',
          },
        ],
      },
    ],
  }),
  computed: {
    diffs() {
      return this.audit.map((el, index) => {
        if (index === 0) return {};
        const diff = {};
        Object.keys(el).forEach((key) => {
          if (
            [
              'uuid',
              'updatedAt',
              'familia',
              'montadora',
              'fotoArquivo',
              'usuarioUpdatedBy',
            ].includes(key)
          ) {
            return;
          }
          if (el[key] !== this.audit[index - 1][key]) {
            diff[key] = this.audit[index - 1][key];
            if (key === 'familiaId') {
              diff['familia'] = this.audit[index - 1]['familia'];
            }
            if (key === 'montadoraId') {
              diff['montadora'] = this.audit[index - 1]['montadora'];
            }
            if (key === 'fotoArquivoId') {
              diff['fotoArquivo'] = this.audit[index - 1]['fotoArquivo'];
            }
          }
        });
        return diff;
      });
    },
  },
  watch: {
    catalisadorPecaId() {
      this.buscaAudit();
    },
  },
  mounted() {
    this.buscaAudit();
  },
  methods: {
    get,
    has,
    async buscaAudit() {
      this.loading.audit = true;
      try {
        if (this.catalisadorPecaId) {
          this.audit = await requester.catalisadoresPecas.buscaAudit(
            this.catalisadorPecaId,
          );
        }
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.audit = false;
      }
    },
  },
});
