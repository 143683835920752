






import LojasForm from '@/components/lojas/LojasForm.vue';
import Vue from 'vue';
export default Vue.extend({
  name: 'LojasCadastro',
  components: {
    LojasForm,
  },
});
