






























import Vue from 'vue';

import FamiliasFormCriarAlterar from './FamiliasFormCriarAlterar.vue';

export default Vue.extend({
  name: 'FamiliasDialogCadastro',
  components: {
    FamiliasFormCriarAlterar,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    fechar() {
      this.$refs?.familiasFormCriarAlterar?.cancelar() ||
        this.$emit('input', false);
    },
    familiaCriada(familia) {
      this.$emit('input', false);
      this.$emit('familia-criada', familia);
    },
  },
});
