import axios from 'axios';

const signedUrl = async (arquivoId: number): Promise<any> => {
  const res = await axios.get<any>(`arquivos/${arquivoId}/signed_url`);
  return res.data;
};

export default {
  signedUrl,
};
