import store from '@/store';
import { DateTime } from 'luxon';

export const priceFormat = (
  number: number | string,
  options?: {
    fractionDigits?: number;
    locale?: string;
  },
): string => {
  const locale =
    options?.locale ||
    store.getters.intlDefaultLocale ||
    process.env.VUE_APP_INTL_DEFAULT_LOCALE ||
    'pt-BR';
  const currency = process.env.VUE_APP_PAIS_CURRENCY || 'BRL';
  const fractionDigits =
    options?.fractionDigits || maximumFractionDigitsCurrency(currency);
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(Number(number));
};

export const maskCurrency = (
  value: number | string | null,
  options?: {
    fractionDigits?: number;
    locale?: string;
  },
): string => {
  const number =
    Number(String(value).replace(/\D*/g, '')) /
    10 ** (options?.fractionDigits ?? 2);
  return priceFormat(number, {
    ...options,
    fractionDigits: options?.fractionDigits ?? 2,
  });
};

export const inputPriceToNumber = (
  value: string,
  fractionDigits = 2,
): number | null => {
  if (!value) return null;
  return Number(value.replace(/\D*/g, '')) / 10 ** fractionDigits;
};

export function maximumFractionDigitsCurrency(currency: string): number {
  switch (currency.toUpperCase()) {
    case 'CLP':
    case 'JPY':
      return 0;
    case 'BRL':
    case 'COP':
    default:
      return 2;
  }
}

export function diffDays(dateIsoString: string) {
  const date = DateTime.fromISO(
    DateTime.fromISO(dateIsoString).toLocal().toFormat('yyyy-MM-dd'),
  );
  const now = DateTime.fromISO(DateTime.now().toLocal().toFormat('yyyy-MM-dd'));
  return date.diff(now, ['days']).toObject().days;
}

export function relativeTime(dateIsoString: string) {
  const diff = diffDays(dateIsoString);
  const rtf = new Intl.RelativeTimeFormat('pt-br', {
    style: 'long',
    numeric: 'auto',
  });
  return rtf.format(diff, 'day');
}
