































import { getPedidoStatusHtml } from '@/utils/pedidos-helper';
import Vue from 'vue';

import { PedidoLog } from '../../../../back/src/models/pedido-log.model';
import TextOrNA from '../TextOrNA.vue';

export default Vue.extend({
  components: { TextOrNA },
  name: 'PedidosTabelaLog',
  props: {
    log: {
      type: Array as () => PedidoLog[],
      default: [],
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    pesoDetalhado: false,
  }),
  computed: {
    thClass() {
      return 'text-no-wrap';
    },
    tdClass() {
      return 'text-no-wrap';
    },
  },
  methods: {
    getPedidoStatusHtml,
  },
});
