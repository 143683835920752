import { KatalisTranslation } from '../translations.interface';
import components from './pt-components.translation';
import comum from './pt-comum.translation';
import enums from './pt-enums.translation';
import pages from './pt-pages.translation';
import routes from './pt-routes.translation';
import rules from './pt-rules.translation';

const messages: KatalisTranslation = {
  ...components,
  ...comum,
  ...enums,
  ...pages,
  ...routes,
  ...rules,
};

export default messages;
