































import Vue from 'vue';

import PlacasFormCriarAlterar from './PlacasFormCriarAlterar.vue';

export default Vue.extend({
  name: 'PlacasDialogCadastro',
  components: {
    PlacasFormCriarAlterar,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    familias: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    fechar() {
      this.$refs?.placasFormCriarAlterar?.cancelar() ||
        this.$emit('input', false);
    },
    placaCriada(placa) {
      this.$emit('input', false);
      this.$emit('placa-criada', placa);
    },
  },
});
